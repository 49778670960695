<template>
  <Page class="max-w-[478px]">
    <div class="mb-8">
      <router-link
        :to="{name: integrationsDashboard}"
        class="text-primary flex items-center font-semibold mb-"
      >
        <TbArrowLeftIcon class="w-4 h-4" /> Back
      </router-link>
    </div>
    <img
      src="../assets/avalara_logo.png"
      alt="Avalara Logo"
      class="h-[32px] self-start mb-4"
    >

    <div
      class="flex flex-col"
    >
      <section>
        <div class="font-semibold text-3xl mb-3">
          Connect to Avalara
        </div>
        <p class="mb-3 sm:max-w-[478px] text-sm">
          Seamlessly calculate and collect sales taxes at checkout with automated reporting.
        </p>
        <p class="mb-4 sm:max-w-[478px] text-sm">
          <a
            href="https://identity.avalara.com/Account/Login"
            target="_blank"
            rel="noopener noreferrer"
          >Log in</a> to your Avalara account to get started.
        </p>
        <div class="sm:w-[478px] sm:flex-row flex-col px-3 py-2.5 mb-6 bg-primary rounded-md justify-between items-center inline-flex">
          <div class="sm:w-[295px] flex-col justify-center items-start gap-1 inline-flex text-white text-sm sm:mb-0 mb-3">
            <div class="font-semibold">
              Need help?
            </div>
            <div>
              Watch our step-by-step video tutorial or view our Help Center article
              <a
                href="https://help.elective.com/hc/en-us/articles/27528560777115"
                target="_blank"
                rel="noopener noreferrer"
                class="text-white font-bold"
              >here</a> to find your API key.
            </div>
          </div>
          <button
            class="button text-nowrap video-button self-start sm:self-center"
            @click="showModal = true"
          >
            Watch Video
          </button>
        </div>
        <form
          @submit.prevent="submit"
        >
          <div class="mb-4">
            <TbInput
              v-model="accountNumber"
              label="Account Number"
              type="text"
              :errors="accountNumberError && [accountNumberError]"
            />
          </div>
          <div class="mb-4">
            <TbInput
              v-model="licenseKey"
              label="License Key"
              type="text"
              :errors="licenseKeyError && [licenseKeyError]"
            />
          </div>

          <div class="mb-6">
            <div class="font-semibold text-lg">
              Environment
            </div>
            <div>
              <input
                id="sandbox"
                v-model="environment"
                :class="{'input-error': environmentError}"
                type="radio"
                value="sandbox"
              >
              <label
                for="sandbox"
                class="pl-3 primary"
              >
                Sandbox
              </label>
            </div>
            <div>
              <input
                id="production"
                v-model="environment"
                :class="{'input-error': environmentError}"
                type="radio"
                value="production"
              >
              <label
                for="production"
                class="pl-3 primary"
              >
                Production
              </label>
            </div>
          </div>

          <div
            v-if="validated && companies.length"
            class="mb-6"
          >
            <div
              class="mb-6"
            >
              <TbSelect
                v-model="companyId"
                label="Select a Company"
                :options="companies.slice(1)"
                :default-options="companies[0]"
                :errors="companyIdError && [companyIdError]"
              />
            </div>
            <div>
              <TbSelect
                v-model="documentHandling"
                label="Tax Collection and Reporting Options"
                :options="documentHandlingOptions"
                :default-options="{ name: '', value: '' }"
                :errors="documentHandlingError && [documentHandlingError]"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="button button--primary w-full mb-4"
            >
              {{ buttonCopy }}
            </button>
          </div>
          <div
            v-if="error"
            class="text-center error text-error-900"
          >
            <small class="error">
              {{ error }}
            </small>
          </div>
        </form>
      </section>
    </div>
    <HelpVideo
      :showModal="showModal"
      @close-modal="showModal = false"
    />
  </Page>
</template>

<script setup lang="ts">
import { ref, onActivated, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  object,
  string,
  InferType,
  number,
  boolean,
} from 'yup';
import { useForm, useField } from 'vee-validate';
import {
  TbArrowLeftIcon, TbInput, TbSelect,
} from '@/components/tasty_bistro';
import Page from '@/pages/app/project/dashboard/components/page.vue';
import HelpVideo from './components/help_video.vue';
import { validateSalesTaxIntegration } from './api/validate_integration';
import { submitSalesTaxIntegration } from './api/submit_integration';

const integrationsDashboard = ref<string>('dashboardIntegrations');

const route = useRoute();
const router = useRouter();
const projectId = route.params.projectId as string;

const showModal = ref(false);
const validated = ref(false);
const companies = ref<{ value: number; name: string }[]>([{ value: 0, name: 'Select Company' }]);

const documentHandlingOptions = [
  { value: 'tax_only', name: 'Calculate and Collect Tax Only' },
  { value: 'post', name: 'Calculate/Collect Tax, Report to Avalara' },
  { value: 'post_and_commit', name: 'Calculate/Collect Tax, Commit to Avalara' },
];

const error = ref<string>('');

const credentialsSchema = object({
  accountNumber: string()
    .required('Please provide a valid account number.'),
  licenseKey: string().required('Please provide a valid license key.'),
  environment: string().required('You need to choose an environment.'),
  companyId: number().when('validated', {
    is: true,
    then: number().required('Please choose the company to be used.'),
  }),
  companyCode: string(),
  validated: boolean(),
  documentHandling: string().when('validated', {
    is: true,
    then: string()
      .required('Please select a document handling option.')
      .oneOf(documentHandlingOptions.map((option) => option.value), 'Please select a document handling option.'),
  }),
});

const { handleSubmit, resetForm } = useForm<InferType<typeof credentialsSchema>>({
  validationSchema: credentialsSchema,
});

const { value: accountNumber, errorMessage: accountNumberError } = useField('accountNumber');
const { value: licenseKey, errorMessage: licenseKeyError } = useField('licenseKey');
const { value: environment, errorMessage: environmentError } = useField('environment');
const { value: companyId, errorMessage: companyIdError } = useField('companyId');
const { value: documentHandling, errorMessage: documentHandlingError } = useField('documentHandling');
const { value: companyCode } = useField('companyCode');
const { value: formValdiated } = useField('validated');

const buttonCopy = ref<string>('Validate');
type fnInput = InferType<typeof credentialsSchema> & { projectId: string };

async function submitValidation(values: fnInput): Promise<boolean> {
  error.value = '';
  try {
    const resp = await validateSalesTaxIntegration({
      ...values,
    });
    validated.value = resp.connected;
    formValdiated.value = resp.connected;
    companies.value = resp.companies.map((company) => ({
      value: company.id,
      name: company.companyCode ?? 'DEFAULT',
    }));
    if (resp.connected) {
      buttonCopy.value = 'Submit';
    } else {
      error.value = 'Validation failed. Please check your credentials and try again.';
    }
  } catch (err: any) {
    error.value = err.response?.data?.message ?? 'Something went wrong';
  }
  return false;
}

async function submitIntegration(values: fnInput): Promise<boolean> {
  try {
    if (values.companyId) {
      companyCode.value = companies.value.find((company) => Number(company.value) === Number(values.companyId))?.name;
    }
    await submitSalesTaxIntegration({
      ...values,
      companyId: values.companyId as number,
      companyCode: companyCode.value as string,
      documentHandling: values.documentHandling as string,
    });
    return true;
  } catch (err: any) {
    error.value = err.response?.data?.message ?? 'Something went wrong';
  }
  return false;
}

const submit = handleSubmit(async (values) => {
  const fn = validated.value ? submitIntegration : submitValidation;
  try {
    const goBack = await fn({
      projectId,
      ...values,
    });
    if (goBack) {
      router.push({ name: integrationsDashboard.value });
    }
  } catch (err: any) {
    error.value = err.response?.data?.message ?? 'Something went wrong';
  }
});

function resetState() {
  resetForm();
  error.value = '';
}

onActivated(() => {
  resetState();
});

watch([accountNumber, licenseKey, environment], () => {
  validated.value = false;
  formValdiated.value = false;
  companies.value = [];
  buttonCopy.value = 'Validate';
});
</script>

<style lang="scss" scoped>
.video-button {
  @apply w-[110px] px-3.5 py-2 bg-gray-100 rounded-lg justify-start items-center gap-2 flex text-center text-gray-500 text-sm font-semibold;
}

.error-icon {
  color: hsla(0, 91%, 61%, 1);
}
</style>
