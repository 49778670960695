import http, { Http } from '@/lib/http';

export interface SaveStudentResponse {
  studentId: string;
}

interface SaveStudentParams {
  id: string | null;
  email: string;
  firstName: string;
  lastName: string;
  projectId: string
}

function saveStudentCtor(http: Http) {
  return async ({ projectId, ...payload }: SaveStudentParams): Promise<SaveStudentResponse> => {
    const { data } = await http.post<SaveStudentResponse>(`/projects/${projectId}/students/student`, {
      ...payload,
    });

    return data;
  };
}

export const saveStudent = saveStudentCtor(http);
