<template>
  <div
    v-if="hasCheckoutSalesTaxEnabled"
    class="p-4 bg-white max-w-lg card mb-4"
  >
    <div class="text-primary font-semibold">
      Tax
    </div>
    <div class="text-sm sm:max-w-[80%]">
      Seamlessly calculate and collect sales taxes at checkout with automated reporting.
    </div>
    <div class="flex justify-between items-center mt-4">
      <div class="text-sm font-semibold text-primary">
        <img
          src="./assets/avalara_logo.png"
          alt="Avalara Logo"
          class="w-[76.94px] self-start mb-2"
        >
        Avalara
      </div>
      <div
        v-if="!integrations.length"
        class="self-end"
      >
        <button
          class="text-center button button--primary button--sm"
          @click="router.push({name: 'avalaraForm'})"
        >
          Connect
        </button>
      </div>
    </div>
    <CredentialsTable
      v-if="integrations && integrations.length"
      :integrations="integrations"
      @archive="emit('archive')"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  inject,
  Ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { AppState } from '@/pages/app/api/get_app_state';
import CredentialsTable from '../components/credentials_table.vue';

export interface Integration {
  id: string;
  displayName: string;
  projectId: string;
  integrationSource: string;
  integrationType: string
  state: 'authorized' | 'unauthorized' | 'validating';
  createdAt: string;
}

const emit = defineEmits([
  'archive',
]);

defineProps<{
  integrations: Integration[];
}>();

const route = useRoute();
const router = useRouter();
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = ref<string>(route.params.projectId as string);
const hasCheckoutSalesTaxEnabled = appState.value.projects[projectId.value].features?.checkoutSalesTaxEnabled;
</script>
