<template>
  <slot
    name="action"
    v-bind="{ deleteHandler: initArchive }"
  />

  <TbDialog
    :show="archiveTeamMemberDialog"
    @close="cancelArchive"
  >
    <template #header>
      <h2 class="text-xl font-bold">
        Remove access from {{ user.name ?? user.email }}
      </h2>
    </template>

    <p>
      You're about to remove access to {{ user.name ?? user.email }}.
      Once this is done, you'll have to create a new invite for them to be part of your team.
    </p>

    <template #footer>
      <div class="flex flex-col items-end">
        <div class="flex gap-4 actions">
          <button
            class="button button--sm"
            @click="cancelArchive"
          >
            Cancel
          </button>
          <button
            class="button button--sm button--danger"
            @click="archive"
          >
            Remove Access
          </button>
        </div>

        <small
          v-if="archiveError"
          class="text-error-600 mt-4"
        >
          {{ archiveError }}
        </small>
      </div>
    </template>
  </TbDialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbDialog } from '@/components/tasty_bistro';
import { User } from '../api/get_active_members';
import { removeUser } from '../api/remove_member';
import { cancelInvite } from '../api/cancel_invite';

const props = defineProps<{ user: User}>();
const route = useRoute();

const { projectId } = route.params;
const emit = defineEmits([
  'archive',
]);

const archiveError = ref<String>('');
const archiveTeamMemberDialog = ref<Boolean>(false);

const initArchive = () => {
  archiveTeamMemberDialog.value = true;
};

const cancelArchive = () => {
  archiveTeamMemberDialog.value = false;
  archiveError.value = '';
};

const archive = async () => {
  try {
    archiveError.value = '';
    const archiveFunction = props.user.inviteId ? cancelInvite : removeUser;
    await archiveFunction({ projectId: projectId as string, userId: props.user.userId!, inviteId: props.user.inviteId! });
    archiveTeamMemberDialog.value = false;
    emit('archive');
  } catch (error: any) {
    archiveError.value = error?.response?.data?.message || 'Something went wrong while removing access for user.';
  }
};
</script>
