import http, { Http } from '@/lib/http';
import { PaymentDetails } from '../../types';

export function addPaymentDetailsCtor(http: Http) {
  return async (form: PaymentDetails, projectId: string) => {
    await http.post(`/projects/${projectId}/payment-details`, form);
  };
}

export const addPaymentDetails = addPaymentDetailsCtor(http);
