import http, { Http } from '@/lib/http';

export interface ArchiveInvoiceParams {
  projectId: string;
  checkoutSessionStaticId: string;
}

function archiveInvoiceCtor(http: Http) {
  return async ({ projectId, ...params }: ArchiveInvoiceParams) => {
    await http.delete<Promise<void>>(`/projects/${projectId}/invoices`, { params });
  };
}

export const archiveInvoice = archiveInvoiceCtor(http);
