<template>
  <div class="w-full text-center">
    <TbWordLogo class="inline-flex max-w-[12rem] pb-12" />
    <div class="pb-3 text-3xl font-bold">
      Create Account
    </div>
    <div class="pb-8 text-lg">
      We just need a little information to get you started.
    </div>
    <form @submit.prevent="submit">
      <TbInput
        v-model="firstName"
        label="First Name"
        type="text"
        class="w-full pb-6"
        :errors="firstNameError && [firstNameError]"
      />
      <TbInput
        v-model="lastName"
        label="Last Name"
        type="text"
        class="w-full pb-6"
        :errors="lastNameError && [lastNameError]"
      />
      <TbInput
        :modelValue="password"
        label="Password"
        type="password"
        class="w-full pb-6"
        :hint="!passwordError ? 'Must be at least 7 characters long.' : ''"
        toggleVisibility
        :errors="passwordError && [passwordError]"
        @change="handlePasswordChange"
      />
      <div class="text-left">
        <div class="font-bold pb-2">
          Verify Your Account
        </div>
        <div class="text-sm">
          Please enter the code below.
        </div>
      </div>
      <TbInput
        v-model="confirmationCode"
        label="Verification Code"
        type="text"
        class="w-full"
        :class="{'input-error': confirmationCodeAPIError}"
        :errors="confirmationCodeError && [confirmationCodeError]"
      />
      <div
        v-if="confirmationCodeAPIError"
        class="mt-2 text-left text-sm text-error"
      >
        <TbIcon
          icon="exclamation-circle"
        />
        {{ confirmationCodeAPIError }}
      </div>
      <FormButton
        class="font-bold"
        text="Create Account"
        color="primary"
        :disabled="state === PageState.loading"
      />
      <div
        v-if="state === PageState.error"
        class="my-4 text-center text-error"
        data-test="error-div"
      >
        {{ apiError }}
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';
import { TbInput, TbWordLogo, TbIcon } from '@/components/tasty_bistro';
import { useField, useForm } from 'vee-validate';
import {
  object, string, InferType,
} from 'yup';
import FormButton from '@/components/form_button.vue';
import { Pages } from '@/router';
import { PageState } from '@/types';
import { acceptInviteNewUser } from './api';

const router = useRouter();
const apiError = ref('');
const confirmationCodeAPIError = ref('');
const state = ref<PageState>(PageState.loaded);

const route = useRoute();

const inviteId = route.params.inviteId as string;

const userInfoSchema = object({
  firstName: string().required('Required field'),
  lastName: string().required('Required field'),
  password: string().required('Required field').min(7, 'Must be at least 7 characters long.'),
  confirmationCode: string()
    .matches(/^\d+$/, 'The verification code must contain only numbers.')
    .length(5, 'The verification code must be at least 5 characters long.')
    .required('Required field'),
});

interface UserInfo extends InferType<typeof userInfoSchema> {}
const { handleSubmit } = useForm<UserInfo>({
  validationSchema: userInfoSchema,
  initialValues: {
    firstName: '',
    lastName: '',
    password: '',
    confirmationCode: route.query.confirmationCode as string ?? '',
  },
});

const { value: firstName, errorMessage: firstNameError } = useField('firstName');
const { value: lastName, errorMessage: lastNameError } = useField('lastName');
const { value: confirmationCode, errorMessage: confirmationCodeError } = useField('confirmationCode');
const { value: password, errorMessage: passwordError, handleChange: handlePasswordChange } = useField('password');

const submit = handleSubmit(async (values) => {
  try {
    state.value = PageState.loading;
    await acceptInviteNewUser({ ...values, inviteId });

    router.replace({ name: Pages.inviteSuccess });

    state.value = PageState.loaded;
  } catch (err: any) {
    confirmationCodeAPIError.value = '';
    apiError.value = '';

    state.value = PageState.error;
    const error = err.response?.data;

    if (error?.type) {
      if (error?.message?.toLowerCase().includes('invalid code')) {
        confirmationCodeAPIError.value = error?.message;
      } else if (error?.message?.toLowerCase().includes('invite expired')) {
        router.replace({ name: Pages.inviteExpired });
      } else {
        apiError.value = error?.message;
      }
    } else {
      apiError.value = 'Something went wrong. Please try again later.';
    }
  }
});
</script>
<style scoped lang="scss">
form > div {
  @apply pb-6;
}
</style>
