import http, { Http } from '@/lib/http';

export interface NotificationsSubscriber {
  subscriberName: string | null;
  projectId: string;
  id: string;
  notificationType: 'email' | 'zapier' | 'sms';
  value: string;
  isPrimary: boolean;
  createdAt: Date;
  archivedAt: Date | null;
}

function getSubscribersCtor(http: Http) {
  return async (projectId: string) => {
    const response = await http.get<NotificationsSubscriber[]>(
      `/projects/${projectId}/notifications`,
    );
    return response.data;
  };
}

export const getSubscribers = getSubscribersCtor(http);
