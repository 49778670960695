import Signup from '@/pages/auth/sign_up/index.vue';
import SignIn from '@/pages/auth/sign_in/index.vue';
import RequestPasswordReset from '@/pages/auth/request_password_reset/index.vue';
import PasswordReset from '@/pages/auth/password_reset/index.vue';

export enum Page {
  signUp = 'signUp',
  signIn = 'signIn',
  requestResetPassword = 'requestPasswordReset',
  resetPassword = 'resetPassword',
}

export const authRoutes = [
  {
    path: '/sign-in',
    name: Page.signIn,
    component: SignIn,
  },
  {
    path: '/signup-page',
    name: Page.signUp,
    component: Signup,
  },
  {
    path: '/reset-password',
    name: Page.requestResetPassword,
    component: RequestPasswordReset,
  },
  {
    path: '/reset-password/:token',
    name: Page.resetPassword,
    component: PasswordReset,
  },
];
