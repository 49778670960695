import http, { Http } from '@/lib/http';

export interface Student {
  id: string;
  studentName: string,
  studentEmail: string | null,
  phoneNumber: string | null;
  phoneCountryCode: number | null;
}

function getStudentsCtor(http: Http) {
  return async (projectId: string, search?: string): Promise<Student[]> => {
    const { data } = await http.get<Student[]>(`/projects/${projectId}/invoices/students`, {
      params: { search },
    });

    return data;
  };
}

export const getStudents = getStudentsCtor(http);
