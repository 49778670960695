import http, { Http } from '@/lib/http';
import { CurrencyCode, OrderOrigin } from '@/types';

interface Amount {
  amountInCents: number;
  currency: CurrencyCode;
}

export interface SettlementMetrics {
  totalPaidOut: Amount;
  totalBalance: Amount;
  nextPayout: Amount;
  refundReserve: Amount;
}

interface Filter {
  startDate?: Date;
  endDate?: Date;
  pageSize?: number;
  currentPage?: number;
  origin: OrderOrigin;
  currency?: CurrencyCode;
}

export interface Settlement {
  id: string;
  projectId: string;
  payoutDate: string;
  period: {
    start: string;
    end: string;
  };
  sales: number;
  refunds: number;
  fees: number;
  adjustments: number;
  netSettled: number;
  origin: 'Funding' | 'In-House';
  status: string;
}

export interface SettlementsResponse {
  payouts: Settlement[];
  metrics: SettlementMetrics;
  totalCount: number;
  currencies: CurrencyCode[];
  selectedCurrency: CurrencyCode;
}

interface Data {
  filter: Filter;
  projectId: string;
}

function getSettlementsCtor(http: Http) {
  return async function getSettlements({ projectId, filter }: Data): Promise<SettlementsResponse> {
    const { data } = await http.get(`/projects/${projectId}/settlements`, { params: { ...filter } });

    return data;
  };
}

export const getSettlements = getSettlementsCtor(http);
