<template>
  <div>
    <FormHeader
      heading="Tell us about your business"
      subHeading="Please review your information to ensure it's accurate."
    />
    <div>
      <div>
        <h2 class="text-lg font-semibold">
          Product Types
        </h2>
        <ul>
          <li
            v-for="type in props.data?.productTypes || []"
            :key="type"
          >
            {{ getProductType(type) }}
          </li>
        </ul>
      </div>
      <div class="mt-4">
        <h2 class="text-lg font-semibold">
          Business Details
        </h2>
        <p>
          <span class="font-semibold">Public Name: </span>
          {{ props.data?.publicName }}
        </p>
        <p>
          <span class="font-semibold">Legal Name: </span>
          {{ props.data?.legalName }}
        </p>
        <p>
          <span class="font-semibold">Business Number: </span>
          {{ props.data?.businessNumber }}
        </p>
        <p>
          <span class="font-semibold">Website URL: </span>
          {{ props.data?.websiteUrl }}
        </p>
      </div>
      <div class="mt-4">
        <h2 class="text-lg font-semibold">
          Business Address
        </h2>
        <p>{{ `${props.data?.addressComplement} ${props.data?.addressStreet}` }}</p>
        <p>{{ `${props.data?.addressCity}, ${props.data?.addressState} ${props.data?.addressPostalCode}` }}</p>
      </div>
      <button
        class="w-full mt-4 mb-2 button button--secondary"
        @click="goToNextPage"
      >
        Continue
      </button>
      <button
        class="w-full text-secondary button button--text"
        @click="goToEdit"
      >
        Edit
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import FormHeader from '@/components/form_header.vue';
import { Pages } from '@/router';
import { BusinessDetails } from '../api/get_business_details';
import { productTypes } from '../product_selection/product_types';

interface Props {
  data: BusinessDetails
}

const props = defineProps<Props>();

const router = useRouter();

function goToNextPage() {
  router.push({ name: Pages.paymentDetails });
}

function goToEdit() {
  router.push({ name: Pages.businessDetailsProduct });
}

function getProductType(type: string) {
  const data = productTypes.find((item) => item.value === type);
  return data!.title;
}
</script>
