<template>
  <FormWrapper>
    <FormHeader
      heading="Tell us about the business owner or significant shareholder"
      subHeading="We use this information to verify the business owner’s identity"
    />
    <form
      class="mt-4"
      @submit.prevent="submit"
    >
      <div class="flex gap-2 mb-2">
        <div class="w-[49%]">
          <TbInput
            v-model="firstName"
            label="First Name*"
            type="text"
            :errors="firstNameError && [firstNameError]"
          />
        </div>
        <div class="w-[49%]">
          <TbInput
            v-model="lastName"
            label="Last Name*"
            type="text"
            :errors="lastNameError && [lastNameError]"
          />
        </div>
      </div>
      <div class="flex gap-2 mb-2">
        <div class="w-[49%]">
          <TbPhone
            v-model.trim="phoneNumber"
            label="Phone Number*"
            type="phone"
            :errors="phoneNumberError && [phoneNumberError]"
          />
        </div>
        <div class="w-[49%]">
          <TbInput
            v-model.trim="email"
            label="Email*"
            type="email"
            :errors="emailError && [emailError]"
          />
        </div>
      </div>
      <div class="flex flex-col sm:flex-row sm:gap-2">
        <div class="sm:w-[49%]">
          <TbInput
            v-model="title"
            label="Title*"
            type="text"
            :errors="titleError && [titleError]"
          />
        </div>
        <div class="sm:w-[49%]">
          <TbInput
            v-model.trim="ssnLast4"
            label="SSN/SIN last 4 digits*"
            maxlength="4"
            minlength="4"
            :errors="ssnLast4Error && [ssnLast4Error]"
          />
        </div>
      </div>
      <div class="mt-4">
        <label class="label">Date Of Birth*</label>
        <TbDate
          :date="getInitialDateOfBirth(props.data?.dateOfBirth)"
          @date-input="handleDobInput"
        />
        <div class="text-sm text-error">
          {{ dobError }}
        </div>
      </div>
      <FormButton
        text="Start Verification"
        :disabled="state === PageState.loading"
      />
      <div v-if="error">
        <div class="text-center text-error">
          {{ error }}
        </div>
      </div>
    </form>
    <p class="text-sm font-light text-center">
      Veriff is an identity verification provider that helps us verify your identity.
      Please prepare a valid identity document, make sure you are in a well-lit room,
      and be prepared to take a selfie and photos of your ID.
    </p>
  </FormWrapper>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useForm, useField } from 'vee-validate';
import { object, string, InferType } from 'yup';
import { useRoute, useRouter } from 'vue-router';
import { format, parseISO } from 'date-fns';
import { createVeriffFrame } from '@veriff/incontext-sdk';
import { TbInput, TbDate, TbPhone } from '@/components/tasty_bistro';
import FormWrapper from '@/components/form_wrapper.vue';
import FormHeader from '@/components/form_header.vue';
import FormButton from '@/components/form_button.vue';
import { Pages } from '@/router';
import { PageState } from '@/types';
import { addBusinessOwner, BusinessOwnerResponse, getVerificationUrl } from '../api';

const error = ref('');
const state = ref<PageState>(PageState.loaded);

const props = defineProps<{ data: BusinessOwnerResponse }>();

const route = useRoute();
const router = useRouter();
const projectId = route.params.projectId as string;

const businessOwnerSchema = object({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  email: string().required('Required'),
  phoneNumber: string().required('Required'),
  title: string().required('Required'),
  ssnLast4: string().required('Required').length(4, 'Should be 4 characters long'),
  dob: string().required('Date of Birth is required'),
});

interface BusinessOwner extends InferType<typeof businessOwnerSchema> {}

const { handleSubmit } = useForm<BusinessOwner>({
  validationSchema: businessOwnerSchema,
  initialValues: {
    firstName: props.data?.firstName,
    lastName: props.data?.lastName,
    email: props.data?.email,
    phoneNumber: props.data?.phoneNumber,
    title: props.data?.title,
    ssnLast4: props.data?.ssnLast4,
    dob: props.data?.dateOfBirth,
  },
});

const { value: firstName, errorMessage: firstNameError } = useField('firstName');
const { value: lastName, errorMessage: lastNameError } = useField('lastName');
const { value: email, errorMessage: emailError } = useField('email');
const { value: phoneNumber, errorMessage: phoneNumberError } = useField('phoneNumber');
const { value: title, errorMessage: titleError } = useField('title');
const { value: ssnLast4, errorMessage: ssnLast4Error } = useField('ssnLast4');
const { value: dob, errorMessage: dobError } = useField('dob');

function handleDobInput(dateOfBirth: Date) {
  dob.value = format(dateOfBirth, 'yyyy-MM-dd');
}

function getInitialDateOfBirth(dob: string | undefined) {
  return dob ? parseISO(dob) : undefined;
}

const submit = handleSubmit(async (values) => {
  if (!dob.value) {
    error.value = 'Invalid Date of Birth.' || dobError;
  }
  const phoneCountryCode = '1';
  const rawPhoneNumber = values.phoneNumber.replace(/\D/g, '').slice(-10);
  if (rawPhoneNumber.length !== 10) {
    error.value = 'Invalid phone number';
  }
  values.phoneNumber = rawPhoneNumber;

  try {
    state.value = PageState.loading;
    await addBusinessOwner({ ...values, phoneCountryCode }, projectId);
    state.value = PageState.loaded;

    const url = await getVerificationUrl(projectId);
    createVeriffFrame({
      url,
      onEvent(msg: string) {
        if (msg !== 'STARTED') {
          router.push({ name: Pages.onboardingProgress });
        }
      },
    });
  } catch (err: any) {
    state.value = PageState.loaded;
    error.value = err.response?.data?.message || 'Something went wrong.';
  }
});
</script>
