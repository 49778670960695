<template>
  <div class="flex flex-col h-full">
    <ProductForm
      action="create"
      :fn="create"
      @cancel="$emit('cancel')"
    />
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import ProductForm from '../../components/product_form/index.vue';
import { CreateProduct, createProduct } from '../../api/create_product';

const route = useRoute();
const projectId = route.params.projectId as string;

const emit = defineEmits([
  'submit',
  'cancel',
]);

const create = async (values: CreateProduct) => {
  await createProduct({ projectId, form: values });
  emit('submit');
};

</script>
