import http, { Http } from '@/lib/http';

export interface AgreementDetails {
  agreementLink: string;
  feeScheduleLink: string;
  agreedAt: string;
}
function getAgreementCtor(http: Http) {
  return async function getAgreement(projectId: string): Promise<AgreementDetails> {
    const { data } = await http.get<AgreementDetails>(`/projects/${projectId}/pricing`);
    return data;
  };
}

export const getAgreement = getAgreementCtor(http);
