<template>
  <div
    v-show="selectedForm === AvaliableForms.Invoice"
    class="flex flex-col h-full"
  >
    <h2 class="text-lg font-bold mb-8">
      Create Invoice
    </h2>

    <div class="flex w-full gap-2 mb-6">
      <div
        class="checkout-option"
        :class="{ 'checkout-option--active': checkoutOption === 'chargeCustomer' }"
      >
        <input
          id="chargeCustomerOption"
          v-model="checkoutOption"
          class="mr-3 cursor-pointer checked"
          type="radio"
          value="chargeCustomer"
        >
        <label
          for="chargeCustomerOption"
          class="primary cursor-pointer"
        >
          <TbOutlinedCreditCardIcon class="w-6" />
          <span class="font-semibold">Charge Customer</span>
        </label>
      </div>
      <div
        class="checkout-option"
        :class="{ 'checkout-option--active': checkoutOption === 'oneTimeCheckout' }"
      >
        <div class="mr-3">
          <input
            id="oneTimeCheckoutOption"
            v-model="checkoutOption"
            class="cursor-pointer"
            type="radio"
            value="oneTimeCheckout"
          >
        </div>
        <label
          for="oneTimeCheckoutOption"
          class="primary cursor-pointer"
        >
          <TbLinkIcon class="w-6" />
          <span class="font-semibold sm:whitespace-nowrap">Create One-Time Checkout</span>
        </label>
      </div>
    </div>

    <CreateInvoiceForm
      :form-type="checkoutOption"
      :submit-form="submitCreateInvoice"
      :created-customer-id="createdCustomerId"
      @cancel="cancel"
      @new-customer="navigateTo(AvaliableForms.Customer)"
    />
  </div>

  <CreateStudentForm
    v-if="selectedForm === AvaliableForms.Customer"
    :show-go-back-cta="true"
    @go-back="navigateTo(AvaliableForms.Invoice)"
    @student-saved="submitCreateStudent"
  />
</template>

<script lang="ts" setup>
import { TbLinkIcon, TbOutlinedCreditCardIcon } from '@/components/tasty_bistro';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import CreateStudentForm from '@/pages/app/project/dashboard/v2/components/new_student_form/index.vue';
import CreateInvoiceForm from './create_invoice_form.vue';
import { createInvoice } from '../api/create_invoice';
import { Student } from '../api/get_students';

export type CheckoutOption = 'chargeCustomer' | 'oneTimeCheckout';

export interface SubmitFormPayload {
  checkoutOption: CheckoutOption;
  checkoutStaticId: string;
  checkoutSessionStaticId: string;
  createdInvoiceId: string;
  student: Student;
}

enum AvaliableForms {
  Invoice = 'invoice',
  Customer = 'customer'
}

interface CreateInvoiceFormData {
  selectedCustomer: Student;
  selectedCheckoutId: string;
  selectedPaymentOption: string;
  selectedPaymentPlanId: string;
  isLnplSwitchEnabled: boolean;
  isPayInFullSwitchEnabled: boolean;
  isBillingSwitchEnabled: boolean;
}

const emit = defineEmits([
  'submit',
  'cancel',
]);

const route = useRoute();
const projectId = route.params.projectId as string;

const checkoutOption = ref<CheckoutOption>('chargeCustomer');
const createdCustomerId = ref<string>();

async function cancel() {
  emit('cancel');
}

const selectedForm = ref<AvaliableForms>(AvaliableForms.Invoice);
const navigateTo = (form: AvaliableForms) => {
  selectedForm.value = form;
};

const submitCreateInvoice = async (values: CreateInvoiceFormData) => {
  const { checkoutStaticId, checkoutSessionStaticId, invoiceId } = await createInvoice({
    projectId,
    student: values.selectedCustomer,
    checkoutId: values.selectedCheckoutId,
    ppmPaymentPlanTemplateId: values.selectedPaymentPlanId,
    isChargeCustomer: checkoutOption.value === 'chargeCustomer',
    isLnplDisabled: checkoutOption.value === 'oneTimeCheckout' ? !values.isLnplSwitchEnabled : true,
  });

  emit('submit', {
    checkoutOption: checkoutOption.value,
    checkoutStaticId,
    checkoutSessionStaticId,
    createdInvoiceId: invoiceId,
    student: values.selectedCustomer,
  });
};

const submitCreateStudent = (id: string) => {
  createdCustomerId.value = id;
};

</script>

<style lang="scss" scoped>
  .checkout-option {
    @apply
      flex
      items-center
      p-4
      bg-white
      border-2
      border-gray-200
      rounded-lg
      w-full
  }

  .checkout-option--active {
    @apply border-primary
  }
</style>
