<template>
  <!-- left panel -->
  <div
    :class="[
      'flex flex-row justify-between w-full items-center p-3 border-gray-200 border-solid border-[1px] rounded-md gap-4',
      [props.class],
      props.disabled && 'cursor-not-allowed',
    ]"
  >
    <div class="flex flex-col flex-1">
      <!-- title row -->
      <div class="flex">
        <slot name="title" />
      </div>

      <!-- description row -->
      <div class="whitespace-normal">
        <slot name="description" />
      </div>
    </div>

    <!-- right panel -->
    <div
      id="toggle"
      class="flex flex-col shrink-0"
    >
      <Toggle
        :model-value="props.modelValue"
        :disabled="props.disabled"
        :tooltip-text="props.tooltipText"
        @update:model-value="handleChange"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Toggle from '@/components/toggle.vue';

interface Props {
  modelValue: boolean;
  class?: string;
  disabled?: boolean;
  tooltipText?: string;
}

const props = defineProps<Props>();
const handleInputChange = (event: Event) => (event.target as HTMLInputElement)?.value;
const emit = defineEmits(['update:modelValue']);
const handleChange = (e: Event) => {
  if (props.disabled) return;
  emit('update:modelValue', !props.modelValue);
  handleInputChange(e);
};

</script>
