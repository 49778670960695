import { AxiosInstance } from 'axios';
import http from '@/lib/http';

interface Data {
  productId:string;
  eventName:string;
  projectId:string;
}

function getMockFormCtor(http: AxiosInstance) {
  return async ({ productId, projectId, eventName }: Data): Promise<any> => {
    const { data } = await http.get(`/projects/${projectId}/products/${productId}/form/${eventName}`);

    return data;
  };
}

export const getMockForm = getMockFormCtor(http);
