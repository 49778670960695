import { createRouter, createWebHistory, RouteRecord } from 'vue-router';
import { auth } from '@/lib/auth';
import { authRoutes, Page as AuthPages } from './auth';
import { appRoutes, Page as AppPages } from './app';
import { errorRoutes, Page as ErrorPages } from './error';
import { createPageLookup } from './helpers';
import { inviteRoutes, Page as InvitePages } from './invite';

const Page = {
  ...AuthPages,
  ...AppPages,
  ...ErrorPages,
  ...InvitePages,
};

export const Pages = createPageLookup([...authRoutes, ...appRoutes, ...errorRoutes, ...inviteRoutes]) as typeof Page;

const routes: RouteRecord[] = [
  ...authRoutes,
  ...appRoutes,
  ...errorRoutes,
  ...inviteRoutes,
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, _from, next) => {
  if (to.meta.requireAuth && !auth.isAuthenticated()) {
    next({ name: Pages.signIn });
  } else {
    next();
  }
});

export default router;
