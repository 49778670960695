import http, { Http } from '@/lib/http';

export interface Role {
    id: string;
    name: string;
    display: string;
    authorized: string[];
    unauthorized: string[];
    description: string;
}

function getRolesCtor(http: Http) {
  return async function getRoles(projectId: string) {
    const { data } = await http.get<Role[]>(`/projects/${projectId}/users/roles`);
    return data;
  };
}

export const getRoles = getRolesCtor(http);
