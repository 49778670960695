import http, { Http } from '@/lib/http';

export interface QuizTextSection {
  order: number;
  sectionName: string;
  sidebarSectionName: string;
  content: string[];
}

export interface Quiz {
    quizText: QuizTextSection[];
    questions: {
      [key: string]: {
        question: string;
        answer: boolean;
      };
    };
  }

export interface GetQuizParam {
    agreementId: string;
    projectId: string;
}

function getQuizCtor(http: Http) {
  return async ({ agreementId, projectId }: GetQuizParam) => {
    const { data } = await http.get<Quiz>(`/projects/${projectId}/agreements/${agreementId}/quiz`);
    return data;
  };
}

export const getQuiz = getQuizCtor(http);
