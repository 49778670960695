<template>
  <div
    class="flex flex-col justify-between px-6 py-4 m-auto mb-8 bg-white card sm:flex-row"
    :class="props.disabled ? 'opacity-60' : ''"
  >
    <div class="flex flex-row flex-auto">
      <div
        class="flex items-center justify-center w-10 h-10 mt-2 rounded-full"
        :class="{
          'bg-white': props.status === 'completed' || props.status === 'approved',
          'bg-secondary': props.status == 'pending' || props.status === 'resubmissionRequested'
        }"
      >
        <TbIcon
          v-if="isCompleted()"
          icon="check-circle"
          class="text-4xl text-success"
        />

        <TbIcon
          v-else
          :icon="props.iconName"
          class="p-3 text-xl text-white"
        />
      </div>

      <div class="ml-4 leading-6 sm:ml-8 sm:leading-7">
        <h2 class="text-lg font-semibold">
          {{ props.name }}
        </h2>

        <p class="text-sm">
          {{ props.description }}
        </p>
      </div>
    </div>

    <div class="flex items-center flex-none mt-4 sm:mt-0">
      <button
        v-if="getButtonType() === 'edit'"
        class="w-full button sm:w-auto text-secondary button--text"
        @click="goToPage(props.toPage)"
      >
        Edit
      </button>

      <button
        v-else-if="getButtonType() === 'resubmit'"
        class="w-full button sm:w-auto button--primary"
        @click="goToPage(props.toPage)"
      >
        Resubmit
      </button>

      <button
        v-else-if="getButtonType() === 'complete'"
        class="w-full button sm:w-auto button--primary"
        :disabled="props.disabled"
        @click="goToPage(props.toPage)"
      >
        Complete
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { TbIcon } from '@/components/tasty_bistro';
import { StepStatus } from '../../types';

interface Props {
  name: string;
  description: string;
  iconName: string;
  status: StepStatus;
  disabled: boolean;
  toPage: string;
  disableEdit?: boolean;
}

type ButtonType = 'edit' | 'resubmit' | 'complete' | null;

const props = defineProps<Props>();

const router = useRouter();

function goToPage(page: string) {
  router.push({ name: page });
}

function isCompleted(): boolean {
  return props.status === 'completed' || props.status === 'approved';
}

function getButtonType(): ButtonType {
  if ((props.status === 'completed' || props.status === 'approved') && !props.disableEdit) {
    return 'edit';
  }
  if (props.status === 'resubmissionRequested') {
    return 'resubmit';
  }
  if (props.status === 'pending') {
    return 'complete';
  }
  return null;
}

</script>
