<template>
  <form
    class="mt-4"
    @submit.prevent="submit"
  >
    <TbSelect
      v-model="accountType"
      label="Bank Account Type*"
      class="mb-2"
      :options="accountTypes"
      :errors="accountTypeError && [accountTypeError]"
    />

    <TbSelect
      v-model="currencyCode"
      label="Bank Account Currency*"
      class="mb-2"
      :options="currencyValues"
      :errors="currencyCodeError && [currencyCodeError]"
    />
    <TbInput
      v-model.trim="routingNumber"
      label="Routing Number*"
      class="mb-2"
      type="number"
      :errors="routingNumberError && [routingNumberError]"
      @change="handleRoutingNumberChange"
    />
    <TbInput
      v-model.trim="accountNumber"
      label="Account Number*"
      class="mb-2"
      type="number"
      :errors="accountNumberError && [accountNumberError]"
    />
    <TbInput
      v-model.trim="accountHolderName"
      label="Name of Account Holder*"
      class="mb-2"
      type="text"
      :errors="accountHolderNameError && [accountHolderNameError]"
    />
    <FormButton
      text="Add Payout Method"
      :disabled="props.state === PageState.loading"
    />
    <div v-if="error">
      <div class="text-center text-error">
        {{ props.error }}
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { object, string, InferType } from 'yup';
import { useForm, useField } from 'vee-validate';
import { TbInput, TbSelect } from '@/components/tasty_bistro';
import FormButton from '@/components/form_button.vue';
import { ref, watch } from 'vue';
import { PageState } from '@/types';
import { PaymentDetailsUS } from '../types';

interface Props {
  data: PaymentDetailsUS;
  error: string;
  state: PageState;
  namePlaceholder: string;
}

const props = defineProps<Props>();
const emit = defineEmits(['submit']);

const currencyValues = [
  { name: 'USD - United States Dollar', value: 'USD' },
];

const accountTypes = [
  { name: 'Checking', value: 'checking' },
  { name: 'Savings', value: 'savings' },
];

const paymentDetailsSchema = object({
  currencyCode: string().required('Required'),
  routingNumber: string().required('Required').length(9, 'Must be 9 digits long'),
  accountNumber: string().required('Required'),
  accountHolderName: string().required('Required'),
  accountType: string().required('Required'),
});

interface PaymentDetails extends InferType<typeof paymentDetailsSchema> {}

const formData = ref({
  currencyCode: props.data?.currencyCode || '',
  routingNumber: props.data?.routingNumber || '',
  accountNumber: props.data?.accountNumber || '',
  accountHolderName: props.data?.accountHolderName || props.namePlaceholder || '',
  accountType: props.data?.accountType || 'checking',
});

const { handleSubmit } = useForm<PaymentDetails>({
  validationSchema: paymentDetailsSchema,
  initialValues: formData.value,
});

const { value: currencyCode, errorMessage: currencyCodeError } = useField('currencyCode');
const { value: accountType, errorMessage: accountTypeError } = useField('accountType');
const { value: accountNumber, errorMessage: accountNumberError } = useField('accountNumber');
const { value: accountHolderName, errorMessage: accountHolderNameError } = useField('accountHolderName');
const { value: routingNumber, errorMessage: routingNumberError, handleChange: handleRoutingNumberChange } = useField('routingNumber');

watch(() => props.namePlaceholder, (newValue) => {
  if (!accountHolderName.value) {
    accountHolderName.value = newValue;
  }
});

const submit = handleSubmit(async (values) => {
  emit('submit', { ...values, countryCode: 'US' });
});
</script>
