import http, { Http } from '@/lib/http';

export interface ApplicationFilter {
  search: string;
  dates: Date[];
}

interface Data {
  filter: ApplicationFilter;
  projectId: string;
}

function getApplicationsDownloadCtor(http: Http) {
  return async function get({ projectId, filter }: Data) {
    const { data } = await http.get(`/projects/${projectId}/applications/download`, {
      responseType: 'arraybuffer',
      params: filter,
    });

    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };
}

export const getApplicationsDownload = getApplicationsDownloadCtor(http);
