<template>
  <span
    :class="{
      [getOutlineClass()]: true,
      [getSizeClass()]: true,
      'rounded-full': props.rounded,
      [getColorClass()]: true
    }"
  >
    <p
      :class="{ [getTextColorClass()]: props.textColor }"
    >
      {{ props.text }}
    </p>
  </span>
</template>

<script setup lang="ts">
interface Props {
  text: string
  outline?: boolean
  small?: boolean
  medium?: boolean
  large?: boolean
  rounded?: boolean
  color?: string
  textColor?: string
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary',
  medium: true,
  rounded: true,
  textColor: 'white',
});

const getOutlineClass = () => (props.outline ? `outline outline-1 outline-${props.color}` : '');

const getColorClass = () => (!props.outline ? `bg-${props.color}` : '');

const getTextColorClass = () => (props.outline ? `text-${props.color}` : `text-${props.textColor}`);

const getSizeClass = () => {
  if (props.medium && props.small && props.large) {
    return 'medium';
  }
  if (props.small) {
    return 'small';
  }
  if (props.large) {
    return 'large';
  }

  return 'medium';
};

</script>

<style lang="scss" scoped>
.small {
  font-size: 0.75rem;
  padding: 0.375rem 0.5rem;
}
.medium {
  font-size: 0.875rem;
  padding: 0.5rem 0.625rem;
}
.large {
  font-size: 1rem;
  padding: 0.75rem 1rem;
}

p {
  display: inline;
}
</style>
