<template>
  <CreateStackForm
    action="create"
    :fn="create"
    @cancel="$emit('to-checkout')"
  >
    <template #escape-hatch>
      <button
        style="box-shadow: none;"
        class="self-end button button--sm bg-transparent font-bold hover:bg-gray-100"
        @click="$emit('to-checkout')"
      >
        <span class="flex items-center gap-2">
          <TbArrowLeftIcon
            class="w-4 h-4 text-primary"
          />
          Back
        </span>
      </button>
    </template>
  </CreateStackForm>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { TbArrowLeftIcon } from '@/components/tasty_bistro';
import { Ref, inject } from 'vue';
import CreateStackForm from '../payment_settings_form/index.vue';
import { CreateStack, createStack } from './api/create_payment_settings';

const route = useRoute();
const projectId = route.params.projectId as string;

const emit = defineEmits([
  'to-checkout',
]);

const updatePaymentSetting = inject<(paymentSettingId: string) => void
  >('updatePaymentSetting') as (paymentSettingId: string) => void;

const selectedCurrencyCode = inject<Ref<string>>('selectedCurrencyCode') as Ref<string>;

const create = async (values: CreateStack) => {
  const response = await createStack({ projectId, form: values });
  if (selectedCurrencyCode.value === 'CAD' && values.ppmEnabled) {
    return;
  }

  updatePaymentSetting(response.id);
  emit('to-checkout');
};
</script>
