import { DoughnutController } from 'chart.js';
import { createTypedChart } from 'vue-chartjs';

export class TbDoughtnutController extends DoughnutController {
  draw() {
    super.draw();
  }
}

TbDoughtnutController.id = 'tb-doughnut-chart';
TbDoughtnutController.defaults = DoughnutController.defaults;

const TbDoughnutChart = createTypedChart(
  'tb-doughnut-chart' as 'doughnut',
  TbDoughtnutController,
);

export default TbDoughnutChart;
