export enum StepName {
  signup = 'signup',
  businessDetails = 'businessDetails',
  businessOwner = 'businessOwner',
  pricing = 'pricing',
  businessPaymentDetails = 'businessPaymentDetails',
}

export enum StepStatus {
  pending = 'pending',
  resubmissionRequested = 'resubmissionRequested',
  completed = 'completed',
  approved = 'approved',
}

export interface OnboardingStep {
  status: StepStatus;
  disabled: boolean;
}
