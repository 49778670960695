import { AxiosInstance } from 'axios';
import http from '@/lib/http';

export interface Checkout {
    staticIdentifier: string;
    name: string;
    amountInCents: number;
    currencyCode: string
}

function getCheckoutsCtor(http: AxiosInstance) {
  return async (projectId: string): Promise<Checkout[]> => {
    const { data } = await http.get(`/projects/${projectId}/checkouts/dropdown`);
    return data;
  };
}

export const getCheckouts = getCheckoutsCtor(http);
