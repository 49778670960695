import http, { Http } from '@/lib/http';

export interface CardPaymentMethod {
  cardNumberLastFour: string;
  expires: string;
  type: string;
  issuer: string;
}

export interface BankPaymentMethod {
  routingNumber: string;
  accountNumber: string;
  type: string;
}

export type PaymentMethod = CardPaymentMethod | BankPaymentMethod | undefined;

interface PaymentSchedule {
  current: boolean;
  status: string;
  paymentNumber: number;
  id: string;
  dueDate: string;
  amountInCents: number;
}

type TimelineEventType =
  | 'Payment Started'
  | 'Payment Succeeded'
  | 'Payment Failed'
  | 'Payment Refunded'
  | 'Payment Chargebacked'
  | 'Payment Canceled'
  | 'Payment Scheduled';

interface TimelineEvent {
  eventType: TimelineEventType;
  eventAt: string;
  info?: string | null;
}

export interface CheckoutItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  total: number;
}

export interface PaymentDrilldown {
  installmentId: string;
  status: string;
  studentName: string | undefined;
  address: string;
  country: string;
  orderId: string;
  currency: string;
  lastUpdatedAt: string | null;
  email: string | null;
  amountInCents: number;
  feeInCents: number | null;
  netAmountInCents: number | null;
  paymentDetailsStatus: string;
  paymentMethodType: 'card' | 'bank';
  paymentMethod: PaymentMethod;
  checkoutSummary: {
    items: CheckoutItem[] | null;
    totalAmountInCents: number;
  };
  paymentSchedule: PaymentSchedule[];
  timelineEvents: TimelineEvent[];
  nextRetryDate?: string | null;
}

function getPaymentDrilldownCtor(http: Http) {
  return async (id: string, projectId: string): Promise<PaymentDrilldown> => {
    const { data } = await http.get(`/projects/${projectId}/ppm/payments/${id}`);
    return data;
  };
}

export const getPaymentDrilldown = getPaymentDrilldownCtor(http);
