<template>
  <div v-if="data">
    <div class="font-bold text-xl mb-2">
      Payment
    </div>
    <div class="flex items-center gap-2">
      <div class="text-4xl font-semibold mb-2">
        {{ data.currency + ' ' + currencyInCents(data.amountInCents) }}
      </div>

      <div class="flex items-center mb-2">
        <div
          class="inline-block w-2 h-2 mr-1 rounded-full"
          :class="paymentStatusToColorMap[data.status]"
        />
        <div class="text-lg">
          {{ data.status }}
        </div>
      </div>
    </div>
    <div class="flex items-center gap-2 mb-4">
      <p>{{ data.installmentId }}</p>
      <button
        @click="copyInstallmentId(data?.installmentId);"
      >
        <TbCopyIcon class="w-4 h-4" />
      </button>
    </div>
    <TbNotification
      :show="copyIdNotification"
      title="Success 🎉"
      :message="copyIdNotificationText"
      card-class="text-white bg-success"
      @close="copyIdNotification = false"
    />
    <div class="max-w-md">
      <div
        v-for="info in summaryData"
        :key="info.name"
        class="grid grid-cols-2"
      >
        <div class="text-gray-400 bold">
          {{ info.name }}
        </div>
        <div class="text-primary font-semibold">
          {{ info.value }}
        </div>
      </div>
    </div>
    <div class="my-8">
      <div class="font-bold text-xl mb-2">
        Timeline
      </div>
      <div class="ml-2">
        <Timeline
          :timelineEvents="timelineEvents"
          :finalPointColour="paymentStatusToColorMap[data.status]"
        />
      </div>
    </div>
    <div class="my-8">
      <div class="font-bold text-xl mb-2">
        Payment Details
      </div>
      <div class="max-w-sm">
        <div
          v-for="info in paymentDetailsData"
          :key="info.name"
          class="grid grid-cols-2"
        >
          <div class="text-gray-400 bold">
            {{ info.name }}
          </div>
          <div class="text-primary font-semibold">
            {{ info.value }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="paymentMethodData"
      class="my-8"
    >
      <div class="font-bold text-xl mb-2">
        Payment Method
      </div>
      <div class="flex gap-8">
        <div class="max-w-sm">
          <div
            v-for="info in paymentMethodData"
            :key="info.name"
            class="grid grid-cols-2"
          >
            <div class="text-gray-400 bold">
              {{ info.name }}
            </div>
            <div class="text-primary font-semibold">
              {{ info.value }}
            </div>
          </div>
        </div>
        <div class="max-w-sm">
          <div
            v-for="info in addressData"
            :key="info.name"
            class="grid grid-cols-2"
          >
            <div class="text-gray-400 bold">
              {{ info.name }}
            </div>
            <div class="text-primary font-semibold">
              {{ info.value }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-8">
      <div class="font-bold text-xl mb-2">
        Checkout Summary
      </div>
      <div>
        <table class="w-full border-collapse border-spacing-0 text-gray-400">
          <thead>
            <tr>
              <th class="w-1/2 text-left">
                Product
              </th>
              <th class="w-1/6 text-left">
                Qty
              </th>
              <th class="w-1/6 text-left pr-4">
                Product Price
              </th>
              <th class="w-1/6 text-left">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in data.checkoutSummary.items"
              :key="item.id"
            >
              <td class="text-primary font-semibold">
                {{ item.name }}
              </td>
              <td>{{ item.quantity }}</td>
              <td class="pr-4">
                {{ currencyInCents(item.price) }}
              </td>
              <td>{{ currencyInCents(item.total) }}</td>
            </tr>
            <tr class="text-primary font-semibold">
              <td>Total</td>
              <td />
              <td />
              <td>{{ currencyInCents(data.checkoutSummary.totalAmountInCents) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="my-8">
      <div class="font-bold text-xl mb-2">
        Payment Schedule
      </div>
      <div>
        <table class="w-full border-collapse border-spacing-0 text-gray-400">
          <thead>
            <tr>
              <th class="w-1/6 text-left pr-8">
                Payment Number
              </th>
              <th class="w-1/2 text-left">
                Due Date
              </th>
              <th class="w-1/6 text-left">
                Amount
              </th>
              <th class="w-1/6 text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in data.paymentSchedule"
              :key="item.id"
              :class="{ 'text-primary font-semibold': item.current }"
            >
              <td class="pr-8">
                {{ item.paymentNumber }}
              </td>
              <td>{{ formatDate(item.dueDate, 'MMM dd, yyyy') }}</td>
              <td>{{ currencyInCents(item.amountInCents) }}</td>
              <td>
                <span
                  v-if="item.status === 'Paid'"
                  class="inline-block"
                >
                  <CheckIcon
                    class="text-success w-4 h-4 mr-2"
                  />
                </span>
                <span
                  class="inline-block"
                >
                  {{ item.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex items-center justify-center w-full h-full">
      <TbLoading />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useClipboard } from '@vueuse/core';
import { CheckIcon } from '@heroicons/vue/24/solid';
import { TbCopyIcon, TbNotification, TbLoading } from '@/components/tasty_bistro';
import Timeline from '@/components/timeline.vue';
import { formatDate } from '@/filters/date';
import { currencyInCents } from '@/filters/currency';
import { paymentStatusToColorMap } from '@/helpers/category_color_mapper';
import {
  getPaymentDrilldown, PaymentDrilldown, CardPaymentMethod, BankPaymentMethod,
} from '../api/get_payment_drilldown';

const route = useRoute();

const data = ref<PaymentDrilldown>();
interface Data {
    name: string;
    value: string | null | undefined;
}

const summaryData = ref<Data[]>();
const paymentDetailsData = ref<Data[]>();
const paymentMethodData = ref<Data[]>();
const addressData = ref<Data[]>();
const timelineEvents = ref();

const copyIdNotification = ref(false);
const copyIdNotificationText = ref('');

async function loadPayment() {
  const id = route.params.id as string;
  const projectId = route.params.projectId as string;
  data.value = await getPaymentDrilldown(id, projectId);

  const lastUpdatedAt = data.value.lastUpdatedAt
    ? formatDate(data.value.lastUpdatedAt, 'MMM dd, yyyy HH:mm')
    : 'N/A';

  summaryData.value = [
    { name: 'Last Update', value: lastUpdatedAt },
    { name: 'Customer Name', value: data.value.studentName },
    { name: 'Email Address', value: data.value.email },
    { name: 'Payment Method', value: data.value.paymentMethod?.type || 'N/A' },
  ];

  paymentDetailsData.value = [
    { name: 'Amount', value: currencyInCents(data.value.amountInCents) },
    { name: 'Fee', value: data.value.feeInCents ? currencyInCents(data.value.feeInCents) : '-' },
    { name: 'Net', value: data.value.netAmountInCents ? currencyInCents(data.value.netAmountInCents) : '-' },
    { name: 'Status', value: data.value.paymentDetailsStatus },
  ];

  if (data.value.nextRetryDate) {
    paymentDetailsData.value.push({ name: 'Next Retry Date', value: formatDate(data.value.nextRetryDate, 'MMM dd, yyyy') });
  }

  paymentMethodData.value = data.value.paymentMethod && (data.value.paymentMethodType === 'card' ? [
    { name: 'Card Number', value: `****${(data.value.paymentMethod as CardPaymentMethod).cardNumberLastFour}` },
    { name: 'Expires', value: (data.value.paymentMethod as CardPaymentMethod).expires },
    { name: 'Type', value: (data.value.paymentMethod as CardPaymentMethod).type },
    { name: 'Issuer', value: (data.value.paymentMethod as CardPaymentMethod).issuer },
  ] : [
    { name: 'Routing Number', value: (data.value.paymentMethod as BankPaymentMethod).routingNumber },
    { name: 'Expires', value: `****${(data.value.paymentMethod as BankPaymentMethod).accountNumber}` },
    { name: 'Type', value: (data.value.paymentMethod as CardPaymentMethod).type },
  ]);

  addressData.value = [
    { name: 'Address', value: data.value.address },
    { name: 'Origin', value: data.value.country },
  ];

  timelineEvents.value = data.value.timelineEvents.map((te) => ({
    ...te,
    eventAt: te.eventAt && formatDate(te.eventAt, 'MMM dd, yyyy HH:mm'),
  }));
}
onMounted(async () => {
  await loadPayment();
});

const copyInstallmentId = (id: string | undefined) => {
  if (!id) {
    return;
  }
  copyIdNotification.value = false;

  setTimeout(() => {
    const clipboard = useClipboard({ source: id });
    clipboard.copy();
    copyIdNotificationText.value = 'Installment ID has been successfully copied to your clipboard';
    copyIdNotification.value = true;
  }, 200);
};
</script>

<style lang="scss" scoped>
    table, th, td {
      @apply border-none
    }
    th, td {
      @apply
        border-b-2 border-t-gray-200 border-solid
        py-4 pr-4
    }

    .bold {
      font-weight: 700;
    }
</style>
