import http, { Http } from '@/lib/http';
import { OnboardingStatus } from '@/pages/app/project/types';

interface State {
  status: OnboardingStatus;
}

function getStateCtor(http: Http) {
  return async (projectId: string): Promise<State> => {
    const { data } = await http.get(`/onboarding/v1/${projectId}/state`);
    return data;
  };
}

export const getState = getStateCtor(http);
