<template>
  <div class="flex flex-col h-full p-1">
    <div class="text-lg font-bold pb-2">
      Invite Team Member
    </div>
    <div>
      Set up your team’s user roles and permissions.
    </div>

    <form
      class="my-4"
      @submit.prevent="submit"
    >
      <TbInput
        v-model="email"
        label="Email"
        type="text"
        :errors="emailError && [emailError]"
      />

      <div>
        <div class="text-md font-bold primary pt-6 pb-4">
          Roles
        </div>
        <div
          v-if="roleError"
          class="mb-4 text-left text-sm text-error"
        >
          <TbIcon
            icon="exclamation-circle"
          />
          {{ roleError }}
        </div>
        <div
          v-for="role in roles"
          :key="role.id"
        >
          <input
            :id="role.id"
            v-model="roleId"
            :class="{'input-error': roleError}"
            type="radio"
            :value="role.id"
          >
          <label
            :for="role.id"
            class="pl-3 primary font-bold"
          > {{ role.display }}</label>
          <div class="pb-2">
            {{ role.description }}
          </div>
          <Accordion class="accordion">
            <template #title>
              <p
                class="pr-2"
              >
                Role Details
              </p>
            </template>
            <template #body>
              <div class="flex">
                <div class="w-1/2 pr-4">
                  <TbIcon
                    icon="check-circle"
                    class="pr-2 text-success"
                  /><span class="primary text-sm font-bold">Authorized</span>
                  <ul class="list-disc list-inside">
                    <li
                      v-for="item in role.authorized"
                      :key="item"
                      class="text-sm text-gray-400"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
                <div class="w-1/2">
                  <TbIcon
                    icon="times-circle"
                    class="pr-2 text-error"
                  /><span class="primary text-sm font-bold">Unauthorized</span>
                  <ul class="list-disc list-inside">
                    <li
                      v-for="item in role.unauthorized"
                      :key="item"
                      class="text-sm text-gray-400"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </Accordion>
        </div>
      </div>
      <div
        v-if="error"
        class="text-center error text-error-900"
      >
        <small class="error">
          {{ error }}
        </small>
      </div>
      <div class="mt-4 action">
        <button
          class="button button--primary button--block"
          type="submit"
        >
          Send Invite
        </button>

        <button
          class="mt-4 button button--text button--block"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { object, string, InferType } from 'yup';
import { useForm, useField } from 'vee-validate';
import { TbInput, TbIcon } from '@/components/tasty_bistro';
import Accordion from '@/components/accordion.vue';
import { sendInvite } from '../api/invite_member';
import { getRoles, Role } from '../api/get_roles';

const emit = defineEmits(['submit', 'cancel']);
const route = useRoute();
const { projectId } = route.params;
const roles = ref<Role[]>([]);

const inviteUserSchema = object({
  email: string().email().required('Email is required'),
  roleId: string().uuid().required('You need to choose a role'),
});

const { handleSubmit } = useForm<InferType<typeof inviteUserSchema>>({
  validationSchema: inviteUserSchema,
});

const { value: email, errorMessage: emailError } = useField('email');
const { value: roleId, errorMessage: roleError } = useField('roleId');

const error = ref<string>('');
const submit = handleSubmit(async (values) => {
  try {
    await sendInvite({ projectId: projectId as string, roleId: values.roleId, email: values.email });
    emit('submit');
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
});

async function loadAvailableRoles() {
  const data = await getRoles(projectId as string);
  roles.value = data;
}

onMounted(async () => {
  loadAvailableRoles();
});
async function cancel() {
  emit('cancel');
}
</script>

<style lang="scss" scoped>
.accent-icon {
  color: hsla(97, 46%, 59%, 1);
}

.error-icon {
  color: hsla(0, 91%, 61%, 1);
}

.accordion {
  @apply pb-6;
}
.accordion :deep #accordion-control {
  @apply text-gray-400 text-sm justify-start;
}

</style>
