<template>
  <div
    v-show="innerShow"
    ref="bannerRef"
    :class="['w-full grow-0 shrink-0 relative h-auto ease-in-out duration-300 py-4 px-5', props.class]"
    :style="dynamicBannerStyle"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

interface Props {
    show: boolean;
    class?: string;
}

const props = defineProps<Props>();
const bannerRef = ref<HTMLDivElement | null>(null);
const innerShow = ref(props.show);
const dynamicBannerStyle = ref('');

watch(() => props.show, (newVal) => {
  if (newVal) {
    innerShow.value = newVal;
  } else {
    if (!bannerRef.value) return;
    // graceful animation of shrinking
    dynamicBannerStyle.value = `
        opacity: 0;
        margin-top: -${bannerRef.value.clientHeight}px;
        `;
    setTimeout(() => {
      innerShow.value = newVal;
    }, 400);
  }
});

</script>
