<template>
  <router-view
    v-slot="{ Component }"
  >
    <div class="flex flex-col items-center">
      <div class="absolute flex w-full h-full overflow-auto">
        <div class="invite-container">
          <component
            :is="Component"
            :data="inviteInfo"
          />
        </div>
      </div>
    </div>
  </router-view>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import { getInviteInfo } from './api';

const route = useRoute();
const router = useRouter();
const inviteId = route.params.inviteId as string;
const inviteInfo = ref();

onBeforeMount(async () => {
  try {
    const info = await getInviteInfo(inviteId);
    inviteInfo.value = info;
    if (!info) {
      router.replace({ name: Pages.notFound });
    } else if (info.expired) {
      router.replace({ name: Pages.inviteExpired });
    } else {
      router.replace({ name: Pages.inviteWelcome, query: route.query });
    }
  } catch (err: any) {
    const error = err.response?.data;
    if (error?.type) {
      router.replace({ name: Pages.notFound });
    } else {
      router.replace({ name: Pages.crash });
    }
  }
});
</script>

<style lang="scss" scoped>
.invite-container {
  @apply
   flex
   justify-center
   p-10
   m-auto
   shadow-gray-200
   shadow-lg
   rounded-[1.75rem]
   border-solid
   border-[1px]
   w-[400px]
   sm:w-[680px];
}
</style>
