import http, { Http } from '@/lib/http';
import { ApplicationDetailsType } from '@/pages/app/project/types';
import { CurrencyCode } from '@/types';

export interface Application {
  id: string;
  createdAt: Date;
  expiredAt: Date;
  origin: string;
  applicationResult: string;
  productName: string;
  productPriceInCents: number;
  currencyCode: CurrencyCode;
  loanOrderId: string | null;
  studentName: string;
  studentEmail: string;
  studentCreditCheckStatus: string | null;
  amountInCents: number;
  formattedOrigin: string;
  details: {
    type: ApplicationDetailsType;
    description?: string;
  }
}

export interface ApplicationFilter {
  search: string;
  pageSize: number;
  currentPage: number;
  dates: Date[];
}

interface Data {
  filter: ApplicationFilter;
  projectId: string;
}

function getApplicationsCtor(http: Http) {
  return async function getApplications({ projectId, filter }: Data): Promise<
  { applications: Application[], totalCount: number }> {
    const { data } = await http.get(`/projects/${projectId}/applications`, {
      params: filter,
    });

    return data;
  };
}

export const getApplications = getApplicationsCtor(http);
