<template>
  <Modal
    :showModal="showModal"
    @close-modal="closeModal"
  >
    <div class="w-[70%]">
      <Video
        :videoUrl="videoUrl"
        class="mb-4 w-full"
      />
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/modal.vue';
import Video from '@/components/video.vue';

defineProps<{showModal: boolean}>();

const videoUrl = 'https://www.loom.com/embed/c513ac3c7d8c4d89a46213a3064abe6a';

const emit = defineEmits(['update:showModal']);

const closeModal = () => {
  emit('update:showModal', false);
};
</script>

<style lang="scss" scoped>

</style>
