<template>
  <div class="h-full flex flex-col gap-2">
    <div
      v-if="props.showGoBackCta"
      class="flex mb-2"
    >
      <button
        style="box-shadow: none;"
        class="self-end button button--sm bg-transparent font-bold hover:bg-gray-100"
        @click="$emit('go-back')"
      >
        <span class="flex items-center gap-2">
          <TbArrowLeftIcon
            class="w-4 h-4 text-primary"
          />
          Back
        </span>
      </button>
    </div>
    <h2 class="text-lg font-bold">
      New Customer
    </h2>

    <form
      class="flex flex-grow flex-col w-full h-full"
      @submit.prevent="submit"
    >
      <div class="flex-grow w-full">
        <div class="flex gap-2 mb-4 items-end">
          <div class="flex-grow">
            <TbInput
              v-model="email"
              :disabled="isEmailInputted"
              label="Email"
              :errors="emailError && [emailError]"
            />
          </div>
          <button
            v-if="isEmailInputted"
            class="button button--sm bg-gray-100"
            type="button"
            @click="editEmail"
          >
            Edit
          </button>
        </div>

        <div v-if="isEmailInputted && !isCustomerAlreadyExists">
          <TbInput
            v-model="firstName"
            label="First Name"
            :errors="firstNameError && [firstNameError]"
          />

          <TbInput
            v-model="lastName"
            class="mt-4"
            label="Last Name"
            :errors="lastNameError && [lastNameError]"
          />
        </div>
        <Note
          v-else-if="isEmailInputted && isCustomerAlreadyExists"
          class="px-4"
          variant="info"
        >
          <p class="font-semibold mb-1">
            Customer Already Exists
          </p>
          <p class="max-w-[90%]">
            A customer with this email already exists. We've grabbed
            <span class="font-semibold">{{ capitalize(`${firstName}`) }} {{ capitalize(`${lastName}`) }}</span>'s profile for you.
            Please click the button below if you'd like to proceed.
          </p>
        </Note>

        <div
          v-if="error"
          class="text-error-800 text-sm py-3"
        >
          {{ error }}
        </div>
      </div>

      <div class="mt-4 action flex flex-col">
        <button
          v-if="!isEmailInputted"
          class="button button--primary button--block"
          type="submit"
          @click="findStudent"
        >
          Next
        </button>
        <button
          v-else
          class="button button--primary button--block"
          :disabled="formState === FormState.submitting"
          type="submit"
        >
          {{ isCustomerAlreadyExists ? 'Select Existing Customer' : 'Save Customer' }}
        </button>

        <button
          class="button mt-2 button--text button--block"
          :disabled="formState === FormState.submitting"
          @click="$emit('go-back')"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import {
  TbInput, TbArrowLeftIcon,
} from '@/components/tasty_bistro';
import { useField, useForm } from 'vee-validate';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { InferType, object, string } from 'yup';
import { capitalize } from 'lodash';
import { findStudentByEmail } from '@/pages/app/project/dashboard/v2/components/new_student_form/api/find_student';
import { FormState } from '@/types';
import Note from '@/components/note.vue';
import { saveStudent } from './api/save_student';

interface Props {
  showGoBackCta?: boolean;
}

const route = useRoute();
const projectId = route.params.projectId as string;
const props = defineProps<Props>();

const emit = defineEmits([
  'go-back',
  'student-saved',
]);

const isEmailInputted = ref(false);
const isCustomerAlreadyExists = ref(false);

const formState = ref<FormState>(FormState.ready);
const error = ref('');

const createStudentSchema = object({
  email: string().email().required('Required'),
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  studentId: string().uuid().nullable(),
});

interface CreateStudent extends InferType<typeof createStudentSchema> {}

const { handleSubmit } = useForm<CreateStudent>({
  validationSchema: createStudentSchema,
  initialValues: {
    email: '',
    firstName: '',
    lastName: '',
    studentId: null,
  },
});

const { value: email, errorMessage: emailError, validate: validateEmail } = useField<string>('email', {}, {
  validateOnValueUpdate: false,
});
const { value: firstName, errorMessage: firstNameError } = useField<string>('firstName');
const { value: lastName, errorMessage: lastNameError } = useField<string>('lastName');
const { value: studentId } = useField<string | null>('studentId');

const editEmail = () => {
  isEmailInputted.value = false;
};

const findStudent = async () => {
  try {
    error.value = '';
    formState.value = FormState.submitting;

    const emailValidationResult = await validateEmail();
    if (!emailValidationResult.valid) return;

    const studentResponse = await findStudentByEmail(email.value, projectId);
    if (studentResponse) {
      firstName.value = studentResponse.firstName || '';
      lastName.value = studentResponse.lastName || '';
      studentId.value = studentResponse.id;
      isCustomerAlreadyExists.value = true;
    } else {
      firstName.value = '';
      lastName.value = '';
      studentId.value = null;
      isCustomerAlreadyExists.value = false;
    }
    isEmailInputted.value = true;

    formState.value = FormState.ready;
  } catch (err: any) {
    formState.value = FormState.error;
    error.value = err?.response?.data?.message || 'Something went wrong';
  }
};

const submit = handleSubmit(async () => {
  try {
    error.value = '';
    formState.value = FormState.submitting;

    const resp = await saveStudent({
      id: studentId.value,
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      projectId,
    });

    studentId.value = resp.studentId;

    emit('student-saved', studentId.value);
    emit('go-back');

    formState.value = FormState.ready;
  } catch (err: any) {
    formState.value = FormState.error;
    error.value = err?.response?.data?.message || 'Something went wrong';
  }
});
</script>
