import http, { Http } from '@/lib/http';

export interface CreatePaymentPlanGroupForm {
  name: string;
  termsOfServiceUrl?: string | null;
  paymentPlans?: { installments?: number, active?: boolean, downPaymentPercentage?: number }[];
}

interface Data {
  form: CreatePaymentPlanGroupForm;
  projectId: string;
}
function createPaymentPlanGroupCtor(http: Http) {
  return async function createPaymentPlanGroup({ projectId, form }: Data): Promise<void> {
    await http.post(`/projects/${projectId}/ppm/payment-plan-groups`, form);
  };
}

export const createPaymentPlanGroup = createPaymentPlanGroupCtor(http);
