<template>
  <div class="flex-grow w-full">
    <form
      class="flex flex-grow flex-col h-full"
      @submit.prevent="submit"
    >
      <div class="flex-grow">
        <div class="h-full">
          <section class="mb-6">
            <div class="flex flex-row gap-2 mb-4">
              <Combobox
                label="Customer"
                searchPlaceholder="Start searching for your students"
                :options="customersList"
                @search-input="handleCustomersSearch"
              >
                <template #placeholder>
                  <span v-if="selectedCustomer">
                    <span class="mr-2">{{ `${selectedCustomer.studentName}` }}</span>
                    <span class="text-gray-400">{{ `${selectedCustomer.studentEmail}` }}</span>
                  </span>
                  <span v-else>Select Customer</span>
                </template>
                <template #default="{ item, close }">
                  <li
                    class="py-3 last-of-type:pb-0 flex flex-row items-center gap-2 cursor-pointer"
                    @click="selectCustomer(item.id); close()"
                  >
                    <span class="truncate text-sm">
                      {{ item.studentName }}
                    </span>
                    <span class="text-sm text-gray-400 shrink-0">
                      {{ item.studentEmail }}
                    </span>
                  </li>
                </template>
              </Combobox>

              <button
                class="self-end button button--sm bg-gray-100"
                @click="emit('new-customer')"
              >
                <span class="flex items-center gap-2">
                  <TbPlusIcon class="w-5 h-5 text-primary" />
                  New
                </span>
              </button>
            </div>

            <Combobox
              class="mb-4"
              label="Checkout"
              :searchBy="'name'"
              :options="checkoutsList"
            >
              <template #placeholder>
                {{ selectedCheckout
                  ? `${selectedCheckout.name}`
                  : 'Select Checkout' }}
              </template>
              <template #default="{ item, close }">
                <Tooltip :disabled="isOneTimeCheckout || item.ppmEnabled">
                  <template #trigger="{ assignReference, show, hide }">
                    <li
                      class="py-3 last-of-type:pb-0 flex flex-row items-center gap-2 cursor-pointer"
                      @click="selectCheckout(item.id, !item.ppmEnabled, close)"
                      @mouseenter="show"
                      @mouseleave="hide"
                    >
                      <p
                        :ref="(r: any) => assignReference(r)"
                        class="text-sm w-full flex items-center justify-between"
                        :class="{'text-gray-400': !isOneTimeCheckout && !item.ppmEnabled}"
                      >
                        <span>{{ item.name }}</span>
                        <span class="text-gray-400 pr-2">{{ `${item.currencyCode} ${currencyInCents(item.totalSumInCents, 2)}` }}</span>
                      </p>
                    </li>
                  </template>

                  <template #tooltip>
                    Checkouts with only LNPL enabled can not be used to charge customer. Please select another checkout.
                  </template>
                </Tooltip>
              </template>
            </Combobox>

            <TbInput
              v-if="checkoutConfig?.totalSumInCents"
              v-model="formattedPrice"
              class="mb-4"
              disabled
              label="Price"
            />

            <Select
              v-if="!isOneTimeCheckout && avaliablePaymentOptions.length"
              v-model="selectedPaymentOption"
              :options="avaliablePaymentOptions"
              :disabled="formState === FormState.submitting"
              label="Payment Options"
              placeholder="Select payment option"
            />

            <div
              v-else-if="isOneTimeCheckout && avaliablePaymentOptions.length"
              class="mt-2"
            >
              <h3 class="text-[16px] font-bold mb-2">
                Payment Options
              </h3>
              <div
                v-if="arePaymentPlansAvailable"
                class="flex items-center justify-between mb-4"
              >
                <div class="flex items-center">
                  <TbBankNotesIcon class="w-4 h-4 mr-2" />
                  Pay in Full
                </div>
                <Tooltip>
                  <template #trigger="{ assignReference, show, hide }">
                    <div
                      :ref="(el: any) => assignReference(el)"
                      @mouseenter="show"
                      @mouseleave="hide"
                    >
                      <div class="flex items-center">
                        <span
                          class="inline-block w-2 h-2 mr-2 rounded-full"
                          :class="payInFullStatusToColorMap[isPayInFullEnabled ? 'enabled' : 'disabled']"
                        />
                        <span>
                          {{ isPayInFullEnabled ? 'Enabled' : 'Disabled' }}
                        </span>
                      </div>
                    </div>
                  </template>

                  <template #tooltip>
                    <p class="font-bold mb-2">
                      Pay in Full
                    </p>
                    <p v-if="isPayInFullEnabled">
                      Pay in full is enabled by default for this checkout.
                    </p>
                    <p v-else>
                      Pay in full is disabled in this checkout’s payment settings.
                    </p>
                  </template>
                </Tooltip>
              </div>
              <div
                v-if="avaliablePaymentOptions.find(opt => opt.value === 'lnpl')"
                class="flex items-center justify-between mb-4"
              >
                <div class="flex items-center">
                  <div class="dollar-icon">
                    <TbIcon
                      icon="dollar-sign"
                      class="text-white"
                    />
                  </div>
                  Learn Now, Pay Later
                </div>
                <Tooltip :disabled="!isCheckoutPriceGreaterThanMaxLnplPrice">
                  <template #trigger="{ assignReference, show, hide }">
                    <div
                      :ref="(el: any) => assignReference(el)"
                      @mouseenter="show"
                      @mouseleave="hide"
                    >
                      <Toggle
                        :model-value="isLnplSwitchEnabled"
                        :disabled="isCheckoutPriceGreaterThanMaxLnplPrice"
                        @update:model-value="() => toggleSwitch(PaymentOptions.LearnNowPayLater)"
                      />
                    </div>
                  </template>
                  <template #tooltip>
                    <p class="font-bold mb-2">
                      Learn Now, Pay Later disabled
                    </p>
                    <p>Your checkout price total surpasses the maximum Learn Now, Pay Later dollar amount.</p>
                  </template>
                </Tooltip>
              </div>
              <div
                v-if="avaliablePaymentOptions.find(opt => opt.value === 'ppm')"
                class="flex items-center justify-between"
              >
                <div class="flex items-center">
                  <TbCreditCardIcon class="w-4 h-4 mr-2" />
                  Billing
                </div>
                <Toggle
                  :model-value="isBillingSwitchEnabled"
                  @update:model-value="() => toggleSwitch(PaymentOptions.Billing)"
                />
              </div>
            </div>
          </section>

          <section v-if="(selectedPaymentOption === PaymentOptions.Billing) || isBillingSwitchEnabled">
            <h3 class="text-[16px] font-bold">
              Billing Terms
            </h3>

            <p
              v-if="isOneTimeCheckout"
              class="mb-4"
            >
              When both Learn Now, Pay Later (LNPL) and Billing are enabled, your Billing plans will
              act as alternative options for customers who are declined for LNPL.
            </p>

            <TbInput
              v-if="checkoutConfig?.downPaymentType && checkoutConfig?.downPaymentValue"
              v-model="formattedDownPayment"
              disabled
              class="mb-4"
              label="Down Payment"
            />

            <div class="flex gap-2 mb-6">
              <div class="flex-grow">
                <Select
                  v-model.number="selectedNumberOfPayments"
                  :options="avaliableNumberOfPayments"
                  :disabled="formState === FormState.submitting"
                  label="Number of Payments"
                  placeholder="Select number of payments"
                />
              </div>
              <div class="flex-grow">
                <TbSelect
                  :default-options="{ name: 'Monthly', value: 'Monthly' }"
                  :model-value="['Monthly']"
                  :disabled="formState === FormState.submitting"
                  label="Payment Frequency"
                />
              </div>
            </div>

            <div
              v-if="selectedNumberOfPayments"
            >
              <h3 class="text-[16px] font-bold">
                Billing Payments Breakdown
              </h3>

              <div class="flex flex-col gap-3">
                <table
                  class="table-fixed border-collapse w-full"
                >
                  <thead>
                    <tr
                      class="border-b border-solid border-gray-300"
                    >
                      <th
                        v-for="header in breakdownTableHeaders"
                        :key="header.key"
                      >
                        <div
                          class="pl-4 py-2 text-left font-semibold text-brand whitespace-nowrap"
                        >
                          {{ header.title }}
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody class="border-b border-solid border-gray-300">
                    <tr>
                      <td>
                        <div
                          class="pl-4 py-3 text-left-50"
                        >
                          <span class="text-sm">{{ selectedNumberOfPayments }} Monthly Payments</span>
                        </div>
                      </td>
                      <td>
                        <div
                          class="pl-4 py-2 text-left-50"
                        >
                          {{ downPaymentInCents &&
                            currencyInCents(downPaymentInCents, 2, checkoutConfig?.currencyCode)
                          }}
                        </div>
                      </td>
                      <td>
                        <div
                          class="pl-4 py-2 text-left-50"
                        >
                          {{ checkoutConfig?.currencyCode && currencyInCents(monthlyPaymentInCents, 2, checkoutConfig.currencyCode) }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Note
        v-if="formValidationErrors.length"
        variant="error"
        class="my-3"
      >
        <ul class="list-disc ml-4">
          <li
            v-for="formError in formValidationErrors"
            :key="formError"
          >
            {{ formError }}
          </li>
        </ul>
      </Note>

      <div class="mt-4 action flex flex-col">
        <div
          v-if="error"
          class="text-error-800 text-sm py-3"
        >
          {{ error }}
        </div>
        <button
          class="button button--primary button--block"
          :disabled="formState === FormState.submitting"
          type="submit"
        >
          <span v-if="isOneTimeCheckout">Create Checkout Link</span>
          <span v-else>Review Transaction</span>
        </button>

        <button
          class="button mt-2 button--text button--block"
          :disabled="formState === FormState.submitting"
          @click="emit('cancel')"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import {
  ref, computed, watch, onMounted,
} from 'vue';
import { useRoute } from 'vue-router';
import { useForm, useField } from 'vee-validate';
import { object, string } from 'yup';
import { AxiosError } from 'axios';
import {
  TbInput, TbSelect, TbIcon, TbBankNotesIcon, TbCreditCardIcon, TbPlusIcon,
} from '@/components/tasty_bistro';
import Dinero from 'dinero.js';
import Combobox from '@/components/combobox.vue';
import Tooltip from '@/components/tooltip.vue';
import Select from '@/components/select.vue';
import Toggle from '@/components/toggle.vue';
import Note from '@/components/note.vue';
import { FormState } from '@/types';
import { currencyInCents } from '@/filters/currency';
import { payInFullStatusToColorMap } from '@/helpers/category_color_mapper';
import { getStudents, Student } from '../api/get_students';
import { getCheckouts, Checkout } from '../api/get_checkouts';
import {
  AvaliablePpmPaymentPlanTemplate, CheckoutConfig, getCheckoutConfig,
} from '../api/get_checkout_config';
import { CheckoutOption } from './create.vue';

interface Props {
  formType: CheckoutOption;
  submitForm: (values: any) => Promise<void>;
  createdCustomerId?: string;
}

enum PaymentOptions {
  Billing = 'ppm',
  PayInFull = 'pay-in-full',
  LearnNowPayLater = 'lnpl'
}

interface PaymentOption {
  name: string;
  value: PaymentOptions
}

const emit = defineEmits([
  'cancel', 'new-customer',
]);

const route = useRoute();
const projectId = route.params.projectId as string;
const props = defineProps<Props>();

const formState = ref<FormState>(FormState.ready);
const error = ref<string>('');

const customersList = ref<Student[]>([]);
const checkoutsList = ref<Checkout[]>([]);

const selectedCheckout = ref<Checkout | null>(null);
const checkoutConfig = ref<CheckoutConfig | null>(null);
const formattedPrice = ref<string | null>(null);
const formattedDownPayment = ref<string | null>(null);
const downPaymentInCents = ref<number>();

const avaliablePaymentOptions = ref<PaymentOption[]>([]);
const avaliableNumberOfPayments = ref();
const selectedNumberOfPayments = ref();
const monthlyPaymentInCents = ref();

const isOneTimeCheckout = computed(() => props.formType === 'oneTimeCheckout');

const breakdownTableHeaders = [
  {
    title: 'Number of Payments',
    key: 'numberOfPayments',
  },
  {
    title: 'Down Payment',
    key: 'downPayment',
  },
  {
    title: 'Monthly Payments',
    key: 'remainingPayments',
  },
];

const chargeCustomerSchema = computed(() => object({
  selectedCustomer: object().shape({
    id: string().required(),
    studentName: string().required(),
    studentEmail: string().nullable(),
  }).typeError('Please select a customer.'),
  selectedCheckoutId: string()
    .nullable()
    .required('Please select a checkout.'),
}));

const { validate, values } = useForm({
  validationSchema: chargeCustomerSchema,
  initialValues: {
    selectedCustomer: null,
    selectedCheckoutId: null,
    selectedPaymentOption: undefined,
    selectedPaymentPlanId: null,
    isLnplSwitchEnabled: false,
    isPayInFullSwitchEnabled: false,
    isBillingSwitchEnabled: false,
  },
});

const { value: selectedCustomer, errorMessage: customerError } = useField<Student | null>('selectedCustomer');
const { value: selectedCheckoutId, errorMessage: checkoutError } = useField<string | null>('selectedCheckoutId');
const { value: selectedPaymentOption } = useField<PaymentOptions | undefined>('selectedPaymentOption');
const { value: selectedPaymentPlanId } = useField<string | null>('selectedPaymentPlanId');
const { value: isLnplSwitchEnabled } = useField<boolean>('isLnplSwitchEnabled');
const { value: isBillingSwitchEnabled } = useField<boolean>('isBillingSwitchEnabled');
const isPayInFullEnabled = ref(false);
const arePaymentPlansAvailable = ref(false);
const isCheckoutPriceGreaterThanMaxLnplPrice = ref(false);

const formValidationErrors = computed(() => {
  const errorsList = [];

  if (customerError.value) {
    errorsList.push(customerError.value);
  }

  if (checkoutError.value) {
    errorsList.push(checkoutError.value);
  }

  if (
    isOneTimeCheckout.value
    && selectedCheckoutId.value
    && !isLnplSwitchEnabled.value
    && !isBillingSwitchEnabled.value
    && !isPayInFullEnabled.value
  ) {
    errorsList.push('Please select a payment option.');
  }

  if (!isOneTimeCheckout.value && selectedCheckoutId.value && !selectedPaymentOption.value) {
    errorsList.push('Please select a payment option.');
  }

  if (
    !isOneTimeCheckout.value
    && selectedCheckoutId.value
    && selectedPaymentOption.value === PaymentOptions.Billing
    && !selectedNumberOfPayments.value
  ) {
    errorsList.push('Please select number of payments.');
  }

  if (
    isOneTimeCheckout.value
    && selectedCheckoutId.value
    && isBillingSwitchEnabled.value
    && !selectedNumberOfPayments.value
  ) {
    errorsList.push('Please select number of payments.');
  }

  return errorsList;
});

const selectCustomer = (customerId: string) => {
  selectedCustomer.value = customersList.value?.find((c) => c.id === customerId) || null;
};

const toggleSwitch = (name: PaymentOptions) => {
  switch (name) {
    case PaymentOptions.LearnNowPayLater:
      isLnplSwitchEnabled.value = !isLnplSwitchEnabled.value;
      break;
    case PaymentOptions.Billing:
      isBillingSwitchEnabled.value = !isBillingSwitchEnabled.value;
      break;
    default:
      break;
  }
};

const calculateAvaliablePaymentOptions = () => {
  selectedPaymentOption.value = undefined;
  avaliablePaymentOptions.value = [];
  isPayInFullEnabled.value = false;
  isBillingSwitchEnabled.value = false;
  isLnplSwitchEnabled.value = false;
  arePaymentPlansAvailable.value = false;

  arePaymentPlansAvailable.value = checkoutConfig.value?.avaliablePpmPaymentPlanTemplates
    ? checkoutConfig.value?.avaliablePpmPaymentPlanTemplates?.length > 0
    : false;

  if (checkoutConfig.value?.avaliablePpmPaymentPlanTemplates?.find((plan) => plan.installments === 1)) {
    avaliablePaymentOptions.value.push({ name: 'Pay-in-full', value: PaymentOptions.PayInFull });
    isPayInFullEnabled.value = true;
  }

  if (checkoutConfig.value?.avaliablePpmPaymentPlanTemplates?.find((plan) => plan.installments !== 1)) {
    avaliablePaymentOptions.value.push({ name: 'Billing', value: PaymentOptions.Billing });
  }

  if (isOneTimeCheckout.value && selectedCheckout.value?.lnplEnabled) {
    avaliablePaymentOptions.value.push({ name: 'Learn Now, Pay Later', value: PaymentOptions.LearnNowPayLater });
  }
};

const selectCheckout = async (checkoutId: string, isDisabled: boolean, closeCallback: () => void) => {
  if (!isOneTimeCheckout.value && isDisabled) return;

  selectedNumberOfPayments.value = undefined;

  selectedCheckout.value = checkoutsList.value.find((c) => c.id === checkoutId) || null;
  selectedCheckoutId.value = selectedCheckout.value?.id || null;
  closeCallback();

  const checkoutConfigData = await getCheckoutConfig(projectId, checkoutId);

  checkoutConfig.value = checkoutConfigData;

  const {
    totalSumInCents: checkoutTotalSum,
    currencyCode: checkoutCurrencyCode,
    downPaymentType: checkoutDownPaymentType,
    downPaymentValue: checkoutDownPaymentValue,
    avaliablePpmPaymentPlanTemplates: checkoutAvaliablePaymentPlans,
    productMaxPriceInCents,
  } = checkoutConfig.value;

  formattedPrice.value = `${checkoutCurrencyCode} ${currencyInCents(checkoutTotalSum, 2)}`;

  isCheckoutPriceGreaterThanMaxLnplPrice.value = productMaxPriceInCents ? checkoutTotalSum > productMaxPriceInCents : false;

  calculateAvaliablePaymentOptions();

  if (checkoutDownPaymentType && checkoutDownPaymentValue) {
    if (checkoutDownPaymentType === 'percentage') {
      downPaymentInCents.value = Dinero({ amount: checkoutTotalSum }).percentage(checkoutDownPaymentValue).getAmount();
      formattedDownPayment.value = `${checkoutDownPaymentValue}%`;
    } else if (checkoutDownPaymentType === 'cents') {
      downPaymentInCents.value = checkoutDownPaymentValue;
      formattedDownPayment.value = currencyInCents(checkoutDownPaymentValue, 2, checkoutCurrencyCode);
    }
  }

  if (checkoutAvaliablePaymentPlans) {
    avaliableNumberOfPayments.value = checkoutAvaliablePaymentPlans
      .filter((plan) => plan.installments !== 1)
      .map((plan: AvaliablePpmPaymentPlanTemplate) => ({
        name: plan.installments.toString(),
        value: plan.installments,
      }));
  }
};

const calculateMonthlyPayment = (downPayment: number, installments: number): number => {
  const remainder = Dinero({ amount: checkoutConfig.value?.totalSumInCents })
    .subtract(Dinero({ amount: downPayment }))
    .allocate(Array.from(Array(installments - 1)).fill(50));

  return remainder[0].getAmount();
};

onMounted(async () => {
  const studentsData = await getStudents(projectId);
  const checkoutsData = await getCheckouts(projectId);

  customersList.value = studentsData;
  checkoutsList.value = !isOneTimeCheckout.value
    ? checkoutsData.sort((checkout) => (checkout.ppmEnabled ? -1 : 1))
    : checkoutsData;
});

const handleCustomersSearch = async (query: string) => {
  const studentsData = await getStudents(projectId, query);
  customersList.value = studentsData;
};

const submit = async () => {
  try {
    error.value = '';
    formState.value = FormState.submitting;

    const response = await validate();

    if (!response.valid || formValidationErrors.value.length) {
      formState.value = FormState.error;
      return;
    }

    if (selectedPaymentOption.value === PaymentOptions.PayInFull) {
      selectedPaymentPlanId.value = checkoutConfig.value?.avaliablePpmPaymentPlanTemplates?.find(
        (plan) => plan.installments === 1,
      )?.id || null;
    }

    await props.submitForm(values);

    formState.value = FormState.ready;
  } catch (err) {
    formState.value = FormState.error;
    if (err instanceof AxiosError) {
      error.value = err.response?.data?.message;
    } else {
      error.value = 'An error occurred';
    }
  }
};

watch(() => props.formType, async (newType) => {
  selectedCheckout.value = null;
  selectedCheckoutId.value = null;
  checkoutConfig.value = null;
  selectedPaymentOption.value = undefined;
  selectedNumberOfPayments.value = undefined;
  avaliablePaymentOptions.value = [];
  isLnplSwitchEnabled.value = false;
  isBillingSwitchEnabled.value = false;
  isPayInFullEnabled.value = false;

  const checkoutsData = await getCheckouts(projectId);

  checkoutsList.value = newType === 'chargeCustomer'
    ? checkoutsData.sort((checkout) => (checkout.ppmEnabled ? -1 : 1))
    : checkoutsData;
});

watch(() => selectedNumberOfPayments.value, (newNumber) => {
  if (checkoutConfig.value?.downPaymentType && checkoutConfig.value?.downPaymentValue && downPaymentInCents.value) {
    monthlyPaymentInCents.value = calculateMonthlyPayment(downPaymentInCents.value, newNumber);
  } else {
    downPaymentInCents.value = Dinero({ amount: checkoutConfig.value?.totalSumInCents })
      .allocate(Array.from(Array(newNumber)).fill(50))[0].getAmount();
    monthlyPaymentInCents.value = calculateMonthlyPayment(downPaymentInCents.value, newNumber);
  }

  selectedPaymentPlanId.value = checkoutConfig.value?.avaliablePpmPaymentPlanTemplates?.find(
    (plan) => plan.installments === selectedNumberOfPayments.value,
  )?.id || null;
});

watch(() => props.createdCustomerId, async () => {
  const studentsData = await getStudents(projectId, props.createdCustomerId);
  customersList.value = studentsData;
  if (props.createdCustomerId) {
    selectCustomer(props.createdCustomerId);
  }
});
</script>

<style lang="scss" scoped>
  .dollar-icon {
    @apply
      w-4
      h-4
      mr-2
      inline-flex
      justify-center
      items-center
      shrink-0
      rounded-full
      bg-primary-900
      text-xs
  }

</style>
