import http, { Http } from '@/lib/http';

export interface Params {
  projectId: string;
  accountNumber: string;
  licenseKey: string;
  environment: string;
}

export interface Response {
  connected: boolean;
  companies: { id: number; companyCode?: string }[]
}

function validateSalesTaxIntegrationCtor(http: Http) {
  return async ({
    projectId,
    accountNumber,
    licenseKey,
    environment,
  }: Params) => {
    const { data } = await http.post<Response>(`/projects/${projectId}/integrations/sales-tax/validate`, {
      accountNumber,
      licenseKey,
      environment,
    });
    return data;
  };
}

export const validateSalesTaxIntegration = validateSalesTaxIntegrationCtor(http);
