export enum PageState {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error'
}

export enum FormState {
  ready = 'ready',
  submitting = 'submitting',
  error = 'error'
}

export enum OrderOrigin {
  lnpl = 'lnpl',
  ppm = 'ppm'
}

export type OrderOriginTitle = 'Funding' | 'In-House';

export enum CurrencyCode {
  'USD' = 'USD',
  'CAD' = 'CAD',
}

export interface RoleControlConfig {
  hidden: boolean;
  restricted: boolean;
}

export interface RoleConfiguration {
  [role: string]: RoleControlConfig;
}
export interface RoleControlledActionConfiguration {
  [action: string]: RoleConfiguration;
}
