<template>
  <FormHeader
    :heading="heading"
    :subHeading="subHeading"
  />
  <form
    class="mt-4"
    @submit.prevent="submit"
  >
    <div>
      <div class="flex items-center mb-2">
        <label class="mr-1 label">
          Legal Business Name*
        </label>
        <TbTooltip
          info="Please ensure this is your legal business entity name as listed in formal company documents."
        />
      </div>
      <TbInput
        v-model="legalName"
        class="w-full mb-1"
        type="text"
        :errors="legalNameError && [legalNameError]"
      />
    </div>
    <TbInput
      v-model="businessNumber"
      label="EIN/Business Number*"
      class="w-full mb-2"
      type="text"
      :errors="businessNumberError && [businessNumberError]"
    />
    <div class="mb-4">
      <div class="flex items-center">
        <label class="mr-1 label">
          Public Business Name*
        </label>
        <TbTooltip
          info="This is the name your customers know your business by and will be used in future Elective communications."
        />
      </div>
      <TbInput
        v-model="publicName"
        class="w-full"
        type="text"
        :errors="publicNameError && [publicNameError]"
      />
    </div>
    <TbInput
      v-model="websiteUrl"
      label="Website URL*"
      class="w-full mb-4"
      type="url"
      :errors="websiteUrlError && [websiteUrlError]"
    />
    <button
      type="submit"
      class="w-full mb-4 button button--secondary"
    >
      Continue
    </button>
  </form>
</template>

<script setup lang="ts">
import { useForm, useField } from 'vee-validate';
import { object, string, InferType } from 'yup';
import { TbInput, TbTooltip } from '@/components/tasty_bistro';
import FormHeader from '@/components/form_header.vue';
import { BusinessDetails } from '../api/get_business_details';

interface Props {
  data: BusinessDetails
}

const props = defineProps<Props>();
const emit = defineEmits(['business-name-submit']);

const heading = 'Tell us about your business';
const subHeading = 'We just need the basics to verify your business so you can start processing transactions with Elective';

const businessNameSchema = object({
  legalName: string().required('Required'),
  businessNumber: string().required('Required'),
  publicName: string().required('Required'),
  websiteUrl: string().required('Required').url('The link provided must be a valid URL (e.g., https://elective.com)'),
});

interface BusinessName extends InferType<typeof businessNameSchema> {}

const { handleSubmit } = useForm<BusinessName>({
  validationSchema: businessNameSchema,
  initialValues: {
    legalName: props.data?.legalName,
    publicName: props.data?.publicName,
    businessNumber: props.data?.businessNumber,
    websiteUrl: props.data?.websiteUrl || '',
  },
});

const { value: legalName, errorMessage: legalNameError } = useField('legalName');
const { value: publicName, errorMessage: publicNameError } = useField('publicName');
const { value: businessNumber, errorMessage: businessNumberError } = useField('businessNumber');
const { value: websiteUrl, errorMessage: websiteUrlError } = useField('websiteUrl');

const submit = handleSubmit(async (values) => {
  emit('business-name-submit', values);
});
</script>
