import http, { Http } from '@/lib/http';

export interface UpdateInviteParams {
    roleId: string;
    inviteId: string;
    projectId: string;
}

function updateInviteCtor(http: Http) {
  return async function updateInvite({ projectId, roleId, inviteId }:UpdateInviteParams) {
    await http.put<void>(`/projects/${projectId}/users/invite/${inviteId}`, { roleId });
  };
}

export const updateInvite = updateInviteCtor(http);
