import http, { Http } from '@/lib/http';

interface Data {
  subscriberId: string;
  projectId: string;
}

function deleteSubscriberCtor(http: Http) {
  return async ({ subscriberId, projectId }: Data) => {
    await http.delete(`/projects/${projectId}/notifications/${subscriberId}`);
  };
}

export const deleteSubscriber = deleteSubscriberCtor(http);
