import { CurrencyCode } from '@/types';
import http, { Http } from '@/lib/http';
import { Filter } from './types';

export interface Payment {
  id: string;
  origin: 'ppm' | 'lnpl';
  date: Date;
  studentId: string;
  studentName: string;
  productName: string;
  currency: string;
  installments: number;
  amountInCents: number;
  status: string;
  totalCount: number;
  totalAmountInCents: number;
}

export type QuickFilterPaymentStatus = 'all' | 'future' | 'refunded' | 'pastDue' | 'failed' | 'terminated';

interface GetPaymentsResponse {
  payments: Payment[];
  currencies: CurrencyCode[];
  selectedCurrency: CurrencyCode;
  statusFilters: { label: string, value: string }[];
  quickFilterStatusCount: Record<QuickFilterPaymentStatus, number>;
}

interface Data {
  filter: Filter;
  projectId: string;
}

function getPaymentsCtor(http: Http) {
  return async ({ filter, projectId }: Data): Promise<GetPaymentsResponse> => {
    const { data } = await http.get(`/projects/${projectId}/ppm/payments`, { params: { ...filter } });
    return data;
  };
}

export const getPayments = getPaymentsCtor(http);
