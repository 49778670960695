export interface ProductType {
  title: string;
  description: string;
  value: string;
}

export const productTypes: ProductType[] = [
  {
    title: 'Online Course',
    description: 'Pre-recorded or cohort based',
    value: 'online_course',
  },
  {
    title: 'Coaching',
    description: 'One-on-one, in real time',
    value: 'coaching',
  },
  {
    title: 'Membership',
    description: 'Access to a community',
    value: 'membership',
  },
  {
    title: 'Events',
    description: 'The product is the event',
    value: 'events',
  },
  {
    title: 'Project Work',
    description: 'Deliverables for clients',
    value: 'project_work',
  },
];
