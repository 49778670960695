<template>
  <div
    class="banner"
  >
    <div class="lg:w-[33%]">
      <h1 class="mb-2 text-xl md:text-2xl font-bold text-center lg:text-left">
        Get Started with Elective 🚀
      </h1>
      <p class="text-lg md:text-xl text-center lg:text-left md:max-w-[90%] lg:max-w-none m-auto">
        Watch our step-by-step walkthrough on how to create and launch your first checkout.
      </p>
    </div>
    <div class="lg:max-w-[54%]">
      <div
        class="cursor-pointer"
        @click="showModal = true"
      >
        <img
          src="/src/pages/app/project/dashboard/home/assets/welcome-thumbnail.png"
        >
      </div>
      <Modal
        :showModal="showModal"
        @close-modal="showModal = false"
      >
        <div class="w-[70%]">
          <Video :videoUrl="videoUrl" />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Modal from '@/components/modal.vue';
import Video from '@/components/video.vue';

const showModal = ref(false);

const videoUrl = 'https://player.vimeo.com/video/910605473?autoplay=1';
</script>

<style lang="scss" scoped>
.banner {
  @apply
    mb-6
    px-8
    py-6
    flex
    flex-col
    items-center
    justify-between
    gap-4
    w-full
    bg-[#F1F9FB]
    rounded-2xl
    md:py-8
    md:gap-8
    lg:flex-row
    xl:px-28
}

</style>
