import http, { Http } from '@/lib/http';

export interface UpdateMemberParams {
    roleId: string;
    userId: string;
    projectId: string;
}

function updateMemberCtor(http: Http) {
  return async function updateMember({ projectId, roleId, userId }:UpdateMemberParams) {
    await http.patch<void>(`/projects/${projectId}/users/${userId}`, { roleId });
  };
}

export const updateMember = updateMemberCtor(http);
