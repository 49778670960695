import http, { Http } from '@/lib/http';

export interface User {
  roleId: string;
  userId: string | null;
  roleName: string;
  inviteId: string | null;
  email: string;
  name: string | null;
}

function getUsersCtor(http: Http) {
  return async function getUsers(projectId: string) {
    const { data } = await http.get<User[]>(`/projects/${projectId}/users`);
    return data;
  };
}

export const getProjectUsers = getUsersCtor(http);
