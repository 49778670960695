<template>
  <div class="flex justify-center items-center">
    <Icon
      icon="spinner"
      class="text-secondary text-8xl animate-spin"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '../icon/icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
});
</script>
