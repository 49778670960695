<template>
  <TbDialog
    :show="modelValue"
    @close="cancelAction"
  >
    <template #header>
      <h2 class="text-lg font-bold text-primary">
        {{ title }}
      </h2>
    </template>

    <div
      class="overflow-hidden flex flex-col w-full gap-3"
    >
      <p class="font-light">
        <slot name="pre" />
      </p>

      <div
        v-if="items && items.length"
        class="flex flex-col gap-1"
      >
        <div class="py-2 px-3 rounded-md bg-gray-100 max-h-24 overflow-auto">
          <div
            v-for="item, key in items"
            :key="key"
            class="text-sm font-light py-1 pl-2"
          >
            <slot
              name="item"
              :item="item"
            />
          </div>
        </div>

        <div
          v-if="items.length > 3"
          class="flex items-center justify-center text-sm text-gray-600 font-medium"
        >
          <div class="flex flex-row gap-1 items-center">
            Scroll to see more
            <TbChevronDownIcon class="w-4 h-4 stroke-2" />
          </div>
        </div>
      </div>

      <p class="font-light">
        <slot name="post" />
      </p>
    </div>

    <template #footer>
      <div
        v-if="updateError"
        class="flex gap-4 actions"
      >
        <small
          v-if="updateError"
          class="text-error-900"
        >
          {{ updateError }}
        </small>
      </div>
      <div
        v-else
        class="flex gap-4 actions"
      >
        <button
          class="button button--sm"
          :disabled="formState !== FormState.ready"
          style="box-shadow: none;"
          @click="cancelAction"
        >
          No, I changed my mind
        </button>
        <button
          class="button button--sm button--danger"
          :disabled="formState !== FormState.ready"
          @click="complete"
        >
          Confirm {{ action }}
        </button>
      </div>
    </template>
  </TbDialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { TbChevronDownIcon, TbDialog } from '@/components/tasty_bistro';
import { FormState } from '@/types';

interface Props {
    modelValue: boolean;
    title: string;
    action: string;
    items: any[];
    fn: () => Promise<void>;
}

const props = defineProps<Props>();

const emit = defineEmits([
  'cancel',
  'update:modelValue',
]);

const cancelAction = () => {
  emit('update:modelValue', false);
  emit('cancel');
};

const updateError = ref<string>('');
const formState = ref(FormState.ready);

const complete = async () => {
  formState.value = FormState.submitting;
  try {
    await props.fn();
    formState.value = FormState.ready;
  } catch (e) {
    formState.value = FormState.error;
    updateError.value = 'Error updating product';
    setTimeout(() => {
      emit('cancel');
    }, 1000);
  }
};

</script>
