<template>
  <RoleControlledAction
    v-slot="{wrapper, restricted}"
    :user-role="roleName"
    :config-object="roleConfig.refundOrder"
  >
    <button
      :class="{ 'custom-disabled': !isRefundable || restricted}"
      class="button button--block button--sm text-left"
      @click="wrapper(() => refundSubmit())"
    >
      Refund
    </button>
  </RoleControlledAction>
  <TbDialog
    :show="refundOrderDialog"
    @close="cancelRefund"
  >
    <template #header>
      <h2 class="text-xl font-bold">
        Refund {{ order?.product }}
      </h2>
    </template>

    <p>
      You're about to process a refund to <strong class="font-bold">{{ order.studentName.toUpperCase() }}</strong>
    </p>

    <br>

    <p>
      Elective will credit any payments the customer has made, up to the refund amount, to the original form of payment
      within 3 to 10 business days of the refund.
    </p>

    <InsufficientFundsWarning
      v-if="isFundedRefund"
      class="mt-4"
      type="refund"
      :merchant-payment-method="merchantAccountLastFourDigits"
      :amount-in-cents="refundAmount"
    />

    <div class="mt-6">
      <label
        for="refund-notes"
        class="label"
      >
        Refund Notes
      </label>

      <textarea
        id="refund-notes"
        v-model="refundNotes"
        class="w-full input"
      />

      <p
        v-if="notFoundError"
        class="mt-2 text-error text-center"
      >
        To complete this action, please provide your payment information. Visit your <router-link
          :to="{ name: Pages.paymentDetails }"
        >
          settings
        </router-link> to submit your payment details and try again.
      </p>
      <p
        v-else-if="refundError"
        class="mt-2 text-error text-center"
      >
        {{ refundError }}
      </p>
    </div>

    <template #footer>
      <div>
        <div class="flex gap-4 actions">
          <button
            class="button button--sm button--text"
            @click="cancelRefund"
          >
            No, I’ve changed my mind
          </button>

          <button
            class="button button--sm button--primary"
            :disabled="isLoading.valueOf()"
            @click="refund"
          >
            <TbIcon
              v-if="isLoading"
              icon="spinner"
              class="text-xl text-white animate-spin"
            />
            <div v-else>
              Confirm Refund
            </div>
          </button>
        </div>
      </div>
    </template>
  </TbDialog>

  <TbDialog
    :show="refundInfoDialog"
    @close="closeRefundDisabled"
  >
    <template #header>
      <h2 class="text-xl font-bold">
        Refund {{ order?.product }}
      </h2>
    </template>

    <p class="mb-5">
      To issue a custom refund, please email help@elective.com with the following information:
    </p>

    <ul class="list-disc pl-5">
      <li>Customer full name</li>
      <li>Customer email</li>
      <li>Refund amount or percentage</li>
    </ul>

    <p class="mt-5">
      We'll process the refund as soon as possible!
    </p>
  </TbDialog>
</template>

<script lang="ts" setup>
import { ref, inject, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbDialog, TbIcon } from '@/components/tasty_bistro';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import { Pages } from '@/router';
import { refundOrder } from '../api/refund';
import { Order } from '../api/get_orders';
import { roleConfig } from '../role_configurations';
import InsufficientFundsWarning from './components/insufficient_funds_warning.vue';
import { getRefundData } from '../api/get_refund_data';

const route = useRoute();
const projectId = route.params.projectId as string;
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const props = defineProps<{ order: Order, refundEnabled: boolean, isRefundable: boolean }>();

const emit = defineEmits([
  'refund',
]);

const refundError = ref<String>('');
const notFoundError = ref<Boolean>(false);
const refundOrderDialog = ref<Boolean>(false);
const refundInfoDialog = ref<Boolean>(false);
const isLoading = ref<Boolean>(false);
const isFundedRefund = ref<boolean>(false);
const refundAmount = ref<number>(0);
const merchantAccountLastFourDigits = ref<string>('');

const cancelRefund = () => {
  refundOrderDialog.value = false;
  notFoundError.value = false;
  refundError.value = '';
};

const closeRefundDisabled = () => {
  refundInfoDialog.value = false;
};

const refundSubmit = async () => {
  if (!props.isRefundable) {
    return;
  }
  if (props.refundEnabled) {
    if (props.order.origin === 'lnpl') {
      try {
        const data = await getRefundData({ orderId: props.order.id, projectId });
        refundAmount.value = data.refundAmountInCents;
        isFundedRefund.value = data.isFundedRefund;
        merchantAccountLastFourDigits.value = data.merchantAccountLastFourDigits;
      } catch (error) {
        const err = error as any;
        if (err.response?.data?.type === 'NotFound') {
          notFoundError.value = true;
        } else {
          refundError.value = 'Error while getting refund data. Please contact support.';
        }
      }
    }
    refundOrderDialog.value = true;
  } else {
    refundInfoDialog.value = true;
  }
};

const refundNotes = ref<string>('');

const refund = async () => {
  try {
    isLoading.value = true;
    notFoundError.value = false;
    refundError.value = '';
    await refundOrder({
      orderId: props.order.id, notes: refundNotes.value, isFundedRefund: isFundedRefund.value, projectId,
    });
    emit('refund');
  } catch (error) {
    const err = error as any;
    if (err.type) {
      refundError.value = err.message;
    } else {
      refundError.value = 'Error processing request. Please contact support.';
    }
  } finally {
    refundOrderDialog.value = false;
    isLoading.value = false;
  }
};
</script>

<style lang="scss" scoped>
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
