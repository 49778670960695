<template>
  <router-view v-slot="{ Component }">
    <Suspense>
      <div class="h-full min-h-full antialiased">
        <component :is="Component" />
      </div>
    </Suspense>
  </router-view>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { loadExternalScript, ExternalScriptName } from './external_scripts';

onMounted(async () => {
  loadExternalScript(ExternalScriptName.zendesk);
});
</script>

<style lang="scss">
  html,
  body,
  #app {
    height: 100%;
    width: 100%;
  }

  #app {
    @apply
      font-light
      text-primary;
  }
</style>
