import http, { Http } from '@/lib/http';

export interface CancelInviteParams {
    projectId:string;
    inviteId: string;
}

function cancelInviteCtor(http: Http) {
  return async function cancelInvite({ projectId, inviteId }:CancelInviteParams) {
    await http.delete<void>(`/projects/${projectId}/users/invite/${inviteId}`);
  };
}

export const cancelInvite = cancelInviteCtor(http);
