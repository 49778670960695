<template>
  <PaymentStackForm
    action="update"
    :stack="stack"
    :fn="update"
    @cancel="$emit('cancel')"
  />

  <ConfirmationDialog
    v-model="showDialog"
    :title="`Update ${stack?.name}?`"
    :items="orderedCheckoutList"
    action="Update"
    :fn="completeUpdate"
    @cancel="$emit('cancel')"
  >
    <template #pre>
      "<strong class="font-semibold">{{ stack.name }}</strong>" is currently used in
      <strong class="font-semibold">{{ stack.activeCheckouts.length }}
        checkout{{ stack.activeCheckouts.length > 1 ? 's' : '' }}</strong>. Updating this payment
      setting will invalidate any active sessions for the following checkout
      {{ stack.activeCheckouts.length > 1 ? 's' : '' }}
    </template>

    <template #item="{ item }">
      <div
        v-if="checkoutIssues(item.totalInCents)"
        class="truncate w-full font-semibold text-error-600 flex flex-row items-center gap-2"
      >
        <Tooltip>
          <template #trigger="{ assignReference, show, hide }">
            <div
              :ref="(el: any) => assignReference(el)"
              class="flex flex-row items-center gap-2 w-min"
              @mouseenter="show"
              @mouseleave="hide"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-4 h-4 fill-error-800"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29
            4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12
            8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0
            8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clip-rule="evenodd"
                />
              </svg>

              {{ item.name }}
            </div>
          </template>

          <template #tooltip>
            This checkout not have any payment options
          </template>
        </Tooltip>
      </div>
      <div
        v-else
        class="truncate w-full font-semibold flex flex-row items-center gap-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-4 h-4 fill-success-800"
        >
          <path
            fill-rule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75
            9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53
            12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clip-rule="evenodd"
          />
        </svg>

        {{ item.name }}
      </div>
    </template>
  </ConfirmationDialog>
</template>

<script lang="ts" setup>
import {
  Ref, computed, inject, ref,
} from 'vue';
import { AppState } from '@/pages/app/api/get_app_state';
import { useRoute } from 'vue-router';
import Tooltip from '@/components/tooltip.vue';
import PaymentStackForm from '../../components/payment_settings_form/index.vue';
import { updateStack, UpdateStack } from '../api/update_stack';
import { PaymentStack } from '../api/get_stacks';
import ConfirmationDialog from '../../components/modals/confirmation.vue';

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const route = useRoute();
const projectId = route.params.projectId as string;
const maxPriceInCents = appState.value.projects[projectId].configs.maxPrice;

const showDialog = ref(false);

const updatePayload = ref<UpdateStack | null>(null);

interface Props {
  stack: PaymentStack;
}

const props = defineProps<Props>();

const emit = defineEmits([
  'submit',
  'cancel',
]);

const completeUpdate = async () => {
  if (updatePayload.value) {
    await updateStack({
      ...updatePayload.value,
      id: props.stack.id,
      projectId,
    });
    updatePayload.value = null;
    emit('submit');
  }
};

const checkoutIssues = (totalInCents: number) => {
  const { ppmEnabled, paymentPlans, lnplEnabled } = updatePayload.value ?? {};

  const payInFullEnabled = paymentPlans?.find((p) => p.installments === 1);

  if (!ppmEnabled && !payInFullEnabled && lnplEnabled && totalInCents > maxPriceInCents) {
    return true;
  }

  return false;
};

const impactedCheckouts = computed(() => {
  const { activeCheckouts } = props.stack;

  return activeCheckouts.filter((c) => checkoutIssues(c.totalInCents));
});

const orderedCheckoutList = computed(() => {
  const { activeCheckouts } = props.stack;

  // make inactive checkouts first, active ones after ordered by name
  return [...activeCheckouts].sort((a, b) => {
    const problems = [a, b].map((c) => impactedCheckouts.value.includes(c));

    if (problems[0] && !problems[1]) {
      return -1;
    }

    if (!problems[0] && problems[1]) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  });
});

const update = async (values: UpdateStack) => {
  if (props.stack.activeCheckouts.length > 0) {
    updatePayload.value = values;
    showDialog.value = true;
  } else {
    await updateStack({
      ...values,
      id: props.stack.id,
      projectId,
    });

    emit('submit');
  }
};

</script>
