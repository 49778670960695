<template>
  <router-view v-slot="{ Component }">
    <component
      :is="Component"
      :data="paymentDetails"
    />
  </router-view>
  <div class="text-center text-error">
    {{ error }}
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import { getPaymentDetails } from './api/get_payment_details';

const route = useRoute();
const router = useRouter();
const projectId = route.params.projectId as string;
const paymentDetails = ref();
const error = ref('');

onMounted(async () => {
  try {
    const data = await getPaymentDetails(projectId);
    paymentDetails.value = data;
    if (data) {
      router.replace({ name: Pages.paymentDetailsReview });
    } else {
      router.replace({ name: Pages.paymentDetailsForm });
    }
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
});
</script>
