import http, { Http } from '@/lib/http';

interface ArchiveIntegrationParams {
  projectId: string;
  integrationId: string;
}

function archiveIntegrationCtor(http: Http) {
  return async ({ projectId, integrationId }: ArchiveIntegrationParams): Promise<void> => {
    await http.delete(`/projects/${projectId}/integrations/${integrationId}`);
  };
}

export const archiveIntegration = archiveIntegrationCtor(http);
