import http, { Http } from '@/lib/http';

export interface Payment {
  id: string;
  origin: 'ppm' | 'lnpl';
  dueDate: Date;
  studentId: string;
  studentName: string;
  productName: string;
  curreny: string;
  feePercentage: number;
  installments: number;
  amountInCents: number;
  status: string;
  totalCount: number;
  totalAmountInCents: number;
}

interface Data {
  id: string;
  projectId: string;
}

function getPaymentsScheduleCtor(http: Http) {
  return async ({ id, projectId }: Data): Promise<Payment[]> => {
    const { data } = await http.get(`/projects/${projectId}/ppm/payments/${id}/schedule`);
    return data;
  };
}

export const getPaymentsSchedule = getPaymentsScheduleCtor(http);
