<template>
  <label
    v-if="$slots.label"
    class="text-primary font-medium text-sm cursor-pointer block select-none"
  >
    <div
      :class="[
        'flex gap-1 ml-1',
        alignment === 'right' && 'flex-row-reverse justify-end items-center',
        alignment === 'top' && 'flex-col justify-start items-start',
      ]"
    >
      <div class="ml-1"><slot name="label" /></div>
      <input
        :checked="checked || modelValue"
        type="checkbox"
        class="checked:bg-primary-800 hover:cursor-pointer"
        @change="handleChange"
      >
    </div>
  </label>
</template>

<script lang="ts" setup>
interface Props {
    modelValue?: boolean;
    checked?: boolean;
    alignment: 'top' | 'right'
}

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);

const handleInputChange = (event: Event) => (event.target as HTMLInputElement)?.value;

const handleChange = (e: Event) => {
  emit('update:modelValue', !props.modelValue);
  handleInputChange(e);
};
</script>
