<template>
  <RoleControlledAction
    v-slot="{restricted, wrapper}"
    :user-role="roleName"
    :config-object="roleConfig.archiveIntegration"
  >
    <button
      :class="{ 'custom-disabled': restricted}"
      class="button button--block text-error-900 button--sm text-left"
      @click="wrapper(()=> showDialog=true)"
    >
      Delete
    </button>
  </RoleControlledAction>

  <TbDialog
    :show="showDialog"
    @close="() => showDialog = false"
  >
    <template #header>
      <h2 class="text-xl font-bold">
        Delete {{ integrationName || integrationSource }}?
      </h2>
    </template>
    <div>
      <p>
        Are you sure you want to delete this integration?
      </p>
      <p>
        Once you delete this integration, it cannot be undone.
      </p>
      <p>
        You'll have to re-enter your credentials to re-enable it.
      </p>
    </div>
    <template #footer>
      <div>
        <div class="flex gap-4 actions">
          <button
            class="button button--sm button--text"
            @click="() => showDialog = false"
          >
            No, I’ve changed my mind
          </button>

          <button
            class="button button--sm button--danger"
            :disabled="isLoading"
            @click="archive"
          >
            <TbIcon
              v-if="isLoading"
              icon="spinner"
              class="text-xl text-white animate-spin"
            />
            <div v-else>
              Confirm Delete
            </div>
          </button>
        </div>
      </div>
    </template>
  </TbDialog>
</template>

<script lang="ts" setup>
import { ref, inject, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbDialog, TbIcon } from '@/components/tasty_bistro';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import { roleConfig } from './role_configurations';
import { archiveIntegration } from './api/archive_integration';

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;

const route = useRoute();
const projectId = route.params.projectId as string;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const props = defineProps<{ integrationName: string | undefined, integrationSource: string, integrationId: string; }>();

const emit = defineEmits([
  'integrationArchived',
]);

const error = ref<string>('');
const showDialog = ref<boolean>(false);
const isLoading = ref<boolean>(false);

const archive = async () => {
  try {
    isLoading.value = true;
    error.value = '';

    await archiveIntegration({
      projectId,
      integrationId: props.integrationId,
    });

    showDialog.value = false;
    emit('integrationArchived');
  } catch (err: any) {
    if (err.type) {
      error.value = err.message;
    } else {
      error.value = 'Error processing request. Please contact support.';
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped lang="scss">
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
