import http, { Http } from '@/lib/http';

interface Data {
  email: string;
  name?: string;
  projectId: string
}

function addSubscriberCtor(http: Http) {
  return async ({ email, projectId, name }: Data) => {
    const payload : {email: string; subscriberName?: string} = { email };
    if (name) {
      payload.subscriberName = name;
    }
    await http.post(`/projects/${projectId}/notifications`, payload);
  };
}

export const addSubscriber = addSubscriberCtor(http);
