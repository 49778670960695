import http, { Http } from '@/lib/http';

export interface Plan {
    installments: number;
    frequency: string;
}

export interface CreateStack {
  name: string;
  lnplEnabled: boolean;
  payInFullEnabled: boolean;
  ppmEnabled: boolean;
  paymentPlans: Plan[];
}

export interface Response {
  id: string;
}
interface Data {
  projectId: string;
  form: CreateStack;
}

function createStackCtor(http: Http) {
  return async function createProduct({ projectId, form }: Data): Promise<Response> {
    const response = await http.post(`/projects/${projectId}/payment-settings`, form);

    return response.data;
  };
}

export const createStack = createStackCtor(http);
