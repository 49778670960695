<template>
  <div class="w-full text-center">
    <TbWordLogo class="inline-flex max-w-[12rem] pb-12" />
    <div class="pb-12">
      <img
        src="./assets/private_info.svg"
        class="inline-flex pb-12"
      >
    </div>
    <div class="pb-3 text-3xl font-bold">
      Code Verification
    </div>
    <div class="pb-8 text-lg">
      <p>A verification code was attached to your email invite from <span class="font-bold">{{ props.data.projectName }}</span>.</p>
      <p>Enter the code below to verify your account.</p>
    </div>
    <form @submit.prevent="submit">
      <TbInput
        v-model="confirmationCode"
        label="Verification Code"
        type="text"
        class="w-full"
        :class="{'input-error': confirmationCodeAPIError}"
        :errors="confirmationCodeError && [confirmationCodeError]"
      />
      <div
        v-if="confirmationCodeAPIError"
        class="mt-2 text-left text-sm text-error"
      >
        <TbIcon
          icon="exclamation-circle"
        />
        {{ confirmationCodeAPIError }}
      </div>
      <FormButton
        class="font-bold"
        text="Verify"
        color="primary"
        :disabled="state === PageState.loading"
      />
      <div
        v-if="state === PageState.error"
        class="my-4 text-center text-error"
        data-test="error-div"
      >
        {{ apiError }}
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';
import { TbInput, TbWordLogo } from '@/components/tasty_bistro';
import { useField, useForm } from 'vee-validate';
import {
  object, string, InferType,
} from 'yup';
import FormButton from '@/components/form_button.vue';
import { Pages } from '@/router';
import { PageState } from '@/types';
import { acceptTeamInvite } from './api';

const router = useRouter();
const apiError = ref('');
const confirmationCodeAPIError = ref('');
const state = ref<PageState>(PageState.loaded);

interface Props {
  data: { userId: string; projectName: string };
}
const props = defineProps<Props>();

const route = useRoute();

const inviteId = route.params.inviteId as string;

const userInfoSchema = object({
  confirmationCode: string()
    .matches(/^\d+$/, 'The verification code must contain only numbers.')
    .length(5, 'The verification code must be at least 5 characters long.')
    .required('Required field'),
});

interface UserInfo extends InferType<typeof userInfoSchema> {}
const { handleSubmit } = useForm<UserInfo>({
  validationSchema: userInfoSchema,
  initialValues: {
    confirmationCode: route.query.confirmationCode as string ?? '',
  },
});

const { value: confirmationCode, errorMessage: confirmationCodeError } = useField('confirmationCode');

const submit = handleSubmit(async (values) => {
  try {
    state.value = PageState.loading;
    await acceptTeamInvite({ ...values, inviteId, userId: props.data.userId });

    router.replace({ name: Pages.inviteSuccess });

    state.value = PageState.loaded;
  } catch (err: any) {
    confirmationCodeAPIError.value = '';
    apiError.value = '';

    state.value = PageState.error;
    const error = err.response?.data;

    if (error?.type) {
      if (error?.message?.toLowerCase().includes('invalid code')) {
        confirmationCodeAPIError.value = error?.message;
      } else if (error?.message?.toLowerCase().includes('invite expired')) {
        router.replace({ name: Pages.inviteExpired });
      } else {
        apiError.value = error?.message;
      }
    } else {
      apiError.value = 'Something went wrong. Please try again later.';
    }
  }
});

</script>

<style scoped lang="scss">
form > div {
  @apply pb-6;
}
</style>
