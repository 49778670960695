import http, { Http } from '@/lib/http';

export interface PaymentPlan {
    installments: number;
    frequency: 'monthly';
  }

export interface StackPreview {
    id: string;
    name: string;
    paymentPlans: PaymentPlan[];
    totalCount: number;
    ppmEnabled: boolean,
    lnplEnabled: boolean,
  }

function getStackPreviewsCtor(http: Http) {
  return async (projectId: string): Promise<StackPreview[]> => {
    const { data } = await http.get<StackPreview[]>(`/projects/${projectId}/checkouts/payment-previews`);
    return data;
  };
}

export const getStackPreviews = getStackPreviewsCtor(http);
