<template>
  <Page>
    <template #title>
      Applications
    </template>

    <div>
      <Table
        table-id="application-table"
        item-type="Application"
        date-key="createdAt"
        :items="applications"
        :state="state"
        :total-item-count="applicationsCount"
        :items-per-page="pageSize"
        :headers="applicationTableHeaders"
        :count="{ singular: 'application', plural: 'applications' }"
        hideHeader
        @next="goToNextPage"
        @prev="goToPreviousPage"
      >
        <template #tableHeader>
          <div class="flex flex-wrap items-end gap-2">
            <div class="w-80">
              <TbInput
                v-model="search"
                label="Search"
                placeholder="Customer / Email / Product"
              />
            </div>
            <TbDateRangePicker
              class="block"
              :dates="dates"
              @selected-date-range="setDateRange"
            />
            <RoleControlledAction
              v-slot="{wrapper, restricted}"
              :user-role="roleName"
              :config-object="roleConfig.exportApplications"
            >
              <button
                :class="{ 'custom-disabled': restricted}"
                class="mt-2 button button--primary button--sm lg:mt-0"
              >
                <span
                  class="flex items-center gap-2"
                  @click="wrapper(()=>exportCsv())"
                >
                  Export to CSV
                  <TbDownloadIcon class="w-4 h-4 text-white" />
                </span>
              </button>
            </RoleControlledAction>
          </div>
        </template>

        <template #header(expiredAt)="{ value }">
          <div class="flex gap-2 items-center">
            <span>{{ value }}</span>
            <TbTooltip
              class="font-light"
              info="Each offer is valid until the shown expiry date, and don't worry,
              customers can easily go through our checkout again any time.
              We will not pull their credit again."
            />
          </div>
        </template>

        <template #row(studentName)="{ item }">
          <routerLink :to="{ name: Pages.dashboardStudent, params: { id: item.studentId }}">
            <div class="flex flex-col">
              <span>{{ item.studentName }}</span>
              <span class="text-xs text-gray-500">
                {{ item.studentEmail }}
              </span>
            </div>
          </routerLink>
        </template>

        <template #row(formattedOrigin)="{ value }">
          <OriginChip :title="value" />
        </template>

        <template #row(productPriceInCents)="{ item, value }">
          <span class="currency">{{ item.currencyCode + currencyInCents(value) }}</span>
        </template>

        <template #row(applicationResult)="{ value }">
          <div>
            <div
              class="inline-block w-2 h-2 mr-1 rounded-full"
              :class="orderApplicationResultToColorMap[value]"
            />
            {{ result(value) }}
          </div>
        </template>

        <template #row(applicationDetails)="{ item }">
          <ApplicationPlanControl
            :application="(item as Application)"
          />
        </template>
      </Table>
    </div>
  </Page>
  <QuickStartGuideLink />
</template>

<script setup lang="ts">
import {
  ref, watch, onActivated, inject, Ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash';
import { subMonths } from 'date-fns';
import {
  TbDateRangePicker, TbInput, TbTooltip, TbDownloadIcon,
} from '@/components/tasty_bistro';
import Table from '@/components/table.vue';
import { Pages } from '@/router';
import { PageState } from '@/types';
import { formatDate } from '@/filters/date';
import { downloadFile } from '@/helpers/download_file';
import { result } from '@/filters/naming/order';
import { orderApplicationResultToColorMap } from '@/helpers/category_color_mapper';
import OriginChip from '@/components/origin_chip.vue';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import { currencyInCents } from '@/filters/currency';
import Page from '../components/page.vue';
import { getApplications, Application } from './api/get_applications';
import { getApplicationsDownload } from './api/get_applications_download';
import ApplicationPlanControl from './components/application_plan_control/index.vue';
import { roleConfig } from './role_configurations';

const route = useRoute();
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = route.params.projectId as string;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const applicationTableHeaders = [
  {
    title: 'Created On',
    key: 'createdAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Expires On',
    key: 'expiredAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Customer',
    key: 'studentName',
  },
  {
    title: 'Checkout',
    key: 'productName',
  },
  {
    title: 'Type',
    key: 'formattedOrigin',
  },
  {
    title: 'Total',
    key: 'productPriceInCents',
  },
  {
    title: 'Result',
    key: 'applicationResult',
  },
  {
    title: 'Details',
    key: 'applicationDetails',
  },
];

const state = ref(PageState.loaded);
const csvState = ref(PageState.loaded);
const applications = ref<Application[]>();
const applicationsCount = ref(0);

const dates = ref([subMonths(new Date(), 6), new Date()]);
const search = ref('');
const pageSize = 20;
const currentPage = ref(0);

const exportCsv = async () => {
  try {
    csvState.value = PageState.loading;
    const file = await getApplicationsDownload({
      projectId,
      filter: {
        search: search.value,
        dates: dates.value,
      },
    });
    downloadFile({ file, fileName: 'elective_applications.csv' });
    csvState.value = PageState.loaded;
  } catch (error) {
    csvState.value = PageState.error;
  }
};

const loadApplications = async () => {
  try {
    state.value = PageState.loading;
    const data = await getApplications({
      projectId,
      filter: {
        search: search.value,
        dates: dates.value,
        currentPage: currentPage.value,
        pageSize,
      },
    });
    applications.value = data.applications;
    applicationsCount.value = data.totalCount;
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadApplications();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadApplications();
};

onActivated(async () => {
  await loadApplications();
});

watch(search, debounce(async () => {
  currentPage.value = 0;
  await loadApplications();
}, 500));

const setDateRange = async (newDates: Date[]) => {
  currentPage.value = 0;
  dates.value = newDates;
  await loadApplications();
};
</script>

<style lang="scss" scoped>
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
