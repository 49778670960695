<template>
  <Page>
    <Table
      date-key="createdAt"
      :items="paymentStacks"
      :total-item-count="paymentStackCount"
      :items-per-page="pageSize"
      :state="state"
      item-type="Payment Setting"
      :headers="tableHeader"
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div class="flex flex-row flex-wrap gap-2">
          <div class="w-full max-w-xs">
            <TbInput
              v-model="search"
              placeholder="Filter payment settings here"
            />
          </div>
          <TbDrawer>
            <template #default="{ toggleDrawer }">
              <div class="flex flex-col h-full">
                <CreateStack
                  @cancel="toggleDrawer()"
                  @submit="toggleDrawer(); loadPaymentStacks()"
                />
              </div>
            </template>

            <template #toggle="{ toggleDrawer }">
              <RoleControlledAction
                v-slot="{ wrapper, restricted }"
                :user-role="roleName"
                :config-object="roleConfig.createPaymentStacks"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--primary button--sm"
                  @click="wrapper(() => toggleDrawer())"
                >
                  <span class="flex items-center gap-2">
                    Create Payment Setting
                    <TbPlusIcon class="w-4 h-4 text-white" />
                  </span>
                </button>
              </RoleControlledAction>
            </template>
          </TbDrawer>
        </div>
      </template>

      <template #action="{ item, toggleDropdown }">
        <div class="flex flex-col h-full gap-1">
          <!-- Update -->
          <TbDrawer>
            <template #default="{ toggleDrawer }">
              <div class="flex flex-col h-full">
                <UpdateStack
                  :stack="item"
                  @cancel="toggleDrawer(); toggleDropdown();"
                  @submit="toggleDrawer(); toggleDropdown(); loadPaymentStacks()"
                />
              </div>
            </template>
            <template #toggle="{ toggleDrawer }">
              <RoleControlledAction
                v-slot="{ wrapper, restricted }"
                :user-role="roleName"
                :config-object="roleConfig.updatePaymentStacks"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--block button--sm text-left"
                  @click="wrapper(() => toggleDrawer())"
                >
                  Update
                </button>
              </RoleControlledAction>
            </template>
          </TbDrawer>

          <!-- Delete -->
          <DeleteStack
            :stack="item"
            @archive="toggleDropdown(); loadPaymentStacks()"
            @cancel="toggleDropdown();"
          >
            <template #action="{ deleteHandler }">
              <RoleControlledAction
                v-slot="{ wrapper, restricted }"
                :user-role="roleName"
                :config-object="roleConfig.deletePaymentStacks"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--block text-error-900 button--sm text-left"
                  @click="wrapper(() => deleteHandler());"
                >
                  Delete
                </button>
              </RoleControlledAction>
            </template>
          </DeleteStack>
        </div>
      </template>

      <template #row(paymentPlans)="{ value }">
        <div class="whitespace-normal">
          <div
            v-for="pp in value"
            :key="pp"
            class="inline-flex flex-wrap gap-2 mb-1"
          >
            <div class="px-2 mr-2 bg-gray-300 rounded-md">
              {{ pp.installments }} Months ({{ pp.downPaymentPercentage }}%)
            </div>
          </div>
        </div>
      </template>

      <template #row(lnplEnabled)="{ item }">
        <template v-if="item.lnplEnabled">
          <div class="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4 fill-success-800"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75
            9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53
            12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clip-rule="evenodd"
              />
            </svg>

            <span>
              Enabled
            </span>
          </div>
        </template>

        <template v-else>
          <div class="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4 fill-gray-300"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75
                9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75
                0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12
                13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75
                0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clip-rule="evenodd"
              />
            </svg>

            <span>
              Not Enabled
            </span>
          </div>
        </template>
      </template>

      <template #row(payInFullEnabled)="{ item }">
        <template v-if="item.payInFullEnabled">
          <div class="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4 fill-success-800"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75
            9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53
            12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clip-rule="evenodd"
              />
            </svg>

            <span>
              Enabled
            </span>
          </div>
        </template>

        <template v-else>
          <div class="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4 fill-gray-300"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75
                9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75
                0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12
                13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75
                0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clip-rule="evenodd"
              />
            </svg>

            <span>
              Not Enabled
            </span>
          </div>
        </template>
      </template>

      <template #row(ppmEnabled)="{ item }">
        <template v-if="item.ppmEnabled && item.paymentPlans.filter((pp: any) => pp.installments !== 1).length > 0">
          <div class="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4 fill-success-800"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75
            9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53
            12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clip-rule="evenodd"
              />
            </svg>

            <div class="flex flex-row gap-2 items-center">
              Enabled
              <!-- update drawer trigger -->
              <TbDrawer>
                <template #default="{ toggleDrawer }">
                  <div class="flex flex-col h-full">
                    <UpdateStack
                      :stack="(item as any)"
                      @cancel="toggleDrawer();"
                      @submit="toggleDrawer(); loadPaymentStacks()"
                    />
                  </div>
                </template>
                <template #toggle="{ toggleDrawer }">
                  <RoleControlledAction
                    v-slot="{ wrapper, restricted }"
                    :user-role="roleName"
                    :config-object="roleConfig.updatePaymentStacks"
                  >
                    <div
                      :class="{ 'custom-disabled': restricted}"
                      class="text-gray-500 text-xs cursor-pointer hover:underline"
                      @click="wrapper(() => toggleDrawer())"
                    >
                      ({{ plansWithoutFull(item.paymentPlans).length }}
                      {{ plansWithoutFull(item.paymentPlans).length > 1 ? 'plans' : 'plan' }})
                    </div>
                  </RoleControlledAction>
                </template>
              </TbDrawer>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4 fill-gray-300"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75
                9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75
                0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12
                13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75
                0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clip-rule="evenodd"
              />
            </svg>

            <span>
              Not Enabled
            </span>
          </div>
        </template>
      </template>
    </Table>
  </Page>
</template>

<script lang="ts" setup>
import {
  onMounted, ref, watch, Ref, inject,
} from 'vue';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash';
import {
  TbInput, TbPlusIcon, TbDrawer,
} from '@/components/tasty_bistro';
import { PageState } from '@/types';
import { AppState } from '@/pages/app/api/get_app_state';
import Table from '@/components/table.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import Page from '../../components/page.vue';
import { getPaymentStacks, PaymentStack } from './api/get_stacks';
import CreateStack from './components/create.vue';
import UpdateStack from './components/update.vue';
import DeleteStack from './components/delete.vue';
import { roleConfig } from './role_configurations';

const route = useRoute();

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = route.params.projectId as string;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const pageSize = 10;
const currentPage = ref(0);
const paymentStacks = ref<PaymentStack[]>([]);
const paymentStackCount = ref<number>(0);
const search = ref('');
const state = ref(PageState.loaded);

const tableHeader = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Learn Now, Pay Later',
    key: 'lnplEnabled',
  },
  {
    title: 'Pay in Full',
    key: 'payInFullEnabled',
  },
  {
    title: 'Elective Billing',
    key: 'ppmEnabled',
  },
];

const plansWithoutFull = (plans: any[]) => plans.filter((pp) => pp.installments !== 1);

async function loadPaymentStacks() {
  try {
    state.value = PageState.loading;

    const data = await getPaymentStacks({
      projectId,
      params: {
        pageSize,
        currentPage: currentPage.value,
        name: search.value,
      },
    });

    paymentStacks.value = data.data.map((d) => ({
      ...d,
      payInFullEnabled: d.paymentPlans.find((pp) => pp.installments === 1),
    }));
    paymentStackCount.value = Number(data.count);

    state.value = PageState.loaded;
  } catch {
    state.value = PageState.error;
  }
}

onMounted(async () => {
  await loadPaymentStacks();
});

watch(search, debounce(async () => {
  currentPage.value = 0;
  await loadPaymentStacks();
}, 500));

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadPaymentStacks();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadPaymentStacks();
};

</script>

<style lang="scss" scoped>
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
