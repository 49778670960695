export const applyMeta = (routes: any[], meta = {}, includeChildren = true): any => routes.map((r: any) => {
  r.meta = { ...r.meta, ...meta };
  if (includeChildren && r.children) { r.children = applyMeta(r.children, meta); }
  return r;
});

export const createPageLookup = (routes: any[]) => {
  const lookup: Record<string, string> = {};

  const findName = (currentRoutes: any[]) => {
    currentRoutes.forEach((route: any) => {
      const name = route.name as string;

      if (!name) {
        return;
      }

      if (lookup[name]) {
        throw Error('Duplicate route name');
      }

      lookup[name] = name;

      const children = route.children as any[] | undefined;
      if (children) {
        findName(children);
      }
    });
  };

  findName(routes);
  return lookup;
};
