import http, { Http } from '@/lib/http';

export interface BusinessDetails {
  legalName: string;
  publicName: string;
  businessNumber: string;
  websiteUrl: string;
  address: {
    street: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    complement: string;
  },
  productTypes: string[];
}

function addBusinessDetailsCtor(http: Http) {
  return async (form: BusinessDetails, projectId: string) => {
    await http.post(`/projects/${projectId}/business-details`, form);
  };
}

export const addBusinessDetails = addBusinessDetailsCtor(http);
