<template>
  <div class="max-h-full max-w-full h-full">
    <TbLineChart
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  ChartOptions,
  TimeScale,
} from 'chart.js';
import TbLineChart from '@/lib/graphs/line_chart';
import TbTooltip from '@/lib/graphs/tooltip';
import { defaultColors } from '@/lib/graphs/colors';
import gradient from 'chartjs-plugin-gradient';
import 'chartjs-adapter-date-fns';
import { chartFonts } from '@/lib/graphs/fonts';

export interface Dataset {
  label: string;
  data: number[];
  title?: {
    display: boolean;
    text: string;
  };
  fill?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  type?: 'line';
}

export interface Props {
  dataset: Dataset[];
  labels: string[];
  labelFormatter?: (value: number | string, index?: number, ticks?: unknown) => string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  gradient,
);

const props = defineProps<Props>();

const chartData = {
  labels: props.labels,
  datasets: props.dataset.map((d) => {
    d.fill ??= true;
    d.borderColor ??= defaultColors[0];
    d.backgroundColor ??= 'rgba(169, 224, 224, .3)';

    return {
      ...d,
      pointStyle: 'circle',
      pointBackgroundColor: defaultColors[0],
      label: false,
      pointRadius: 4,
      pointHoverRadius: 8,
      display: !!d.title,
      borderWidth: 2,
    };
  }),
};

const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 24,
    },
  },
  interaction: {
    intersect: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      ...TbTooltip,
      callbacks: {
        title: () => '',
        label: (ctx: any) => {
          const label = ctx.label || '';
          const value = ctx.parsed.y;

          if (value && value > 0) {
            return `${label}: ${props.labelFormatter ? props.labelFormatter(value) : value}`;
          }

          return label;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      type: 'time',
      offset: false,
      time: {
        minUnit: 'week',
        tooltipFormat: 'MMM do, yyyy',
      },
      ticks: {
        padding: 4,
        font: {
          size: 14,
          ...chartFonts,
        },
      },
    },
    y: {
      offset: false,
      beginAtZero: true,
      grid: {
        display: true,
      },
      ticks: {
        callback: props.labelFormatter,
        padding: 4,
        maxTicksLimit: 5,
        font: {
          size: 14,
          family: 'karla-regular, ui-sans-serif, system-ui, Arial',
        },
      },
      border: {
        dash: [6, 8],
      },
    },
  },
};

</script>
