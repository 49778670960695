<template>
  <div class="flex flex-row items-center w-full bg-gray-100 text-gray-400 rounded-md">
    <div class="h-full flex-shrink-0">
      <select
        class="input relative"
        style="padding-right: 30px; z-index: 10;"
        :value="
          type"
        @change="handleTypeChange"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
    </div>

    <input
      ref="inputRef"
      :value="valueInDollars"
      class="input flex-grow relative flex-1"
      type="text"
      :placeholder="placeholder"
      @change="handleValueChange"
    >
  </div>

  <small
    v-if="errorMessage"
    class="text-error-900"
  >{{ errorMessage }}</small>
</template>

<script lang="ts" setup>
import {
  ref, toRefs, watch, nextTick, computed,
} from 'vue';
import { useMask } from '../hooks/use_mask';

  interface Props{
      type: string;
      value: number | null;
      placeholder: string;
      errorMessage?: string;
      totalAmount: number;
      maxInCents?: number;
  }

const props = defineProps<Props>();
const emit = defineEmits(['change-type', 'change-value']);

const errorMessage = ref('');

const refProps = toRefs(props);

const { inputRef, formatInputValue } = useMask({
  type: refProps.type,
});

const valueInDollars = computed(() => {
  if (!props.value || !props.type) return 0;
  if (props.type === 'percentage') {
    return props.value.toFixed(2);
  }

  return (props.value / 100).toFixed(2);
});

watch(() => props.totalAmount, async () => {
  await nextTick();
  formatInputValue();
});

watch(() => props.type, () => {
  // temporary
  emit('change-value', 0);
});

const options = [
  { name: '%', value: 'percentage' },
  { name: '$', value: 'cents' },
];

const handleTypeChange = (event: Event) => {
  const { value } = event.target as HTMLSelectElement;
  emit('change-type', value);
};

const handleValueChange = (event: Event) => {
  const { value } = event.target as HTMLInputElement;

  if (props.type === 'cents') {
    if (props.maxInCents && Number(value) * 100 > props.maxInCents) {
      emit('change-value', props.maxInCents);
      return;
    }
    emit('change-value', Number(value) * 100);
    return;
  }

  if (props.type === 'percentage') {
    // if number is a float round to a whole number
    if (Number(value) > 100) {
      emit('change-value', Number(100));
      return;
    }

    if (!Number.isInteger(Number(value))) {
      emit('change-value', Math.round(Number(value)));
      return;
    }

    emit('change-value', Number(value));
  }
};
</script>

  <style lang="scss" scoped>
  input, select {
    &:focus {
      z-index: 10;
    }
  }
  </style>
