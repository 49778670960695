import http, { Http } from '@/lib/http';

export interface CreateCheckoutParams {
  name: string;
  status: 'inactive' | 'active';
  redirectUrl: string | null;
  termsUrl: string | null;
  paymentSettingsId: string;
  products: string[];
  downPaymentType: 'percentage' | 'cents';
  downPaymentValue: number;
}

interface Data {
  projectId: string;
  form: CreateCheckoutParams;
}

function createCheckoutCtor(http: Http) {
  return async function createProduct({ projectId, form }: Data): Promise<void> {
    await http.post(`/projects/${projectId}/checkouts`, form);
  };
}

export const createCheckout = createCheckoutCtor(http);
