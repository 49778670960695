import http, { Http } from '@/lib/http';

interface Credentials {
  securityKey: string;
  collectJs?: string;
  threeDSecure?: string;
}

interface Params {
  projectId: string;
  credentials: Credentials;
}

function submitCredentialsCtor(http: Http) {
  return async ({ projectId, credentials }: Params): Promise<any> => {
    const { data } = await http.post(`/projects/${projectId}/integrations/nmi/credentials`, { credentials });
    return data;
  };
}

export const submitCredentials = submitCredentialsCtor(http);
