import http, { Http } from '@/lib/http';

interface FileLocation {
  path: string;
  link: string;
}

interface Agreement {
  agreement: FileLocation;
  feeSchedule: FileLocation;
}

function getPricingAgreementCtor(http: Http) {
  return async (projectId: string) => {
    const { data } = await http.get<Agreement>(`/projects/${projectId}/pricing/agreement`);

    return data;
  };
}

export const getPricingAgreement = getPricingAgreementCtor(http);
