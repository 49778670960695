<template>
  <Input
    v-maska="'+1 (###) ###-####'"
    type="phone"
    inputmode="numeric"
  />
</template>

<script setup lang="ts">
import { vMaska as maska } from 'maska/vue';
import Input from '../input/input.vue';

// custom directive used for phone input masking
const vMaska = maska;

</script>
