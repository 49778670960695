<template>
  <Banner
    class="bg-primary-100"
    :show="showBanner"
  >
    <template #default>
      <div class="flex flex-row">
        <!-- left col -->
        <div class="flex flex-col grow">
          <h6 class="font-bold text-md">
            We've upgraded your checkouts! 🎉
          </h6>
          <p>
            You can now create a checkout with multiple products for sale. <a
              href="https://help.elective.com/hc/en-us/articles/22011989804699"
              class="text-secondary"
            >
              Click here to learn more!
            </a>
          </p>
        </div>

        <!-- right col -->
        <div class="flex flex-row gap-4 justify-center items-center">
          <button
            class="button button--primary button--sm"
            @click="closeBanner"
          >
            Dismiss
          </button>
        </div>
      </div>
    </template>
  </Banner>
</template>

<script lang="ts" setup>
import {
  onMounted, ref,
} from 'vue';
import { useStorage } from '@vueuse/core';
import Banner from '@/components/banner.vue';

const showBanner = ref(false);

const closeBanner = () => {
  showBanner.value = false;
  localStorage.setItem('ignoreV2Banner', 'true');
};

onMounted(() => {
  const storedValue = useStorage('ignoreV2Banner', false);

  if (!storedValue.value) {
    showBanner.value = true;
  }
});
</script>
