import NotAllowed from '@/pages/error/not_allowed.vue';
import NotFound from '@/pages/error/not_found.vue';
import Crash from '@/pages/error/crash.vue';

export enum Page {
  notAllowed = 'notAllowed',
  notFound = 'notFound',
  crash = 'crash',
}

export const errorRoutes = [
  {
    path: '/not-allowed',
    name: Page.notAllowed,
    component: NotAllowed,
  },
  {
    path: '/crash',
    name: Page.crash,
    component: Crash,
  },
  {
    path: '/:pathMatch(.*)*',
    name: Page.notFound,
    component: NotFound,
  },
];
