<template>
  <Page class="max-w-[478px]">
    <div class="mb-8">
      <router-link
        :to="{name: 'dashboardIntegrations'}"
        class="text-primary flex items-center font-semibold mb-"
      >
        <TbArrowLeftIcon class="w-4 h-4" /> Back
      </router-link>
    </div>
    <img
      src="../assets/zapier.svg"
      alt="Zapier Logo"
      class="h-[65px] self-start mb-4"
    >
    <TbLoading
      v-if="state === 'loading'"
      class="p-8"
    />
    <div
      v-else-if="state === 'error'"
      class="my-4 text-center text-error"
    >
      {{ apiError }}
    </div>

    <div
      v-else-if="state === 'loaded'"
      class="flex flex-col"
    >
      <section v-if="zapierCredentials.apiKey && zapierCredentials.secret">
        <div class="font-semibold text-3xl mb-3">
          Connect to Zapier
        </div>

        <p class="pb-3 max-w-sm text-sm">
          Use the <strong class="font-bold">Username</strong>, and <strong class="font-bold">Password</strong> when
          authenticating a new Zap in your Zapier account.
        </p>

        <div class="mb-2">
          <p class="text-sm pl-2 pb-1">
            Username
          </p>
          <p class="bg-gray-200 py-2 px-4 text-sm rounded-md">
            {{ zapierCredentials.apiKey }}
          </p>
        </div>
        <div>
          <p class="text-sm pl-2 pb-1">
            Password
          </p>
          <p class="bg-gray-200 py-2 px-4 text-sm rounded-md">
            {{ zapierCredentials.secret }}
          </p>
        </div>
        <p class="py-3">
          Need help getting setup with Zapier? <br>
          <a
            href="
            https://help.elective.com/hc/en-us/articles/8219760840091"
            target="_blank"
          >
            Click here for our Help Center article for more.
          </a>
        </p>

        <TbDrawer v-if="zapierCredentials.apiKey && zapierCredentials.secret">
          <template #toggle="{toggleDrawer}">
            <Tooltip :disabled="!isTestButtonDisabled">
              <template #trigger="{ assignReference, show, hide }">
                <div
                  :ref="(el: any) => assignReference(el)"
                  @mouseenter="show"
                  @mouseleave="hide"
                >
                  <button
                    class="w-full text-center button button--primary"
                    :disabled="isTestButtonDisabled"
                    @click="toggleDrawer"
                  >
                    Test Zaps
                  </button>
                </div>
              </template>

              <template #tooltip>
                You must have a published Zap to Test Zaps.
              </template>
            </Tooltip>
          </template>
          <template #default="{toggleDrawer}">
            <FormsDialog
              :checkouts="checkouts"
              :connected-zaps="connectedZaps"
              @close-modal="toggleDrawer"
              @triggered-successfully="onTriggerSuccessful"
            />
          </template>
        </TbDrawer>
      </section>
      <section v-else>
        <p class="py-3">
          There are no Zapier credentials associated with your account.
        </p>

        <button
          class="w-full text-center button button--primary"
          @click="generateKeys"
        >
          Generate Login Details
        </button>
      </section>

      <TbNotification
        :show="zapNotification"
        title="Success"
        message="All connected Zaps were triggered."
        @close="zapNotification = false"
      />
    </div>
  </Page>
</template>

<script setup lang="ts">
import {
  computed,
  onMounted, reactive, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  TbLoading, TbNotification, TbDrawer, TbArrowLeftIcon,
} from '@/components/tasty_bistro';
import Tooltip from '@/components/tooltip.vue';
import Page from '@/pages/app/project/dashboard/components/page.vue';
import { keysApi } from './api/keys_api';
import FormsDialog from './forms/forms-dialog.vue';
import { ConnectedZaps, getConnectedZaps } from './api/get_connected_zaps';
import { getCheckouts, Checkout } from './api/get_checkouts';

const route = useRoute();
const projectId = route.params.projectId as string;

const state = ref<'loading' | 'error' | 'loaded'>('loaded');
const apiError = ref<string>();
const zapierCredentials = reactive({
  apiKey: '',
  secret: '',
});
const connectedZaps = ref<ConnectedZaps[]>([]);
const checkouts = ref<Checkout[]>([]);
const zapNotification = ref<boolean>(false);

async function loadKeys() {
  const apiKeys = await keysApi.getApiKey(projectId);
  if (apiKeys) {
    zapierCredentials.apiKey = apiKeys.apiKey;
    zapierCredentials.secret = apiKeys.secret;
  }
}

async function loadZapsConnected() {
  const loadedZaps = await getConnectedZaps(projectId);
  connectedZaps.value = loadedZaps;
}

async function loadActiveProducts() {
  const loadedCheckouts = await getCheckouts(projectId);
  checkouts.value = loadedCheckouts;
}

onMounted(async () => {
  try {
    await loadKeys();
    await loadZapsConnected();
    await loadActiveProducts();
  } catch (err: any) {
    console.error(err);
    state.value = 'error';
    apiError.value = err;
  } finally {
    state.value = 'loaded';
  }
});

function hasZapConnected() {
  return connectedZaps.value.some((a) => a.count > 0);
}

function hasCheckouts() {
  return checkouts.value.length > 0;
}

const isTestButtonDisabled = computed(() => !(hasZapConnected() && hasCheckouts()));

function onTriggerSuccessful() {
  zapNotification.value = true;
}

async function generateKeys() {
  try {
    state.value = 'loading';
    const apiKeys = await keysApi.createApiKey(projectId);
    zapierCredentials.apiKey = apiKeys.apiKey;
    zapierCredentials.secret = apiKeys.secret;
  } catch (err: any) {
    console.error(err);
    state.value = 'error';
    apiError.value = err;
  } finally {
    state.value = 'loaded';
  }
}
</script>
