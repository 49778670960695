<template>
  <slot
    :assignReference="assignReference"
    name="trigger"
    :show="show"
    :hide="manualClose"
  />

  <Teleport to="body">
    <Transition
      :enter-active-class="getTransisionStates.enter"
      :enter-from-class="getTransisionStates.enterFrom"
      :enter-to-class="getTransisionStates.enterTo"
      :leave-active-class="getTransisionStates.leave"
      :leave-from-class="getTransisionStates.leaveFrom"
      :leave-to-class="getTransisionStates.leaveTo"
      @after-enter="onAfterEnter"
      @after-leave="onAfterLeave"
    >
      <div
        v-if="visible"
        ref="floating"
        :style="floatingStyles"
        :class="['absolute z-50']"
      >
        <slot
          name="tooltip"
          :close="manualClose"
          :triggerWidth="triggerWidth"
        />
      </div>
    </Transition>
  </Teleport>

  <Teleport
    v-if="visualize"
    to="body"
  >
    <svg
      v-if="svgVisible"
      width="100%"
      height="100%"
      style="
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;
          z-index: 999;
        "
    >
      <!-- Definitions for masking -->
      <defs>
        <mask id="referenceMask">
          <rect
            width="100%"
            height="100%"
            fill="white"
          />
          <polygon
            :points="polygonToPoints(areas[0])"
            fill="black"
          />
        </mask>
      </defs>

      <!-- Render polygons -->
      <template v-for="(polygon, index) in areas">
        <polygon
          v-if="index !== 0"
          :key="'polygon-' + index"
          :points="polygonToPoints(polygon)"
          :style="
            index === 2
              ? {
                fill: fillColors[index],
                stroke: 'black',
                strokeWidth: '1',
                mask: 'url(#referenceMask)',
              }
              : {
                fill: fillColors[index],
                stroke: 'black',
                strokeWidth: '1',
              }
          "
        />
      </template>

      <!-- Render reference polygon last to keep it on top -->
      <polygon
        :points="polygonToPoints(areas[0])"
        :style="{
          fill: fillColors[0],
          stroke: 'black',
          strokeWidth: '1',
        }"
      />
    </svg>
  </Teleport>
</template>

<script lang="ts" setup>
import {
  VNodeRef,
  computed, nextTick, onUnmounted, ref, watch,
} from 'vue';
import {
  autoUpdate, flip, offset, shift, useFloating,
} from '@floating-ui/vue';

interface Props {
    mode?: 'hover' | 'click';
    arrow?: boolean;
    visualize?: boolean;
    traverse?: boolean;
    placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end';
    offset?: number;
    disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'hover',
  arrow: false,
  visualize: false,
  traverse: false,
  placement: 'top-start',
  offset: 8,
});

const emit = defineEmits(['close']);

type Point = {
  x: number;
  y: number;
};
type Polygon = Point[];

const visible = ref(false);

const reference = ref<VNodeRef | null>(null);
const floating = ref<HTMLElement | null>(null);

const assignReference = (el: VNodeRef | undefined) => {
  reference.value = el;
};

const { floatingStyles, placement } = useFloating(reference, floating, {
  whileElementsMounted: autoUpdate,
  middleware: [flip(), shift(), offset(8)],
  placement: props.placement || 'top-start',
  transform: false,
});

// make the interaction polygon between the reference and the floating element
const areas = ref<Polygon[]>([]);

const triggerWidth = computed(() => {
  if (!reference.value) return 0;
  return reference.value.getBoundingClientRect().width;
});

function updateCorners() {
  if (!floating.value || !reference.value) return;

  const referenceBox = reference.value.getBoundingClientRect();
  const tooltipBox = floating.value.getBoundingClientRect();

  const [primaryDirection] = placement.value.split('-');

  interface Spec {
    direction: 'top' | 'bottom' | 'left' | 'right';
  }
  const spec: Spec = {
    direction: primaryDirection as Spec['direction'],
  };

  const polygons = [
    // The areas of the reference and floating themselves
    [
      { x: referenceBox.left, y: referenceBox.top },
      { x: referenceBox.right, y: referenceBox.top },
      { x: referenceBox.right, y: referenceBox.bottom },
      { x: referenceBox.left, y: referenceBox.bottom },
    ],
    [
      { x: tooltipBox.left, y: tooltipBox.top },
      { x: tooltipBox.right, y: tooltipBox.top },
      { x: tooltipBox.right, y: tooltipBox.bottom },
      { x: tooltipBox.left, y: tooltipBox.bottom },
    ],
  ];

  // Add the safe polygon based on direction
  if (spec.direction === 'right') {
    polygons.push([
      { x: referenceBox.left, y: referenceBox.top },
      { x: tooltipBox.left, y: tooltipBox.top },
      { x: tooltipBox.left, y: tooltipBox.bottom },
      { x: referenceBox.left, y: referenceBox.bottom },
    ]);
  } else if (spec.direction === 'left') {
    polygons.push([
      { x: referenceBox.right, y: referenceBox.top },
      { x: tooltipBox.right, y: tooltipBox.top },
      { x: tooltipBox.right, y: tooltipBox.bottom },
      { x: referenceBox.right, y: referenceBox.bottom },
    ]);
  } else if (spec.direction === 'bottom') {
    polygons.push([
      { x: referenceBox.left, y: referenceBox.top },
      { x: tooltipBox.left, y: tooltipBox.top },
      { x: tooltipBox.right, y: tooltipBox.top },
      { x: referenceBox.right, y: referenceBox.top },
    ]);
  } else {
    polygons.push([
      { x: referenceBox.left, y: referenceBox.bottom },
      { x: tooltipBox.left, y: tooltipBox.bottom },
      { x: tooltipBox.right, y: tooltipBox.bottom },
      { x: referenceBox.right, y: referenceBox.bottom },
    ]);
  }

  areas.value = polygons;
}

const svgVisible = ref(false);
const visualizeAreas = () => {
  svgVisible.value = true;
};
const unvisualizeAreas = () => {
  svgVisible.value = false;
};

const polygonToPoints = (polygon: Polygon) => polygon.map((point) => `${point.x},${point.y}`).join(' ');
const fillColors = [
  'rgba(255,0,0,0.3)',
  'rgba(0,255,0,0.3)',
  'rgba(0,0,255,0.3)',
];

function isPointInPolygon(x: number, y: number, polygon: Polygon) {
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i, i += 1) {
    const xi = polygon[i].x;
    const yi = polygon[i].y;
    const xj = polygon[j].x;
    const yj = polygon[j].y;

    const deltaX = xj - xi;
    const deltaY = y - yi;
    const deltaYPrime = yj - yi;

    const interpolatedValue = (deltaX * deltaY) / deltaYPrime + xi;

    const result = x < interpolatedValue;

    const intersect = yi > y !== yj > y && result;

    if (intersect) inside = !inside;
  }

  return inside;
}

function isPointInAnyPolygon(x: number, y: number, polygons: Polygon[]) {
  return polygons.some((polygon) => isPointInPolygon(x, y, polygon));
}

const moveHandler = (e: MouseEvent) => {
  const pointInside = props.mode === 'click'
    ? isPointInPolygon(e.clientX, e.clientY, areas.value[1])
    : isPointInAnyPolygon(e.clientX, e.clientY, areas.value);

  if (!pointInside) {
    if (props.mode === 'hover') {
      document.removeEventListener('mousemove', moveHandler);
    } else {
      document.removeEventListener('click', moveHandler);
    }
    visible.value = false;
    emit('close');
  }
};

const manualClose = () => {
  if (props.mode === 'hover') {
    document.removeEventListener('mousemove', moveHandler);
  } else {
    document.removeEventListener('click', moveHandler);
  }
  visible.value = false;
  emit('close');
};

const registerListener = () => {
  if (props.mode === 'hover') {
    document.addEventListener('mousemove', moveHandler);
  } else {
    document.addEventListener('click', moveHandler);
  }
};

onUnmounted(() => {
  if (props.mode === 'hover') {
    document.removeEventListener('mousemove', moveHandler);
  } else {
    document.removeEventListener('click', moveHandler);
  }
});

const show = () => {
  if (props.disabled) return;

  visible.value = true;
};

watch(placement, async (newPlacement, oldPlacement) => {
  if (newPlacement !== oldPlacement) {
    visible.value = false;
    await nextTick();
    visible.value = true;
  }
});

const onAfterEnter = () => {
  updateCorners();
  registerListener();
  visualizeAreas();
};

const onAfterLeave = () => {
  unvisualizeAreas();
};

const getTransisionStates = computed(() => {
  const [direction] = placement.value.split('-');
  switch (direction) {
    case 'top':
      return {
        enter:
          'transition duration-300 ease-out transform translate-y-2 opacity-0',
        enterFrom: 'opacity-0 transform translate-y-2',
        enterTo: 'opacity-100 transform translate-y-0',
        leave:
          'transition duration-300 ease-in transform translate-y-0 opacity-100',
        leaveFrom: 'opacity-100 transform translate-y-0',
        leaveTo: 'opacity-0 transform translate-y-2',
      };
    case 'bottom':
      return {
        enter:
          'transition duration-300 ease-out transform -translate-y-2 opacity-0',
        enterFrom: 'opacity-0 transform -translate-y-2',
        enterTo: 'opacity-100 transform translate-y-0',
        leave:
          'transition duration-300 ease-in transform translate-y-0 opacity-100',
        leaveFrom: 'opacity-100 transform translate-y-0',
        leaveTo: 'opacity-0 transform -translate-y-2',
      };

    case 'left':
      return {
        enter:
          'transition duration-300 ease-out transform -translate-x-2 opacity-0',
        enterFrom: 'opacity-0 transform -translate-x-2',
        enterTo: 'opacity-100 transform translate-x-0',
        leave:
          'transition duration-300 ease-in transform translate-x-0 opacity-100',
        leaveFrom: 'opacity-100 transform translate-x-0',
        leaveTo: 'opacity-0 transform -translate-x-2',
      };

    case 'right':
      return {
        enter:
          'transition duration-300 ease-out transform translate-x-2 opacity-0',
        enterFrom: 'opacity-0 transform translate-x-2',
        enterTo: 'opacity-100 transform translate-x-0',
        leave:
          'transition duration-300 ease-in transform translate-x-0 opacity-100',
        leaveFrom: 'opacity-100 transform translate-x-0',
        leaveTo: 'opacity-0 transform translate-x-2',
      };

    default:
      return {};
  }
});
</script>
