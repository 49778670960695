<template>
  <div class="mb-3 md:mb-5 flex flex-col justify-between lg:flex-row">
    <h2 class="text-lg font-bold">
      Quick Start Guide
    </h2>

    <p class="text-sm font-light sm:text-base">
      Looking for additional support? Email us at <HelpEmailLink />
    </p>
  </div>

  <div>
    <ActionCard
      v-for="(action, ind) in actionList"
      :key="action.name"
      :sequenceNumber="String(ind + 1)"
      :name="action.name"
      :description="action.description"
      :actionText="action.actionText"
      :actionLink="action.actionLink"
    />
  </div>

  <div class="py-4">
    <h2 class="text-lg font-bold mb-4">
      Additional Resources
    </h2>
    <div class="flex flex-col flex-grow-0 gap-4 pb-4 lg:flex-row">
      <InfoCard
        name="Visit Elective University"
        description="We've provided answers to commonly asked questions right here for you and your customers."
        icon="school"
        linkText="See FAQs"
        link="https://help.elective.com/hc/en-us"
      />

      <InfoCard
        name="A-Z Onboarding Guide"
        description="We've created a comprehensive and easy-to-follow onboarding guide to help you get set up with Elective."
        icon="book"
        linkText="Learn more"
        link="https://help.elective.com/hc/en-us/sections/8236836704027"
      />

      <InfoCard
        name="Marketing Guide"
        description="We have you covered with everything you need to educate your customers on Elective."
        icon="shop"
        linkText="Market Elective"
        link="https://help.elective.com/hc/en-us/sections/4459538477723"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Pages } from '@/router';
import HelpEmailLink from '@/components/help_email_link.vue';
import InfoCard from './info_card.vue';
import ActionCard from './action_card.vue';

const actionList = computed(() => {
  const actions = [{
    name: 'Get your checkout links',
    description: 'Easily create a checkout link from your Checkouts Dashboard.',
    actionText: 'Create Checkouts',
    actionLink: Pages.checkouts,
  }, {
    name: 'Integrate with Zapier',
    description: 'Connect Elective with hundreds of different applications with Zapier.',
    actionText: 'Connect Zapier',
    actionLink: Pages.dashboardIntegrations,
  }, {
    name: 'Upload your business logo',
    description: 'Choose your logo for your checkout page and marketing materials. You can update this at any time.',
    actionText: 'Upload Logo',
    actionLink: Pages.dashboardSettingsLogo,
  }];

  return actions;
});

</script>
