<template>
  <FormWrapper>
    <FormHeader
      heading="Tell us where to send your payments"
      subHeading="You’ll get paid upfront in full from Elective every week for transactions that we process for you"
    />

    <TbLoading
      v-if="state === PageState.loading"
      class="p-8"
    />

    <div v-show="state === PageState.loaded">
      <FormCA
        v-if="countryCode === 'CA'"
        :data="paymentDetailsCA"
        :namePlaceholder="namePlaceholder"
        :error="error"
        :state="state"
        @submit="submit"
      />

      <FormUS
        v-else
        :data="paymentDetailsUS"
        :namePlaceholder="namePlaceholder"
        :error="error"
        :state="state"
        @submit="submit"
      />
    </div>
  </FormWrapper>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import { PageState } from '@/types';
import { TbLoading } from '@/components/tasty_bistro';
import FormWrapper from '@/components/form_wrapper.vue';
import FormHeader from '@/components/form_header.vue';
import FormCA from './form_ca.vue';
import FormUS from './form_us.vue';
import { addPaymentDetails, getInitialData } from './api';
import {
  PaymentDetails,
  PaymentDetailsUS,
  PaymentDetailsCA,
  CountryCode,
} from '../types';

const props = defineProps<{ data: PaymentDetails }>();

const route = useRoute();
const router = useRouter();
const projectId = route.params.projectId as string;

const error = ref('');
const state = ref<PageState>(PageState.loaded);

const countryCode = ref(CountryCode.us);
const namePlaceholder = ref<string>('');

const paymentDetailsUS = ref(props.data as PaymentDetailsUS);
const paymentDetailsCA = ref(props.data as PaymentDetailsCA);

onMounted(async () => {
  try {
    state.value = PageState.loading;
    const initialData = await getInitialData(projectId);
    countryCode.value = initialData.addressCountry;
    namePlaceholder.value = `${initialData.ownerFirstName} ${initialData.ownerLastName}`;

    state.value = PageState.loaded;
  } catch (err: any) {
    state.value = PageState.loaded;
    error.value = err?.response?.data.message || 'Something went wrong';
  }
});

async function submit(data: PaymentDetails) {
  try {
    state.value = PageState.loading;
    await addPaymentDetails(data, projectId);
    router.push({ name: Pages.onboardingProgress });
  } catch (err: any) {
    state.value = PageState.loaded;
    error.value = err?.response?.data.message || 'Something went wrong';
  }
}

</script>
