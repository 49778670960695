import http, { Http } from '@/lib/http';

interface Data {
  productId: string;
  projectId: string;
}

function archiveProductCtor(http: Http) {
  return async function archiveProduct({ projectId, productId }:Data): Promise<void> {
    await http.delete(`/projects/${projectId}/products/${productId}`);
  };
}

export default archiveProductCtor(http);
