import http, { Http } from '@/lib/http';
import { CurrencyCode } from '@/types';

export interface PaymentPlan {
  id: string;
  v2?: boolean;
  installments: number;
  amountInCents: number;
  currencyCode: CurrencyCode;
  firstPaymentInCents: number;
  firstPaymentPercentage: number;
}

export interface Plans {
  plans: PaymentPlan[];
}

interface Data {
  id: string;
  projectId: string;
}

function getPaymentPlansCtor(http: Http) {
  return async ({ id, projectId }: Data): Promise<Plans> => {
    const { data } = await http.get<Plans>(`projects/${projectId}/applications/${id}/plans`);
    return data;
  };
}

export const getPaymentPlans = getPaymentPlansCtor(http);
