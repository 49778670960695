<template>
  <div class="h-full w-full">
    <Product
      v-if="selectedPage === 'product'"
      @to-checkout="navigateTo('checkout')"
    />

    <PaymentSettings
      v-if="selectedPage === 'stack'"
      @to-checkout="navigateTo('checkout')"
    />

    <Checkout
      v-if="selectedPage === 'checkout'"
      :form-state="formState"
      :error="error"
      :action="action"
      @new-product="navigateTo('product')"
      @new-stack="navigateTo('stack')"
      @submit="submit"
      @cancel="cancel"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  ref, provide, computed,
} from 'vue';
import {
  array, mixed, number, object, string,
} from 'yup';
import { useField, useForm } from 'vee-validate';
import { FormState } from '@/types';
import { AxiosError } from 'axios';
import Product from './product.vue';
import PaymentSettings from './payment_settings.vue';
import Checkout from './checkout.vue';
import { CreateCheckoutParams } from '../../checkouts/api/create_checkout';

interface Props {
    checkout?: CreateCheckoutParams;
    action: 'create' | 'update';
    fn: (checkout: any) => Promise<void>;
}

const props = defineProps<Props>();

const emit = defineEmits(['cancel']);

const selectedPage = ref<'checkout' | 'product' | 'stack'>('checkout');
const navigateTo = (page: 'checkout' | 'product' | 'stack') => {
  selectedPage.value = page;
};

const grandTotalInCents = ref(0);
provide('grandTotalInCents', grandTotalInCents);

const createCheckoutSchema = computed(() => object({
  status: string()
    .oneOf(['active', 'inactive'], 'Checkout status must be either active or inactive')
    .required('Checkout status is required '),
  name: string()
    .required('Checkout name is required'),
  termsUrl: string()
    .url('Please enter a valid terms of service link (e.g., https://elective.com)')
    .nullable(),
  redirectUrl: string()
    .url('Please enter a valid thank you page link (e.g., https://elective.com)')
    .nullable(),
  paymentSettingsId: string()
    .uuid()
    .required('Please assign a payment setting.'),
  downPaymentType: string().nullable()
    .oneOf(['percentage', 'cents', null], 'Down payment type must be either percentage or a fixed value'),
  downPaymentValue: mixed().nullable().when('downPaymentType', {
    is: (val: 'percentage' | 'cents') => !!val,
    then: mixed().when('downPaymentType', {
      is: 'percentage',
      then: number()
        .min(0, 'Down payment percentage cannot be less than 0%')
        .max(90, 'Down payment percentage cannot exceed 90%')
        .required('Down payment percentage is required'),
      otherwise: number()
        .min(0, 'Down payment cannot be less than $0')
        .max(grandTotalInCents.value - 1_00, 'Down payment cannot exceed the total amount of your checkout')
        .required('Down payment is required'),
    }),
    otherwise: number().min(0).nullable(),
  }),
  products: array()
    .of(string()
      .uuid())
    .min(1, 'At least one product is required')
    .required('A checkout must have at least one product'),
}));

const { validate, values } = useForm({
  validationSchema: createCheckoutSchema,
  initialValues: {
    status: props.checkout?.status || 'active',
    name: props.checkout?.name || '',
    paymentSettingsId: props.checkout?.paymentSettingsId || '',
    products: props.checkout?.products || [],
    termsUrl: props.checkout?.termsUrl || null,
    redirectUrl: props.checkout?.redirectUrl || null,
    downPaymentType: props.checkout?.downPaymentType || null,
    downPaymentValue: props.checkout?.downPaymentValue ?? null,
  },
});

const { value: status, errorMessage: statusError } = useField<'active' | 'inactive'>('status', {}, {
  validateOnValueUpdate: false,
});
const { value: name, errorMessage: nameError } = useField<string>('name', {}, {
  validateOnValueUpdate: false,
});
const { value: redirectUrl, errorMessage: redirectUrlError } = useField<string>('redirectUrl', {}, {
  validateOnValueUpdate: false,
});
const { value: termsUrl, errorMessage: termsUrlError } = useField<string>('termsUrl', {}, {
  validateOnValueUpdate: false,
});

const { value: downPaymentType, errorMessage: downPaymentTypeError } = useField<'percentage' | 'cents' | null>('downPaymentType', {}, {
  validateOnValueUpdate: false,
});
const { value: downPaymentValue, errorMessage: downPaymentValueError } = useField<number | null>('downPaymentValue', {}, {
  validateOnValueUpdate: false,
});

const { value: paymentSettingsId, errorMessage: paymentSettingsIdError } = useField<string>('paymentSettingsId', {}, {
  validateOnValueUpdate: false,
});
const { value: products, errorMessage: productsError } = useField<string[]>('products', {}, {
  validateOnValueUpdate: false,
});

provide('name', name);
provide('nameError', nameError);

provide('status', status);
provide('statusError', statusError);

provide('redirectUrl', redirectUrl);
provide('redirectUrlError', redirectUrlError);

provide('termsUrl', termsUrl);
provide('termsUrlError', termsUrlError);

provide('downPaymentType', downPaymentType);
provide('downPaymentTypeError', downPaymentTypeError);

provide('downPaymentValue', downPaymentValue);
provide('downPaymentValueError', downPaymentValueError);

provide('paymentSettingsId', paymentSettingsId);
provide('paymentSettingsIdError', paymentSettingsIdError);

const selectedCurrencyCode = ref<string | null>(null);
provide('selectedCurrencyCode', selectedCurrencyCode);

const addProduct = (product: { id: string; currencyCode: string }) => {
  const { id, currencyCode } = product;
  if (!selectedCurrencyCode.value || currencyCode === selectedCurrencyCode.value) {
    products.value = [...products.value, id];
    selectedCurrencyCode.value = currencyCode;
  }
};

const setPaymentSettingId = (paymentSettingId: string) => {
  paymentSettingsId.value = paymentSettingId;
};

provide('products', products);
provide('productsError', productsError);

provide('registerProduct', addProduct);
provide('updatePaymentSetting', setPaymentSettingId);

const formState = ref<FormState>(FormState.ready);

const error = ref<string>('');
const submit = async () => {
  try {
    error.value = '';
    formState.value = FormState.submitting;

    const response = await validate();

    if (!response.valid) {
      formState.value = FormState.error;
      return;
    }

    await props.fn(values);

    formState.value = FormState.ready;
  } catch (err) {
    formState.value = FormState.error;
    if (err instanceof AxiosError) {
      error.value = err.response?.data?.message;
    } else {
      error.value = 'An error occurred';
    }
  }
};

async function cancel() {
  emit('cancel');
}

</script>
