import http, { Http } from '@/lib/http';

function getDefaultProjectCtor(http: Http) {
  return async function getDefaultProject(): Promise<string | undefined> {
    const { data } = await http.get<string | undefined>('/default-project');
    return data;
  };
}

export const getDefaultProject = getDefaultProjectCtor(http);
