<template>
  <div class="w-full h-full">
    <div class="flex justify-center items-center w-full h-full">
      <template
        v-for="project in appState.projects"
        :key="project.id"
      >
        <router-link
          :to="`/projects/${project.id}`"
          class="flex gap-4 nav-link button focus:ring-0"
        >
          {{ project.name }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref } from 'vue';
import { AppState } from '@/pages/app/api/get_app_state';

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
</script>
