<template>
  <Transition v-if="show">
    <div
      class="fixed top-0 left-0 w-full h-full transition-all duration-200 opacity-30 overlay bg-primary-300"
      :class="zIndex"
      @click="setShowFalse"
    >
      <slot />
    </div>
  </Transition>
</template>

<script lang="ts" setup>
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },

  persistent: {
    type: Boolean,
    default: false,
  },

  zIndex: {
    type: String,
    default: 'z-20',
  },
});

const emit = defineEmits(['clicked']);

const setShowFalse = () => {
  if (props.persistent) {
    return;
  }

  emit('clicked');
};

document.addEventListener('keyup', (event) => {
  if (event.key === 'Escape') {
    setShowFalse();
  }
});
</script>

    <style lang="scss">
      .overlay {
        background-image:
          linear-gradient(135deg,#6b728080 10%,transparent 0,transparent 50%,#6b728080 0,#6b728080 60%,transparent 0,transparent);
        background-size: 7.07px 7.07px;
      }

      .v-enter-active,
      .v-leave-active {
        transition: opacity 200ms ease;
      }

      .v-enter-from,
      .v-leave-to {
        opacity: 0;
      }
    </style>
