<template>
  <Page>
    <div class="max-w-lg p-4 bg-white card">
      <h2 class="text-lg font-bold">
        Logo
      </h2>

      <div
        v-if="logo"
        className="max-w-52 relative flex items-start justify-start"
      >
        <img
          :src="logo"
          alt="Your logo"
          className="max-h-32 object-contain h-full w-full object-left"
        >
      </div>

      <p
        v-else
        class="max-w-sm text-sm"
      >
        No logo uploaded yet
      </p>

      <div class="flex flex-col gap-1 mt-4 text-gray-400 font-medium">
        <strong>Please ensure your logo meets the following criteria:</strong>
        <ul class="lisdisc pl-5">
          <li>Accepted file formats include .png and .jpg</li>
          <li>Recommended dimensions: 208 x 128px</li>
          <li>No background or padding</li>
        </ul>
      </div>

      <!-- Warning -->
      <Note
        v-if="warning"
        variant="warning"
        class="my-3"
      >
        {{ warning }}
      </Note>

      <router-link
        :to="{ name: Pages.uploadLogo }"
        class="block w-full text-center button button--primary mt-2"
      >
        Upload
      </router-link>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Pages } from '@/router';
import Note from '@/components/note.vue';
import Page from '../../components/page.vue';
import { getLogoUrl } from './api/get_logo_url';

const logo = ref('');
const warning = ref('');

const projectId = useRoute().params.projectId as string;

onMounted(async () => {
  try {
    logo.value = await getLogoUrl(projectId);
  } catch (error) {
    console.log(error);
  }
});

watch(logo, (newVal) => {
  const img = new Image();

  img.onload = () => {
    const uploadedRatio = img.width / img.height;
    const idealRatio = 208 / 128;

    if (uploadedRatio !== idealRatio) {
      warning.value = 'Your logo does not fit our recommendations and may appear distorted.';
    }
  };

  img.src = newVal;
});
</script>
