<template>
  <button
    class="button button--xs button--danger"
    :disabled="subscriber.isPrimary"
    @click="initRemove()"
  >
    Delete
  </button>

  <TbDialog
    :show="removeSubscriberDialog"
    @close="cancelRemove"
  >
    <template #header>
      <h2 class="text-lg font-bold">
        Remove Subscriber
      </h2>
    </template>

    <p>
      Are you sure? You're about to delete {{ subscriber.value }}.
      Once this is done, you'll have to add them again.
    </p>

    <template #footer>
      <div class="flex">
        <div class="flex gap-4 actions">
          <button
            class="button button--sm"
            @click="cancelRemove"
          >
            Cancel
          </button>
          <button
            class="button button--sm button--danger"
            @click="remove"
          >
            Remove
          </button>
        </div>

        <small v-if="error">
          {{ error }}
        </small>
      </div>
    </template>
  </TbDialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbDialog } from '@/components/tasty_bistro';
import { NotificationsSubscriber, deleteSubscriber } from '../api';

const route = useRoute();
const projectId = route.params.projectId as string;
const props = defineProps<{ subscriber: NotificationsSubscriber }>();

const emit = defineEmits([
  'remove',
]);

const error = ref('');
const removeSubscriberDialog = ref(false);

const initRemove = () => {
  removeSubscriberDialog.value = true;
};

const cancelRemove = () => {
  removeSubscriberDialog.value = false;
  error.value = '';
};

const remove = async () => {
  try {
    await deleteSubscriber({ subscriberId: props.subscriber.id, projectId });
    removeSubscriberDialog.value = false;
    emit('remove');
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
};
</script>
