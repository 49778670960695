export default {
  backgroundColor: '#414a68',
  bodyFontColor: '#FFF',
  bodyFont: {
    size: 14,
    family: 'karla-regular, ui-sans-serif, system-ui, Arial',
  },
  displayColors: false,
  padding: {
    x: 16,
    y: 8,
  },
  callbacks: {
    title: () => '',
    label: (item: any) => `${item.label} ${item.formattedValue}`,
  },
};
