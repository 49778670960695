import http, { Http } from '@/lib/http';
import { PaymentDetails } from '../types';

export function getPaymentDetailsCtor(http: Http) {
  return async (projectId: string): Promise<PaymentDetails | null> => {
    const { data } = await http.get<{ paymentDetails: PaymentDetails }>(`/projects/${projectId}/payment-details`);
    return data?.paymentDetails;
  };
}

export const getPaymentDetails = getPaymentDetailsCtor(http);
