<template>
  <Modal
    :showModal="showModal"
    @close-modal="closeModal"
  >
    <div class="w-[70%]">
      <Video
        :videoUrl="videoUrl"
        class="mb-4 w-full"
      />
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/modal.vue';
import Video from '@/components/video.vue';

defineProps<{showModal: boolean}>();

const videoUrl = 'https://player.vimeo.com/video/943451690?autoplay=0';

const emit = defineEmits(['update:showModal']);

const closeModal = () => {
  emit('update:showModal', false);
};
</script>

<style lang="scss" scoped>

</style>
