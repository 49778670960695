import http, { Http } from '../../../../lib/http';

interface SetNewPasswordForm {
  password: string;
}

function resetPasswordCtor(http: Http) {
  return async function resetPassword(token: string, form: SetNewPasswordForm) {
    await http.post(`/auth/v1/reset-password/${token}`, form);
  };
}

export const resetPassword = resetPasswordCtor(http);
