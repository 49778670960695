<template>
  <Page>
    <template #title>
      Payments
    </template>

    <Table
      item-type="Payment Detail"
      date-key="dueDate"
      :items="payments"
      :total-item-count="paymentsCount"
      :items-per-page="pageSize"
      :headers="tableHeaders"
      :state="state"
      :count="{ singular: 'payment', plural: 'payments' }"
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div class="mb-4">
          <div class="flex flex-wrap gap-2 my-4">
            <button
              v-for="status in quickFilters"
              :key="status.value"
              class="flex items-end border button button--sm font-bold border-gray-200"
              :class="{'selected-quick-filter' : status.selected }"
              @click="applyQuickFilters(status)"
            >
              <span
                class="text-primary"
                :class="{'text-white' : status.selected }"
              >{{ status.label }}</span>
              <span class="text-info-300 ml-4">{{ status.count }}</span>
            </button>
          </div>
          <div class="flex flex-wrap items-end w-full gap-2">
            <div class="w-full max-w-xs">
              <TbInput
                v-model="search"
                label="Search"
                class="flex-1"
                placeholder="Customer / Order ID / Product"
              />
            </div>

            <div>
              <TbDateRangePicker
                :dates="dates"
                @selected-date-range="setDateRange"
              />
            </div>

            <div v-if="availableCurrencies && availableCurrencies.length > 1">
              <TbSelect
                v-model="currency"
                :options="selectOptionsCurrency"
                :default-options="{ name: 'USD', value: CurrencyCode.USD }"
                label="Currency"
                class="w-full sm:w-48"
              />
            </div>

            <div class="flex justify-between flex-grow">
              <RoleControlledAction
                v-slot="{wrapper, restricted}"
                :user-role="roleName"
                :config-object="roleConfig.exportPayments"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--primary button--sm"
                >
                  <span
                    class="flex items-center gap-2"
                    @click="wrapper(() => exportCsv())"
                  >
                    Export to CSV
                    <TbDownloadIcon class="w-4 h-4 text-white" />
                  </span>
                </button>
              </RoleControlledAction>
              <Filter
                class="self-center"
                :showFilters="showFilter"
                :toggleFilter="toggleFilters"
                :cancelFilters="cancelFilters"
                :applyFilters="applyFilters"
                :filters="{
                  status: {
                    value: 'status',
                    label: 'By Status',
                    options: statusFilters,
                  },
                }"
              />
            </div>
          </div>
          <div
            v-if="selectedStatus.length > 0"
            class="flex flex-wrap gap-2 mt-4"
          >
            <button
              v-for="status in selectedStatus"
              :key="status.value"
              class="flex items-center border button button--sm border-primary"
              @click="removeStatusFilters(status as FilterOption)"
            >
              <TbXMarkIcon class="w-4 h-4 mr-2" />
              <span>{{ status.label }}</span>
            </button>
          </div>
        </div>
      </template>

      <template #row(studentName)="{ item }">
        <routerLink :to="{ name: Pages.dashboardStudent, params: { id: item.studentId }}">
          <div class="flex flex-col">
            <span>{{ item.studentName }}</span>
            <span class="text-xs text-gray-500">
              {{ item.studentEmail }}
            </span>
          </div>
        </routerLink>
      </template>

      <template #row(id)="{ item }">
        <div class="truncate w-36">
          <routerLink :to="{ name: Pages.dashboardInHousePaymentsDrilldown, params: { id: item.id }}">
            <span>{{ item.id }}</span>
          </routerLink>
        </div>
      </template>

      <template #row(status)="{ item }">
        <Tooltip v-if="(item.status === 'Failed' || item.status === 'Past Due') && item.failureReason">
          <template #trigger="{ assignReference, show, hide }">
            <div
              :ref="(el: any) => assignReference(el)"
              @mouseenter="show"
              @mouseleave="hide"
            >
              <div
                class="inline-block w-2 h-2 mr-1 rounded-full"
                :class="paymentStatusToColorMap[item.status]"
              />
              {{ item.status }}
            </div>
          </template>
          <template #tooltip>
            {{ item.failureReason }}
          </template>
        </Tooltip>
        <div v-else>
          <div
            class="inline-block w-2 h-2 mr-1 rounded-full"
            :class="paymentStatusToColorMap[item.status]"
          />
          {{ item.status }}
        </div>
      </template>

      <template #row(amountInCents)="{ item }">
        <span class="currency">{{ item.currency + currencyInCents(item.amountInCents) }}</span>
      </template>

      <template #action="{ item, toggleDropdown }">
        <TbDrawer>
          <template
            v-if="selectedPaymentSchedule"
            #default="{ toggleDrawer }"
          >
            <Table
              :table-id="`payments-schedule-${item.id}`"
              item-type="Payment"
              date-key="dueDate"
              :items="selectedPaymentSchedule"
              :total-item-count="selectedPaymentSchedule?.length"
              :headers="paymentsScheduleTableHeaders"
              :state="paymentsScheduleState"
              flat
            />

            <button
              class="button button--secondary button--block"
              @click="toggleDrawer"
            >
              Close
            </button>
          </template>

          <template #toggle="{ toggleDrawer }">
            <button
              class="button button--block button--sm text-left"
              @click="toggleDrawer(); loadPaymentSchedule(item.id)"
            >
              Schedule
            </button>
          </template>
        </TbDrawer>

        <button
          class="button button--block button--sm text-left flex gap-2 items-center"
          @click="toggleDropdown(); copyOrderId(item.orderId)"
        >
          Copy Order ID
          <TbCopyIcon class="w-4 h-4" />
        </button>
      </template>
    </Table>
    <TbNotification
      :show="copyIdNotification"
      title="Success 🎉"
      :message="copyIdNotificationText"
      card-class="text-white bg-success"
      @close="copyIdNotification = false"
    />
  </Page>
  <QuickStartGuideLink />

  <TbOverlay
    :show="csvState === PageState.loading"
    persistent
  >
    <div class="flex items-center justify-center w-full h-full">
      <TbLoading />
    </div>
  </TbOverlay>
</template>

<script setup lang="ts">
import {
  ref, Ref, onActivated, watch, inject,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  TbDateRangePicker,
  TbDownloadIcon,
  TbLoading,
  TbOverlay,
  TbInput,
  TbDrawer,
  TbNotification,
  TbSelect,
  TbCopyIcon,
  TbXMarkIcon,
} from '@/components/tasty_bistro';
import { subMonths } from 'date-fns';
import { useClipboard } from '@vueuse/core';
import Table from '@/components/table.vue';
import Tooltip from '@/components/tooltip.vue';
import { PageState, CurrencyCode } from '@/types';
import { formatDate } from '@/filters/date';
import { currencyInCents } from '@/filters/currency';
import { downloadFile } from '@/helpers/download_file';
import { Pages } from '@/router/index';
import { paymentStatusToColorMap } from '@/helpers/category_color_mapper';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import { debounce } from 'lodash';
import Filter, { FilterOption } from '@/components/filter.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import Page from '../components/page.vue';
import { getPayments, QuickFilterPaymentStatus } from './api/get_payments';
import { getPaymentsDownload } from './api/get_payments_download';
import { getPaymentsSchedule, Payment } from './api/get_payments_schedule';
import { ppmPaymentStatus } from './api/types';
import { roleConfig } from './role_configurations';

interface QuickFilterOption {
  label: string;
  value: QuickFilterPaymentStatus;
  selected: boolean;
  count: number | null;
}

const route = useRoute();
const projectId = route.params.projectId as string;
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const copyIdNotification = ref(false);
const copyIdNotificationText = ref('');

const payments: Ref<any> = ref([]);

type Dates = [Date, Date]
const dates = ref<Dates>([subMonths(new Date(), 3), new Date()]);
const state = ref<PageState>(PageState.loaded);
const paymentsScheduleState = ref<PageState>(PageState.loaded);
const csvState = ref(PageState.loaded);
const pageSize = 20;
const currentPage = ref(0);
const paymentsCount = ref<number>(0);
const selectedPaymentSchedule = ref<Payment[] | null>(null);
const search = ref<string>('');
const availableCurrencies = ref<CurrencyCode[]>();
const selectedCurrency = ref();
const currency = ref(CurrencyCode.USD);
const statusFilters = ref<FilterOption[]>([]);
const selectedStatus = ref<(FilterOption| QuickFilterOption)[]>([]);
const showFilter = ref(false);

const selectOptionsCurrency = [
  { name: 'CAD', value: CurrencyCode.CAD },
];

const tableHeaders = [
  {
    title: 'Date',
    key: 'date',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Payment ID',
    key: 'id',
  },
  {
    title: 'Customer',
    key: 'studentName',
  },
  {
    title: 'Checkout',
    key: 'productName',
  },
  {
    title: 'Type',
    key: 'type',
  },
  {
    title: 'Amount',
    key: 'amountInCents',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const quickFilters = ref<QuickFilterOption[]>([
  {
    label: 'All Payments',
    value: 'all',
    selected: true,
    count: null,
  },
  {
    label: 'Scheduled',
    value: 'future',
    selected: false,
    count: null,
  },
  {
    label: 'Failed',
    value: 'failed',
    selected: false,
    count: null,
  },
  {
    label: 'Past Due',
    value: 'pastDue',
    selected: false,
    count: null,
  },
  {
    label: 'Refunded',
    value: 'refunded',
    selected: false,
    count: null,
  },
  {
    label: 'Canceled',
    value: 'terminated',
    selected: false,
    count: null,
  },
]);

const paymentsScheduleTableHeaders = [
  {
    title: 'Due Date',
    key: 'dueDate',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Amount',
    key: 'amountInCents',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const loadPayments = async () => {
  try {
    state.value = PageState.loading;
    const data = await getPayments({
      projectId,
      filter: {
        search: search.value,
        startDate: dates.value[0],
        endDate: dates.value[1],
        page: currentPage.value,
        pageSize,
        currency: (availableCurrencies.value && availableCurrencies.value.length > 1)
          ? currency.value
          : selectedCurrency.value,
        status: selectedStatus.value.map((s) => s.value) as ppmPaymentStatus[],
      },
    });
    payments.value = data.payments;
    paymentsCount.value = data.payments.length ? data.payments[0].totalCount : 0;
    availableCurrencies.value = data.currencies;
    selectedCurrency.value = data.selectedCurrency;
    statusFilters.value = data.statusFilters.map(
      (sf) => ({
        ...sf,
        checked: selectedStatus.value
          .map((ss) => ss.value).includes(sf.value),
      }),
    );
    quickFilters.value = quickFilters.value.map((qf) => ({
      ...qf,
      count: data.quickFilterStatusCount[qf.value],
    }));
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

const loadPaymentSchedule = async (paymentId: string) => {
  selectedPaymentSchedule.value = null;

  try {
    paymentsScheduleState.value = PageState.loading;
    selectedPaymentSchedule.value = await getPaymentsSchedule({ id: paymentId, projectId });
    paymentsScheduleState.value = PageState.loaded;
  } catch (error) {
    paymentsScheduleState.value = PageState.error;
  }
};

const exportCsv = async () => {
  try {
    csvState.value = PageState.loading;
    const file = await getPaymentsDownload({
      projectId,
      filter: {
        search: search.value,
        startDate: dates.value[0],
        endDate: dates.value[1],
        page: currentPage.value,
        pageSize,
        currency: availableCurrencies.value ? currency.value : selectedCurrency.value,
        status: selectedStatus.value.map((s) => s.value) as ppmPaymentStatus[],
      },
    });
    downloadFile({ file, fileName: 'elective_in_house_payments.csv' });
    csvState.value = PageState.loaded;
  } catch (error) {
    csvState.value = PageState.error;
  }
};

const copyOrderId = (id: string) => {
  copyIdNotification.value = false;

  setTimeout(() => {
    const clipboard = useClipboard({ source: id });
    clipboard.copy();
    copyIdNotificationText.value = 'Order ID has been successfully copied to your clipboard';
    copyIdNotification.value = true;
  }, 200);
};

onActivated(async () => {
  await loadPayments();
});

watch(currency, async () => {
  await loadPayments();
});

const setDateRange = async (newDates: Dates) => {
  dates.value = newDates;
  await loadPayments();
};

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadPayments();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadPayments();
};

const toggleFilters = () => {
  showFilter.value = !showFilter.value;
};

const applyFilters = async () => {
  showFilter.value = false;
  selectedStatus.value = statusFilters.value?.filter((s) => s.checked) || [];
  await loadPayments();
};

const applyQuickFilters = async (status: QuickFilterOption) => {
  selectedStatus.value = status.value !== 'all' ? [status] : [];
  quickFilters.value.forEach((qf) => {
    qf.selected = qf.value === status.value;
  });
  await loadPayments();
};

const removeStatusFilters = async (status: FilterOption | QuickFilterOption) => {
  selectedStatus.value = selectedStatus.value.filter((ss) => ss.value !== status.value);
  statusFilters.value
    .forEach((sf) => {
      if (sf.value === status.value) {
        sf.checked = false;
      }
    });
  quickFilters.value.forEach((qf) => {
    if (qf.value === status.value) {
      qf.selected = false;
    }
  });
  await loadPayments();
};

const cancelFilters = () => {
  showFilter.value = false;
};

watch(search, debounce(async () => {
  currentPage.value = 0;
  await loadPayments();
}, 500));

watch(currency, async () => {
  await loadPayments();
});

</script>

<style lang="scss" scoped>
  .table {
    @apply bg-secondary-100 w-full
  }

  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }

  .selected-quick-filter {
    @apply bg-primary border-primary ring-0
  }
</style>
