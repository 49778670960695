export enum OnboardingStatus {
  pending = 'pending',
  submitted = 'submitted',
  resubmissionRequested = 'resubmission_requested',
  approved = 'approved',
  declined = 'declined',
}

export enum ApplicationDetailsType {
  QualifiedWithNoOrder = 'qualified_no_order',
  QualifiedWithOrder = 'qualified_with_order',
  PendingVeriffChecks = 'pending_veriff_checks',
  PendingFrozenCreditCheck = 'pending_frozen_credit_check',
  DeclinedIncomeBased = 'declined_income_based',
  DeclinedOtherReasons = 'declined_other_reasons',
}
