import axios, {
  AxiosInstance, AxiosStatic, AxiosRequestConfig, AxiosResponse, AxiosError,
} from 'axios';
import { auth } from './auth';
import router, { Pages } from '../router';

interface HttpContext {
  axios: AxiosStatic;
  baseURL?: string;
}

export type Http = AxiosInstance;

const baseURL = import.meta.env.VITE_APP_BASE_URL_API;

export function httpCtor({ axios }: HttpContext) {
  const http = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': true,
    },
    timeout: 60000,
  });

  http.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = auth.getToken();
    config.headers!.Authorization = `Bearer ${token}`;

    return config;
  });

  http.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError) => {
    if (error.response?.status === 401) {
      auth.signOut();
      const currentPage = router.currentRoute.value;
      router.push({ name: Pages.signIn, query: { next: currentPage.path } });
    }

    if (error.response?.status === 403) {
      router.replace({ name: Pages.notAllowed });
    }

    return Promise.reject(error);
  });

  return http;
}

export default httpCtor({ axios });
