<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-wrap justify-between gap-2">
      <h2 class="text-lg font-bold">
        {{ name || 'Create a Payment Setting' }}
      </h2>
      <p>
        This is where you set up payment options for your products.
        Once set up, you can easily add your payment setting to any of your products.
      </p>
    </div>

    <form
      class="my-4"
      @submit.prevent="submit"
    >
      <TbInput
        v-model="name"
        class="mb-2"
        :disabled="formState === FormState.submitting"
        label="Name"
        :errors="nameError && [nameError]"
      />

      <TbInput
        v-model="termsOfServiceUrl"
        class="mb-4"
        :disabled="formState === FormState.submitting"
        label="Terms of Service link (optional)"
        :errors="termsOfServiceUrlError && [termsOfServiceUrlError]"
        tooltipText="The terms of service link you provide will be embedded in your Elective Billing checkout."
      />

      <LnplSwitch
        :isLnplEnabled="!ppmOnly"
        @switch-toggle="ppmOnly = !ppmOnly"
      />

      <h2 class="my-2 text-lg font-bold">
        Add Payment Plans
      </h2>

      <p class="mb-4">
        Payment plans default to equal installments unless you specify a custom first payment amount.
      </p>

      <div
        v-for="(paymentPlan, ind) in paymentPlans"
        :key="paymentPlan.installments"
        class="payment-term"
      >
        <div class="w-[47%]">
          <TbSelect
            :value="paymentPlan.installments"
            class="mb-1"
            :options="paymentPlanOptions"
            :default-options="{ name: '', value: '' }"
            label="Number of Payments"
            :errors="paymentPlan.error && [paymentPlan.error]"
            @change="setSelectedTerm($event, paymentPlan)"
          />

          <div
            v-if="paymentPlan.installments !== 1"
            class="flex items-center"
          >
            <input
              id="customFirstPaymentCheckbox"
              v-model="paymentPlan.customFirstPaymentPercentage"
              type="checkbox"
              class="mr-2 checked:bg-primary-800"
            >

            <label
              class="text-sm leading-6"
              for="customFirstPaymentCheckbox"
            >
              Custom First Payment
            </label>
          </div>
        </div>
        <div class="w-[47%]">
          <TbSelect
            class="mb-1"
            :disabled="paymentPlan.installments === 1"
            :default-options="{ name: 'Monthly', value: 'Monthly' }"
            :model-value="['Monthly']"
            label="Payment Frequency"
          />
          <TbInput
            v-if="paymentPlan.customFirstPaymentPercentage"
            v-model.number="paymentPlan.downPaymentPercentage"
            required
            type="number"
            max="99"
            min="1"
            step=".01"
            label="First Payment (%)"
            tooltipText="We use this percentage to calculate the first payment amount for a given product."
          />
        </div>
        <TbTrashIcon
          class="mt-11 w-5 h-5 cursor-pointer"
          @click="removePaymentPlan(ind)"
        />
      </div>

      <button
        class="button text-gray-500 font-semibold bg-gray-100 new-term-button"
        type="button"
        @click="addPaymentPlan"
      >
        <span class="flex items-center gap-2">
          <TbPlusIcon class="w-4 h-4" />
          New Term
        </span>
      </button>

      <div
        v-if="error"
        class="text-center error text-error-900"
      >
        <small class="error">
          {{ error }}
        </small>
      </div>

      <div class="my-4">
        <button
          class="button button--primary button--block"
          type="submit"
          :disabled="formState === FormState.submitting"
        >
          Create Payment Stack
        </button>

        <button
          type="button"
          class="mt-4 button button--text button--block"
          :disabled="formState === FormState.submitting"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  object, string, InferType, number, array, boolean,
} from 'yup';
import { useForm, useField } from 'vee-validate';
import {
  TbInput, TbSelect, TbPlusIcon, TbTrashIcon,
} from '@/components/tasty_bistro';
import { AxiosError } from 'axios';
import { FormState } from '@/types';
import { createPaymentPlanGroup } from '../api/create_payment_plan_group';
import { Configs } from '../api/get_configs';
import LnplSwitch from './components/lnpl_switch.vue';

interface PaymentPlan {
  id?: string;
  active?: boolean;
  installments: number;
  downPaymentPercentage: number;
  customFirstPaymentPercentage: boolean;
  error?: string;
}

const route = useRoute();
const projectId = route.params.id as string;
const props = defineProps<{ configs: Configs }>();

const emit = defineEmits([
  'submit',
  'cancel',
]);

const formState = ref<FormState>(FormState.ready);
const error = ref<string>('');

const createPaymentPlanGroupSchema = object({
  name: string().required('Required'),
  termsOfServiceUrl: string().url('The link provided must be a valid URL (e.g., https://elective.com)').nullable(),
  ppmOnly: boolean(),
  paymentPlans: array().of(
    object({
      installments: number(),
      downPaymentPercentage: number(),
      customFirstPaymentPercentage: boolean(),
    }),
  ),
});

interface CreatePaymentPlanGroup extends InferType<typeof createPaymentPlanGroupSchema> {}

const blankPaymentPlan = {
  installments: 0,
  downPaymentPercentage: 0,
  customFirstPaymentPercentage: false,
};

const { handleSubmit } = useForm<CreatePaymentPlanGroup>({
  validationSchema: createPaymentPlanGroupSchema,
  initialValues: {
    name: '',
    termsOfServiceUrl: '',
    ppmOnly: false,
    paymentPlans: [{ ...blankPaymentPlan }],
  },
});

const { value: name, errorMessage: nameError } = useField<string>('name');
const { value: termsOfServiceUrl, errorMessage: termsOfServiceUrlError } = useField<string>('termsOfServiceUrl');
const { value: ppmOnly } = useField<boolean>('ppmOnly');
const { value: paymentPlans } = useField<PaymentPlan[]>('paymentPlans');

const paymentPlanOptions = computed(() => Array.from({ length: props.configs.maxInstallments }, (_, index) => index + 1)
  .map((i) => ({
    value: i,
    name: i === 1 ? 'Pay In Full' : i,
  })));

const setSelectedTerm = (event: Event, pp: PaymentPlan) => {
  const installmentsValue = Number((event.target as HTMLSelectElement).value);

  if (pp.installments === installmentsValue) return;

  if (paymentPlans.value.some((pp) => pp.installments === installmentsValue)) {
    pp.error = 'The payment plan with this number of payments already exists';
    pp.installments = 0;
  } else {
    pp.error = '';
    pp.installments = installmentsValue;
  }
};

const indexOfPlanWithZeroInstallments = () => {
  const emptyPaymentPlan = paymentPlans.value.find((pp) => pp.installments === 0);

  return emptyPaymentPlan ? paymentPlans.value.indexOf(emptyPaymentPlan) : -1;
};

const addPaymentPlan = () => {
  const emptyPaymentPlanIndex = indexOfPlanWithZeroInstallments();

  if (emptyPaymentPlanIndex !== -1) {
    paymentPlans.value[emptyPaymentPlanIndex].error = 'Please select a term for your payment plan';
  } else {
    paymentPlans.value.push({ ...blankPaymentPlan });
  }
};

const removePaymentPlan = (planIndex: number) => {
  if (paymentPlans.value.length === 1) {
    return;
  }

  paymentPlans.value.splice(planIndex, 1);
};

const submit = handleSubmit(async (values) => {
  const emptyPaymentPlanIndex = indexOfPlanWithZeroInstallments();

  if (emptyPaymentPlanIndex !== -1) {
    paymentPlans.value[emptyPaymentPlanIndex].error = 'Please select a term for your payment plan';
    return;
  }

  const preparedPaymentPlans = paymentPlans.value.map((paymentPlan) => ({
    active: true,
    installments: paymentPlan.installments,
    downPaymentPercentage: paymentPlan.customFirstPaymentPercentage
      ? paymentPlan.downPaymentPercentage : Number((100 / paymentPlan.installments).toFixed(7)),
  }));

  try {
    error.value = '';
    formState.value = FormState.submitting;

    await createPaymentPlanGroup({ projectId, form: { ...values, paymentPlans: preparedPaymentPlans } });
    formState.value = FormState.ready;
    emit('submit');
  } catch (err) {
    formState.value = FormState.error;
    if (err instanceof AxiosError) {
      error.value = err.response?.data?.message;
    } else {
      error.value = 'An error occurred';
    }
  }
});

async function cancel() {
  emit('cancel');
}
</script>

<style lang="scss" scoped>
  .new-term-button {
    @apply rounded-lg
  }

  .payment-term {
    @apply flex justify-between gap-1 mb-4
  }
</style>

<style lang="scss">
  .payment-term .inline-flex .rounded-md {
    width: 150px; // to fix First payment tooltip going beyond the screen border
  }

</style>
