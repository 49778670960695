import http, { Http } from '@/lib/http';

function getVerificationUrlCtor(http: Http) {
  return async (projectId: string) => {
    const response = await http.get<{ url: string }>(`/projects/${projectId}/business-owner/verification-url`);

    return response.data.url;
  };
}

export const getVerificationUrl = getVerificationUrlCtor(http);
