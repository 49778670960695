<template>
  <DashboardLayout>
    <router-view
      v-slot="{ Component }"
    >
      <Suspense>
        <div class="h-full min-h-full max-w-7xl">
          <keep-alive :exclude="excludedRoutes">
            <component
              :is="Component"
              :key="route.path"
            />
          </keep-alive>
        </div>
      </Suspense>
    </router-view>
  </DashboardLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import DashboardLayout from './components/layout.vue';

const route = useRoute();
const excludedRoutes = ref(['nmiForm']);
</script>
