import http, { Http } from '@/lib/http';
import { OrderOrigin, OrderOriginTitle } from '@/types';

type InstallmentStatus =
  'canceled' |
  'chargebacked' |
  'failed' |
  'future' |
  'paid' |
  'pending' |
  'refunded' |
  'reversed' |
  'terminated' |
  'unpaid';

interface Installment {
  id: string;
  dueDate: string;
  status: InstallmentStatus;
}

export interface Order {
  id: string;
  completedAt: string;
  amountInCents: number;
  installmentsCount: number;
  currency: string;
  status: string;
  product: string;
  studentName: string;
  studentEmail: string;
  studentId: string;
  country: string;
  stateOrProvince: string;
  reverseCandidate: boolean;
  downPaymentAmountInCents: number;
  paidAmountInCents: string;
  paidInstallments: string;
  payment_processor: string;
  feeAmountInCents: number;
  netAmountInCents: number;
  origin: OrderOrigin;
  formattedOrigin: OrderOriginTitle;
  reversalPeriod: number;
  installments: Installment[];
  totalCount: string;
  refundable: boolean;
  cancellable: boolean;
}

export interface FilterOptions {
  student?: string;
  product?: string;
  pageSize?: number;
  offset?: number;
  startDate?: Date;
  endDate?: Date;
  search?: string;
  status: string[] | undefined;
  origin: string[] | undefined;
}

interface GetOrdersResponse {
  orders: Order[];
  totalCount: number;
  statusFilters: { label: string, value: string }[]
}

interface Params {
  projectId: string;
  filter: FilterOptions;
}
function getOrdersCtor(http: Http) {
  return async function getOrders({ projectId, filter }: Params): Promise<GetOrdersResponse> {
    const { data } = await http.get(`/projects/${projectId}/orders`, {
      params: filter,
    });

    return {
      orders: data.data,
      totalCount: data.totalCount,
      statusFilters: data.statusFilters,
    };
  };
}

export const getOrders = getOrdersCtor(http);
