import { AES, enc } from 'crypto-js';

type GetItem = (key: string) => string | null;
type SetItem = (key: string, value: string) => void
type RemoveItem = (key: string) => void
type Clear = () => void

interface LocalStorage {
  getItem: GetItem,
  setItem: SetItem,
  removeItem: RemoveItem,
  clear: Clear
}

export interface StorageLib {
  get: GetItem,
  set: SetItem,
  remove: RemoveItem,
  clear: Clear
}

export function storageCtor(storage: LocalStorage) {
  const nonce = import.meta.env.VITE_APP_STORAGE_NONCE;
  const secret = import.meta.env.VITE_APP_STORAGE_SECRET;

  const getKey = (key: string) => `${nonce}_${key}`;

  const encrypt = (value: string) => AES.encrypt(value, secret).toString();

  const decrypt = (value: string) => {
    const decryption = AES.decrypt(value, secret);
    return decryption.toString(enc.Utf8);
  };

  const get = (key: string) => {
    const item = storage.getItem(getKey(key));
    return item ? decrypt(item) : null;
  };

  const set = (key: string, value: string): void => {
    storage.setItem(getKey(key), encrypt(value));
  };

  const remove = (key: string): void => {
    storage.removeItem(getKey(key));
  };

  const clear = (): void => {
    storage.clear();
  };

  return {
    get,
    set,
    remove,
    clear,
  };
}

export const storageLib = storageCtor(window.localStorage);
