<template>
  <Datepicker
    v-model="selectedDate"
    typeable
    :upperLimit="props.max"
    :lowerLimit="props.min"
  />
</template>

<script setup lang="ts">
import Datepicker from 'vue3-datepicker';
import { watch, ref } from 'vue';

interface Props {
  date?: Date;
  min?: Date;
  max?: Date;
}

const props = withDefaults(defineProps<Props>(), {
  date: () => new Date(),
  min: undefined,
  max: undefined,
});

const emits = defineEmits(['selected-date']);

const selectedDate = ref(props.date);

watch(selectedDate, () => {
  emits('selected-date', selectedDate.value);
});
</script>

<style lang="scss">
  .v3dp__popout {
    width: auto !important;
    min-width: 17rem;;
    padding: .5rem !important;
    border-radius: 1rem !important;
    margin-top: .5rem;
  }

  .v3dp__elements button {
    padding: .25rem !important;
    @apply
      rounded-md
      disabled:cursor-not-allowed
  }

  .v3dp__heading__center,
  .v3dp__subheading {
    @apply font-bold
  }

  .v3dp__heading__center {
    margin-left: .5rem;
    margin-right: .5rem;
    @apply rounded-md;
  }

  .v3dp__heading__button {
    @apply
      py-2
      px-4
      ring-offset-2
      focus:ring-1
      ring-current
      transition
      shadow-sm
      ease-in-out
      disabled:bg-gray-100
      disabled:hover:bg-gray-100
      disabled:text-gray-400
      disabled:shadow-inner
      disabled:cursor-not-allowed
      text-sm
      w-10 h-10 p-2
      rounded-full
      hover:bg-primary-500;
  }

  .v3dp__input_wrapper input {
    @apply
      bg-gray-100
      w-full
      px-4
      py-2
      rounded-md
      text-sm
      focus:outline-info-100
      disabled:bg-gray-200
      disabled:cursor-not-allowed
      disabled:shadow-inner
      text-primary
      focus:ring-0
      border-0
      max-w-4xl;
  }

  :root {
    --vdp-selected-bg-color:  hsla(227, 36%, 61.55555555555556%, 1);
    --vdp-hover-bg-color:  hsla(227, 36%, 61.55555555555556%, 1);
    --vdp-elem-font-size: 1rem;
  }
</style>
