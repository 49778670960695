<template>
  <slot
    name="action"
    v-bind="{ deleteHandler: initArchive }"
  />

  <ConfirmationDialog
    v-model="showDialog"
    title="Delete Product?"
    :items="orderedCheckoutList"
    action="Delete"
    :fn="archive"
    @cancel="$emit('cancel')"
  >
    <template
      v-if="product.activeCheckouts.length"
      #pre
    >
      "<strong class="font-semibold">{{ product.name }}</strong>" is used in
      the following <strong class="font-semibold">{{ product.activeCheckouts.length }}
        checkout{{ product.activeCheckouts.length > 1 ? 's' : '' }}</strong>. This action
      will remove the product from {{ product.activeCheckouts.length > 1 ? 'these' : 'this' }}
      checkout{{ product.activeCheckouts.length > 1 ? 's' : '' }} and cannot be undone.
    </template>

    <template
      v-else
      #pre
    >
      Are you sure you want to delete "<strong class="font-semibold">{{ product.name }}</strong>"? This
      action cannot be undone.
    </template>

    <template #item="{ item }">
      <div
        v-if="item.itemCount === 1"
        class="truncate w-full font-semibold text-error-600"
      >
        <Tooltip>
          <template #trigger="{ assignReference, show, hide }">
            <div
              :ref="(el: any) => assignReference(el)"
              class="flex flex-row items-center gap-2 w-min"
              @mouseenter="show"
              @mouseleave="hide"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-4 h-4 fill-error-800"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29
            4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12
            8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0
            8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clip-rule="evenodd"
                />
              </svg>

              {{ item.name }}
            </div>
          </template>

          <template #tooltip>
            This checkout will be disabled without any linked products
          </template>
        </Tooltip>
      </div>
      <div
        v-else
        class="truncate w-full font-semibold flex flex-row items-center gap-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-4 h-4 fill-success-800"
        >
          <path
            fill-rule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75
            9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53
            12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clip-rule="evenodd"
          />
        </svg>

        {{ item.name }}
      </div>
    </template>
  </ConfirmationDialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import Tooltip from '@/components/tooltip.vue';
import archiveProduct from '../api/archive_product';
import { Product } from '../api/get_products';
import ConfirmationDialog from '../../components/modals/confirmation.vue';

const route = useRoute();
const projectId = route.params.projectId as string;

const props = defineProps<{ product: Product}>();

const emit = defineEmits([
  'archive',
  'cancel',
]);

const showDialog = ref(false);

const initArchive = () => {
  showDialog.value = true;
};

const impactedCheckouts = computed(() => {
  const { activeCheckouts } = props.product;

  return activeCheckouts.filter((c) => c.itemCount === 1);
});

const orderedCheckoutList = computed(() => {
  const { activeCheckouts } = props.product;

  // make inactive checkouts first, active ones after ordered by name
  return [...activeCheckouts].sort((a, b) => {
    const problems = [a, b].map((c) => impactedCheckouts.value.includes(c));

    if (problems[0] && !problems[1]) {
      return -1;
    }

    if (!problems[0] && problems[1]) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  });
});

const archive = async () => {
  await archiveProduct({ projectId, productId: props.product.productId });
  emit('archive');
};
</script>
