import http, { Http } from '@/lib/http';
import { CurrencyCode } from '@/types';
import { DownPaymentType } from '@/pages/app/project/dashboard/v2/checkouts/api/get_checkouts';

export interface AvaliablePpmPaymentPlanTemplate {
  id: string;
  installments: number;
  frequency: number;
}

export interface CheckoutConfig {
  totalSumInCents: number;
  currencyCode: CurrencyCode;
  downPaymentType: DownPaymentType | null;
  downPaymentValue: number | null;
  avaliablePpmPaymentPlanTemplates: AvaliablePpmPaymentPlanTemplate[] | null;
  productMaxPriceInCents: number | null;
}

function getCheckoutConfigCtor(http: Http) {
  return async (projectId: string, checkoutId: string): Promise<CheckoutConfig> => {
    const { data } = await http.get<CheckoutConfig>(`/projects/${projectId}/invoices/checkouts/${checkoutId}/config`);

    return data;
  };
}

export const getCheckoutConfig = getCheckoutConfigCtor(http);
