<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-wrap justify-between gap-4">
      <div class="w-full mb-4">
        <h2 class="text-lg font-bold whitespace-normal">
          {{ props.title }}
        </h2>
      </div>
    </div>

    <Table
      hideHeader
      :items="preparedData"
      :headers="tableHeaders"
      flat
      :items-per-page="pageSize"
      :totalItemCount="data.length"
      @next="goToNextPage"
      @prev="goToPreviousPage"
    />

    <button
      class="button button--secondary button--block"
      @click="close"
    >
      Close
    </button>
  </div>
</template>

<script lang="ts" setup>
import {
  computed, defineProps, ref, defineEmits,
} from 'vue';
import { percentage } from '@/filters/percentage';
import Table from '@/components/table.vue';

export interface Props {
  title: string;
  data: { label: string, value: number }[];
  formatter?: (value: number | string) => string;
  showPercentage?: boolean;
  firstColumnTitle?: string;
}

const props = withDefaults(defineProps<Props>(), {
  firstColumnTitle: 'Product',
});

const emit = defineEmits([
  'close',
]);

async function close() {
  emit('close');
}

const tableHeaders = [
  {
    title: props.firstColumnTitle,
    key: 'label',
  },
  {
    title: 'Volume',
    key: 'value',
    formatter: (value: number) => props.formatter?.(value),
  },
  ...(props.showPercentage
    ? [
      {
        title: 'Share',
        key: 'percentage',
        formatter: (value: number) => percentage(value),
      },
    ]
    : []),
];

const pageSize = 20;
const currentPage = ref(0);

const preparedData = computed(() => {
  const totalValue = props.data.reduce((prev, curr) => prev + curr.value, 0);

  const startIndex = currentPage.value * pageSize;
  const endIndex = startIndex + pageSize;

  return props.data
    .slice(startIndex, endIndex)
    .map(({ label, value }) => ({ label, value, percentage: value / totalValue }));
});

const goToNextPage = async () => {
  currentPage.value += 1;
};

const goToPreviousPage = async () => {
  currentPage.value -= 1;
};

</script>
