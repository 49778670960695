<template>
  <div class="modal">
    <div class="iframe-container">
      <iframe
        :src="props.checkoutLink"
        frameborder="0"
      />
      <button
        class="modal-close"
        @click="emitCloseModal"
      >
        <TbXMarkIcon class="w-4 h-4" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  TbXMarkIcon,
} from '@/components/tasty_bistro';

const props = defineProps<{ checkoutLink: string }>();
const emit = defineEmits(['close-modal']);

function emitCloseModal() {
  emit('close-modal');
}
</script>

<style lang="scss" scoped>
  .iframe-container {
    @apply
      fixed
      top-[50%]
      left-[50%]
      h-[80vh]
      w-11/12
      sm:w-7/12
      translate-x-[-50%]
      translate-y-[-50%];

    iframe {
      @apply w-full h-full rounded-xl;
    }
  }

  .modal {
    @apply
      flex
      items-center
      justify-center
      fixed
      top-0
      left-0
      w-full
      h-full
      bg-gray-100;
    background-color: rgba(217, 217, 217, 0.5);
  }

  .modal-close {
    @apply
      flex
      items-center
      justify-center
      text-primary
      w-6
      h-6
      absolute
      top-3
      right-4
      bg-white
      border-none
      rounded-[50%]
      cursor-pointer;
  }
</style>
