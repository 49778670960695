import http, { Http } from '@/lib/http';

export interface Profile {
  firstName: string;
  lastName: string;
  email: string;
}

function getProfileCtor(http: Http) {
  return async function getUserProfile(): Promise<Profile> {
    const { data } = await http.get('/app/v1/user_profile');
    return data;
  };
}

export default getProfileCtor(http);
