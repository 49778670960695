import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref, Ref, watch,
} from 'vue';

interface Setup {
    type: Ref<string>;
}

export const useMask = ({
  type,
}: Setup) => {
  const inputRef = ref<HTMLInputElement | null>(null);

  const formatValue = (val: string) => {
    if (!/^\d*(\.\d{0,2})?$/.test(val)) {
      return val;
    }

    const numberValue = parseFloat(val);

    if (Number.isInteger(numberValue)) {
      return numberValue.toLocaleString(); // This will add commas
    }

    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(numberValue);
  };

  const formatInputValue = () => {
    if (!inputRef.value) return;

    inputRef.value.value = `${formatValue(inputRef.value.value)}`;
  };

  const handleFocus = () => {
    if (!inputRef.value) return;
    inputRef.value.value = inputRef.value.value.replace(/[^0-9.]/g, '');
  };

  const handleBlur = () => {
    formatInputValue();
  };

  watch(type, async () => {
    await nextTick();
    formatInputValue();
  });

  onMounted(async () => {
    await nextTick();
    formatInputValue();
  });

  const handleKeyPress = (event: KeyboardEvent) => {
    if (!inputRef.value) return;
    const char = String.fromCharCode(event.which);
    const pattern = /[0-9.]/;

    if (!pattern.test(char)) {
      event.preventDefault();
      return;
    }

    if (char === '.' && inputRef.value.value.includes('.')) {
      event.preventDefault();
      return;
    }

    const potentialValue = inputRef.value.value + char;
    const decimalParts = potentialValue.split('.');

    if (decimalParts.length === 2 && decimalParts[1].length > 2) {
      event.preventDefault();
    }
  };

  watch(inputRef, (ref) => {
    if (ref) {
      ref.addEventListener('keypress', handleKeyPress);
      ref.addEventListener('focus', handleFocus);
      ref.addEventListener('blur', handleBlur);
    }
  });

  onBeforeUnmount(() => {
    if (inputRef.value) {
      inputRef.value.removeEventListener('keypress', handleKeyPress);
      inputRef.value.removeEventListener('focus', handleFocus);
      inputRef.value.removeEventListener('blur', handleBlur);
    }
  });

  return {
    inputRef,
    formatInputValue,
  };
};
