import http, { Http } from '@/lib/http';
import { CurrencyCode } from '@/types';

export interface Checkout {
  id: string,
  name: string,
  ppmEnabled: boolean | null,
  lnplEnabled: boolean | null,
  totalSumInCents: number;
  currencyCode: CurrencyCode;
}

function getCheckoutsCtor(http: Http) {
  return async (projectId: string): Promise<Checkout[]> => {
    const { data } = await http.get<Checkout[]>(`/projects/${projectId}/invoices/checkouts`);

    return data;
  };
}

export const getCheckouts = getCheckoutsCtor(http);
