import { AxiosInstance } from 'axios';
import http from '@/lib/http';

export interface ApiKeys {
  apiKey: string;
  secret: string;
}

export interface KeysApi {
  createApiKey: (projectId: string) => Promise<ApiKeys>;
  getApiKey: (projectId: string) => Promise<ApiKeys>;
  deleteApiKey: (projectId: string) => Promise<ApiKeys>;
}

export function keysApiCtor(http: AxiosInstance) {
  const getApiKey = async (projectId: string) => {
    const { data } = await http.get(`/projects/${projectId}/api-keys`);
    return data;
  };

  const createApiKey = async (projectId: string) => {
    const { data } = await http.post(`/projects/${projectId}/api-keys`);
    return data;
  };

  const deleteApiKey = async (projectId: string) => {
    const { data } = await http.delete(`/projects/${projectId}/api-keys`);
    return data;
  };

  return {
    createApiKey,
    getApiKey,
    deleteApiKey,
  };
}

export const keysApi = keysApiCtor(http);
