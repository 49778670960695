import http, { Http } from '@/lib/http';
import { OnboardingStatus } from '@/pages/app/project/types';

export type AgreementType = 'fee_agreement' | 'merchant_agreement' | 'role_terms_of_service' | 'terms_of_service' | 'terms_of_service_quiz';

export interface Agreements {
  agreementId: string;
  fileName: string;
  type: AgreementType;
}
  type User = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
  }

  type ProjectState = {
    id: string;
    name: string;
    createdAt: Date;
    currentUserRoleName: string;
    projectMissingAgreements: Agreements[];
    actions: {
      showDemoPage: boolean;
    };
    states: {
      onboardingState: {
        status: OnboardingStatus;
        hasWatchedWelcomeVideo: boolean;
      };
    };
    features: {
      lnplEnabled: boolean;
      ppmEnabled: boolean;
      analyticsEnabled: boolean;
      checkoutV2Enabled: boolean;
      userPilotEnabled: boolean;
      userManagementEnabled: boolean;
      payInFullEnabled: boolean;
      invoicingEnabled: boolean;
      checkoutSalesTaxEnabled: boolean;
    };
    configs: {
      checkoutSalesConfigured: boolean;
      refundEnabled: boolean;
      minPrice: number;
      maxPrice: number;
    };
  };

export interface AppState {
    user: User;
    projects: Record<string, ProjectState>;
  }

function getAppStateCtor(http: Http) {
  return async () => {
    const { data } = await http.get<AppState>('/app/v2/state');
    return data;
  };
}

export const getAppState = getAppStateCtor(http);
