import http, { Http } from '@/lib/http';

interface Data {
  id: string;
  projectId: string;
}

function archivePaymentPlanGroupCtor(http: Http) {
  return async function archivePaymentPlanGroup({ id, projectId }: Data): Promise<void> {
    await http.delete(`/projects/${projectId}/ppm/payment-plan-groups/${id}`);
  };
}

export const archivePaymentPlanGroup = archivePaymentPlanGroupCtor(http);
