import inviteLanding from '@/pages/invite/index.vue';
import newUser from '@/pages/invite/new_user/index.vue';
import existingUser from '@/pages/invite/existing_user/index.vue';
import welcome from '@/pages/invite/welcome/index.vue';
import expired from '@/pages/invite/expired/index.vue';
import success from '@/pages/invite/success/index.vue';

export enum Page {
  inviteLanding = 'inviteLanding',
  inviteWelcome = 'inviteWelcome',
  inviteExpired = 'inviteExpired',
  inviteNewUser = 'inviteNewUser',
  inviteExistingUser = 'inviteExistingUser',
  inviteSuccess = 'inviteSuccess',
}

export const inviteRoutes = [
  {
    path: '/invite/:inviteId',
    name: Page.inviteLanding,
    component: inviteLanding,
    children: [
      {
        path: 'welcome',
        name: Page.inviteWelcome,
        component: welcome,
      },
      {
        path: 'expired',
        name: Page.inviteExpired,
        component: expired,
      },
      {
        path: 'join_team',
        name: Page.inviteExistingUser,
        component: existingUser,
      },
      {
        path: 'join_project',
        name: Page.inviteNewUser,
        component: newUser,
      },
      {
        path: 'success',
        name: Page.inviteSuccess,
        component: success,
      },
    ],
  },
];
