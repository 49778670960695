<template>
  <div
    class="relative flex items-stretch bg-image"
    :style="`background-image: url('${getImageUrl(props.imageName)}')`"
  >
    <div class="absolute bottom-0 p-6 text-white">
      <h1 class="mb-4 text-2xl font-medium">
        {{ `"${props.quote}"` }}
      </h1>
      <p class="text-lg font-medium">
        - {{ props.name }}
      </p>
      <p>
        {{ props.designation }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">

interface Props {
  quote: string;
  name: string;
  designation: string;
  imageName: string;
}
const props = defineProps<Props>();

function getImageUrl(name: string) {
  return new URL(`./assets/${name}`, import.meta.url).href;
}
</script>

<style lang="scss">
  .bg-image {
    background-size: cover;
    background-position: center;
    @apply
      h-full
      relative;
  }
</style>
