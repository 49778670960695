import { OrderOrigin } from '@/types';

export const productStatusToColorMap: Record<string, string> = {
  active: 'bg-success',
  inactive: 'bg-error',
  needs_attention: 'bg-warning',
};

export const orderApplicationResultToColorMap: Record<string, string> = {
  qualified: 'bg-info-900',
  not_qualified: 'bg-warning',
  pending_resolution: 'bg-gray-300',
  purchased: 'bg-success',
};

export const originColorMap: Record<OrderOrigin, string> = {
  lnpl: 'bg-accent-100',
  ppm: 'bg-primary',
};

export const paymentStatusToColorMap: Record<string, string> = {
  'Payment Pending': 'bg-gray-400',
  Paid: 'bg-success',
  Future: 'bg-info',
  'Past Due': 'bg-black',
  Terminated: 'bg-warning',
  Chargebacked: 'bg-error',
  Canceled: 'bg-pink-400',
  Refunded: 'bg-purple-700',
  Unpaid: 'bg-gray-400',
  Scheduled: 'bg-gray-400',
  'N/A': 'bg-gray-200',
  Failed: 'bg-error',
};

export const orderStatusToColorMap: Record<string, string> = {
  Completed: 'bg-success',
  Current: 'bg-info',
  Delinquent: 'bg-black',
  Terminated: 'bg-warning',
  Chargebacked: 'bg-error',
  Reversed: 'bg-secondary',
  Canceled: 'bg-pink-400',
  Refunded: 'bg-purple-700',
  'Partially Refunded': 'bg-purple-800',
  ReversedCandidate: 'bg-gray-500',
  Paid: 'bg-success',
  Unrecoverable: 'bg-warning',
  'Processing Refund': 'bg-warning',
  Pending: 'bg-gray-200',
  Voided: 'bg-pink-100',
  Failed: 'bg-error',
  'Pending Refund': 'bg-warning',
  'Pending Cancellation': 'bg-warning',
};

export const payoutStatusToColorMap: Record<string, string> = {
  Active: 'bg-info',
  Pending: 'bg-warning',
  Paid: 'bg-success',
};

export const roleToColorMap: Record<string, string> = {
  Owner: 'primary',
  Admin: 'info',
  'Sales Manager': 'accent',
  'Sales Representative': 'success',
  'Support Specialist': 'secondary',
  'View Only': 'warning',
};

export const invoiceStatusToColorMap: Record<string, string> = {
  open: 'bg-info-700',
  paid: 'bg-success',
  void: 'bg-gray-400',
};

export const payInFullStatusToColorMap: Record<string, string> = {
  enabled: 'bg-success',
  disabled: 'bg-gray-400',
};
