import { format, parseISO, addMinutes } from 'date-fns';

export function formatDate(
  value: string,
  dateFormat = 'MM/dd/yyyy HH:mm',
  utc = true,
) {
  let parsedDate = parseISO(value);

  if (utc) {
    parsedDate = addMinutes(parsedDate, parsedDate.getTimezoneOffset());
  }

  return format(parsedDate, dateFormat);
}

export function formatDateUTC(rawDate: Date) {
  const year = rawDate.getUTCFullYear();
  const month = (rawDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = rawDate.getUTCDate().toString().padStart(2, '0');
  const hour = rawDate.getUTCHours();
  const formatedHour = (hour > 12 ? hour - 12 : hour).toString().padStart(2, '0');
  const min = rawDate.getUTCMinutes().toString().padStart(2, '0');
  const secs = rawDate.getUTCSeconds().toString().padStart(2, '0');
  const AMPM = hour >= 12 && hour < 24 ? 'PM' : 'AM';

  return `${month}/${day}/${year} ${formatedHour}:${min}:${secs} ${AMPM}`;
}
