<template>
  <div>
    <TbLoading
      v-if="state === 'loading'"
    />
    <form
      v-else
      @submit.prevent="trigger"
    >
      <TbSelect
        v-model="checkout"
        class="uppercase"
        label="Product"
        :options="getCheckoutsFormatted()"
        @change="onCheckoutChanged()"
      />

      <template v-if="checkoutEntity">
        <div class="bg-gray-100 rounded-md flex flex-col gap-1 p-4 my-4 text-sm">
          <div class="flex flex-row gap-2">
            <strong class="font-bold">Name:</strong>
            {{ checkoutEntity.name }}
          </div>

          <div class="flex flex-row gap-2">
            <strong class="font-bold">Price:</strong>
            {{ checkoutEntity.currencyCode }} {{ currencyInCents(checkoutEntity.amountInCents) }}
          </div>
        </div>
      </template>

      <TbInput
        v-model="customerEmail"
        class="w-full"
        label="Customer Email"
        :errors="customerEmailError && [customerEmailError]"
        type="text"
      />

      <TbInput
        v-model="customerFirstName"
        class="w-full"
        label="Customer First Name"
        :errors="customerFirstNameError && [customerFirstNameError]"
        type="text"
      />

      <TbInput
        v-model="customerLastName"
        class="w-full"
        label="Customer Last Name"
        :errors="customerLastNameError && [customerLastNameError]"
        type="text"
      />

      <button class="w-full button button--primary p-2">
        Trigger Zap
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { TbInput, TbSelect, TbLoading } from '@/components/tasty_bistro';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { InferType, object, string } from 'yup';
import { useField, useForm } from 'vee-validate';
import { currencyInCents } from '@/filters/currency';
import { FormSchema } from '../types';
import { Checkout } from '../../api/get_checkouts';
import { getMockForm } from '../api/get_mock_form';

const route = useRoute();
const projectId = route.params.projectId as string;

interface Props {
    checkouts: Checkout[];
    eventName: string;
}

const props = defineProps<Props>();

const mockForm = ref<FormSchema>();
const state = ref<'loading' | 'error' | 'loaded'>('loaded');

const formSchema = object({
  customerEmail: string().required('Required'),
  customerFirstName: string().required('Required'),
  customerLastName: string().required('Required'),
});

interface Form extends InferType<typeof formSchema> {}
const { handleSubmit } = useForm<Form>({
  validationSchema: formSchema,
  initialValues: {
    customerFirstName: 'First Name',
    customerLastName: 'Last Name',
    customerEmail: 'customer@email.com',
  },
});

const checkout = ref<string>();
const { value: customerEmail, errorMessage: customerEmailError } = useField('customerEmail');
const { value: customerFirstName, errorMessage: customerFirstNameError } = useField('customerFirstName');
const { value: customerLastName, errorMessage: customerLastNameError } = useField('customerLastName');

const emit = defineEmits(['trigger']);

async function loadMockForm() {
  mockForm.value = await getMockForm({ projectId, productId: String(checkout.value), eventName: props.eventName });
}

watch(() => props.eventName, async () => {
  await loadMockForm();
});

const checkoutEntity = computed(() => props.checkouts.find((a) => a.staticIdentifier === checkout.value));

async function onCheckoutChanged() {
  await loadMockForm();
}

onMounted(async () => {
  try {
    state.value = 'loading';
    checkout.value = props.checkouts[0].staticIdentifier;
    await loadMockForm();
  } catch (err: any) {
    console.error(err);
  } finally {
    state.value = 'loaded';
  }
});

function getCheckoutsFormatted() {
  return props.checkouts.map((checkout) => ({
    name: checkout.name,
    value: checkout.staticIdentifier,
  }));
}

const trigger = handleSubmit(async (values) => {
  const formValues = {
    ...mockForm.value,
    ...values,
  };
  emit('trigger', formValues);
});
</script>
../../../api/get_checkouts
