<template>
  <Page>
    <div class="max-w-2xl p-4 bg-white card">
      <div>
        <h2 class="text-lg font-bold">
          Team
        </h2>
        <p class="text-sm">
          Please add any email addresses here that you would like us to include when providing notifications about your account.
        </p>
      </div>

      <div
        v-if="primarySubscriber"
        class="flex items-center pt-4 text-sm text-secondary"
      >
        <TbInformationIcon
          class="w-4 h-4 mr-2 text-secondary"
        />
        {{ primarySubscriber.value }} is the primary subscriber
      </div>

      <div class="mt-4">
        <TbDrawer>
          <template #default="{ toggleDrawer }">
            <div class="flex flex-col h-full">
              <FormAddSubscriber
                @cancel="toggleDrawer()"
                @submit="toggleDrawer(); loadSubscribers()"
              />
            </div>
          </template>
          <template #toggle="{ toggleDrawer }">
            <button
              class="mt-2 button sm:mt-0 button--primary button--sm"
              @click="toggleDrawer"
            >
              <span class="flex items-center gap-2">
                Add Team Member
              </span>
            </button>
          </template>
        </TbDrawer>
      </div>

      <div
        v-if="notificationsSubscribers.length === 0"
        class="pt-4 text-sm text-gray-400"
      >
        No additional subscribers yet
      </div>

      <table
        v-else
        class="w-full mt-4 border-separate lg:border-spacing-4 sm:border-spacing-3 border-spacing-1"
      >
        <thead class="text-gray-400">
          <tr>
            <th class="text-sm text-left">
              Email
            </th>
            <th class="hidden text-sm text-left sm:block">
              Added on
            </th>
            <th />
          </tr>
        </thead>
        <tbody class="py-2">
          <tr
            v-for="subscriber in notificationsSubscribers"
            :key="subscriber.value"
          >
            <td class="text-sm font-bold">
              {{ subscriber.value }}
            </td>
            <td class="hidden text-sm text-gray-400 sm:table-cell">
              {{ format(new Date(subscriber.createdAt), 'MMMM dd, yyyy') }}
            </td>
            <td class="align-baseline">
              <TbDrawer>
                <template #default="{ toggleDrawer }">
                  <div class="flex flex-col h-full">
                    <FormUpdateSubcriber
                      :subscriber="subscriber"
                      @cancel="toggleDrawer()"
                      @submit="toggleDrawer(); loadSubscribers()"
                    />
                  </div>
                </template>
                <template #toggle="{ toggleDrawer }">
                  <div class="text-right">
                    <button
                      class="button button--sm button--secondary"
                      @click="toggleDrawer"
                    >
                      <span class="flex items-center gap-2">
                        Preferences
                      </span>
                    </button>
                  </div>
                </template>
              </TbDrawer>
            </td>
            <td class="inline-flex justify-end">
              <FormRemoveSubscriber
                :subscriber="subscriber"
                @remove="loadSubscribers()"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="state === 'error' && error"
      class="p-4 text-error"
    >
      {{ error }}
    </div>
  </Page>
</template>

<script setup lang="ts">
import {
  onMounted, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { TbDrawer, TbInformationIcon } from '@/components/tasty_bistro';
import { format } from 'date-fns';
import { PageState } from '@/types';
import Page from '../../components/page.vue';
import FormAddSubscriber from './forms/add_subscriber.vue';
import FormUpdateSubcriber from './forms/update_subscription.vue';
import FormRemoveSubscriber from './forms/remove_subscriber.vue';
import { getSubscribers, NotificationsSubscriber } from './api';

const route = useRoute();
const projectId = route.params.projectId as string;
const notificationsSubscribers = ref<NotificationsSubscriber[]>([]);
const primarySubscriber = ref<NotificationsSubscriber | null>(null);
const error = ref('');
const state = ref(PageState.loading);

async function loadSubscribers() {
  try {
    notificationsSubscribers.value = await getSubscribers(projectId);
    primarySubscriber.value = notificationsSubscribers.value.find((subscriber) => subscriber.isPrimary) || null;
    state.value = PageState.loaded;
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
    state.value = PageState.error;
  }
}

onMounted(async () => {
  loadSubscribers();
});

</script>
