<template>
  <div>
    <label
      v-if="label"
      class="block label"
    >
      {{ label }}
    </label>

    <select
      class="input"
      :class="{ 'input-error' : errors.length, [inputClass]: !!inputClass, [color]: color }"
      :value="modelValue"
      v-bind="$attrs"
      @change="$emit('update:modelValue', handleSelectChange($event))"
    >
      <option :value="defaultOptions.value">
        {{ defaultOptions.name }}
      </option>

      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>

    <div
      v-if="errors && errors.length !== 0"
      class="flex flex-col-reverse justify-between pb-2 mt-1 ml-2 mr-2 sm:flex-row"
    >
      <ul class="list-none left-6">
        <li
          v-for="(error, index) in errors"
          :key="index"
          class="text-xs text-error"
        >
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export interface SelectOption {
  name: string;
  value: string | number;
  selected?: boolean;
}

type ColorOptions = 'white' | 'grey';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },

    color: {
      type: String as PropType<ColorOptions>,
      default: 'grey',
    },

    inputClass: {
      type: String,
      default: '',
    },

    defaultOptions: {
      type: Object as PropType<SelectOption>,
      default: () => ({ name: 'None', value: '' }),
    },

    placeholder: {
      type: String,
      default: '',
    },

    options: {
      type: [Array] as PropType<SelectOption[]>,
      required: true,
    },

    modelValue: {
      type: [String, Number],
      default: '',
    },

    errors: {
      type: [Array, String],
      default: () => [],
    },
  },
  emits: ['update:modelValue'],

  setup() {
    const handleSelectChange = (event: Event) => (event.target as HTMLInputElement).value;

    return { handleSelectChange };
  },
});
</script>
