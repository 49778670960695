import http, { Http } from '@/lib/http';

export interface StudentFilter {
  textFilter: string;
}
interface Data {
  filter: StudentFilter;
  projectId: string;
}

function getStudentsDownloadCtor(http: Http) {
  return async function getStudentsDownload({ filter, projectId }: Data) {
    const { data } = await http.get(`/projects/${projectId}/students/download`, {
      responseType: 'arraybuffer',
      params: filter,
    });

    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };
}

export const getStudentsDownload = getStudentsDownloadCtor(http);
