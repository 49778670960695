<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu
    as="div"
    class="relative inline-block text-left dropdown"
  >
    <div>
      <MenuButton
        class="inline-flex justify-center w-full"
        v-bind="$attrs"
      >
        <slot />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 w-56 mt-2 origin-top-right z-10 bg-white rounded-md
        shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <template
            v-for="(item, index) in items"
            :key="index"
          >
            <MenuItem
              v-slot="{ active }"
              @click="itemClick(index)"
            >
              <a
                href="#"
                class="inline-flex w-full"
                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
              >
                {{ item.text }}
              </a>
            </MenuItem>
          </template>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';

export interface Item {
  text: string;
  icon?: string;
}

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },

  inheritAttrs: false,

  props: {
    items: {
      default: () => [],
      type: Array as PropType<Item[]>,
    },
  },

  emits: ['itemClicked'],

  setup(_props, { emit }) {
    function itemClick(index: number) {
      emit('itemClicked', index);
    }

    return {
      itemClick,
    };
  },
});
</script>
