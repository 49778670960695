<template>
  <div>
    <label
      v-if="label"
      class="flex items-center text-primary font-medium text-sm ml-2 mt-1 mb-1"
    >
      {{ label }}
    </label>
    <div class="flex flex-row items-center w-full bg-gray-100 text-gray-400 rounded-md px-2">
      <div class="h-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </div>

      <input
        style="box-shadow: none; margin: 0;"
        class="relative bg-gray-100 text-sm focus:outline-none text-primary outline-none border-0 shadow-none py-2 block w-full"
        :value="modelValue"
        v-bind="$attrs"
        type="text"
        @input="$emit('update:modelValue', handleInputChange($event))"
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
    label?: string;
    modelValue: string
}

defineProps<Props>();
defineEmits(['update:modelValue']);

const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;
</script>
