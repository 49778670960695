<template>
  <h1 class="mb-4 text-xl font-bold">
    Plans Offered
  </h1>

  <form
    class="my-4"
    @submit.prevent="submit"
  >
    <div class="flex flex-col gap-6 sm:flex-row">
      <div
        class="flex-none gap-4"
      >
        <h2 class="mb-4 text-lg font-bold">
          Number of Payments
        </h2>

        <div
          class="checkbox-container"
        >
          <div
            v-for="(plan, i) in paymentPlans"
            :key="plan.installments"
            class="item"
          >
            <input
              :id="`${plan.installments}m`"
              v-model="paymentPlans[i].active"
              type="checkbox"
              class="mr-2 checked:bg-primary-800"
            >
            <label
              :for="`${plan.installments}m`"
            >
              {{ `${plan.installments} ${plan.installments > 1 ? 'Months' : 'Month (Pay-In-Full)'}` }}
            </label>
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-6">
          <template
            v-for="(pp) in paymentPlans.filter(pp => pp.active)"
            :key="pp.installments"
          >
            <div
              v-if="pp.active"
            >
              <span
                class="text-sm font-semibold"
              >
                {{ `${pp.installments} ${pp.installments > 1 ? 'Months' : 'Month'}` }}
              </span>
              <div
                class="flex flex-row gap-2 mb-2"
              >
                <template v-if="isV2Plan">
                  <TbInput
                    v-if="pp.installments !== 1"
                    v-model="pp.dpFormattedAmount"
                    disabled
                    type="number"
                    :label="`First Payment ($)`"
                  />
                </template>

                <template v-else>
                  <TbInput
                    v-if="pp.installments !== 1"
                    v-model="pp.downPaymentPercentage"
                    type="number"
                    max="99"
                    min="1"
                    step=".01"
                    :label="`First Payment (%)`"
                  />
                </template>
              </div>
            </div>
          </template>
        </div>

        <small class="text-xs text-error">
          {{ error }}
        </small>
      </div>
    </div>

    <div
      v-if="error"
      class="text-center error text-error-900"
    >
      <small class="error">
        {{ error }}
      </small>
    </div>

    <div class="flex flex-col mt-2">
      <button
        class="button button--secondary button--block"
        type="submit"
        :disabled="formState === FormState.submitting"
      >
        Update
      </button>

      <button
        type="button"
        class="mt-4 button button--text button--block"
        :disabled="formState === FormState.submitting"
        @click="cancel"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script lang="ts" setup>
// TODO update with checkout v1 vs v2 origins
import Dinero from 'dinero.js';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbInput } from '@/components/tasty_bistro';
import { AxiosError } from 'axios';
import { FormState } from '@/types';
import { PaymentPlan, getPaymentPlans } from '../api/get_payment_plans';
import { editPaymentPlans } from '../api/edit_payment_plans';

interface PaymentPlanUpdate extends Omit<PaymentPlan,
 'currencyCode' |
 'downPaymentAmountInCents' |
 'amountInCents' |
 'id' |
 'v2' |
 'firstPaymentPercentage'
 > {
  id?: string;
  active: boolean;
  downPaymentPercentage: number;
  dpFormattedAmount?: number;
}

const route = useRoute();
const projectId = route.params.projectId as string;
const props = defineProps<{ applicationId: string }>();
const paymentPlans = ref<PaymentPlanUpdate[]>([]);
const emit = defineEmits([
  'submit',
  'cancel',
]);

const formState = ref(FormState.ready);
const error = ref('');
const isV2Plan = ref(false);

const paymentPlanOptions: PaymentPlanUpdate[] = Array.from({ length: 12 }, (_, index) => index + 1)
  .map((i) => ({
    id: undefined,
    active: false,
    installments: i,
    downPaymentPercentage: Number((100 / i).toFixed(2)),
    firstPaymentInCents: 0,
  }));

function cancel() {
  emit('cancel');
}

const submit = async () => {
  try {
    error.value = '';
    await editPaymentPlans({ id: props.applicationId, paymentPlans: paymentPlans.value, projectId });
    cancel();
  } catch (err) {
    if (err instanceof AxiosError) {
      error.value = err.response?.data?.message;
    } else {
      error.value = 'An error occurred';
    }
  }
};

function getAmountInCentsFormatted(amountInCents: number) {
  return Dinero({ amount: amountInCents }).toUnit();
}

onMounted(async () => {
  const data = await getPaymentPlans({ id: props.applicationId, projectId });

  isV2Plan.value = data.plans.some((pp) => pp.v2);
  paymentPlans.value = paymentPlanOptions.map((ppo) => {
    const exists = data.plans.find((ppg) => ppg.installments === ppo.installments);
    return exists ? {
      ...exists,
      active: true,
      dpFormattedAmount: getAmountInCentsFormatted(exists.firstPaymentInCents),
      downPaymentPercentage: exists.firstPaymentPercentage,
    } : ppo;
  });
});

</script>

<style lang="scss" scoped>
  .checkbox-container {
    display: grid;
    grid-template-columns: 170px 170px;
    column-gap: 10px;
  }
</style>
