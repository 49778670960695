<template>
  <slot
    name="action"
    v-bind="{ deleteHandler: initArchive }"
  />

  <ConfirmationDialog
    v-model="showDialog"
    title="Delete Checkout?"
    :items="[]"
    action="Delete"
    :fn="archive"
    @cancel="$emit('cancel')"
  >
    <template #pre>
      Are you sure you want to delete "<strong class="font-semibold text-brand">{{ checkout.name }}</strong>"? This
      action cannot be undone.
    </template>
  </ConfirmationDialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { archiveCheckout } from '../api/archive_checkout';
import ConfirmationDialog from '../../components/modals/confirmation.vue';
import { Checkout } from '../api/get_checkouts';

const route = useRoute();
const projectId = route.params.projectId as string;

const props = defineProps<{ checkout: Checkout}>();

const emit = defineEmits([
  'archive',
  'cancel',
]);

const showDialog = ref(false);

const initArchive = () => {
  showDialog.value = true;
};

const archive = async () => {
  await archiveCheckout({ projectId, checkoutId: props.checkout.staticIdentifier });
  emit('archive');
};
</script>
