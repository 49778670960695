import http, { Http } from '@/lib/http';

export interface InviteInfo {
    expired: boolean;
    userId?: string;
    projectName?: string | null;
}

function getInviteInfoCtor(http: Http) {
  return async (inviteId: string) => {
    const { data } = await http.get<InviteInfo | undefined>(`/invite/${inviteId}`);
    return data;
  };
}

export const getInviteInfo = getInviteInfoCtor(http);
