<template>
  <Table
    :headers="tableHeaders"
    :items="props.integrations"
    flat
    :hide-header="true"
    :is-pagination-enabled="false"
    class="custom-table"
  >
    <template #row(state)="{ value }">
      <div class="flex flex-row">
        <div class="mr-1">
          <TbIcon
            v-if="value === 'authorized'"
            icon="check-circle"
            class="w-4 h-4 text-success"
          />

          <TbIcon
            v-else-if="value === 'unauthorized'"
            icon="exclamation-circle"
            class="w-4 h-4 text-error"
          />

          <Tooltip
            v-else
          >
            <template #trigger="{ show, assignReference, hide }">
              <div
                :ref="(el: any) => assignReference(el)"
                class="flex flex-row items-center gap-2 w-min"
                @mouseenter="show"
                @mouseleave="hide"
              >
                <TbIcon
                  icon="exclamation-triangle"
                  class="w-4 h-4 text-warning"
                />
              </div>
            </template>

            <template #tooltip>
              Validation can take 1-3 minutes. Please contact help@elective.com if this message persists.
            </template>
          </Tooltip>
        </div>
        {{ getVerboseState(value) }}
      </div>
      <span
        v-if="value === 'unauthorized'"
        class="py-2 text-gray-400 font-bold"
      >
        Update and Fix Account
      </span>
    </template>
    <template #action="{item}">
      <div class="flex flex-col h-full gap-1">
        <archiveIntegrationForm
          :integration-id="item.id"
          :integration-name="item.displayName"
          :integration-source="item.source"
          @integration-archived="() => emit('archive')"
        />
      </div>
    </template>
  </Table>
</template>

<script setup lang="ts">
import { TbIcon } from '@/components/tasty_bistro';
import Table from '@/components/table.vue';
import Tooltip from '@/components/tooltip.vue';
import { Integration } from '../api';
import archiveIntegrationForm from './archiveIntegration.vue';

interface Props {
  integrations: Integration[];
}

const emit = defineEmits([
  'update',
  'archive',
]);

const props = defineProps<Props>();

const tableHeaders = [
  { title: 'Account', key: 'displayName' },
  { title: 'Status', key: 'state' },
];

function getVerboseState(state: string): string {
  switch (state.toLowerCase()) {
    case 'authorized':
      return 'Connected';
    case 'unauthorized':
      return 'Unauthorized';
    default:
      return 'Validating';
  }
}
</script>

<style scoped lang="scss">
.custom-table {
  @apply p-0 text-xs;
}
.custom-table :deep > div {
  @apply px-0;
}

.custom-table :deep th {
  @apply bg-white text-gray-400 font-light border-b rounded-r-none rounded-l-none;

}
.custom-table :deep th > tr {
}
</style>
