import http, { Http } from '@/lib/http';

export interface CheckoutReference {
  name: string;
  itemCount: number;
  ppmEnabled: boolean;
  lnplEnabled: boolean;
  rawTotalInCents: number;
  payInFullEnabled: boolean;
}

export interface Product {
  productId: string;
  name: string;
  description: string | null;
  projectId: string;
  currencyCode: string;
  priceInCents: number;
  state: 'active' | 'inactive';
  createdAt: string;
  activeCheckouts: CheckoutReference[];
  taxCode?: string;
}

export interface ProductsFiltered {
  data: Product[];
  count: number;
}

export interface ProductFilter {
  name: string;
  state: string | undefined;
  pageSize: number;
  currentPage: number;
}

interface Data {
  filter: ProductFilter;
  projectId: string;
}

function getProductsCtor(http: Http) {
  return async function getProducts({ projectId, filter }: Data) {
    const { data } = await http.get<ProductsFiltered>(`/projects/${projectId}/products`, {
      params: filter,
    });
    return data;
  };
}

export default getProductsCtor(http);
