import http, { Http } from '@/lib/http';

export interface BusinessOwnerResponse {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ssnLast4: string;
  title: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
}

function getBusinessOwnerCtor(http: Http) {
  return async (projectId: string) => {
    const response = await http.get<BusinessOwnerResponse>(`/projects/${projectId}/business-owner`);

    return response.data;
  };
}

export const getBusinessOwner = getBusinessOwnerCtor(http);
