import http, { Http } from '@/lib/http';

export interface SendInviteParams {
    roleId: string;
    email: string;
    projectId: string;
}

function sendInviteCtor(http: Http) {
  return async function sendInvite({ projectId, roleId, email }:SendInviteParams) {
    await http.post<void>(`/projects/${projectId}/users/invite`, { email, roleId });
  };
}

export const sendInvite = sendInviteCtor(http);
