import http, { Http } from '@/lib/http';

interface Data {
  stackId: string;
  projectId: string;
}

function archivePaymentStackCtor(http: Http) {
  return async function archiveProduct({ projectId, stackId }: Data): Promise<void> {
    await http.delete(`/projects/${projectId}/payment-settings/${stackId}`);
  };
}

export const archiveStack = archivePaymentStackCtor(http);
