import http, { Http } from '@/lib/http';

function getSchedulingLinkCtor(http: Http) {
  return async (projectId: string): Promise<string> => {
    const { data } = await http.get<string>(`/projects/${projectId}/demo/scheduling-link`);
    return data;
  };
}

export const getSchedulingLink = getSchedulingLinkCtor(http);
