<template>
  <div class="flex flex-col items-center min-h-full bg-primary">
    <div class="w-full max-w-6xl p-8 m-auto">
      <div class="flex flex-col bg-white lg:flex-row rounded-xl">
        <div class="w-full p-8 text-center sm:p-12 lg:w-1/2 lg:text-start">
          <h2 class="my-8 text-xl font-medium lg:text-2xl">
            👋 Welcome to Elective
          </h2>
          <div class="px-4 text-center lg:text-start">
            <h1 class="mb-8 text-2xl font-medium lg:text-3xl">
              Schedule Your Free Demo
            </h1>
            <p class="mb-4">
              Schedule a demo with our CEO, John Najafi.
            </p>
            <p class="px-0 mb-8 sm:px-20 lg:px-0">
              In a 30-minute call, he'll give you an overview of our platform and help answer any questions you might
              have about working with Elective.
            </p>
            <div class="flex flex-col justify-center mb-8 lg:justify-start sm:flex-row">
              <a
                class="w-auto mr-2 font-bold text-center button button--primary"
                :href="schedulingLink"
              >
                Pick a time
              </a>
              <button
                class="w-auto button button--text"
                @click="goToDemoScheduled"
              >
                I've scheduled a demo
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full p-8 sm:p-12 lg:w-1/2">
          <img src="./assets/creator-graph.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import { getSchedulingLink } from './api/get_scheduling_link';

const router = useRouter();
const route = useRoute();

const schedulingLink = ref('');
onMounted(async () => {
  schedulingLink.value = await getSchedulingLink(route.params.projectId as string);
});

function goToDemoScheduled() {
  router.push({ name: Pages.demoScheduled });
}
</script>
