import http, { Http } from '@/lib/http';

export interface UpdateCheckoutParams {
  checkoutId: string;
  projectId: string;
  name: string;
  status: 'inactive' | 'active';
  redirectUrl: string | null;
  termsUrl: string | null;
  paymentSettingsId: string | null;
  products: string[];
  downPaymentType: 'percentage' | 'cents';
  downPaymentValue: number;
}

function updateCheckoutCtor(http: Http) {
  return async ({ checkoutId, projectId, ...form }: UpdateCheckoutParams) => {
    await http.patch(`/projects/${projectId}/checkouts/${checkoutId}`, form);
  };
}

export const updateCheckout = updateCheckoutCtor(http);
