<template>
  <div>
    <p>This will trigger all zaps connected for the selected event in your Zapier account.</p>

    <TbSelect
      v-model="eventName"
      class="uppercase"
      label="Trigger"
      :options="getEventsWithZapierConnected()"
    />

    <eventForm
      v-if="eventName"
      :eventName="eventName"
      :checkouts="props.checkouts"
      @trigger="onTrigger"
    />

    <button
      class="w-full button button--secondary p-2 mt-2"
      @click="cancel"
    >
      Cancel
    </button>

    <p
      v-if="error"
      class="p-1 text-error"
    >
      {{ error }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useField } from 'vee-validate';
import { TbSelect } from '@/components/tasty_bistro';
import { Checkout } from '../api/get_checkouts';
import { ConnectedZaps } from '../api/get_connected_zaps';
import { triggerTestZap } from './api/trigger_test_zap';
import eventForm from './form_by_event/standard_event_form.vue';

const route = useRoute();
const projectId = route.params.projectId as string;

interface Props {
    connectedZaps: ConnectedZaps[];
    checkouts: Checkout[];
}

const emit = defineEmits(['close-modal', 'triggered-successfully']);
const props = defineProps<Props>();
const { value: eventName } = useField<string>('eventName');
const error = ref<string>();

onBeforeMount(async () => {
  eventName.value = props.connectedZaps[0]?.eventName;
});

function getEventsWithZapierConnected() {
  return props.connectedZaps
    .filter((a) => a.count > 0)
    .map((a) => ({
      value: a.eventName,
      name: a.eventName.replace('_', ' '),
    }));
}

async function onTrigger(values: any) {
  try {
    await triggerTestZap({ projectId, form: values, eventName: eventName.value });
    emit('close-modal');
    emit('triggered-successfully');
  } catch (err: any) {
    console.error(err);
    error.value = 'Error trying to trigger zaps. Please try again later.';
  }
}

function cancel() {
  emit('close-modal');
}

</script>
../../api/get_checkouts../../api/get_connected_zaps
