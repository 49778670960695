<template>
  <div
    :class="[
      'flex flex-row items-center gap-2 relative',
      [props.class],
      props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    ]"
  >
    <span class="text-sm font-bold">{{
      props.modelValue ? 'On' : 'Off'
    }}</span>
    <Tooltip :disabled="!tooltipText || !props.disabled">
      <template #trigger="{ assignReference, show, hide }">
        <label
          :ref="(r: any) => assignReference(r)"
          :class="[
            'relative inline-flex items-center',
            props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          ]"
          @mouseenter="show"
          @mouseleave="hide"
        >
          <input
            :checked="modelValue"
            type="checkbox"
            class="sr-only peer"
            :disabled="disabled"
            @change="handleChange"
          >
          <div
            class="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full
                      peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
                      after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full
                      after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-900"
            :class="props.disabled ? 'bg-gray-300' : 'bg-gray-200'"
          />
        </label>
      </template>

      <template #tooltip>
        {{ tooltipText }}
      </template>
      <span class="text-sm font-bold">On</span>
    </tooltip>
  </div>
</template>

<script lang="ts" setup>
import Tooltip from './tooltip.vue';

  interface Props {
      modelValue: boolean;
      class?: string;
      disabled?: boolean;
      tooltipText?: string;
  }

const props = defineProps<Props>();

const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

const emit = defineEmits(['update:modelValue']);

const handleChange = (e: Event) => {
  if (props.disabled) return;
  emit('update:modelValue', !props.modelValue);
  handleInputChange(e);
};
</script>
