<template>
  <div
    class="p-2 bg-white tb-table"
    :class="{ 'card bg-white': !flat }"
  >
    <div class="px-4 py-2 bg-white tb-table-filter">
      <div class="table-header">
        <h3
          v-if="!hideHeader"
          class="mb-2 text-lg font-bold"
        >
          {{ totalItemCount === 1 ? itemType : `${itemType}s` }}
        </h3>
        <slot name="tableHeader" />
      </div>
    </div>

    <div class="px-4 pb-4 overflow-x-auto">
      <table
        :id="tableId"
        class="w-full"
      >
        <thead>
          <tr class="h-10 text-white rounded-sm bg-primary-900">
            <th
              v-for="(header, i) in headers"
              :key="i"
              align="left"
              class="p-2 font-regular"
            >
              <slot
                :name="`header(${header.key})`"
                :value="header.title"
              >
                {{ header.title }}
              </slot>
            </th>

            <th
              v-if="$slots.action"
              width="44px"
              class="px-4 py-2 font-regular"
            />
          </tr>
        </thead>

        <template v-if="state === PageState.loading">
          <tbody>
            <tr
              v-for="(_n, ni) in 5"
              :key="ni"
              class="h-10 even:bg-gray-100 odd:bg-white"
            >
              <td
                v-for="(_h, hi) in headers"
                :key="hi"
              >
                <div class="h-6 mr-2 bg-gray-200 rounded" />
              </td>
            </tr>
          </tbody>
        </template>

        <template v-else-if="state === PageState.error">
          <div class="my-12 text-center">
            <h3 class="text-lg font-light text-error-900">
              We experienced an loading error your information
            </h3>
          </div>
        </template>

        <tbody
          v-else-if="state === PageState.loaded && items?.length === 0"
        >
          <tr
            v-if="$slots.topRowInfo"
            class="h-14 items-center"
          >
            <td colspan="100%">
              <slot
                name="topRowInfo"
              />
            </td>
          </tr>
          <h3 class="m-4 text-base font-semibold">
            No {{ `${itemType}s` }}
          </h3>
        </tbody>

        <tbody v-else>
          <tr
            v-if="$slots.topRowInfo"
            class="h-14 items-center"
          >
            <td colspan="100%">
              <slot
                name="topRowInfo"
              />
            </td>
          </tr>

          <tr
            v-for="(item) in items"
            :key="item.id"
            class="h-10 even:bg-gray-100 odd:bg-white"
          >
            <td
              v-for="(header) in headers"
              :key="header.key"
              class="px-2 py-1 text-sm whitespace-nowrap"
            >
              <slot
                :name="`row(${header.key})`"
                :value="item[header.key]"
                :item="item"
              >
                <template v-if="item[header.key] || item[header.key] === 0">
                  <template v-if="header.goto">
                    <router-link :to="{ name: header.goto.page, params: { id: item[header.goto.idKey] } }">
                      {{ header.formatter ? header.formatter(item[header.key]) : item[header.key] }}
                    </router-link>
                  </template>

                  <template v-else>
                    <div :class="header.class">
                      {{ header.formatter ? header.formatter(item[header.key]) : item[header.key] }}
                    </div>
                  </template>
                </template>
              </slot>
            </td>

            <td
              v-if="$slots.action"
              class="py-1 pl-2 pr-1 whitespace-nowrap"
            >
              <Dropdown>
                <template #toggle="{ toggleDropdown }">
                  <button
                    class="py-5 button button--xs"
                    @click="toggleDropdown"
                  >
                    <TbIcon icon="ellipsis" />
                  </button>
                </template>
                <template #default="{ toggleDropdown }">
                  <slot
                    name="action"
                    :item="item"
                    :toggleDropdown="toggleDropdown"
                  />
                </template>
              </Dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <template v-if="$slots.alternative">
      <template
        v-for="(item) in items"
        :key="item.id"
      >
        <div class="mx-4">
          <small
            v-if="dateKey && dateSeen(item[dateKey], item.id) === item.id"
            class="relative block w-full my-4 font-bold text-right right-4"
          >{{ formatDate(item[dateKey], 'MMM - yyyy') }}</small>

          <div
            class="mb-4 transition-all bg-white card tb-table-row"
            :class="{ 'expandable relative': expandable }"
          >
            <div class="p-4">
              <slot
                v-if="items && items?.length > 0"
                name="alternative"
                :item="item"
                :isExpanded="expanded[item.id]"
              />

              <div class="flex justify-end gap-2 mt-2">
                <slot
                  name="action"
                  v-bind="item"
                />
              </div>

              <button
                v-if="expandable && !expanded[item.id]"
                class="expand-hint expand-hint-position button button--icon button--primary"
                @click="expand(item.id)"
              >
                <TbArrowDownIcon class="w-4 h-4" />
              </button>

              <div v-else-if="expandable && expanded[item.id]">
                <button
                  class="expand-hint-position button button--icon button--primary"
                  @click="minimize(item.id)"
                >
                  <TbXMarkIcon
                    v-if="expanded[item.id]"
                    class="w-4 h-4"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>

    <div
      class="flex flex-row justify-between mx-4 mb-4"
    >
      <div
        class="font-normal"
      >
        <span v-if="count">
          {{ Number(totalItemCount) }} {{ Number(totalItemCount) === 1 ? count.singular : count.plural }}
        </span>
      </div>
      <div
        v-if="isPaginationEnabled"
        class="flex justify-end gap-4"
      >
        <button
          v-if="currentPage > 0"
          class="button button--info button--sm"
          @click="prev"
        >
          <span class="flex items-center justify-center gap-2">
            <TbArrowLeftIcon class="w-4 h-4" />
            Prev
          </span>
        </button>

        <button
          v-if="numberOfPages > 1"
          class="button button--info button--sm"
          :disabled="(currentPage + 1) === numberOfPages"
          @click="next"
        >
          <span class="flex items-center justify-center gap-2">
            Next
            <TbArrowRightIcon class="w-4 h-4" />
          </span>
        </button>
      </div>
    </div>

    <div
      v-if="state === PageState.loaded"
      class="load-more-point"
    />

    <p
      v-if="isPaginationEnabled && totalItemCount > 0 && numberOfPages > 1"
      class="mx-8 my-4 ml-4 text-sm italic font-light text-center"
    >
      Page {{ currentPage + 1 }} / {{ numberOfPages }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { PropType, watch, ref } from 'vue';
import { getYear, getMonth } from 'date-fns';
import {
  TbArrowRightIcon, TbArrowLeftIcon, TbArrowDownIcon, TbXMarkIcon, TbIcon,
} from '@/components/tasty_bistro';
import Dropdown from '@/components/dropdown.vue';
import { PageState } from '@/types';
import { formatDate } from '../filters/date';

const emit = defineEmits([
  'next',
  'prev',
]);

type CountDisplay = {
  singular: string;
  plural: string;
}

const currentPage = ref(0);
const expanded = ref<Record<string, boolean>>({});

const props = defineProps(
  {
    state: {
      type: String,
      default: PageState.loaded,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    itemsPerPage: {
      type: Number,
      default: 20,
    },

    items: {
      type: Array as PropType<Record<any, any>[] | null>,
      default: () => [],
    },

    totalItemCount: {
      type: Number,
      default: 0,
    },

    dateKey: {
      type: String,
      default: null,
    },

    itemType: {
      type: String,
      default: 'Item',
    },

    expandable: {
      type: Boolean,
      default: false,
    },

    headers: {
      type: Array as PropType<any>,
      default: () => [],
    },

    tableId: {
      type: String,
      default: Date.now(),
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    count: {
      type: Object as PropType<CountDisplay>,
      default: null,
    },

    isPaginationEnabled: {
      type: Boolean,
      default: true,
    },
  },
);

const numberOfPages = ref<number>(Math.ceil(props.totalItemCount / props.itemsPerPage));
let dateLookUp: Record<string, Record<string, string>> = {};
const dateSeen = (date: string, id: string) => {
  const asDate = new Date(date);
  const year = getYear(asDate);
  const month = getMonth(asDate);

  if (!dateLookUp[year]) {
    dateLookUp[year] = {};
  }

  if (!dateLookUp[year][month]) {
    dateLookUp[year][month] = id;
  }

  return dateLookUp[year][month];
};

const expand = (id: string) => {
  if (!props.expandable || expanded.value[id]) return;
  expanded.value[id] = true;
};

const minimize = (id: string) => {
  if (!props.expandable) return;
  expanded.value[id] = false;
};

const next = () => {
  currentPage.value += 1;
  emit('next', currentPage.value);
};

const prev = () => {
  currentPage.value -= 1;
  emit('prev', currentPage.value);
};

watch(() => props.totalItemCount, () => {
  currentPage.value = 0;
});

watch(() => props.items, () => {
  dateLookUp = {};
  numberOfPages.value = Math.ceil(props.totalItemCount / props.itemsPerPage);
});

</script>

<style lang="scss" scoped>
 th:first-of-type {
    border-top-left-radius: 0.375rem;
  }
  th:last-of-type {
    border-top-right-radius: 0.375rem;
  }
  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 0.375rem;
  }
  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 0.375rem;
  }

  td {
    vertical-align: middle;
  }

  .tb-table-filter {
    @apply rounded-b-none rounded-md;
  }

  .tb-table-row {
    @apply hover:mx-2;
  }

  .tb-table-row.expandable {
    .expand-hint {
      @apply opacity-60;
    }

    .expand-hint-position {
      @apply absolute left-0 right-0 ml-auto mr-auto;
    }

    &:hover {
      .expand-hint {
        @apply opacity-100 delay-100 transition-all;
      }
    }
  }

  .table-overlay {
    background-image:
      linear-gradient(135deg,#c5c8ce80 10%,transparent 0,transparent 50%,#c5c8ce80 0,#c5c8ce80 60%,transparent 0,transparent);
    background-size: 7.07px 7.07px;
  }
</style>
