<!-- eslint-disable vue/no-v-html -->
<template>
  <Modal
    class="custom-modal"
    :showModal="shouldShowModal"
  >
    <div class="modal-container">
      <!-- Sidecar section -->
      <div class="tophat">
        <p class="font-bold text-lg mb-4">
          Welcome to Elective
        </p>
        <Accordion
          :hideTitleWhenOpen="true"
        >
          <template #title>
            <div class="flex items-center">
              <div
                class="circle active"
              >
                <span class="font-bold">{{ currentSection + 1 }}</span>
              </div>
              <p class="text-white pr-2">
                {{ quizTextParts[currentPage].sidebarSectionName }}
              </p>
            </div>
          </template>
          <template #body>
            <QuizSectionsGroup
              :uniqueSections="uniqueSections"
              :currentSection="currentSection"
              :seenSections="seenSections"
              @change-to-section-page="setPage"
            />
          </template>
        </Accordion>
        <QuizProgressBar :progress="progress" />
      </div>
      <div
        class="sidecar"
      >
        <div>
          <p class="font-bold text-lg mb-4">
            Welcome to Elective
          </p>
          <QuizSectionsGroup
            :uniqueSections="uniqueSections"
            :currentSection="currentSection"
            :seenSections="seenSections"
            @change-to-section-page="setPage"
          />
        </div>
        <QuizProgressBar :progress="progress" />
      </div>
      <div class="content">
        <!-- Welcome section -->
        <div
          v-if="currentPage === 0"
        >
          <TbWordLogo class="max-w-[164px]" />
          <p class="primary md:text-3xl sm:text-xl font-bold py-6">
            Welcome to Elective! 👋🏼👋🏽👋🏿
          </p>
          <p class="pb-8">
            We’re excited to guide you through the onboarding process and ensure you have a solid understanding of
            Elective before you get started.
          </p>
          <p class="pb-8">
            Please note: financial services is a highly regulated industry, requiring financial institutions like
            Elective to comply with a number of different laws and regulations.
          </p>
          <p class="pb-8">
            As a merchant utilizing Elective, you’re obligated to adhere to
            <a
              href="https://wizebank.s3.amazonaws.com/public-agreements/Elective+Merchant+Laws+and+Regulations.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              the same standards that govern our operations.
            </a> The following sections are designed to help you understand how to best use Elective in
            accordance with our company standards and the legal obligations we abide by.
          </p>
          <p class="pb-8">
            Rest assured, this won’t take long, just a few minutes, and we genuinely appreciate your cooperation
            and understanding during this process.
          </p>
          <p class="pb-8">
            With that said, let’s get started! 🚀
          </p>
          <button
            class="button button--primary button--md custom-button"
            @click="nextPage"
          >
            Next <TbIcon icon="long-arrow-right" />
          </button>
        </div>

        <!-- Quiz Copy -->
        <div v-else-if="currentPage < quizTextParts.length-2">
          <button @click="previousPage">
            <TbIcon icon="long-arrow-left" />
            <span class="font-bold"> Back</span>
          </button>

          <p class="primary text-3xl font-bold py-6">
            {{ quizTextParts[currentPage].sectionName }}
          </p>

          <!-- the numbers should only appear on multi page sections. -->
          <div
            v-if="currectSectionPages.length > 1"
            class="page-copy-circle circle"
          >
            {{ getCurrentSubPageNumber(currentPage) }}
          </div>

          <div
            class="whitespace-pre-line"
            v-html="quizTextParts[currentPage].content"
          />

          <button
            v-if="quizTextParts[currentPage].sectionName !== knowledgeTestSectionName"
            class="button button--primary button--md custom-button"
            @click="nextPage"
          >
            {{ currentPageSeen[currentPage] ? "Next" : "I Understand" }}
            <TbIcon
              v-if="currentPageSeen[currentPage]"
              icon="long-arrow-right"
            />
          </button>
          <button
            v-else
            class="button button--primary button--md custom-button"
            @click="nextPage"
          >
            Start
            <TbIcon
              icon="long-arrow-right"
            />
          </button>
        </div>

        <!-- Quiz Test -->
        <div
          v-else-if="quizTextParts[currentPage].sectionName === knowledgeTestSectionName"
        >
          <p class="primary text-3xl font-bold py-6">
            You're almost done! 🙏
          </p>

          <div
            v-for="(question, index) in questions"
            :key="index"
            class="mb-4"
          >
            <div class="flex mb-4">
              <div
                class="circle border-accent-300"
              >
                {{ question.question }}
              </div>
              <p v-html="question.questionText" />
            </div>
            <QuizButtonGroup
              :failed="failed"
              :question="questions[index]"
              :index="index"
              :selectedButtons="selectedButtons"
            />
          </div>
          <button
            class="button button--primary button--md custom-button"
            @click="quizButtonAction"
          >
            <TbIcon
              v-if="failed"
              icon="refresh"
            />
            {{ quizButtonText }}
            <TbIcon
              v-if="failed === false"
              icon="long-arrow-right"
            />
          </button>
        </div>

        <!-- Done -->
        <div
          v-else
          class="w-full h-full flex items-center"
        >
          <div class="w-full">
            <TbIcon
              icon="check-circle"
              class="pr-2 text-success text-6xl"
            />
            <p class="primary text-3xl font-bold py-6">
              Congratulations!
            </p>
            <p>Congratulations! You’re ready to start selling with Elective! 🎉</p>
            <button
              class="button button--primary button--md custom-button"
              @click="shouldShowModal=false; emit('close-modal');"
            >
              Let’s get started 🚀 <TbIcon icon="long-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { TbWordLogo, TbIcon } from '@/components/tasty_bistro';
import Modal from '@/components/modal.vue';
import Accordion from '@/components/accordion.vue';
import { getQuiz, submitQuiz, QuizTextSection } from './api';
import QuizButtonGroup from './components/quiz_button_group.vue';
import QuizSectionsGroup from './components/quiz_sections_group.vue';
import QuizProgressBar from './components/quiz_progress_bar.vue';
import { Question, QuizContent } from './types';

const emit = defineEmits(['close-modal']);

const props = defineProps<{ agreementId: string; }>();

const route = useRoute();
const projectId = route.params.projectId as string;
const shouldShowModal = ref(true);
const knowledgeTestSectionName = 'Test Your Knowledge 📃';

const currentPage = ref(0);
const currentPageSeen = ref<Record<number, boolean>>({});
const seenSections = ref<number>(0);
const quizTextParts = ref<QuizContent[]>([]);
const questions = ref<Question[]>([]);
const currentStep = ref(1);
const totalSteps = ref(1);
const selectedButtons = ref<{'true': boolean; 'false': boolean}[]>([]);
const failed = ref<boolean | null>(null);
const totalPages = ref<number>(1);

const progress = computed(() => Math.floor((currentPage.value / totalPages.value) * 100));

const uniqueSections = computed(() => {
  const sections = quizTextParts.value.map((part) => part.sectionName);
  return [...new Set(sections)].map((sectionName) => quizTextParts.value.find((part) => part.sectionName === sectionName)) as QuizContent[];
});

const currentSection = computed(() => {
  const currentSectionName = quizTextParts.value[currentPage.value]?.sectionName;
  return uniqueSections.value.findIndex((section) => section?.sectionName === currentSectionName);
});

const currectSectionPages = computed(() => {
  const currentSectionName = quizTextParts.value[currentPage.value]?.sectionName;

  if (currentSectionName === knowledgeTestSectionName) {
    return [];
  }

  return quizTextParts.value.filter((part) => part.sectionName === currentSectionName);
});

function getCurrentSubPageNumber(currentPage: number) {
  const currentPageCopy = quizTextParts.value[currentPage].content;
  const currentSectionPage = currectSectionPages.value.findIndex((part) => part.content === currentPageCopy);
  return currentSectionPage + 1;
}

function buildQuizContent(quizText: QuizTextSection[]): QuizContent[] {
  const resp = quizText.map((section, index) => ({
    sectionName: section.sectionName,
    sidebarSectionName: section.sidebarSectionName,
    sectionNumber: index + 2,
    content: section.content,
  }));

  resp.unshift({
    sectionName: 'Welcome',
    sidebarSectionName: 'Welcome',
    sectionNumber: 1,
    content: [''],
  });

  resp.push({
    sectionName: knowledgeTestSectionName,
    sidebarSectionName: 'Knowledge Check',
    sectionNumber: resp.length + 1,
    content: [
      `You’re almost done! 🏁\n
      We just need you to complete a quick true-or-false quiz to confirm your understanding.
      Once you’re finished, you’re ready to start selling with Elective! 🎉`,
      '',
    ],
  }, {
    sectionName: 'Done',
    sidebarSectionName: 'Done',
    sectionNumber: resp.length + 2,
    content: ['Done'],
  });

  return resp.flatMap((section) => section.content.map((content) => ({
    sectionName: section.sectionName,
    sidebarSectionName: section.sidebarSectionName,
    sectionNumber: section.sectionNumber,
    content,
  })));
}

async function fetchData() {
  try {
    const response = await getQuiz({ projectId, agreementId: props.agreementId });
    quizTextParts.value = buildQuizContent(response.quizText);
    questions.value = Object.entries(response.questions).map(([questionNumber, questionObj]) => ({
      question: questionNumber,
      questionText: questionObj.question,
      answer: null,
    }));
    selectedButtons.value = questions.value.map(() => ({
      true: false,
      false: false,
    }));
    totalPages.value = Math.abs((quizTextParts.value.length - 1)) || 1;
    totalSteps.value = quizTextParts.value.reduce((acc, curr) => Math.max(acc, curr.sectionNumber), 1);
  } catch (error) {
    console.error(error);
  }
}

const nextPage = () => {
  currentPageSeen.value[currentPage.value] = true;

  const currentSectionNumber = quizTextParts.value[currentPage.value].sectionNumber;
  const nextSectionNumber = quizTextParts.value[currentPage.value + 1].sectionNumber;
  const isDifferentSection = currentSectionNumber !== nextSectionNumber;

  const sectionAlreadySeen = quizTextParts.value[currentPage.value].sectionNumber <= seenSections.value;

  if (isDifferentSection && !sectionAlreadySeen) {
    currentStep.value += 1;
    seenSections.value += 1;
  }
  currentPage.value += 1;
};

const previousPage = () => {
  if (quizTextParts.value[currentPage.value].sectionNumber !== quizTextParts.value[currentPage.value - 1].sectionNumber) {
    currentStep.value -= 1;
  }
  currentPage.value -= 1;
};
const setPage = (sectionIndex: number) => {
  const sectionHasBeenSeen = seenSections.value >= sectionIndex;

  if (!sectionHasBeenSeen) {
    return;
  }
  const page = quizTextParts.value.findIndex((part) => part.sectionNumber === sectionIndex + 1);
  currentPage.value = page;
};

const clearAnswers = () => {
  questions.value.forEach((question) => {
    question.answer = null;
    question.correct = undefined;
  });
  selectedButtons.value = questions.value.map(() => ({
    true: false,
    false: false,
  }));
  failed.value = null;
};

async function submitAnswers() {
  const answers = questions.value.reduce((acc, curr) => ({ ...acc, [curr.question]: curr.answer }), {});
  const result = await submitQuiz({ projectId, agreementId: props.agreementId, answers });
  Object.entries(result.answersResult).forEach(([question, correct]) => {
    const questionIndex = questions.value.findIndex((q) => q.question === question);
    questions.value[questionIndex].correct = correct;
  });
  failed.value = !result.pass;
  if (result.pass) {
    nextPage();
  }
}

const quizButtonAction = computed(() => {
  if (failed.value === null) return submitAnswers;
  return failed.value ? clearAnswers : nextPage;
});

const quizButtonText = computed(() => {
  const funcToLabel = {
    [submitAnswers.name]: 'Submit',
    [clearAnswers.name]: 'Retry',
    [nextPage.name]: 'Next',
  };
  return funcToLabel[quizButtonAction.value.name];
});

onMounted(() => {
  fetchData();
});
</script>

<style scoped>
  .modal-container {
    @apply
      flex
    bg-white
      items-center
      rounded-l-2xl
      rounded-r-xl
      w-[400px]
      h-[905px]
      flex-col
      sm:flex-row
      sm:w-[730px]
      sm:h-[894px]
      min-[1042px]:w-[1042px]
      min-[1042px]:h-[744px];
  }

  .content {
    @apply
      w-full
      h-full
      text-sm
      py-6
      px-5
      sm:pt-8
      sm:pb-16
      sm:px-12
      sm:text-base
      flex
      flex-col
      content-start
      items-start
      gap-2
      self-stretch;
  }

  .sidecar {
    @apply
      hidden
      sm:flex
      text-white
      gap-2
      flex-col
      justify-between
      items-start
      self-stretch
      rounded-l-xl
      min-[1042px]:w-[35%]
      w-[55%]
      py-8
      px-11
      bg-[#424A68];
  }
  .tophat {
    @apply
      sm:hidden
      flex
      flex-col
      justify-between
      items-start
      self-stretch
      text-white
      rounded-t-xl
      py-5
      px-5
      bg-[#424A68];
  }
  .custom-button {
    @apply
      py-2
      px-8
      mt-8
      float-right;
  }
  .circle {
    @apply
      mr-4
      items-center
      justify-center
      flex
      p-[0.9rem]
      h-8
      w-8
      rounded-full
      border-2;
  }

  .circle.active {
    @apply
      bg-white
      text-primary;
  }

  .circle.page-copy-circle {
    @apply p-6 text-2xl mb-6 border-accent-300 font-bold;
  }

  .custom-modal :deep .modal {
    @apply z-10 backdrop-blur-sm bg-[#9D9D9D80];
  }
  .custom-modal :deep .modal-close {
    @apply hidden;
  }
</style>
