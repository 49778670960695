<template>
  <div class="container">
    <div>
      <div class="flex items-center mb-2">
        <div class="icon-container">
          <TbIcon
            icon="dollar-sign"
            class="text-white"
          />
        </div>
        <p class="leading-tight">
          Learn Now, Pay Later
        </p>
        <TbTooltip class="py-0">
          <div class="tooltip-card">
            <p>
              LNPL and Elective Billing operate differently.
              Learn more <a
                target="_blank"
                href="https://help.elective.com/hc/en-us/articles/17814303816475"
              >here</a>.
            </p>
          </div>
        </TbTooltip>
      </div>
      <p class="text-sm">
        When LNPL is on, customers can pay over time, and you'll receive upfront payment.
        If they're ineligible for funding, we'll auto-approve them for your own payment plans below
        and you’ll get paid as payments are received.
      </p>
    </div>

    <div class="lnpl-switch">
      <span class="switch-label">Off</span>
      <label class="relative inline-flex items-center cursor-pointer">
        <input
          :checked="props.isLnplEnabled"
          type="checkbox"
          class="sr-only peer"
          @change="toggleIsLnplEnabled"
        >
        <div
          class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full
                  peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
                  after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full
                  after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-900"
        />
      </label>
      <span class="switch-label">On</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  TbTooltip, TbIcon,
} from '@/components/tasty_bistro';

const props = defineProps<{ isLnplEnabled: boolean }>();
const emit = defineEmits(['switch-toggle']);

const toggleIsLnplEnabled = () => {
  emit('switch-toggle');
};
</script>

<style lang="scss" scoped>
  .tooltip-card {
    @apply p-3 shadow-md rounded-md text-sm bg-white
  }

  .container {
    @apply
      flex
      gap-1
      md:gap-8
      items-center
      justify-between
      p-3
      mb-6
      border-gray-200
      border-[1px]
      rounded-[6px]
  }

  .lnpl-switch {
    @apply flex items-center gap-2
  }

  .icon-container {
    @apply
      w-4
      h-4
      mr-2
      inline-flex
      justify-center
      items-center
      shrink-0
      rounded-full
      bg-primary-900
      text-xs
  }

  .switch-label {
    @apply text-xs md:text-sm font-semibold text-primary
  }

</style>
