<template>
  <CreateProductForm
    action="create"
    :fn="create"
    @cancel="emit('to-checkout')"
  >
    <template #escape-hatch>
      <button
        style="box-shadow: none;"
        class="self-end button button--sm bg-transparent font-bold hover:bg-gray-100"
        @click="emit('to-checkout')"
      >
        <span class="flex items-center gap-2">
          <TbArrowLeftIcon
            class="w-4 h-4 text-primary"
          />
          Back
        </span>
      </button>
    </template>
  </CreateProductForm>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { TbArrowLeftIcon } from '@/components/tasty_bistro';
import { inject } from 'vue';
import CreateProductForm from '../product_form/index.vue';
import { CreateProduct, createProduct } from './api/create_product';

const route = useRoute();
const projectId = route.params.projectId as string;

const emit = defineEmits([
  'to-checkout',
]);

const registerProduct = inject<(product: { id: string, currencyCode: string }) => void
  >('registerProduct');

const create = async (values: CreateProduct) => {
  const response = await createProduct({ projectId, form: values });
  // @ts-ignore
  registerProduct({
    id: response.id,
    currencyCode: values.currencyCode,
  });
  emit('to-checkout');
};
</script>
