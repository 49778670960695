import http, { Http } from '@/lib/http';

export interface PaymentPlans {
  id: string;
  active: boolean;
  installments: number;
  downPaymentPercentage: number;
}
export interface PaymentPlanGroup {
  id: string;
  name: string;
  termsOfServiceUrl: string | null;
  ppmOnly: boolean;
  paymentPlans: PaymentPlans[];
  totalCount: number;
  createdAt: Date;
}

export interface GetPaymentPlanGroupParams {
  pageSize: number;
  currentPage: number;
  search: string;
}

interface Data {
  params: GetPaymentPlanGroupParams;
  projectId: string;
}

function getPaymentPlanGroupsCtor(http: Http) {
  return async function getPaymentPlanGroups({ projectId, params }: Data): Promise<PaymentPlanGroup[]> {
    const { data } = await http.get(`/projects/${projectId}/ppm/payment-plan-groups`, { params });
    return data;
  };
}

export const getPaymentPlanGroups = getPaymentPlanGroupsCtor(http);
