<template>
  <router-view />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { Pages } from '@/router';

const router = useRouter();
router.replace({ name: Pages.pricingInfo });
</script>
