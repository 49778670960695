import http, { Http } from '@/lib/http';

export interface EditPaymentPlanGroupForm {
  name: string;
  termsOfServiceUrl?: string | null;
  paymentPlans?: { installments?: number, active?: boolean, downPaymentPercentage?: number }[];
  ppmOnly: boolean;
}

interface Data {
  id: string;
  form: EditPaymentPlanGroupForm;
  projectId: string;
}

function editPaymentPlanGroupCtor(http: Http) {
  return async function editPaymentPlanGroup({ id, projectId, form }: Data): Promise<void> {
    await http.put(`/projects/${projectId}/ppm/payment-plan-groups/${id}`, form);
  };
}

export const editPaymentPlanGroup = editPaymentPlanGroupCtor(http);
