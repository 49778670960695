import http, { Http } from '@/lib/http';

export interface AcceptTeamInvite {
    inviteId: string;
    userId: string;
    confirmationCode: string;
}

function acceptTeamInviteCtor(http: Http) {
  return async (data: AcceptTeamInvite) => {
    await http.post<void>(`/invite/${data.inviteId}/user`, data);
  };
}

export const acceptTeamInvite = acceptTeamInviteCtor(http);
