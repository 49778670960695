<template>
  <Page>
    <WelcomeBanner v-if="ppmEnabled" />
    <QuickStartGuide
      :is-ppm-enabled="ppmEnabled"
    />
  </Page>
</template>

<script setup lang="ts">
import { inject, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { AppState } from '@/pages/app/api/get_app_state';
import Page from '@/pages/app/project/dashboard/components/page.vue';
import QuickStartGuide from '@/pages/app/project/dashboard/home/components/quick_start_guide.vue';
import WelcomeBanner from '@/pages/app/project/dashboard/home/components/welcome_banner.vue';

const route = useRoute();
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = route.params.projectId as string;
const { ppmEnabled } = appState.value.projects[projectId].features;
</script>
