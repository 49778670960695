export enum CountryCode {
  'us' = 'US',
  'ca' = 'CA'
}

export enum CurrencyCode {
  'usd' = 'USD',
  'cad' = 'CAD',
}

export interface PaymentDetailsCA {
  currencyCode: CurrencyCode;
  countryCode: CountryCode;
  accountHolderName: string;
  accountNumber: string;
  institutionNumber: string;
  transitNumber: string;
  accountType: 'checking' | 'savings';
}

export interface PaymentDetailsUS {
  currencyCode: CurrencyCode;
  countryCode: CountryCode;
  accountHolderName: string;
  accountNumber: string;
  routingNumber: string;
  accountType: 'checking' | 'savings';
}

export type PaymentDetails = PaymentDetailsCA | PaymentDetailsUS;

// export interface PaymentDetails {
//   currencyCode: CurrencyCode;
//   countryCode: CountryCode;
//   accountHolderName: string;
//   accountNumber: string;

// }
