import http, { Http } from '@/lib/http';

interface Props {
  formId: string;
  userEmail: string;
}

function isOnTypeformWaitlistCtor(http: Http) {
  return async ({ formId, userEmail }: Props): Promise<boolean> => {
    const { data } = await http.get('/typeform/is-on-waitlist', {
      params: { formId, userEmail },
    });
    return data;
  };
}

export const isOnWaitlist = isOnTypeformWaitlistCtor(http);
