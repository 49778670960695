<template>
  <div class="sections text-sm">
    <div
      v-for="(section, index) in uniqueSections"
      :key="index"
      class="section"
      @click="setPage(index)"
    >
      <img
        v-if="index < seenSections && index !== currentSection"
        class="mr-4"
        src="./assets/check-mark.svg"
        alt="check mark"
      >
      <div
        v-else
        class="circle"
        :class="{
          active: index === currentSection,
          completed: index < seenSections && index !== currentSection,
        }"
      >
        <span class="font-bold">
          {{ index + 1 }}
        </span>
      </div>
      <p>{{ section.sidebarSectionName }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineEmits } from 'vue';
import { QuizContent } from '../types';

const emit = defineEmits(['changeToSectionPage']);
const setPage = (index: number) => {
  emit('changeToSectionPage', index);
};

interface Props {
  uniqueSections: QuizContent[];
  currentSection: number;
  seenSections: number;
}

defineProps<Props>();
</script>

<style scoped>
  .sections {
    @apply flex
      flex-col
      items-start;
  }

  .section {
    @apply flex
      flex-row
      items-center
      mb-8;
  }

  .circle {
    @apply mr-4
      items-center
      justify-center
      flex
      p-[0.9rem]
      h-8
      w-8
      rounded-full
      border-2;
  }

  .circle.active {
    @apply bg-white
      text-primary;
  }

  .circle.completed {
    @apply bg-accent-300
      border-accent-300
      text-primary
      text-base;
  }
</style>
