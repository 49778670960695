import http, { Http } from '@/lib/http';

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  revenue: any;
  termsAndConditions: boolean;
}

interface SignupResponse {
  user: User;
  token: string;
}

function signUpCtor(http: Http) {
  return async function signUp(form: User) {
    const response = await http.post<SignupResponse>('/auth/v2/signup', form);
    const { user, token } = response.data;

    return {
      user,
      token,
    };
  };
}

export default signUpCtor(http);
