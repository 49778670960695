import http, { Http } from '@/lib/http';

export interface Params {
  projectId: string;
  accountNumber: string;
  licenseKey: string;
  environment: string;
  companyId: number;
  companyCode: string;
  documentHandling: string;
}

function submitSalesTaxIntegrationCtor(http: Http) {
  return async ({
    projectId,
    accountNumber,
    licenseKey,
    environment,
    companyCode,
    companyId,
    documentHandling,
  }: Params) => {
    await http.post(`/projects/${projectId}/integrations/sales-tax`, {
      accountNumber,
      licenseKey,
      environment,
      companyCode,
      companyId: Number(companyId),
      documentHandling,
    });
  };
}

export const submitSalesTaxIntegration = submitSalesTaxIntegrationCtor(http);
