<template>
  <FormHeader
    :heading="heading"
    :subHeading="subHeading"
  />
  <form
    class="mt-4"
    @submit.prevent="submit"
  >
    <TbSelect
      v-model="country"
      :options="countries"
      label="Country*"
      class="w-full mb-2"
      :errors="countryError && [countryError]"
    />
    <TbInput
      v-model.trim="street"
      label="Street Address*"
      type="text"
      class="w-full mb-2"
      :errors="streetError && [streetError]"
    />
    <div class="flex flex-row justify-between mb-2">
      <div class="w-[48%]">
        <TbInput
          v-model.trim="complement"
          label="Unit # (Optional)"
          type="text"
          class="w-full"
        />
      </div>
      <div class="w-[48%]">
        <TbInput
          v-model.trim="city"
          label="City*"
          type="text"
          class="w-full"
          :errors="cityError && [cityError]"
        />
      </div>
    </div>
    <div class="flex flex-row justify-between mb-2">
      <div class="w-[48%]">
        <TbSelect
          v-model="state"
          :options="states[country] || []"
          label="Province/State*"
          class="w-full"
          :errors="stateError && [stateError]"
        />
      </div>
      <div class="w-[48%]">
        <TbInput
          v-model.trim="postalCode"
          label="Postal Code*"
          type="text"
          class="w-full"
          :errors="postalCodeError && [postalCodeError]"
        />
      </div>
    </div>
    <button
      type="submit"
      class="w-full my-4 button button--secondary"
    >
      Submit
    </button>
    <div
      class="text-sm font-light text-center"
    >
      Business outside North America? Please reach out to us at <HelpEmailLink /> and we’ll help you get set up!
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm, useField } from 'vee-validate';
import { object, string, InferType } from 'yup';
import { TbInput, TbSelect } from '@/components/tasty_bistro';
import FormHeader from '@/components/form_header.vue';
import HelpEmailLink from '@/components/help_email_link.vue';
import states from './states';
import countries from './countries';
import { BusinessDetails } from '../api/get_business_details';

interface Props {
  data: BusinessDetails
}

const props = defineProps<Props>();
const emit = defineEmits(['business-address-submit']);

const heading = 'Where is your business located?';
const subHeading = 'We just need the basics to verify your business so you can start processing transactions with Elective';

const businessAddressSchema = object({
  street: string().required('Required').matches(
    /^[A-Za-z0-9\s(),.-/]+$/,
    'Invalid Input. Valid input can only contain: A-Z, a-z, 0-9, comma(,), whitespace( ), hyphen(-), parenthesis(()), period(.), slash(/)',
  ),
  city: string().required('Required'),
  state: string().required('Required'),
  country: string().required('Required'),
  postalCode: string().required('Required'),
  complement: string(),
});

interface BusinessAddress extends InferType<typeof businessAddressSchema> {}

const { handleSubmit } = useForm<BusinessAddress>({
  validationSchema: businessAddressSchema,
  initialValues: {
    street: props.data?.addressStreet,
    city: props.data?.addressCity,
    state: props.data?.addressState,
    country: props.data?.addressCountry,
    postalCode: props.data?.addressPostalCode,
    complement: props.data?.addressComplement,
  },
});

const { value: street, errorMessage: streetError } = useField('street');
const { value: city, errorMessage: cityError } = useField('city');
const { value: state, errorMessage: stateError } = useField('state');
const { value: country, errorMessage: countryError } = useField<'US' | 'CA'>('country');
const { value: postalCode, errorMessage: postalCodeError } = useField('postalCode');
const { value: complement } = useField('complement');

const submit = handleSubmit(async (values) => {
  emit('business-address-submit', values);
});
</script>
