import http, { Http } from '@/lib/http';

export interface RemoveUserParams {
    projectId:string;
    userId: string;
}

function removeUserCtor(http: Http) {
  return async function removeUser({ projectId, userId }: RemoveUserParams) {
    await http.delete<void>(`/projects/${projectId}/users/${userId}`);
  };
}

export const removeUser = removeUserCtor(http);
