export function percentage(value = 0, decimals = 2) {
  const result = Math.round((value * 100) * (10 ** decimals)) / (10 ** decimals);
  if (Number.isNaN(result) || !Number.isFinite(result)) {
    return '-%';
  }
  return `${result}%`;
}

export function percentageDiff(newValue: number, oldValue: number) {
  const valueOne = Number(newValue);
  const valueTwo = Number(oldValue);

  if (valueTwo === 0) return 0;

  const difference = valueOne - valueTwo;
  return (difference / Number(valueTwo));
}
