import { View } from '@/pages/app/project/dashboard/home/api/get_overview';

const percentile = {
  approvalRate: {
    all: {
      '10th': 0.10,
      '30th': 0.15,
      '50th': 0.25,
      '70th': 0.35,
      '90th': 0.55,
    },
    lnpl: {
      '10th': 0.07,
      '30th': 0.11,
      '50th': 0.21,
      '70th': 0.33,
      '90th': 0.52,
    },
    ppm: null,
  },
  takeUpRate: {
    lnpl: {
      '10th': 0.03,
      '30th': 0.06,
      '50th': 0.33,
      '70th': 0.47,
      '90th': 0.68,
    },
    all: {
      '10th': 0.05,
      '30th': 0.10,
      '50th': 0.35,
      '70th': 0.47,
      '90th': 0.68,
    },
    ppm: null,
  },
  conversionRate: {
    lnpl: {
      '10th': 0.01,
      '30th': 0.03,
      '50th': 0.05,
      '70th': 0.12,
      '90th': 0.21,
    },
    all: {
      '10th': 0.04,
      '30th': 0.08,
      '50th': 0.10,
      '70th': 0.15,
      '90th': 0.21,
    },
    ppm: null,
  },
};

export const percentileToIcon: Record<string, string> = {
  '90th': '🔥',
  '70th': '💪',
  '50th': '👍',
  '30th': '❄️',
  '10th': '🥶',
};

interface Args {
    value: number;
    category: keyof typeof percentile;
    view: View;
}

export const checkoutBenchmark = ({ category, value, view }: Args) => {
  const resolvedView = view || 'all';
  const categoryData = percentile[category];
  if (!categoryData) {
    throw new Error('Invalid category');
  }

  const originData = categoryData[resolvedView];
  if (!originData) {
    throw new Error('Invalid origin');
  }

  let icon = percentileToIcon['10th'];
  Object.entries(originData).forEach(([percentileKey, percentileValue]) => {
    if (value >= percentileValue) {
      icon = percentileToIcon[percentileKey];
    }
  });

  return icon || '';
};
