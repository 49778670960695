<template>
  <div class="relative">
    <h1 class="mb-2 text-lg font-bold">
      Settings
    </h1>

    <div class="inline-block mb-2 bg-gray-200 rounded-md shadow-md">
      <div class="mx-2 my-2">
        <div
          v-for="navRoute in navRoutes"
          :key="navRoute.title"
          class="inline-flex"
        >
          <RoleControlledAction
            v-slot="{restricted}"
            :user-role="roleName"
            :config-object="roleConfig[navRoute.pageName]"
          >
            <router-link
              v-if="navRoute.hasAccess"
              :to="restricted ? '' : { name: navRoute.pageName }"
              class="mr-4 button nav-link text-primary button--text button--sm"
            >
              {{ navRoute.title }}
            </router-link>
          </RoleControlledAction>
        </div>
        <button
          to=""
          class="font-bold text-white button bg-error-700 button--xs"
          @click="signOut"
        >
          <span class="flex items-center justify-center gap-2">
            Logout
            <TbLogoutIcon class="w-4 h-4" />
          </span>
        </button>
      </div>
    </div>

    <div>
      <div class="h-full min-h-full">
        <router-view v-slot="{ Component }">
          <Suspense>
            <div class="h-full min-h-full">
              <component :is="Component" />
            </div>
          </Suspense>
        </router-view>
      </div>
    </div>
  </div>
  <QuickStartGuideLink />
</template>

<script setup lang="ts">
import {
  Ref, ref, provide, inject,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { TbLogoutIcon } from '@/components/tasty_bistro';
import { Pages } from '@/router';
import { auth } from '@/lib/auth';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import getProfile, { Profile } from './api/get_profile';
import { roleConfig } from './role_configurations';

const router = useRouter();
const route = useRoute();

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = route.params.projectId as string;
const { userManagementEnabled } = appState.value.projects[projectId].features;
const roleName = appState.value.projects[projectId].currentUserRoleName;

interface NavRoutes {
  title: string;
  pageName: any,
  hasAccess: boolean;
}

const navRoutes: NavRoutes[] = [
  { title: 'Profile', pageName: Pages.dashboardProfile, hasAccess: true },
  { title: 'Payment Details', pageName: Pages.paymentDetails, hasAccess: true },
  { title: 'Team', pageName: Pages.userManagement, hasAccess: Boolean(userManagementEnabled) },
  { title: 'Integrations', pageName: Pages.dashboardIntegrations, hasAccess: true },
  { title: 'Logo', pageName: Pages.dashboardSettingsLogo, hasAccess: true },
  { title: 'Notifications', pageName: Pages.dashboardNotificationsSubscribers, hasAccess: true },
];

const signOut = () => {
  auth.signOut();
  router.push({ name: Pages.signIn });
};

const profile: Ref<Profile | null> = ref(null);
profile.value = await getProfile();
provide('profile', profile);
</script>

<style lang="scss" scoped>
  .nav-link{
    @apply ring-0 text-primary ring-offset-0 focus:ring-0 focus:underline focus:underline-offset-2;
    &.router-link-active {
      @apply font-bold;
    }
  }
</style>
