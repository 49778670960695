<template>
  <RoleControlledAction
    v-slot="{restricted, wrapper}"
    :user-role="roleName"
    :config-object="roleConfig.cancelOrders"
  >
    <button
      :class="{ 'custom-disabled': !isCancellable || restricted}"
      class="button button--block button--sm text-left"
      @click="wrapper(()=> initCancelOrder())"
    >
      Cancel Order
    </button>
  </RoleControlledAction>

  <TbDialog
    :show="cancelOrderDialog"
    @close="closeCancelOrder"
  >
    <template #header>
      <h2 class="text-xl font-bold">
        Cancel {{ order?.product }}
      </h2>
    </template>

    <p>
      You're about to cancel an order for <strong class="font-bold">{{ order.studentName?.toUpperCase() }}</strong>
    </p>

    <br>

    <p>
      Once confirmed, we will cancel all future payments and no refunds will take place.
    </p>

    <p v-if="order.origin === 'lnpl' && cancellationAmount">
      <br>

      Your next eligible payout will be adjusted by
      <strong class="font-semibold">
        {{ `-${currencyInCents(cancellationAmount)}` }}
      </strong>.
    </p>

    <InsufficientFundsWarning
      v-if="isFundedCancellation"
      class="mt-4"
      type="cancellation"
      :merchant-payment-method="merchantAccountLastFourDigits"
      :amount-in-cents="cancellationAmount"
    />

    <div class="mt-6">
      <label
        for="cancellation-reason"
        class="label"
      >
        Reason (required)
      </label>

      <textarea
        id="cancellation-reason"
        v-model="cancellationReason"
        class="block w-full input mt-2 resize-none"
      />
    </div>

    <p
      v-if="order.origin === 'lnpl'"
      class="mt-5 text-gray-400 font-light"
    >
      To learn more about how we calculate cancellation adjustments, check out our help centre article
      <a
        target="_blank"
        href="https://help.elective.com/hc/en-us/articles/10400657851547"
      >
        here</a>.
    </p>

    <p
      v-if="notFoundError"
      class="mt-2 text-error text-center"
    >
      To complete this action, please provide your payment information. Visit your <router-link
        :to="{ name: Pages.paymentDetails }"
      >
        settings
      </router-link> to submit your payment details and try again.
    </p>
    <p
      v-else-if="cancelError"
      class="mt-2 text-error text-center"
    >
      {{ cancelError }}
    </p>

    <template #footer>
      <div>
        <div class="flex gap-4 actions">
          <button
            class="button button--sm button--text"
            @click="closeCancelOrder"
          >
            No, I’ve changed my mind
          </button>

          <button
            class="button button--sm button--primary"
            :disabled="isLoading.valueOf() || !cancellationReason.trim()"
            @click="submitCancellation"
          >
            <TbIcon
              v-if="isLoading"
              icon="spinner"
              class="text-xl text-white animate-spin"
            />
            <div v-else>
              Confirm Cancellation
            </div>
          </button>
        </div>
      </div>
    </template>
  </TbDialog>
</template>

<script lang="ts" setup>
import { ref, inject, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbDialog, TbIcon } from '@/components/tasty_bistro';
import { currencyInCents } from '@/filters/currency';
import { Pages } from '@/router';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import { cancelOrder } from '../api/cancel';
import { getCancellationData } from '../api/get_cancellation_data';
import { Order } from '../api/get_orders';
import { roleConfig } from '../role_configurations';
import InsufficientFundsWarning from './components/insufficient_funds_warning.vue';

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;

const route = useRoute();
const projectId = route.params.projectId as string;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const props = defineProps<{ order: Order, isCancellable: boolean }>();

const emit = defineEmits([
  'cancelOrder',
]);

const cancelError = ref<String>('');
const notFoundError = ref<Boolean>(false);
const cancelOrderDialog = ref<Boolean>(false);
const isLoading = ref<Boolean>(false);
const cancellationAmount = ref<number>(0);
const cancellationReason = ref<string>('');
const isFundedCancellation = ref<boolean>(false);
const merchantAccountLastFourDigits = ref<string>('');

const initCancelOrder = async () => {
  if (!props.isCancellable) {
    return;
  }

  try {
    if (props.order.origin === 'lnpl') {
      const data = await getCancellationData({ orderId: props.order.id, projectId });
      cancellationAmount.value = data.cancellationAmountInCents;
      isFundedCancellation.value = data.isFundedCancellation;
      merchantAccountLastFourDigits.value = data.merchantAccountLastFourDigits;
    }
  } catch (error) {
    const err = error as any;
    if (err.response?.data?.type === 'NotFound') {
      notFoundError.value = true;
    } else {
      cancelError.value = 'Error while getting cancellation data. Please contact support.';
    }
  } finally {
    cancelOrderDialog.value = true;
  }
};

const closeCancelOrder = () => {
  cancelOrderDialog.value = false;
  notFoundError.value = false;
  cancelError.value = '';
};

const submitCancellation = async () => {
  try {
    isLoading.value = true;
    notFoundError.value = false;
    cancelError.value = '';

    await cancelOrder({
      orderId: props.order.id, reason: cancellationReason.value, isFundedCancellation: isFundedCancellation.value, projectId,
    });

    cancelOrderDialog.value = false;
    emit('cancelOrder');
  } catch (error) {
    const err = error as any;
    if (err.type) {
      cancelError.value = err.message;
    } else {
      cancelError.value = 'Error processing request. Please contact support.';
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped lang="scss">
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
