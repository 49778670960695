<template>
  <div class="antialiased">
    <Teleport :to="to || 'body'">
      <Overlay
        :show="drawerVisible"
        z-index="z-30"
        @clicked="drawerVisible = false"
      />

      <Transition
        enter-active-class="transition-transform"
        leave-active-class="transition-transform"
        enter-from-class="translate-x-[80vw] md:translate-x-[600px]"
        leave-to-class="translate-x-[80vw] md:translate-x-[600px]"
      >
        <div
          v-if="drawerVisible"
          class="antialiased fixed top-0 z-40 right-0 duration-200 shadow-lg p-4 md:p-8 h-full
          transition-transform w-[80vw] md:w-[600px] bg-white overflow-y-auto"
        >
          <slot :toggleDrawer="toggleDrawer" />
        </div>
      </Transition>
    </Teleport>

    <slot
      name="toggle"
      :toggleDrawer="toggleDrawer"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Overlay from './overlay.vue';

interface Props {
    to?: string;
}

defineProps<Props>();

const drawerVisible = ref(false);
const toggleDrawer = () => {
  drawerVisible.value = !drawerVisible.value;
};

</script>
