import http, { Http } from '@/lib/http';
import { Filter } from './types';

interface Data {
  filter: Filter;
  projectId: string;
}

function getPaymentsDownloadCtor(http: Http) {
  return async ({ filter, projectId }: Data): Promise<any> => {
    const { data } = await http.get(`/projects/${projectId}/ppm/payments/download`, {
      params: { ...filter },
      responseType: 'arraybuffer',
    });

    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };
}

export const getPaymentsDownload = getPaymentsDownloadCtor(http);
