import http, { Http } from '@/lib/http';

interface GetRefundDataResponse {
  isFundedRefund: boolean;
  refundAmountInCents: number;
  merchantAccountLastFourDigits: string;
}

interface Params {
  projectId: string;
  orderId: string;
}

function getRefundDataCtor(http: Http) {
  return async ({ orderId, projectId }: Params): Promise<GetRefundDataResponse> => {
    const { data } = await http.get(`/projects/${projectId}/orders/${orderId}/refund-data`);

    return data;
  };
}

export const getRefundData = getRefundDataCtor(http);
