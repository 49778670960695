import http, { Http } from '@/lib/http';
import { StepName, OnboardingStep } from '../types';

export type OnboardingProgress = Record<StepName, OnboardingStep>;

export interface OnboardingProgressResponse {
  progress: OnboardingProgress;
  isOnboardingComplete: boolean;
  creatorFirstName: string;
}

function getOnboardingProgressCtor(http: Http) {
  return async (projectId: string): Promise<OnboardingProgressResponse> => {
    const { data } = await http.get<OnboardingProgressResponse>(`/onboarding/v1/${projectId}/progress`);
    return data;
  };
}

export const getOnboardingProgress = getOnboardingProgressCtor(http);
