<template>
  <FormWrapper>
    <router-view v-slot="{ Component }">
      <component
        :is="Component"
        :data="businessDetails"
        @business-name-submit="nameCollected"
        @business-address-submit="addressCollected"
        @product-types-submit="productTypesCollected"
      />
    </router-view>
    <div class="text-center text-error">
      {{ error }}
    </div>
  </FormWrapper>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import FormWrapper from '@/components/form_wrapper.vue';
import { Pages } from '@/router';
import { addBusinessDetails, getBusinessDetails } from './api';

interface Form {
  address: {
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
    complement: string,
  },
  details: {
    legalName: string,
    publicName: string,
    businessNumber: string,
    websiteUrl: string,
  },
  productTypes: string[],
}

const route = useRoute();
const router = useRouter();
const projectId = route.params.projectId as string;

const form = reactive<Form>({
  address: {
    street: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    complement: '',
  },
  details: {
    legalName: '',
    publicName: '',
    businessNumber: '',
    websiteUrl: '',
  },
  productTypes: [],
});

const businessDetails = ref();

const error = ref('');

function productTypesCollected(data: string[]) {
  form.productTypes = data;
  router.push({ name: Pages.businessDetailsName });
}

function nameCollected(data: any) {
  form.details = { ...data };
  router.push({ name: Pages.businessDetailsAddress });
}

async function addressCollected(data: any) {
  form.address = { ...data };

  try {
    await addBusinessDetails({
      ...form.details,
      address: { ...form.address },
      productTypes: form.productTypes,
    }, projectId);

    router.push({ name: Pages.onboardingProgress });
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
}

onMounted(async () => {
  try {
    const data = await getBusinessDetails(route.params.projectId as string);
    if (data?.state === 'completed') {
      businessDetails.value = data?.form;
      router.replace({ name: Pages.businessDetailsReview });
    } else {
      router.replace({ name: Pages.businessDetailsProduct }); // product selection is the first page in the business details form
    }
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
});
</script>
