import http, { Http } from '@/lib/http';

interface QuizValidation {
  pass: boolean;
  answersResult: Record<string, boolean>;
}

export interface SubmitQuizParams {
    agreementId: string;
    projectId: string;
    answers: Record<string, boolean>
}

function submitQuizCtor(http: Http) {
  return async ({ agreementId, projectId, answers }: SubmitQuizParams) => {
    const { data } = await http.post<QuizValidation>(`/projects/${projectId}/agreements/${agreementId}/quiz`, { answers });
    return data;
  };
}

export const submitQuiz = submitQuizCtor(http);
