<template>
  <p
    v-if="!paymentPlans?.plans?.length"
    class="mb-4 text-lg font-bold"
  >
    This application was not qualified for any offers.
  </p>
  <div v-else>
    <h1 class="mb-4 text-xl font-bold">
      Plans Offered
    </h1>

    <div
      v-for="plan in paymentPlans?.plans"
      :key="plan.installments"
      class="flex flex-col mb-4"
    >
      <span class="mb-1 font-semibold">
        {{ plan.installments }} Monthly Payments
      </span>
      <span class="mb-1">
        {{ plan.currencyCode }}{{ currencyInCents(plan.firstPaymentInCents) }}
        ({{ plan.firstPaymentPercentage }}%) down with the rest split over {{ plan.installments - 1 }} months
      </span>
    </div>
  </div>
  <button
    class="mt-4 button button--secondary button--block"
    @click="cancel"
  >
    Close
  </button>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { currencyInCents } from '@/filters/currency';
import { getPaymentPlans, Plans } from '../api/get_payment_plans';

const route = useRoute();
const projectId = route.params.projectId as string;
const emit = defineEmits([
  'cancel',
]);

const props = defineProps<{ applicationId: string }>();
const paymentPlans = ref<Plans>();

function cancel() {
  emit('cancel');
}

onMounted(async () => {
  const data = await getPaymentPlans({ id: props.applicationId, projectId });
  data.plans.sort((a, b) => a.installments - b.installments);
  paymentPlans.value = data;
});
</script>
