import http, { Http } from '@/lib/http';

export interface Student {
  id: string;
  firstName: string | null,
  lastName: string | null,
}

function findStudentCtor(http: Http) {
  return async (email: string, projectId: string): Promise<Student | null> => {
    const { data } = await http.get<Student | null>(`/projects/${projectId}/students/student/${email}`);

    return data;
  };
}

export const findStudentByEmail = findStudentCtor(http);
