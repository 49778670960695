<template>
  <div>
    <TbDrawer
      v-if="props.application.details?.type === ApplicationDetailsType.QualifiedWithNoOrder"
    >
      <template #default="{ toggleDrawer }">
        <UpdatePlan
          v-if="canUpdatePlans"
          :applicationId="props.application.id"
          @cancel="toggleDrawer()"
        />
        <ViewPlan
          v-else
          :applicationId="props.application.id"
          @cancel="toggleDrawer()"
        />
      </template>

      <template #toggle="{ toggleDrawer }">
        <RoleControlledAction
          v-slot="{ wrapper, restricted }"
          :user-role="roleName"
          :config-object="roleConfig.seePlans"
        >
          <button
            :class="{ 'custom-disabled': restricted}"
            class="text-secondary font-semibold underline"
            @click="wrapper(() => toggleDrawer())"
          >
            {{ canUpdatePlans ? 'Update' : 'See' }} Plans
          </button>
        </RoleControlledAction>
      </template>
    </TbDrawer>
    <div v-else>
      <span>{{ props.application.details?.description }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  inject,
  Ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { TbDrawer } from '@/components/tasty_bistro';
import { ApplicationDetailsType } from '@/pages/app/project/types';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import ViewPlan from '@/pages/app/project/dashboard/applications/forms/view_plan.vue';
import UpdatePlan from '@/pages/app/project/dashboard/applications/forms/update_plan.vue';
import { roleConfig } from '@/pages/app/project/dashboard/applications/role_configurations';

interface Application {
  id: string;
  origin: string;
  details?: {
    type: ApplicationDetailsType;
    description?: string;
  };
}

interface Props {
  application: Application;
}

const props = defineProps<Props>();

const route = useRoute();
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = route.params.projectId as string;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const canUpdatePlans = computed(() => {
  const ppmOrder = ['ppm', 'in-house'].includes(props.application.origin.toLowerCase());
  const isRoleBlocked = roleConfig.updatePlans[roleName].hidden || roleConfig.updatePlans[roleName].restricted;
  return ppmOrder && !isRoleBlocked;
});
</script>

<style scoped lang="scss">
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
