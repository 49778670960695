<template>
  <div
    class="inline-flex p-2 rounded-full relative"
    @mouseover="activated = true"
    @mouseleave="activated = false"
  >
    <div
      class="h-4 w-4 bg-gray-300 rounded-full inline-flex justify-center items-center"
    >
      <div
        class="inline-block h-3 w-3 rounded-full text-white font-medium text-center text-xxs z-100"
      >
        <Icon icon="info" />
      </div>
    </div>

    <div
      v-if="activated"
      class="w-[300px] absolute top-0 z-10 rounded-md shadow-md overflow-hidden"
    >
      <div
        v-if="props.info"
        class="py-1 px-2 bg-white text-black text-sm rounded-md"
      >
        <h5
          v-if="props.title"
          class="font-bold text-sm"
        >
          {{ props.title }}
        </h5>

        <p class="text-sm">
          {{ props.info }}
        </p>
      </div>

      <template v-else>
        <slot />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Icon from '../icon/icon.vue';

interface TooltipProps {
  title?: string;
  info?: string;
}

const props = defineProps<TooltipProps>();

const activated = ref(false);
</script>

<style lang="scss">
  .text-xxs {
    font-size: 0.50rem;
    line-height: 0.75rem;
  }
</style>
