import http, { Http } from '@/lib/http';

function uploadLogoCtor(http: Http) {
  return async (projectId: string, file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('logo', file);
    await http.post(`/projects/${projectId}/logo`, formData);
  };
}

export default uploadLogoCtor(http);
