import { CurrencyCode, OrderOrigin } from '@/types';
import http, { Http } from '@/lib/http';

export interface Params {
  id: string;
  currency: CurrencyCode;
  origin: OrderOrigin;
  projectId: string;
}

function getSettlementDownloadCtor(http: Http) {
  return async ({
    id, currency, origin, projectId,
  }: Params) => {
    const { data } = await http.get(`/projects/${projectId}/settlements/download/${id}`, {
      responseType: 'arraybuffer',
      params: { currency, origin },
    });

    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  };
}

export const getSettlementDownload = getSettlementDownloadCtor(http);
