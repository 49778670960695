<template>
  <div
    id="accordion-container"
    class="w-full"
  >
    <button
      id="accordion-control"
      class="w-full flex items-center justify-between"
      @click.prevent="togglePanel"
    >
      <slot
        v-if="showTitle"
        name="title"
      />
      <TbIcon
        v-if="showPanel"
        icon="chevron-up"
      />
      <TbIcon
        v-else
        icon="chevron-down"
      />
    </button>
    <div
      v-if="showPanel"
      id="accordion-content"
      :hidden="!showPanel"
      class="p-4"
    >
      <slot name="body" />
    </div>
  </div>
</template>

<script lang='ts' setup>
import { ref } from 'vue';
import { TbIcon } from '@/components/tasty_bistro';

const props = defineProps({
  hideTitleWhenOpen: {
    type: Boolean,
    default: false,
  },
});
const showPanel = ref(false);
const showTitle = ref(true);

const togglePanel = () => {
  showPanel.value = !showPanel.value;
  if (props.hideTitleWhenOpen) {
    showTitle.value = !showTitle.value;
  }
};
</script>
