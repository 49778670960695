import http, { Http } from '@/lib/http';
import { CountryCode } from '../../types';

interface BusinessDetails {
  publicName: string;
  legalName: string;
  businessNumber: string;
  addressStreet: string;
  addressComplement: string;
  addressCity: string;
  addressState: string;
  addressCountry: CountryCode;
  addressPostalCode: string;
  productTypes: string[];
  ownerFirstName: string;
  ownerLastName: string;
}

export function getInitialDataCtor(http: Http) {
  return async (projectId: string) => {
    const response = await http.get<{form: BusinessDetails }>(`/projects/${projectId}/business-details`);

    return response.data.form;
  };
}

export const getInitialData = getInitialDataCtor(http);
