<template>
  <FormWrapper>
    <FormHeader
      heading="Supercharge sales. Attract more customers!"
      subHeading="Elective is a proven solution trusted by leading course creators and coaches to boost sales and conversions."
    />
    <div class="mt-6">
      <div
        v-for="info in planInfoData"
        :key="info.title"
        class="flex flex-row p-4 border-gray-300 border-[1px] rounded mb-4"
      >
        <div class="text-3xl mr-4">
          {{ info.icon }}
        </div>
        <div class="text-normal">
          <span class="font-semibold">{{ info.title }}</span>
          <span class="font-light">{{ info.description }}</span>
        </div>
      </div>
    </div>
    <button
      class="button button--secondary w-full my-4"
      @click="submit"
    >
      Continue
    </button>
  </FormWrapper>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import FormWrapper from '@/components/form_wrapper.vue';
import FormHeader from '@/components/form_header.vue';
import { Pages } from '@/router';

const router = useRouter();

const planInfoData = [
  {
    icon: '💪',
    title: 'Supercharge Sales: ',
    description: 'Our partners see significant improvements in conversions, sales, and customer satisfaction',
  },
  {
    icon: '🙌',
    title: 'Money Upfront: ',
    description: 'We pay you upfront in full every Monday so you don\'t have to wait for funds to come in over time',
  },
  {
    icon: '😍',
    title: 'No Payment Risk: ',
    description: 'We take on the risk of non-payments so you can focus on your business',
  },
  {
    icon: '👌',
    title: 'Eliminate Processing Fees: ',
    description: 'We take on all payment processing fees so you can keep more',
  },
];

function submit() {
  router.push({ name: Pages.pricingTerms });
}
</script>
