import jwtDecode from 'jwt-decode';
import { storageLib, StorageLib } from './storage';

export const authCtor = (storage: StorageLib) => {
  const tokenName = 'token';

  const getToken = () => storage.get(tokenName);

  const isTokenExpired = (token: string) => {
    const { exp } = jwtDecode<{ exp: number }>(token);
    return Date.now() >= exp * 1000;
  };

  const isAuthenticated = () => {
    const token = getToken();
    return !!token && !isTokenExpired(token);
  };

  const signIn = (token: string) => {
    storage.set(tokenName, token);
  };

  const signOut = () => {
    storage.clear();
  };

  return {
    signIn,
    signOut,
    isAuthenticated,
    getToken,
  };
};

export const auth = authCtor(storageLib);
