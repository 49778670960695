import http, { Http } from '@/lib/http';

export type DownPaymentType = 'cents' | 'percentage';

export interface Checkout {
  products: { name: string, id: string }[];
  totalCount: number;
  staticIdentifier: string;
  name: string;
  redirectUrl: string | null;
  status: 'active' | 'inactive';
  termsUrl: string | null;
  paymentSettingsId: string | null;
  checkoutTotal: string;
  currencyCode: string;
  downPaymentType: DownPaymentType;
  downPaymentValue: number;
  createdAt: Date;
  lnplEnabled: boolean;
}

export interface CheckoutsResponse {
    data: Checkout[];
    count: number;
}

export interface CheckoutFilter {
    name: string;
    status: string | undefined;
    pageSize: number;
    currentPage: number;
  }

interface Data {
  projectId: string;
  filter: CheckoutFilter;
}

function getCheckoutsCtor(http: Http) {
  return async ({ projectId, filter }: Data): Promise<CheckoutsResponse> => {
    const { data } = await http.get<CheckoutsResponse>(`/projects/${projectId}/checkouts`, {
      params: filter,
    });
    return data;
  };
}

export const getCheckouts = getCheckoutsCtor(http);
