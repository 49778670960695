import http, { Http } from '@/lib/http';

export interface PaymentPlan {
  id?: string;
  active: boolean;
  installments: number;
  downPaymentPercentage: number | null;
}

interface Data {
  id: string;
  projectId: string;
  paymentPlans: PaymentPlan[];
}

function editPaymentPlansCtor(http: Http) {
  return async function editPaymentPlans({ id, projectId, paymentPlans }: Data): Promise<void> {
    await http.put(`/projects/${projectId}/applications/${id}/plans`, paymentPlans);
  };
}

export const editPaymentPlans = editPaymentPlansCtor(http);
