<template>
  <div
    class="w-full h-1"
    :class="props.parentClass"
  >
    <Transition
      appear
      appear-active-class="progressFn"
    >
      <div
        class="w-full h-full origin-left scale-0"
        :class="props.childClass"
        :style="{ animationDuration: `${props.duration}ms` }"
      />
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';

// create a pause effect

interface Props {
  duration: number;
  parentClass?: string;
  childClass?: string;
}
const props = withDefaults(defineProps<Props>(), {
  parentClass: 'bg-grey-400',
  childClass: 'bg-grey-500',
});

const emit = defineEmits(['finish']);

onMounted(() => {
  setTimeout(() => {
    emit('finish');
  }, props.duration);
});
</script>

<style lang="scss" scoped>
.progressFn {
  animation-name: progressFn;
  animation-timing-function: linear;
}
@keyframes progressFn {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}
</style>
