<template>
  <div v-if="pageState === PageState.loading">
    Loading
  </div>

  <div
    v-else-if="pageState === PageState.loaded"
    class="analytics"
  >
    <h2 class="font-regular text-xl">
      Here are the key numbers to understand how your business is doing.
    </h2>

    <div class="search my-4">
      <div class="flex gap-4 flex-wrap">
        <div class="w-44">
          <TbSelect
            v-model="dates"
            label="Range"
            :default-options="{ name: 'All Time', value: JSON.stringify({ startDate: null, endDate: null }) }"
            :options="dateRangeOptions"
          />
        </div>

        <div class="w-44">
          <TbSelect
            v-model="view"
            label="View"
            :default-options="{ name: 'All', value: '' }"
            :options="[{ name: 'Funding', value: 'lnpl' }]"
          />
        </div>

        <div v-if="availableCurrencies && availableCurrencies.length > 1">
          <TbSelect
            v-model="currency"
            :options="selectOptionsCurrency"
            :default-options="{ name: 'USD', value: CurrencyCode.USD }"
            label="Currency"
            class="w-full sm:w-48"
          />
        </div>
      </div>
    </div>

    <div class="flex gap-12 flex-wrap mb-8">
      <HighlightCard
        title="Revenue"
        period="year"
        :value="selectedCurrency + currencyInCents(overview.orders.totalInCents)"
        :percentage-diff="percentageDiff(overview.orders.totalInCents, overview.ordersYoy.totalInCents)"
      >
        <template #icon>
          <TbCurrencyIcon class="w-4 h-4" />
        </template>

        <template #tooltip>
          <div class="tooltip-container">
            <h3>Revenue</h3>
            <p>
              The total dollar amount of new sales through Elective
            </p>
          </div>
        </template>
      </HighlightCard>

      <HighlightCard
        title="Orders"
        period="year"
        :value="String(overview.orders.totalOrders)"
        :percentage-diff="percentageDiff(overview.orders.totalOrders, overview.ordersYoy.totalOrders)"
      >
        <template #icon>
          <TbDocumentCheckIcon class="w-4 h-4" />
        </template>
        <template #tooltip>
          <div class="tooltip-container">
            <h3>Number of Orders</h3>
            <p>
              The total number of new orders processed through Elective
            </p>
          </div>
        </template>
      </HighlightCard>

      <HighlightCard
        title="Avg. Order Value"
        period="year"
        :value="selectedCurrency + currencyInCents(overview.orders.avgAmountInCents)"
        :percentage-diff="percentageDiff(overview.orders.avgAmountInCents, overview.ordersYoy.avgAmountInCents)"
      >
        <template #icon>
          <TbBankNotesIcon class="w-4 h-4" />
        </template>
        <template #tooltip>
          <div class="tooltip-container">
            <h3>Avg. Order Value</h3>
            <p>
              This is the average purchase price for your customers when they use Elective
            </p>
          </div>
        </template>
      </HighlightCard>

      <!-- <HighlightCard
        title="Approval Rate"
        period="year"
        :value="percentage(overview.applicationApprovals.approvedApplications / overview.applicationApprovals.totalApplications)"
        :percentage-diff="percentageDiff(
          overview.applicationApprovals.approvedApplications / overview.applicationApprovals.totalApplications,
          overview.applicationApprovalsYoy.approvedApplications / overview.applicationApprovalsYoy.totalApplications,
        )"
      >
        <template #icon>
          <TbThumbUpIcon class="w-4 h-4" />
        </template>
      </HighlightCard>

      <HighlightCard
        title="Take-Up Rate"
        period="year"
        :value="percentage(overview.orders.totalOrders / overview.applicationApprovals.approvedApplications)"
        :percentage-diff="overview.orders.totalOrders / overview.applicationApprovals.approvedApplications"
      >
        <template #icon>
          <TbChartIcon class="w-4 h-4" />
        </template>
      </HighlightCard> -->
    </div>

    <div class="sales-chart mb-8">
      <div class="flex items-center gap-2 mb-4">
        <TbClockIcon class="w-6 h-6" />

        <div class="flex items-center flex-row justify-center">
          <h2 class="text-xl font-bold">
            Revenue Over Time
          </h2>

          <TbTooltip>
            <div class="tooltip-container">
              <h3>Revenue Over Time</h3>
              <p>
                The total dollar value of new sales through Elective over time
              </p>
            </div>
          </TbTooltip>
        </div>
      </div>

      <div class="h-96">
        <LineChart
          :labels="revenueOverTimeChartData.labels"
          :dataset="revenueOverTimeChartData.dataset"
          :label-formatter="revenueOverTimeChartData.labelFormatter"
        />
      </div>
    </div>

    <div class="funnel-chart mb-8">
      <div class="flex items-center mb-4 gap-2">
        <TbCartIcon class="w-6 h-6" />

        <div class="flex items-center flex-row justify-center">
          <h2 class="text-xl font-bold">
            Checkout Performance
          </h2>

          <TbTooltip>
            <div class="tooltip-container">
              <h3>Checkout Performance</h3>
              <p>
                The number of events at each stage of your Elective checkout funnel.
                This funnel is the customer journey of applying for, getting approved for, and deciding to pay with Elective
              </p>
            </div>
          </TbTooltip>
        </div>
      </div>

      <div>
        <FunnelChart
          :view="view"
          :dataset="checkoutFunnel.dataset"
          :dataset-yoy="checkoutFunnel.datasetYoy"
          :labels="checkoutFunnel.labels"
          :isProjectOlderThanApplicationsStartDate="checkoutFunnel.isProjectOlderThanApplicationsStartDate"
        />
      </div>
    </div>

    <div class="mb-8 grid grid-cols-1 grid-rows-4 lg:grid-cols-2 md:grid-rows-2 gap-6">
      <div class="card bg-white p-4 w-full flex flex-col gap-2">
        <div class="flex items-center gap-2 mb-4">
          <TbChartPieIcon class="w-4 h-4" />

          <h2 class="text-xl font-bold">
            {{ TITLE_REVENUE_BY_TERM }}
          </h2>

          <TbTooltip>
            <div class="tooltip-container text-sm">
              <h3>{{ TITLE_REVENUE_BY_TERM }}</h3>
              <p>
                The dollar value of new sales through Elective, broken down by term
              </p>
            </div>
          </TbTooltip>
        </div>

        <div class="h-full">
          <DoughnutChart
            id="avg-value-by-term"
            first-column-table-title="Term"
            :title="TITLE_REVENUE_BY_TERM"
            :labels="revenueByTerm.labels"
            :dataset="revenueByTerm.dataset"
            :label-formatter="revenueByTerm.labelFormatter"
            :sort-by-label="true"
          />
        </div>
      </div>

      <div class="card bg-white p-4 w-full flex flex-col gap-2">
        <div class="flex items-center gap-2 mb-4">
          <TbCalendarIcon class="w-4 h-4" />

          <h2 class="text-xl font-bold">
            {{ TITLE_REVENUE_BY_PRODUCT }}
          </h2>

          <TbTooltip>
            <div class="tooltip-container">
              <h3>{{ TITLE_REVENUE_BY_PRODUCT }}</h3>
              <p>
                The total dollar value of new sales through Elective, broken down by product
              </p>
            </div>
          </TbTooltip>
        </div>

        <div class="h-full">
          <DoughnutChart
            id="revenue-by-term"
            :title="TITLE_REVENUE_BY_PRODUCT"
            :labels="revenueByProduct.labels"
            :dataset="revenueByProduct.dataset"
            :label-formatter="revenueByProduct.labelFormatter"
          />
        </div>
      </div>

      <div class="card bg-white p-4 w-full flex flex-col gap-2">
        <div class="mb-4 relative">
          <div class="flex items-center gap-2">
            <TbIdentificationIcon class="w-4 h-4" />

            <h2 class="text-xl font-bold">
              {{ TITLE_CREDIT_MIX }}
            </h2>

            <TbTooltip>
              <div class="tooltip-container">
                <h3>{{ TITLE_CREDIT_MIX }}</h3>
                <p>
                  This is the breakdown of all customers that applied for Elective in each credit score category
                </p>
              </div>
            </TbTooltip>
          </div>

          <div
            v-if="overview.applicationsCreditMix.medianScore"
            class="absolute"
          >
            <h3
              class="text-xl font-regular"
            >
              {{ overview?.applicationsCreditMix.medianScoreGrade }}
            </h3>
            <label>Median {{ overview.applicationsCreditMix.medianScore }}</label>
          </div>
        </div>

        <div class="h-full">
          <DoughnutChart
            id="revenue-by-term1"
            :title="TITLE_CREDIT_MIX"
            :labels="customerCreditMix.labels"
            :dataset="customerCreditMix.dataset"
            :label-formatter="customerCreditMix.labelFormatter"
          />
        </div>
      </div>

      <div class="card bg-white p-4 w-full flex flex-col gap-2">
        <div class="flex items-center gap-2 mb-4">
          <TbCreditCardIcon class="w-4 h-4" />

          <h2 class="text-xl font-bold">
            {{ TITLE_REVENUE_BY_ORDER_STATUS }}
          </h2>

          <TbTooltip>
            <div class="tooltip-container">
              <h3>{{ TITLE_REVENUE_BY_ORDER_STATUS }}</h3>
              <p>
                This is the total dollar value of sales attributed to each relevant order status in Elective
              </p>
            </div>
          </TbTooltip>
        </div>

        <div class="h-full">
          <DoughnutChart
            id="revenue-by-order-status"
            title="Order Statuses"
            :labels="revenueByOrderStatus.labels"
            :dataset="revenueByOrderStatus.dataset"
            :label-formatter="revenueByOrderStatus.labelFormatter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LineChart, { Props as LineChartProps } from '@/components/chart_line.vue';
import DoughnutChart, { Props as DoughtnutChartProps } from '@/components/doughnut/index.vue';
import FunnelChart, { Props as FunnelChartProps } from '@/components/checkout_funnel.vue';
import {
  TbSelect, TbTooltip, TbDocumentCheckIcon, TbCurrencyIcon, TbBankNotesIcon,
  TbCartIcon, TbClockIcon, TbCalendarIcon, TbCreditCardIcon, TbIdentificationIcon, TbChartPieIcon,
} from '@/components/tasty_bistro';
import {
  onMounted, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { CurrencyCode, PageState } from '@/types';
import { currencyInCents } from '@/filters/currency';
import { percentageDiff } from '@/filters/percentage';
import {
  subDays, startOfYear, startOfMonth, subMonths, endOfMonth,
} from 'date-fns';
import HighlightCard from '@/components/highlight_card.vue';
import {
  getOverview, OverviewAnalytics, View,
} from '../api/get_overview';

const route = useRoute();
const pageState = ref(PageState.loading);

const view = ref<View>('');
const currency = ref('USD');
const selectedCurrency = ref();
const availableCurrencies = ref([] as CurrencyCode[]);
const dates = ref<string>(JSON.stringify({ startDate: null, endDate: null }));
const overview = ref<OverviewAnalytics>({
  orders: {
    totalInCents: 0,
    avgAmountInCents: 0,
    totalOrders: 0,
  },
  applicationApprovals: {
    approvedApplications: 0,
    totalApplications: 0,
    totalOrders: 0,
  },
  applicationApprovalsYoy: {
    approvedApplications: 0,
    totalApplications: 0,
    totalOrders: 0,
  },
  ordersYoy: {
    totalInCents: 0,
    avgAmountInCents: 0,
    totalOrders: 0,
  },
  revenueOverTime: [],
  revenueByTerm: [],
  avgOrderValueByTerm: [],
  revenueByOrderStatus: [],
  revenueByProduct: [],
  applicationsCreditMix: {
    medianScore: null,
    medianScoreGrade: null,
    breakdown: [],
  },
  isProjectOlderThanApplicationsStartDate: false,
  currencies: [],
  selectedCurrency: '',
});

const selectOptionsCurrency = [
  { name: 'CAD', value: CurrencyCode.CAD },
];

const dateRangeOptions = [
  {
    name: 'Last 7 Days',
    value: JSON.stringify({
      startDate: subDays(new Date(), 8),
      endDate: subDays(new Date(), 1),
    }),
  },
  {
    name: 'This Month',
    value: JSON.stringify({
      startDate: startOfMonth(new Date()),
      endDate: subDays(new Date(), 1),
    }),
  },
  {
    name: 'Last Month',
    value: JSON.stringify({
      startDate: startOfMonth(subMonths(new Date(), 1)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
    }),
  },
  {
    name: 'Last 3 Months',
    value: JSON.stringify({
      startDate: startOfMonth(subMonths(new Date(), 3)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
    }),
  },
  {
    name: 'Last 12 Months',
    value: JSON.stringify({
      startDate: startOfMonth(subMonths(new Date(), 12)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
    }),
  },
  {
    name: 'Year to Date',
    value: JSON.stringify({
      startDate: startOfYear(new Date()),
      endDate: subDays(new Date(), 1),
    }),
  },
];

const revenueOverTimeChartData = ref<LineChartProps>({
  labelFormatter: (val) => `${selectedCurrency.value} ${currencyInCents(Number(val))}`,
  labels: [],
  dataset: [
    {
      label: 'Orders over time',
      data: [],
      title: {
        display: true,
        text: 'Orders over time',
      },
    },
  ],
});

const TITLE_REVENUE_BY_TERM = 'Revenue by Term';
const TITLE_REVENUE_BY_PRODUCT = 'Revenue by Product';
const TITLE_CREDIT_MIX = 'Applications by Credit Mix';
const TITLE_REVENUE_BY_ORDER_STATUS = 'Revenue by Order Status';

const revenueByTerm = ref<DoughtnutChartProps>({
  title: TITLE_REVENUE_BY_TERM,
  id: 'revenue-by-term',
  labelFormatter: (val: number | string) => currencyInCents(Number(val)),
  labels: [],
  dataset: [
    {
      label: TITLE_REVENUE_BY_TERM,
      data: [],
    },
  ],
});

const revenueByProduct = ref<DoughtnutChartProps>({
  title: TITLE_REVENUE_BY_PRODUCT,
  id: 'revenue-by-product',
  labels: [],
  labelFormatter: (val: number | string) => currencyInCents(Number(val)),
  dataset: [
    {
      label: 'Orders by term',
      data: [],
    },
  ],
});

const revenueByOrderStatus = ref<DoughtnutChartProps>({
  title: TITLE_REVENUE_BY_ORDER_STATUS,
  id: 'revenue-by-order-status',
  labels: [],
  labelFormatter: (val: number | string) => currencyInCents(Number(val)),
  dataset: [
    {
      label: 'Revenue by product',
      data: [],
    },
  ],
});

const customerCreditMix = ref<DoughtnutChartProps>({
  title: 'Customer Credit Mix',
  id: 'customer-credit-mix',
  labels: [],
  dataset: [
    {
      label: TITLE_CREDIT_MIX,
      data: [],
    },
  ],
});

const checkoutFunnel = ref<FunnelChartProps>({
  dataset: [],
  datasetYoy: [],
  labels: ['Applications', 'Approved', 'Purchased'],
  view: view.value,
  isProjectOlderThanApplicationsStartDate: false,
});

async function loadOverview() {
  const projectId = route.params.projectId as string;
  try {
    pageState.value = PageState.loading;
    overview.value = await getOverview({
      projectId,
      dates: JSON.parse(dates.value),
      view: view.value,
      currency: (availableCurrencies.value && availableCurrencies.value.length > 1)
        ? currency.value
        : selectedCurrency.value,
    });
    pageState.value = PageState.loaded;
    availableCurrencies.value = overview.value.currencies;
    selectedCurrency.value = overview.value.selectedCurrency;

    revenueOverTimeChartData.value.labels = overview.value.revenueOverTime.map((o) => o.date);
    revenueOverTimeChartData.value.dataset[0].data = overview.value.revenueOverTime.map((o) => Number(o.totalInCents));

    revenueByOrderStatus.value.labels = overview.value.revenueByOrderStatus.map((o) => o.status);
    revenueByOrderStatus.value.dataset[0].data = overview.value.revenueByOrderStatus.map((o) => Number(o.totalInCents));

    revenueByTerm.value.labels = overview.value.revenueByTerm.map((o) => `${o.installments} Months`);
    revenueByTerm.value.dataset[0].data = overview.value.revenueByTerm.map((o) => Number(o.totalInCents));

    revenueByProduct.value.labels = overview.value.revenueByProduct.map((o) => o.name);
    revenueByProduct.value.dataset[0].data = overview.value.revenueByProduct.map((o) => Number(o.totalInCents));

    customerCreditMix.value.labels = overview.value.applicationsCreditMix?.breakdown.map((o) => o.title);
    customerCreditMix.value.dataset[0].data = overview.value.applicationsCreditMix?.breakdown.map((o) => Number(o.total));

    checkoutFunnel.value.dataset = [
      overview.value.applicationApprovals.totalApplications,
      overview.value.applicationApprovals.approvedApplications,
      overview.value.applicationApprovals.totalOrders,
    ];

    checkoutFunnel.value.datasetYoy = [
      overview.value.applicationApprovalsYoy.totalApplications,
      overview.value.applicationApprovalsYoy.approvedApplications,
      overview.value.applicationApprovalsYoy.totalOrders,
    ];

    checkoutFunnel.value.isProjectOlderThanApplicationsStartDate = overview.value.isProjectOlderThanApplicationsStartDate;
  } catch (error) {
    console.log(error);
  }
}

onMounted(async () => {
  await loadOverview();
});

watch(view, async () => {
  await loadOverview();
});

watch(currency, async () => {
  await loadOverview();
});

watch(dates, async (selectedDates: any) => {
  dates.value = selectedDates;
  await loadOverview();
});

</script>

<style lang="scss">
  // .highlight-grid {
  //   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // }

  .overview-page {
    .v3dp__input_wrapper input {
      background-color: white !important;
    }
  }

  .tooltip-container {
    @apply p-3 shadow-md rounded-md text-sm bg-white
  }

  .tooltip-container {
    h3 {
      @apply font-bold text-sm
    }

    p {
      @apply mt-2
    }
  }
</style>
