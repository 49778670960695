<template>
  <div>
    <label
      v-if="label"
      class="flex items-center text-primary font-medium text-sm ml-2 mt-1 mb-1"
    >
      {{ label }}
    </label>

    <div
      ref="wrapper"
      class="wrapper flex flex-row items-center justify-start"
      :class="[{ 'focused': wrapperFocused, 'input-error' : errors.length }, [props.class]]"
    >
      <div
        v-if="shouldShowBubble"
        :class="['w-[10px] h-[10px]  shrink-0 rounded-full bg-black',
                 [bubbleColor]
        ]
        "
      />
      <select
        class="select pl-2"
        :value="modelValue"
        v-bind="$attrs"
        @change="$emit('update:modelValue', handleSelectChange($event))"
        @focus="handleFocus"
        @blur="handleBlur"
      >
        <option
          v-if="!modelValue"
          value=""
        >
          Select one
        </option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
    </div>

    <div
      v-if="errors && errors.length !== 0"
      class="flex flex-col-reverse justify-between pb-2 mt-1 ml-2 mr-2 sm:flex-row"
    >
      <ul class="list-none left-6">
        <li
          v-for="(error, index) in errors"
          :key="index"
          class="text-xs text-error"
        >
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref } from 'vue';

interface Option {
    name: string;
    value: string;
    color?: string;
}

const wrapperFocused = ref(false);
const wrapper = ref(null);

const handleFocus = () => {
  wrapperFocused.value = true;
};

const handleBlur = () => {
  wrapperFocused.value = false;
};

// Props and other logic
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: 'grey',
  },
  inputClass: {
    type: String,
    default: '',
  },
  defaultOptions: {
    type: Object as PropType<Option>,
    default: () => ({ name: 'None', value: '', color: 'gray' }),
  },
  placeholder: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<Option[]>,
    required: true,
  },
  class: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  errors: {
    type: [Array, String],
    default: () => [],
  },
});

const shouldShowBubble = computed(() => props.options.every((option) => option.color));

const bubbleColor = computed(() => {
  if (!props.modelValue) return props.defaultOptions.color;
  const selectedValue = props.options.find((option) => option.value === props.modelValue);

  if (!selectedValue) return props.defaultOptions.color;

  return selectedValue.color;
});

defineEmits(['update:modelValue']);
const handleSelectChange = (event: Event) => (event.target as HTMLInputElement).value;
</script>

<style scoped lang="scss">
.select {
    @apply
      bg-transparent
      w-full
      py-2
      text-sm
      disabled:bg-gray-200
      disabled:cursor-not-allowed
      disabled:shadow-inner
      text-primary
      focus:ring-0
      border-0;
}

.wrapper {
    @apply
      bg-gray-100
      w-full
      rounded-md
      text-sm
      pl-4
      pr-1
      disabled:bg-gray-200
      disabled:cursor-not-allowed
      disabled:shadow-inner
      text-primary
}

.wrapper.focused {
    @apply outline-none ring-2 ring-offset-2 ring-primary-100
}
</style>
