import http, { Http } from '@/lib/http';

export interface BusinessDetails {
  publicName: string;
  legalName: string;
  businessNumber: string;
  websiteUrl: string;
  addressStreet: string;
  addressComplement: string;
  addressCity: string;
  addressState: string;
  addressCountry: string;
  addressPostalCode: string;
  productTypes: string[];
}

export enum State {
  pending = 'pending',
  completed = 'completed'
}

interface GetBusinessDetails {
  form: BusinessDetails,
  state: State,
}

export function getBusinessDetailsCtor(http: Http) {
  return async (projectId: string): Promise<GetBusinessDetails> => {
    const response = await http.get<GetBusinessDetails>(`/projects/${projectId}/business-details`);

    return response.data;
  };
}

export const getBusinessDetails = getBusinessDetailsCtor(http);
