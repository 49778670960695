import http, { Http } from '@/lib/http';

function setDefaultProjectCtor(http: Http) {
  return async function setDefaultProject(projectId: string): Promise<void> {
    const { data } = await http.post<void>('/default-project', { projectId });
    return data;
  };
}

export const setDefaultProject = setDefaultProjectCtor(http);
