<template>
  <FormWrapper>
    <FormHeader
      heading="Tell us about your business owner"
      subHeading="Please review your information to ensure it's accurate."
    />
    <div>
      <div class="mt-4">
        <div>
          <p>
            <span class="font-semibold">First Name: </span>
            {{ props.data?.firstName }}
          </p>
          <p>
            <span class="font-semibold">Last Name: </span>
            {{ props.data?.lastName }}
          </p>
          <p>
            <span class="font-semibold">Email: </span>
            {{ props.data?.email }}
          </p>
          <p>
            <span class="font-semibold">Title: </span>
            {{ props.data?.title }}
          </p>
          <p>
            <span class="font-semibold">SSN/SIN Last 4 Digits: </span>
            {{ props.data?.ssnLast4 }}
          </p>
          <p>
            <span class="font-semibold">Date of Birth: </span>
            {{ formatDateOfBirth(props.data?.dateOfBirth) }}
          </p>
          <p>
            <span class="font-semibold">Phone Number: </span>
            {{ formatPhoneNumber(props.data?.phoneCountryCode, props.data?.phoneNumber) }}
          </p>
        </div>
      </div>
      <button
        class="w-full mt-4 mb-2 button button--secondary"
        @click="goToNextPage"
      >
        Continue
      </button>
      <button
        class="w-full text-secondary button button--text"
        @click="goToEdit"
      >
        Edit
      </button>
    </div>
  </FormWrapper>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { format, parseISO } from 'date-fns';
import FormHeader from '@/components/form_header.vue';
import FormWrapper from '@/components/form_wrapper.vue';
import { Pages } from '@/router';
import { BusinessOwnerResponse } from '../api';

const router = useRouter();

const props = defineProps<{ data: BusinessOwnerResponse }>();

function goToNextPage() {
  router.push({ name: Pages.onboardingProgress });
}

function goToEdit() {
  router.push({ name: Pages.businessOwnerForm });
}

function formatDateOfBirth(dob: string) {
  return dob ? format(parseISO(dob), 'yyyy-MMM-dd') : '';
}

function formatPhoneNumber(code: string, phoneNumber: string) {
  return `+${code}-${phoneNumber}`;
}

</script>
