import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import App from './app.vue';
import router from './router';
import './styles/index.scss';

const app = createApp(App);
const trackedOrigins = ['creator.elective.com', /^\//];
if (process.env.NODE_ENV !== 'production') trackedOrigins.push('localhost');

Sentry.init({
  app,
  dsn: 'https://38104f81db4b435a9341031d023f1649@o553831.ingest.sentry.io/4503943772241920',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: trackedOrigins,
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  tracingOptions: {
    trackComponents: true,
  },
  logErrors: process.env.NODE_ENV === 'development',
});

app.use(router);
app.mount('#app');
