<template>
  <TbLayoutAuth>
    <div class="z-10 flex items-center w-full max-w-5xl p-8 m-auto">
      <SplitCard
        quote="The benefits are clear: more sales and happy students!"
        name="Tim Vipond"
        designation="Founder of Corporate Finance Institute"
        imageName="tim_vipond.svg"
      >
        <div class="p-8 mb-14">
          <TbLetterLogo class="h-20 w-20 m-auto mb-3" />

          <div class="mb-3 text-center">
            <h1 class="text-xl sm:text-2xl font-serif-bold">
              Reset your Password
            </h1>
            <p>
              Enter the email associated with your account.
            </p>
            <p>
              We'll send you a link to reset your password.
            </p>
          </div>

          <form @submit.prevent="submit">
            <TbInput
              v-model.trim="form.email"
              label="Email*"
              type="email"
              required
            />

            <FormButton
              text="Send reset password email"
              :disabled="form.state === 'loading'"
            />

            <div
              v-if="form.state === 'error'"
              class="mb-4 text-center text-error"
              data-test="error-div"
            >
              <small>
                {{ form.error }}
              </small>
            </div>
          </form>
        </div>
      </SplitCard>
    </div>

    <TbNotification
      :show="requestPasswordNotification"
      title="Success"
      message="An email with the password reset link was sent to your e-mail"
      @close="requestPasswordNotification = false"
    />
  </TbLayoutAuth>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import {
  TbLayoutAuth, TbInput, TbLetterLogo, TbNotification,
} from '@/components/tasty_bistro';
import SplitCard from '@/components/split_card/index.vue';
import FormButton from '@/components/form_button.vue';
import { requestResetPassword } from './api/request_reset_password';

interface ResetPasswordForm {
  email: string;
  error: string;
  state: 'loaded' | 'loading' | 'error';
}

const form: ResetPasswordForm = reactive({
  email: '',
  error: '',
  state: 'loaded',
});

const requestPasswordNotification = ref<boolean>(false);

async function submit() {
  try {
    form.state = 'loading';
    await requestResetPassword(form.email);
    requestPasswordNotification.value = true;
    form.state = 'loaded';
  } catch (err: any) {
    form.state = 'error';
    form.error = err.response?.data?.message;
  }
}

</script>
