<template>
  <div
    ref="menu"
    class="flex flex-col relative w-full"
  >
    <div class="flex items-center text-primary font-medium text-sm ml-2 mt-1 mb-1">
      {{ label }}
    </div>

    <Popper
      placement="bottom-start"
      mode="click"
      @close="searchQuery = ''"
    >
      <template #trigger="{ show, assignReference }">
        <div
          :ref="(r: any) => assignReference(r)"
          class="bg-gray-100
        w-full
        px-4
        py-2
        text-sm
        rounded-md
        focus:outline-info-100
        disabled:bg-gray-200
        disabled:cursor-not-allowed
        disabled:shadow-inner
        text-primary
        focus:ring-0
        border-0
        flex
        flex-row
        justify-between
        cursor-pointer"
          @mousedown="show"
        >
          <slot name="placeholder" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </template>

      <template #tooltip="{ close, triggerWidth }">
        <div
          class="
        p-3 z-100 bg-white rounded-md shadow-lg ring-1
        ring-black ring-opacity-5 focus:outline-none overflow-hidden w-96"
          :style="{
            width: triggerWidth + 'px',
          }"
        >
          <Search
            v-model="searchQuery"
            :placeholder="searchPlaceholder || 'Search'"
            class="mb-1"
          />
          <div class="overflow-y-auto max-h-[300px]">
            <ul class="h-full">
              <template
                v-for="(option, ind) in filteredOptions"
                :key="ind"
              >
                <slot
                  :item="option"
                  :close="close"
                />
              </template>
            </ul>
          </div>
        </div>
      </template>
    </Popper>
  </div>
  <!-- <div
      class="bg-gray-100
        w-full
        px-4
        py-2
        text-sm
        rounded-md
        focus:outline-info-100
        disabled:bg-gray-200
        disabled:cursor-not-allowed
        disabled:shadow-inner
        text-primary
        focus:ring-0
        border-0
        flex
        flex-row
        justify-between
        cursor-pointer"
      @click.stop="toggleMenu"
    >
      {{ props.placeholder }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="h-5"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    </div>
    <TransitionRoot
      :show="open"
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-if="open"
        ref="menu"
        class="absolute top-full mt-3 w-full
        p-3 z-100 bg-white rounded-md shadow-lg ring-1
        ring-black ring-opacity-5 focus:outline-none overflow-hidden"
      >
        <Search
          v-model="searchQuery"
          placeholder="Search"
          class="mb-1"
        />
        <div class="overflow-y-auto max-h-[300px]">
          <div class="h-full">
            <template
              v-for="option in filteredOptions"
              :key="option[searchBy]"
            >
              <slot :item="option" />
            </template>
          </div>
        </div>
      </div>
    </TransitionRoot>
  </div> -->
</template>

<script lang="ts" setup generic="T extends object">
import {
  ref, computed, watch,
} from 'vue';
import { debounce } from 'lodash';
import Search from './search.vue';
import Popper from './popper.vue';

interface Props {
  label: string;
  options: any[];
  searchBy?: string;
  searchPlaceholder?: string;
}

const searchQuery = ref('');
const props = defineProps<Props>();
const emit = defineEmits(['search-input']);

const menu = ref(null);

watch(searchQuery, debounce(async () => {
  emit('search-input', searchQuery.value);
}, 250));

const filteredOptions = computed(() => {
  if (!props.searchBy) return props.options;
  return props.options.filter((option) => option[props.searchBy!].toLowerCase().includes(searchQuery.value.toLowerCase()));
});
</script>
