import { RoleControlledActionConfiguration } from '@/types';

export const roleConfig: RoleControlledActionConfiguration = {
  createInvoice: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: true, hidden: false },
    view_only: { restricted: true, hidden: false },
  },
  voidInvoice: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: true, hidden: false },
  },
};
