import http, { Http } from '@/lib/http';
import { AxiosResponse } from 'axios';

export interface SendCheckoutLinkParams {
  projectId: string;
  invoiceId: string;
  method: 'sms' | 'email';
  checkoutLink: string;
  contact: string;
}

function sendCheckoutLinkCtor(http: Http) {
  return async ({ projectId, invoiceId, ...params }: SendCheckoutLinkParams): Promise<AxiosResponse> => {
    const response = await http.post(`/projects/${projectId}/invoices/send-link/${invoiceId}`, params);
    return response;
  };
}

export const sendCheckoutLink = sendCheckoutLinkCtor(http);
