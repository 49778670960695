<template>
  <div>
    <div
      v-for="timeline, index in modifiedTimelineEvents"
      :key="timeline.eventAt ? timeline.eventAt : index"
      class="timeline"
    >
      <div
        v-if="!timeline.hideElement"
        class="vertical_line bg-accent-200"
      >
        <div class="point bg-accent-200" />
      </div>
      <div
        v-if="!timeline.hideElement"
        class="content flex flex-col items-start"
      >
        <div>
          <div
            class="text-lg text-primary font-bold leading-none pb-1"
          >
            {{ timeline.eventType }}
          </div>
          <div
            v-if="timeline.info"
            class="text-sm text-primary font-normal pb-2"
          >
            {{ timeline.info }}
          </div>
          <div
            v-if="timeline.details"
            class="text-xs text-gray-500"
          >
            {{ timeline.details }}
          </div>
          <time
            v-if="timeline.eventAt"
            class="text-gray-500 text-xs"
          >
            {{ timeline.eventAt }}
          </time>
        </div>
        <button
          v-if="modifiedTimelineEvents[index + 1]?.hideElement"
          class="mt-2 text-primary-700 font-bold"
          @click="showHiddenElements()"
        >
          +{{ hiddenElementsCount }} more events
        </button>
      </div>
      <div
        v-if="isSecondLastEvent(index)"
        class="vertical_line"
        :class="finalPointColour"
      >
        <div
          class="point"
          :class="finalPointColour"
        />
      </div>
    </div>
    <div class="flex">
      <div
        v-if="props.timelineEvents.length === 1"
        class="inline-block w-3 h-3 mr-1 rounded-full mt-1"
        :class="finalPointColour"
      />
      <div class="last_element content">
        <div class="text-lg text-primary font-bold leading-none pb-1">
          {{ lastEvent.eventType }}
        </div>
        <div
          v-if="lastEvent.info"
          class="text-sm text-primary pb-2"
        >
          {{ lastEvent.info }}
        </div>
        <div
          v-if="lastEvent.details"
          class="text-xs text-gray-500"
        >
          {{ lastEvent.details }}
        </div>
        <time
          v-if="lastEvent.eventAt"
          class="text-xs text-gray-500"
        >
          {{ lastEvent.eventAt }}
        </time>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, computed, ref } from 'vue';

interface TimelineContent {
  eventType: string;
  info?: string;
  details?: string;
  eventAt: string;
}

const props = defineProps<{
  timelineEvents: TimelineContent[],
  finalPointColour: string,
}>();

const eventsExceptLast = props.timelineEvents.slice(0, -1);
const lastEvent = computed(() => props.timelineEvents[props.timelineEvents.length - 1]);

const isSecondLastEvent = (index: number) => {
  if (props.timelineEvents.length < 1) return false;
  return index === props.timelineEvents.length - 2;
};

const hiddenElementsCount = ref(0);

function shouldHideElement(index: number) {
  const eventCount = props.timelineEvents.length;
  if (eventCount <= 5) return false;

  const middleElements = props.timelineEvents.slice(2, -2);
  hiddenElementsCount.value = middleElements.length;

  const allMiddleElementsSame = middleElements.every(
    (element, _, arr) => element.eventType === arr[0].eventType,
  );

  const isMiddleElement = index >= 2 && index < (eventCount - 2);

  return allMiddleElementsSame && isMiddleElement;
}
const modifiedTimelineEvents = ref(eventsExceptLast.map((ev, index) => ({
  ...ev,
  hideElement: shouldHideElement(index),
})));

const showHiddenElements = () => {
  modifiedTimelineEvents.value = modifiedTimelineEvents.value.map((ev) => ({
    ...ev,
    hideElement: false,
  }));
};
</script>

<style lang="scss">
  .timeline {
    grid-template-columns: 3px 1fr;
    @apply
      max-w-sm
      grid
  }

  .vertical_line {
    @apply
      relative
  }

  .point {
    transform: translateX(-50%);
    @apply
      absolute
      top-0
      left-1/2
      w-4
      h-4
      rounded-full
  }

  .content {
    margin: 0 20px 20px 20px;
  }

  .last_element {
    @apply
      max-w-sm
  }

</style>
