<template>
  <div class="flex flex-col h-full whitespace-normal">
    <div class="flex flex-wrap justify-between gap-2">
      <h2 class="text-lg font-bold">
        {{ paymentPlanGroup.name }}
      </h2>
    </div>

    <form
      class="my-4"
      @submit.prevent="submit"
    >
      <TbInput
        v-model="name"
        class="mb-2"
        :disabled="formState === FormState.submitting"
        label="Name"
        :errors="nameError && [nameError]"
      />

      <TbInput
        v-model="termsOfServiceUrl"
        class="mb-4"
        :disabled="formState === FormState.submitting"
        label="Terms of Service link (optional)"
        :errors="termsOfServiceUrlError && [termsOfServiceUrlError]"
        tooltipText="The terms of service link you provide will be embedded in your Elective Billing checkout."
      />

      <LnplSwitch
        :isLnplEnabled="!ppmOnly"
        @switch-toggle="ppmOnly = !ppmOnly"
      />

      <h2 class="my-2 text-lg font-bold">
        Payment Plans
      </h2>

      <div v-if="paymentPlans">
        <div
          v-for="(paymentPlan, ind) in paymentPlans"
          :key="paymentPlan.installments"
          class="mb-4"
        >
          <div
            v-if="paymentPlan.active"
            class="payment-term"
          >
            <div
              class="w-[47%]"
            >
              <TbSelect
                :value="paymentPlan.installments"
                class="mb-1"
                :options="paymentPlanOptions"
                :default-options="{ name: '', value: '' }"
                label="Number of Payments"
                :errors="paymentPlan.error && [paymentPlan.error]"
                @change="setSelectedTerm($event, paymentPlan)"
              />

              <div
                v-if="paymentPlan.installments !== 1"
                class="flex items-center"
              >
                <input
                  id="customFirstPaymentCheckbox"
                  v-model="paymentPlan.customFirstPaymentPercentage"
                  type="checkbox"
                  class="mr-2 checked:bg-primary-800"
                >

                <label
                  class="text-sm leading-6"
                  for="customFirstPaymentCheckbox"
                >
                  Custom First Payment
                </label>
              </div>
            </div>
            <div
              class="w-[47%]"
            >
              <TbSelect
                class="mb-1"
                :disabled="paymentPlan.installments === 1"
                :default-options="{ name: 'Monthly', value: 'Monthly' }"
                :model-value="['Monthly']"
                label="Payment Frequency"
              />
              <TbInput
                v-if="paymentPlan.customFirstPaymentPercentage"
                v-model.number="paymentPlan.downPaymentPercentage"
                required
                type="number"
                max="99"
                min="1"
                step=".01"
                label="First Payment (%)"
                tooltipText="We use this percentage to calculate the first payment amount for a given product."
              />
            </div>
            <TbTrashIcon
              class="mt-11 w-5 h-5 cursor-pointer"
              @click="removePaymentPlan(ind)"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="text-error"
      >
        Error loading payment plans. Please try again later.
      </div>

      <button
        class="button text-gray-500 font-semibold bg-gray-100 new-term-button"
        type="button"
        @click="addPaymentPlan"
      >
        <span class="flex items-center gap-2">
          <TbPlusIcon class="w-4 h-4" />
          New Term
        </span>
      </button>

      <div
        v-if="error"
        class="text-center error text-error-900"
      >
        <small class="error">
          {{ error }}
        </small>
      </div>

      <div class="flex flex-col my-4">
        <button
          class="button button--primary button--block"
          type="submit"
          :disabled="formState === FormState.submitting"
        >
          Update
        </button>

        <button
          type="button"
          class="mt-4 button button--text button--block"
          :disabled="formState === FormState.submitting"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import {
  object, string, InferType, number, array, boolean,
} from 'yup';
import { useForm, useField } from 'vee-validate';
import {
  TbInput, TbSelect, TbPlusIcon, TbTrashIcon,
} from '@/components/tasty_bistro';
import { AxiosError } from 'axios';
import { FormState } from '@/types';
import { editPaymentPlanGroup } from '../api/edit_payment_plan_group';
import { PaymentPlanGroup } from '../api/get_payment_plan_groups';
import { Configs } from '../api/get_configs';
import LnplSwitch from './components/lnpl_switch.vue';

const route = useRoute();
const projectId = route.params.projectId as string;

const props = defineProps<{ paymentPlanGroup: PaymentPlanGroup, configs: Configs }>();

const emit = defineEmits([
  'submit',
  'cancel',
]);

interface PaymentPlan {
  id?: string;
  active?: boolean;
  installments: number;
  downPaymentPercentage: number;
  customFirstPaymentPercentage?: boolean;
  error?: string;
}

const formState = ref<FormState>(FormState.ready);
const error = ref<string>('');

const editPaymentPlanGroupSchema = object({
  name: string().required('Required'),
  termsOfServiceUrl: string().url('The link provided must be a valid URL (e.g., https://elective.com)').nullable(),
  ppmOnly: boolean().required(),
  paymentPlans: array().of(
    object({
      id: string().optional(),
      active: boolean(),
      installments: number(),
      downPaymentPercentage: number(),
      customFirstPaymentPercentage: boolean(),
    }),
  ),
});

interface EditPaymentPlanGroup extends InferType<typeof editPaymentPlanGroupSchema> {}

const paymentPlanOptions = computed(() => Array.from({ length: props.configs.maxInstallments }, (_, index) => index + 1)
  .map((i) => ({
    value: i,
    name: i === 1 ? 'Pay In Full' : i,
  })));

const isCustomDownPayment = (paymentPlan: PaymentPlan) => {
  const defaultDownPayment = Number((100 / paymentPlan.installments).toFixed(7));

  if (paymentPlan.downPaymentPercentage !== defaultDownPayment) {
    return true;
  }
  return false;
};

const { handleSubmit } = useForm<EditPaymentPlanGroup>({
  validationSchema: editPaymentPlanGroupSchema,
  initialValues: {
    name: props.paymentPlanGroup.name,
    termsOfServiceUrl: props.paymentPlanGroup.termsOfServiceUrl,
    ppmOnly: props.paymentPlanGroup.ppmOnly,
    paymentPlans: props.paymentPlanGroup.paymentPlans?.map(
      (pp) => ({
        ...pp,
        active: true,
        error: '',
        customFirstPaymentPercentage: isCustomDownPayment(pp),
      }),
    ),
  },
});

const { value: name, errorMessage: nameError } = useField<string>('name');
const { value: termsOfServiceUrl, errorMessage: termsOfServiceUrlError } = useField<string>('termsOfServiceUrl');
const { value: paymentPlans } = useField<PaymentPlan[]>('paymentPlans');
const { value: ppmOnly } = useField<boolean>('ppmOnly');

const blankPaymentPlan = {
  installments: 0,
  active: true,
  downPaymentPercentage: 0,
  customFirstPaymentPercentage: false,
};

const setSelectedTerm = (event: Event, pp: PaymentPlan) => {
  const installmentsValue = Number((event.target as HTMLSelectElement).value);

  if (pp.installments === installmentsValue) return;

  const existingPaymentPlan = paymentPlans.value.find((pp) => pp.installments === installmentsValue);
  if (existingPaymentPlan) {
    if (existingPaymentPlan.active) {
      pp.error = 'The payment plan with this number of payments already exists';
      pp.installments = 0;
    } else {
      const planIndex = paymentPlans.value.indexOf(existingPaymentPlan);
      paymentPlans.value.splice(planIndex, 1);
      paymentPlans.value[paymentPlans.value.length - 1] = existingPaymentPlan;
      existingPaymentPlan.active = true;
    }
  } else {
    pp.error = '';
    pp.installments = installmentsValue;
  }
};

const indexOfPlanWithZeroInstallments = () => {
  const emptyPaymentPlan = paymentPlans.value.find((pp) => pp.installments === 0);

  return emptyPaymentPlan ? paymentPlans.value.indexOf(emptyPaymentPlan) : -1;
};

const addPaymentPlan = () => {
  const emptyPaymentPlanIndex = indexOfPlanWithZeroInstallments();

  if (emptyPaymentPlanIndex !== -1) {
    paymentPlans.value[emptyPaymentPlanIndex].error = 'Please select a term for your payment plan';
  } else {
    paymentPlans.value.push({ ...blankPaymentPlan });
  }
};

const removePaymentPlan = (planIndex: number) => {
  if (paymentPlans.value.filter((pp) => pp.active).length === 1) {
    return;
  }

  if (paymentPlans.value[planIndex].id) {
    paymentPlans.value[planIndex].active = false;
  } else {
    paymentPlans.value.splice(planIndex, 1);
  }
};

const submit = handleSubmit(async (values) => {
  const emptyPaymentPlanIndex = indexOfPlanWithZeroInstallments();

  if (emptyPaymentPlanIndex !== -1) {
    paymentPlans.value[emptyPaymentPlanIndex].error = 'Please select a term for your payment plan';
    return;
  }

  const preparedPaymentPlans = paymentPlans.value.map((paymentPlan) => ({
    id: paymentPlan.id,
    active: paymentPlan.id ? paymentPlan.active : true,
    installments: paymentPlan.installments,
    downPaymentPercentage: paymentPlan.customFirstPaymentPercentage
      ? paymentPlan.downPaymentPercentage : Number((100 / paymentPlan.installments).toFixed(7)),
  }));

  try {
    error.value = '';
    formState.value = FormState.submitting;
    await editPaymentPlanGroup({ projectId, id: props.paymentPlanGroup.id, form: { ...values, paymentPlans: preparedPaymentPlans } });
    formState.value = FormState.ready;
    emit('submit');
  } catch (err) {
    formState.value = FormState.error;
    if (err instanceof AxiosError) {
      error.value = err.response?.data?.message;
    } else {
      error.value = 'An error occurred';
    }
  }
});

async function cancel() {
  emit('cancel');
}
</script>

<style lang="scss" scoped>
  .new-term-button {
    @apply rounded-lg
  }

  .payment-term {
    @apply flex justify-between gap-1
  }
</style>

<style lang="scss">
  .payment-term .inline-flex .rounded-md {
    width: 150px; // to fix First payment tooltip going beyond the screen border
  }

</style>
