import http, { Http } from '../../../../lib/http';

interface User {
  email: string;
  firstName: string;
  lastName: string;
}

interface SignInResponse {
  user: User;
  token: string;
}

function signInCtor(http: Http) {
  return async function signIn(email: string, password: string) {
    const response = await http.post<SignInResponse>('/auth/v1/login', { email, password });
    const { user, token } = response.data;

    return {
      user,
      token,
    };
  };
}

export const signIn = signInCtor(http);
