<template>
  <div>
    <p class="text-sm pb-2">
      Default Company
    </p>
    <div class="flex justify-between items-center">
      <TbSelect
        v-model="newDefaultProjectId"
        :default-options="currentProjectOptions"
        :options="availableProjects"
        class="custom-select"
      />
      <button
        :disabled="newDefaultProjectId === defaultProjectId"
        class="button button--primary button--sm ml-4"
        @click="saveDefaultProject"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref, Ref, inject, onMounted, computed,
} from 'vue';
import { TbSelect } from '@/components/tasty_bistro';
import { AppState } from '@/pages/app/api/get_app_state';
import { getDefaultProject, setDefaultProject } from './api';

const newDefaultProjectId = ref<string>('');
const defaultProjectId = ref<string | undefined>('');
const currentProjectOptions = ref({
  name: '',
  value: '',
});

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const availableProjects = computed(() => {
  const { projects } = appState.value;
  return Object.keys(projects)
    .filter((id) => id !== currentProjectOptions.value.value)
    .map((id) => ({ name: projects[id].name, value: id }));
});

async function getDefaultProjectOption() {
  const data = await getDefaultProject();
  defaultProjectId.value = data ?? '';
  newDefaultProjectId.value = defaultProjectId.value;

  const defaultProjectName = availableProjects.value.find(
    (project) => project.value === defaultProjectId.value,
  )?.name ?? 'Set a default project';

  currentProjectOptions.value = {
    name: defaultProjectName,
    value: defaultProjectId.value,
  };
}

async function saveDefaultProject() {
  await setDefaultProject(newDefaultProjectId.value);
  await getDefaultProjectOption();
}

onMounted(async () => {
  await getDefaultProjectOption();
});
</script>

<style scoped>
.custom-select {
  @apply min-w-[50%];
}

.custom-select :deep select {
  @apply pr-8;
}
</style>
