import http, { Http } from '@/lib/http';

export interface ProductPreview {
    id: string;
    name: string;
    priceInCents: number;
    currencyCode: string;
}

function getProductPreviewsCtor(http: Http) {
  return async (projectId: string): Promise<ProductPreview[]> => {
    const { data } = await http.get<ProductPreview[]>(`/projects/${projectId}/checkouts/product-previews`);
    return data;
  };
}

export const getProductPreviews = getProductPreviewsCtor(http);
