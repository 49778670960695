import http, { Http } from '@/lib/http';
import { Student } from './get_students';

export interface CreateInvoiceParams {
  projectId: string;
  student: Student;
  checkoutId: string;
  ppmPaymentPlanTemplateId: string;
  isChargeCustomer: boolean;
  isLnplDisabled?: boolean;
}

export interface CreateInvoiceResponse {
  checkoutSessionStaticId: string;
  checkoutStaticId: string;
  invoiceId: string;
}

function createInvoiceCtor(http: Http) {
  return async ({ projectId, ...params }: CreateInvoiceParams): Promise<CreateInvoiceResponse> => {
    const response = await http.post(`/projects/${projectId}/invoices`, params);
    return response.data;
  };
}

export const createInvoice = createInvoiceCtor(http);
