<template>
  <slot
    name="action"
    v-bind="{ deleteHandler: initArchive }"
  />

  <ConfirmationDialog
    v-model="showDialog"
    title="Delete Payment Settings?"
    :items="orderedCheckouts"
    action="Delete"
    :fn="archive"
    @cancel="$emit('cancel')"
  >
    <template
      v-if="stack.activeCheckouts.length"
      #pre
    >
      "<strong class="font-semibold">{{ stack.name }}</strong>" is used in
      <strong class="font-semibold">{{ stack.activeCheckouts.length }}
        checkout{{ stack.activeCheckouts.length > 1 ? 's' : '' }}</strong>.
      Deleting {{ stack.activeCheckouts.length > 1 ? 'these payment settings' : 'this payment setting' }}
      will disable the following checkout{{ stack.activeCheckouts.length > 1 ? 's' : '' }}:
    </template>

    <template
      v-else
      #pre
    >
      Are you sure you want to delete "<strong class="font-semibold">{{ stack.name }}</strong>"? This
      action cannot be undone.
    </template>

    <template #item="{ item }">
      <div
        class="truncate w-full font-semibold text-error-600 flex flex-row items-center gap-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-4 h-4 fill-error-800"
        >
          <path
            fill-rule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29
            4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12
            8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0
            8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clip-rule="evenodd"
          />
        </svg>

        {{ item.name }}
      </div>
    </template>
  </ConfirmationDialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import ConfirmationDialog from '../../components/modals/confirmation.vue';
import { archiveStack } from '../api/archive_stack';
import { PaymentStack } from '../api/get_stacks';

const route = useRoute();
const projectId = route.params.projectId as string;

const props = defineProps<{ stack: PaymentStack}>();

const emit = defineEmits([
  'archive',
  'cancel',
]);

const showDialog = ref(false);

const initArchive = () => {
  showDialog.value = true;
};

const orderedCheckouts = computed(() => {
  const { activeCheckouts } = props.stack;

  return [...activeCheckouts].sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
});

const archive = async () => {
  await archiveStack({ projectId, stackId: props.stack.id });
  emit('archive');
};
</script>
