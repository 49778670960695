<template>
  <FormWrapper>
    <FormHeader
      heading="Tell us about your business"
      subHeading="Please review your information to ensure it's accurate."
    />
    <div>
      <div class="mt-4">
        <h2 class="mb-4 text-lg font-semibold">
          Payment Details
        </h2>
        <div>
          <p>
            <span class="font-semibold">Account Holder Name: </span>
            {{ props.data?.accountHolderName }}
          </p>
          <p>
            <span class="font-semibold">Account Number: </span>
            {{ props.data?.accountNumber }}
          </p>
          <p v-if="props.data?.countryCode === CountryCode.us">
            <span class="font-semibold">Routing Number: </span>
            {{ (props.data as PaymentDetailsUS)?.routingNumber }}
          </p>
          <p v-if="props.data?.countryCode === CountryCode.ca">
            <span class="font-semibold">Institution Number: </span>
            {{ (props.data as PaymentDetailsCA)?.institutionNumber }}
          </p>
          <p v-if="props.data?.countryCode === CountryCode.ca">
            <span class="font-semibold">Transit Number: </span>
            {{ (props.data as PaymentDetailsCA)?.transitNumber }}
          </p>
          <p>
            <span class="font-semibold">Currency: </span>
            {{ props.data?.currencyCode }}
          </p>
        </div>
      </div>
      <button
        class="w-full mt-4 mb-2 button button--secondary"
        @click="goToNextPage"
      >
        Continue
      </button>
      <button
        class="w-full text-secondary button button--text"
        @click="goToEdit"
      >
        Edit
      </button>
    </div>
  </FormWrapper>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import FormHeader from '@/components/form_header.vue';
import FormWrapper from '@/components/form_wrapper.vue';
import { Pages } from '@/router';
import {
  PaymentDetails,
  PaymentDetailsUS,
  PaymentDetailsCA,
  CountryCode,
} from '../types';

const router = useRouter();

const props = defineProps<{ data: PaymentDetails }>();

function goToNextPage() {
  router.push({ name: Pages.onboardingProgress });
}

function goToEdit() {
  router.push({ name: Pages.paymentDetailsForm });
}

</script>
