import http, { Http } from '@/lib/http';

export interface UpdateSubscriptionParams {
  subscriptionId: string;
  eventsToDisable: string[];
  eventsToEnable: string[];
  projectId: string;
}

function updateSubscriptionCtor(http: Http) {
  return async ({
    subscriptionId,
    eventsToDisable,
    eventsToEnable,
    projectId,
  }: UpdateSubscriptionParams) => {
    await http.put(`/projects/${projectId}/notifications/${subscriptionId}`, {
      eventsToDisable,
      eventsToEnable,
    });
  };
}

export const updateSubscription = updateSubscriptionCtor(http);
