import http, { Http } from '@/lib/http';

export interface Plan {
    id?: string;
    installments: number;
    frequency: string;
}

export interface UpdateStack {
  id: string;
  projectId: string;
  name: string;
  lnplEnabled: boolean;
  payInFullEnabled: boolean;
  ppmEnabled: boolean;
  paymentPlans: Plan[];
}

function updateStackCtor(http: Http) {
  return async function createProduct({ id, projectId, ...form }: UpdateStack): Promise<void> {
    await http.patch(`/projects/${projectId}/payment-settings/${id}`, form);
  };
}

export const updateStack = updateStackCtor(http);
