export function result(result: string) {
  if (!result) return result;

  const normalizedOrigin = result.toLocaleLowerCase();
  switch (normalizedOrigin) {
    case 'qualified':
      return 'Qualified';
    case 'pending_resolution':
      return 'Pending';
    case 'not_qualified':
      return 'Not Qualified';
    case 'purchased':
      return 'Purchased';
    default:
      return result;
  }
}

export function status(result: string, origin: string): string {
  if (!result) return result;

  const normalizedResult = result.toLocaleLowerCase();
  switch (normalizedResult) {
    case 'placed':
      return 'N/A';
    case 'pending_payment':
      return 'Pending Payment';
    case 'refunded':
      return 'Refunded';
    case 'canceled':
      return 'Canceled';
    case 'chargebacked':
      return 'Chargebacked';
    case 'reversed':
      return origin === 'lnpl'
        ? 'Reversed'
        : 'N/A';
    case 'chargeback_reversed':
      return origin === 'lnpl'
        ? 'Reversed'
        : 'N/A';
    case 'charge_off':
      return origin === 'lnpl'
        ? 'Paid/Default'
        : 'Delinquent';
    case 'delinquent':
      return origin === 'lnpl'
        ? 'Paid/Default'
        : 'Delinquent';
    case 'paid':
      return origin === 'lnpl'
        ? 'Paid'
        : 'Completed';
    case 'payout':
      return 'Balance Due';
    case 'future':
      return 'Future';
    case 'unrecoverable':
      return 'Unrecoverable';
    default:
      return 'N/A';
  }
}
