// @ts-nocheck
// external script which had to be copied and pasted thus disabling typescript check

export function hotjar(element: HTMLScriptElement) {
  const hotjarId = import.meta.env.VITE_APP_HOTJAR_ID;
  element.src = ((h, o, t, j, a, r) => {
    // eslint-disable-next-line func-names, prefer-rest-params
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); };
    h._hjSettings = {
      hjid: hotjarId,
      hjsv: 6,
    };
    [a] = o.getElementsByTagName('head');
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(
    window,
    document,
    'https://static.hotjar.com/c/hotjar-',
    '.js?sv=',
  );

  return element;
}
