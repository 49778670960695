<template>
  <div class="flex gap-2">
    <div>
      <label
        class="label"
        for="start-date"
      >
        Start Date
      </label>

      <DatePicker
        id="start-date"
        :date="startDate"
        :min="props.boundaryStart"
        :max="endDate"
        @selected-date="handleStartDate"
      />
    </div>

    <div>
      <label
        class="label"
        for="end-date"
      >
        End Date
      </label>

      <DatePicker
        id="end-date"
        :date="endDate"
        :min="startDate"
        :max="props.boundaryEnd"
        @selected-date="handleEndDate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';
import { sub, startOfDay, endOfDay } from 'date-fns';
import DatePicker from '../date_picker/date_picker.vue';

interface DateRangeProps {
  dates?: [Date, Date];
  boundaryStart?: Date;
  boundaryEnd?: Date;

}

const props = withDefaults(defineProps<DateRangeProps>(), {
  dates: () => [
    sub(new Date(), { days: 7 }),
    new Date(),
  ],
  boundaryStart: undefined,
  boundaryEnd: undefined,
});

const emits = defineEmits(['selected-date-range']);

const startDate = ref(props.dates[0]);
const endDate = ref(props.dates[1]);

function handleStartDate(date: Date) {
  startDate.value = date;
}

function handleEndDate(date: Date) {
  endDate.value = date;
}

watch(([startDate, endDate]), () => {
  const dateRange = [startOfDay(startDate.value), endOfDay(endDate.value)];
  emits('selected-date-range', dateRange);
});
</script>
