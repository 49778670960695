<template>
  <div>
    <h1 class="mb-6 text-3xl font-semibold md:text-4xl">
      <slot name="title" />
    </h1>

    <div class="my-4">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>
