<template>
  <router-view v-slot="{ Component }">
    <div class="h-full min-h-full">
      <component :is="Component" />
    </div>
  </router-view>
</template>

<script lang="ts" setup>
import { onBeforeMount, inject, Ref } from 'vue';
import { Userpilot } from 'userpilot';
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import { AppState } from '@/pages/app/api/get_app_state';

const route = useRoute();
const router = useRouter();
const projectId = route.params.projectId as string;

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const project = appState.value.projects[projectId];

onBeforeMount(async () => {
  if (project.features.userPilotEnabled) {
    try {
      Userpilot.initialize(import.meta.env.VITE_USER_PILOT_TOKEN);
      Userpilot.identify(appState.value.user.id, {
        name: `${appState.value.user.firstName} ${appState.value.user.lastName}`,
        email: appState.value.user.email,
        created_at: appState.value.user.createdAt,
      });
    } catch (error) {
      console.log('Failed to load UserPilot');
    }
  }

  if (project.states.onboardingState.status !== 'approved') {
    const getRouteName = () => {
      if (!project.states.onboardingState.hasWatchedWelcomeVideo) {
        return Pages.welcomeVideo;
      }

      if (project.actions.showDemoPage) {
        return Pages.scheduleDemo;
      }

      return Pages.onboarding;
    };

    router.replace({ name: getRouteName(), params: { projectId } });
  } else {
    const next = route.query.next as string;

    if (next) {
      router.replace({ path: next });
    } else if (route.name === Pages.app) {
      router.replace({ name: Pages.dashboardHome, params: { projectId } });
    }
  }
});
</script>
