<template>
  <div class="self-end">
    <button
      :class="isLoading? 'w-[82px]' : ''"
      class="text-center button button--primary button--sm"
      @click="handleClick"
    >
      <TbIcon
        v-if="isLoading"
        icon="spinner"
        class="text-xl text-white animate-spin"
      />
      <div v-else>
        Add Account
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { TbIcon } from '@/components/tasty_bistro';
import { getConnectUrl } from './api';

const props = defineProps<{ projectId: string}>();
const isLoading = ref(false);

const handleClick = async () => {
  if (isLoading.value) {
    return;
  }

  isLoading.value = true;

  try {
    const resp = await getConnectUrl(props.projectId);
    window.location.href = resp;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};
</script>
