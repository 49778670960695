import { LineController } from 'chart.js';
import { createTypedChart } from 'vue-chartjs';

export class TbLineChartController extends LineController {
  draw() {
    super.draw();

    if (this.chart?.tooltip && this.chart.tooltip.opacity > 0) {
      const { ctx } = this.chart;
      const x = this.chart.tooltip.caretX;

      const topY = this.chart.scales.y.top;
      const bottomY = this.chart.scales.y.bottom;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 4;
      ctx.strokeStyle = 'rgba(65, 74, 104, .2)';
      ctx.stroke();
      ctx.restore();
    }
  }
}

TbLineChartController.id = 'tb-line-chart';
TbLineChartController.defaults = LineController.defaults;

const TbLineChart = createTypedChart(
  'tb-line-chart' as any,
  TbLineChartController,
);

export default TbLineChart;
