<template>
  <RoleControlledAction
    v-slot="{wrapper, restricted}"
    :user-role="roleName"
    :config-object="roleConfig.deletePaymentStack"
  >
    <button
      :class="{ 'custom-disabled': restricted}"
      class="button button--block button--sm text-left text-error-900"
      @click="wrapper(()=> initArchive())"
    >
      Delete
    </button>
  </RoleControlledAction>
  <TbDialog
    :show="archivePaymentPlanGroupDialog"
    @close="cancelArchive"
  >
    <template #header>
      <h2 class="text-xl font-bold">
        Delete {{ paymentPlanGroup?.name || 'Payment Plan' }}
      </h2>
    </template>

    <p>
      You're about to delete {{ paymentPlanGroup?.name || 'a payment plan' }}.
      Once this is done, you'll have to create a new payment plan, or associate your products with another payment plan.
    </p>

    <template #footer>
      <div class="flex">
        <div class="flex gap-4 actions">
          <button
            type="button"
            class="button button--sm"
            @click="cancelArchive"
          >
            Cancel
          </button>
          <button
            class="button button--sm button--danger"
            @click="archive"
          >
            Delete
          </button>
        </div>

        <small
          v-if="archiveError"
          class=""
        >
          {{ archiveError }}
        </small>
      </div>
    </template>
  </TbDialog>
</template>

<script lang="ts" setup>
import { ref, inject, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbDialog } from '@/components/tasty_bistro';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import { archivePaymentPlanGroup } from '../api/archive_payment_plan_group';
import { PaymentPlanGroup } from '../api/get_payment_plan_groups';
import { roleConfig } from '../role_configurations';

const route = useRoute();
const projectId = route.params.projectId as string;
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const props = defineProps<{ paymentPlanGroup: PaymentPlanGroup}>();

const emit = defineEmits([
  'archive',
  'test',
]);

const archiveError = ref<String>('');
const archivePaymentPlanGroupDialog = ref<Boolean>(false);

const initArchive = () => {
  archivePaymentPlanGroupDialog.value = true;
};

const cancelArchive = () => {
  archivePaymentPlanGroupDialog.value = false;
  archiveError.value = '';
  emit('test');
};

const archive = async () => {
  try {
    archiveError.value = '';
    await archivePaymentPlanGroup({ id: props.paymentPlanGroup.id, projectId });
    archivePaymentPlanGroupDialog.value = false;
    emit('archive');
  } catch (error) {
    archiveError.value = 'Error archiving';
  }
};
</script>
<style lang="scss" scoped>
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
