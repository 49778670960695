<template>
  <div class="flex min-h-full">
    <div
      v-if="state === PageState.loading"
      class="flex items-center justify-center w-full min-h-full"
    >
      <TbLoading />
    </div>

    <template v-else>
      <SplitViewLayout>
        <div class="flex flex-col w-full p-8">
          <div class="max-w-3xl border-gray-300">
            <h1 class="text-2xl sm:text-3xl font-serif-bold text-primary">
              Welcome to Elective {{ creatorFirstName }} 👋
            </h1>

            <p class="my-2 sm:text-lg">
              This is your account setup guide. It has everything you need to get set up with Elective
              so you can supercharge sales.
            </p>

            <p class="sm:text-lg">
              Need help along the way? Please reach out to our support team at
              <HelpEmailLink />
            </p>
          </div>

          <hr class="my-8">

          <div
            v-if="error"
            class="py-8 text-error"
          >
            {{ error }}
          </div>

          <div
            v-else
            class="w-full pb-6"
          >
            <StepCard
              disable-edit
              name="Sign up for Elective"
              description="Create your own Elective account"
              icon-name="user-plus"
              :status="progress.signup.status"
              :disabled="progress.signup.disabled"
              :toPage="Pages.signUp"
            />
            <StepCard
              name="Tell us about your business (EST 5 MIN)"
              description="We just need the basics to get started"
              icon-name="newspaper"
              :status="progress.businessDetails.status"
              :disabled="progress.businessDetails.disabled"
              :toPage="Pages.businessDetails"
            />
            <StepCard
              name="Tell us about the business owner (EST 2 MIN)"
              description="We'll only use this for verification purposes"
              icon-name="tags"
              :status="progress.businessOwner.status"
              :disabled="progress.businessOwner.disabled"
              :toPage="Pages.businessOwner"
            />
            <StepCard
              name="Set a payment method (EST 2 MIN)"
              description="We use this info to perform payouts"
              icon-name="bank"
              :status="progress.businessPaymentDetails.status"
              :disabled="progress.businessPaymentDetails.disabled"
              :toPage="Pages.paymentDetails"
            />
            <StepCard
              disable-edit
              name="Confirm your pricing package (EST 2 MIN)"
              description="Give your customers the flexibility to pay at their own pace"
              icon-name="hand-holding-dollar"
              :status="progress.pricing.status"
              :disabled="progress.pricing.disabled"
              :toPage="Pages.pricing"
            />
            <button
              class="button button--primary button--lg button--block"
              :disabled="!isOnboardingComplete"
              @click="submitApplication"
            >
              Submit Application 🎉
            </button>
          </div>
        </div>
      </SplitViewLayout>
    </template>
  </div>
</template>

<script setup lang="ts">

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TbLoading } from '@/components/tasty_bistro';
import HelpEmailLink from '@/components/help_email_link.vue';
import { Pages } from '@/router';
import { PageState } from '@/types';
import { loadExternalScript, ExternalScriptName } from '@/external_scripts';
import SplitViewLayout from '../components/split_view_layout/index.vue';
import StepCard from './components/step_card/index.vue';
import { getOnboardingProgress, OnboardingProgress, submitOnboardingApplication } from './api';
import { StepStatus } from './types';

const error = ref('');
const state = ref<PageState>(PageState.loaded);

const progress = ref<OnboardingProgress>({
  signup: { status: StepStatus.pending, disabled: false },
  businessDetails: { status: StepStatus.pending, disabled: false },
  businessOwner: { status: StepStatus.pending, disabled: true },
  businessPaymentDetails: { status: StepStatus.pending, disabled: true },
  pricing: { status: StepStatus.pending, disabled: true },
});

const isOnboardingComplete = ref(false);
const creatorFirstName = ref('');

const router = useRouter();
const route = useRoute();
const projectId = route.params.projectId as string;

onMounted(async () => {
  try {
    if (import.meta.env.PROD) {
      loadExternalScript(ExternalScriptName.hotjar);
    }

    state.value = PageState.loading;

    const data = await getOnboardingProgress(projectId);

    state.value = PageState.loaded;

    progress.value = data.progress;
    isOnboardingComplete.value = data.isOnboardingComplete;
    creatorFirstName.value = data.creatorFirstName;
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
});

async function submitApplication() {
  try {
    await submitOnboardingApplication(projectId);

    router.push({ name: Pages.onboardingSubmitted });
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
}

</script>
