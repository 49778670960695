import http, { Http } from '@/lib/http';

export interface Integration {
  id: string;
  displayName: string;
  projectId: string;
  integrationSource: string;
  integrationType: string;
  state: 'authorized' | 'unauthorized' | 'validating';
  createdAt: string;
}

function getIntegrationsCtor(http: Http) {
  return async (projectId: string): Promise<Integration[]> => {
    const { data } = await http.get(`/projects/${projectId}/integrations`);
    return data;
  };
}

export const getIntegrations = getIntegrationsCtor(http);
