<template>
  <div>
    <div
      v-if="props.failed === null"
      class="ml-12"
    >
      <button
        v-for="(isSelected, label) in props.selectedButtons[props.index]"
        :key="label"
        class="button button--md border-primary quiz-button"
        :class="{ selected: isSelected }"
        @click="
          selectAnswer(props.question, label === 'true');
          toggleButton(props.selectedButtons[props.index], label);
        "
      >
        {{ capitalize(`${label}`) }}
      </button>
    </div>
    <div
      v-else
      class="ml-12"
      :class="props.question.correct ? 'correct' : 'incorrect'"
    >
      <button class="button button--md quiz-button">
        {{ capitalize(`${props.question.answer}`) }}
      </button>
      <span class="font-bold ml-2">
        <TbIcon
          :icon="props.question.correct? 'check-circle' : 'times-circle'"
          class="pr-2"
          :class="props.question.correct ? 'text-success' : 'text-error'"
        />
        <span>
          {{ props.question.correct ? 'Correct' : 'Incorrect' }}
        </span>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { capitalize } from 'lodash';
import { TbIcon } from '@/components/tasty_bistro';
import { Question, SelectedButtons } from '../types';

interface Props {
    failed: boolean | null;
    question: Question;
    index: number;
    selectedButtons: Array<SelectedButtons>;
}

const props = defineProps<Props>();

const selectAnswer = (question:Question, answer: boolean) => {
  question.answer = answer;
};

function toggleButton(selectedButtons: SelectedButtons, label: keyof SelectedButtons) {
  const complementaryLabel = label === 'true' ? 'false' : 'true';
  if (selectedButtons[label]) {
    return;
  }
  selectedButtons[label] = !selectedButtons[label];
  selectedButtons[complementaryLabel] = !selectedButtons[label];
}
</script>

<style scoped lang="scss">
  .quiz-button {
    @apply
      mr-3
      px-10
      py-1
      border-2
      inline-flex
      items-center
      justify-center
      w-[105px]
      h-[36px]
      sm:w-[146px]
      sm:h-[40px];
  }

  .quiz-button.selected {
    @apply bg-accent-300;
  }
  .correct * {
    @apply
      border-success
      text-success;
  }

  .incorrect * {
    @apply
      border-error
      text-error;
  }
</style>
