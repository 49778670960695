<template>
  <div class="layout-auth">
    <div class="background-shapes">
      <img
        :src="topImage"
        class="background-shape background-shape--top"
      >
      <img
        :src="bottomLeftImage"
        class="background-shape background-shape--bottom-left"
      >
      <img
        :src="bottomRightImage"
        class="background-shape background-shape--bottom-right"
      >
    </div>

    <div class="absolute top-0 z-10 flex w-full h-full overflow-auto">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import topImage from '@/assets/images/layout_auth/top_shape.svg';
import bottomLeftImage from '@/assets/images/layout_auth/bottom_left_shape.svg';
import bottomRightImage from '@/assets/images/layout_auth/bottom_right_shape.svg';

export default defineComponent({
  setup() {
    return {
      topImage,
      bottomLeftImage,
      bottomRightImage,
    };
  },
});
</script>

<style lang="scss" scoped>
  .layout-auth {
    @apply
      flex
      flex-col
      items-center
      h-screen
      relative
      overflow-auto
      bg-accent-200;
  }

  .background-shapes {
    @apply
      w-full
      h-full
      absolute
      z-0
      max-w-5xl
      overflow-hidden;

    .background-shape {
      @apply
        absolute
        bg-no-repeat;
    }

    .background-shape--top {
      max-height: 611px;
      left: calc(50% - 162px);
      top: 0;
    }

    .background-shape--bottom-left {
      max-height: 438px;
      @apply
        bottom-0
        left-4;
    }

    .background-shape--bottom-right {
      max-height: 153px;
      @apply
        bottom-0
        right-4;
    }
  }
</style>
