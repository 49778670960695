<template>
  <div class="flex flex-col items-center min-h-full p-4 bg-primary">
    <div class="w-full max-w-4xl m-auto">
      <div class="flex flex-col bg-white lg:flex-row rounded-xl">
        <div class="w-full p-8 lg:w-3/5">
          <FormHeader
            heading="No setup fees, no monthly minimums"
            subHeading="Boost sales and get paid upfront (less our processing fee)"
          />
          <form @submit.prevent="submit">
            <div class="overflow-x-scroll">
              <table class="w-full">
                <thead>
                  <tr class="h-10 text-white rounded-sm bg-primary-900">
                    <th
                      v-for="(header, i) in tableHeaders"
                      :key="i"
                      align="center"
                      class="p-2 font-regular"
                    >
                      {{ header.title }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in pricingTerms"
                    :key="index"
                    class="h-10 even:bg-gray-100 odd:bg-white"
                  >
                    <td
                      v-for="header in tableHeaders"
                      :key="header.key"
                      class="px-2 py-1 text-sm text-center whitespace-nowrap"
                    >
                      {{ item[header.key] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex p-2 mt-4">
              <input
                id="termsAndConditions"
                required
                type="checkbox"
                class="mt-1 mr-2 cursor-pointer checkbox"
              >
              <label
                for="termsAndConditions"
                class="text-sm font-light"
              >
                I have reviewed and agree to Elective's
                <a
                  class="cursor-pointer button--text text-secondary"
                  target="_blank"
                  :href="agreementLink"
                >
                  Merchant Agreement
                </a>
                and
                <a
                  class="cursor-pointer button--text text-secondary"
                  target="_blank"
                  :href="feeScheduleLink"
                >
                  Fee Schedule
                </a>
              </label>
            </div>
            <div class="flex p-2">
              <input
                id="creditLossTerm"
                required
                type="checkbox"
                class="mt-1 mr-2 cursor-pointer checkbox"
              >
              <label
                for="creditLossTerm"
                class="text-sm font-light"
              >
                I agree and understand that a transaction's payout will be refunded
                if the customer fails to make their first and second payments.*
              </label>
            </div>
            <button class="w-full my-2 button button--secondary">
              Accept Agreement
            </button>
          </form>
          <div v-if="error">
            <div class="text-center text-error">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="hidden lg:block lg:w-2/5">
          <ImageWithQuote
            quote="Elective was the best decision we made this year to supercharge sales."
            name="Teri Iejoma"
            designation="Founder of Trade & Travel - #1 Teachable Business"
            imageName="teri.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import FormHeader from '@/components/form_header.vue';
import ImageWithQuote from '@/components/image_with_quote/index.vue';
import { addPricingAgreement } from './api/add_pricing_agreement';
import { getPricingAgreement } from './api/get_pricing_agreement';

const error = ref('');
const agreementLink = ref('');
const feeScheduleLink = ref('');

const projectId = useRoute().params.projectId as string;
const router = useRouter();

type TableKey = 'ficoRange' | 'fee' | 'apr' | 'maxAmount' | 'maxMonths';

const tableHeaders: { title: string; key: TableKey }[] = [
  {
    title: 'Credit Score',
    key: 'ficoRange' as TableKey,
  },
  {
    title: 'Elective Fee',
    key: 'fee' as TableKey,
  },
  {
    title: 'APR for Customer',
    key: 'apr' as TableKey,
  },
  {
    title: 'Max. Amount',
    key: 'maxAmount' as TableKey,
  },
  {
    title: 'Max. Months',
    key: 'maxMonths' as TableKey,
  },
];

const pricingTerms = [
  {
    ficoRange: '600-900',
    fee: '15% + $0.30',
    apr: '0%',
    maxAmount: '$15,000',
    maxMonths: '12',
  },
];

onMounted(async () => {
  const data = await getPricingAgreement(projectId);
  agreementLink.value = data.agreement.link;
  feeScheduleLink.value = data.feeSchedule.link;
});

async function submit() {
  try {
    await addPricingAgreement(projectId);

    router.push({ name: Pages.onboardingProgress });
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
}

</script>
