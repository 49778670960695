import { RoleControlledActionConfiguration } from '@/types';

export const roleConfig: RoleControlledActionConfiguration = {
  createProducts: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: true, hidden: false },
    support_specialist: { restricted: true, hidden: false },
    view_only: { restricted: true, hidden: false },
  },
  updateProducts: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: true, hidden: false },
    support_specialist: { restricted: true, hidden: false },
    view_only: { restricted: true, hidden: false },
  },
  deleteProducts: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: true, hidden: false },
    support_specialist: { restricted: true, hidden: false },
    view_only: { restricted: true, hidden: false },
  },
};
