import { RoleControlledActionConfiguration } from '@/types';

export const roleConfig: RoleControlledActionConfiguration = {
  dashboardHome: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: true, hidden: true },
    support_specialist: { restricted: true, hidden: true },
    view_only: { restricted: true, hidden: true },
  },
  dashboardProducts: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  ppmPaymentPlans: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  checkouts: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  products: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  paymentSettings: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  invoices: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  dashboardApplications: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  dashboardOrders: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  dashboardInHousePayments: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  dashboardInHousePaymentsDrilldown: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  dashboardInHousePaymentsAnalytics: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: true },
    support_specialist: { restricted: false, hidden: true },
    view_only: { restricted: false, hidden: false },
  },
  dashboardStudents: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  dashboardSettlements: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: true },
    support_specialist: { restricted: false, hidden: true },
    view_only: { restricted: false, hidden: false },
  },
  dashboardProfile: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
};
