import { zendesk } from './zendesk';
import { hotjar } from './hotjar';

export type ExternalScriptBuilder = (element: HTMLScriptElement) => HTMLScriptElement;

export enum ExternalScriptName {
  'zendesk' = 'zendesk',
  'hotjar' = 'hotjar',
}

const externalScripts: Record<ExternalScriptName, ExternalScriptBuilder> = {
  zendesk,
  hotjar,
};

function loadScriptCtor(document: any) {
  return (name: ExternalScriptName) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;

    const scriptSetup = externalScripts[name];

    scriptSetup(script);

    document.body.appendChild(script);
  };
}

export const loadExternalScript = loadScriptCtor(window.document);
