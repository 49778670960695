import http, { Http } from '@/lib/http';

function getConnectUrlCtor(http: Http) {
  return async (projectId: string): Promise<any> => {
    const { data } = await http.get(`/projects/${projectId}/integrations/stripe/connect-url`);
    return data;
  };
}

export const getConnectUrl = getConnectUrlCtor(http);
