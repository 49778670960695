<template>
  <Page>
    <h1 class="mb-2 text-3xl font-semibold md:text-4xl">
      Invoicing
    </h1>

    <p class="mb-6">
      Flexibility at your fingertips. Create custom charges and one-time checkouts for your customers.
    </p>

    <Table
      date-key="createdAt"
      :items="invoices"
      :state="state"
      :total-item-count="invoicesCount"
      :items-per-page="pageSize"
      item-type="Invoice"
      :count="{ singular: 'invoice', plural: 'invoices' }"
      :headers="tableHeaders"
      hideHeader
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div class="flex flex-wrap justify-between items-end w-full gap-2">
          <div class="flex items-end flex-grow gap-2">
            <div class="w-full max-w-xs">
              <TbInput
                v-model="search"
                label="Search"
                placeholder="Customer / Email / Phone Number / ID"
              />
            </div>

            <Drawer to="#app">
              <template #default="{ toggleDrawer }">
                <div class="flex flex-col h-full">
                  <CreateInvoice
                    @cancel="toggleDrawer();"
                    @submit="toggleDrawer(); invoiceSubmitted($event)"
                  />
                </div>
              </template>

              <template #toggle="{ toggleDrawer }">
                <RoleControlledAction
                  v-slot="{ wrapper, restricted }"
                  :user-role="roleName"
                  :config-object="roleConfig.createInvoice"
                >
                  <button
                    :class="{ 'custom-disabled': restricted}"
                    class="button button--primary button--sm"
                    @click="wrapper(() => toggleDrawer())"
                  >
                    <span class="flex items-center gap-2">
                      Create Invoice
                      <TbPlusIcon class="w-4 h-4 text-white" />
                    </span>
                  </button>
                </RoleControlledAction>
              </template>
            </Drawer>
          </div>

          <Filter
            :showFilters="showFilter"
            :toggleFilter="toggleFilter"
            :cancelFilters="cancelFilter"
            :applyFilters="applyFilter"
            :filters="{
              status: {
                value: 'status',
                label: 'By Status',
                options: statusOptions,
              },
            }"
          />
        </div>

        <div
          v-if="selectedStatuses.length > 0"
          class="flex flex-wrap gap-2 mt-4"
        >
          <button
            v-for="status in selectedStatuses"
            :key="status.value"
            class="flex items-center border button button--sm border-primary"
            @click="removeStatusFilter(status)"
          >
            <TbXMarkIcon class="w-4 h-4 mr-2" />
            <span>{{ status.label }}</span>
          </button>
        </div>
      </template>

      <template #action="{ item, toggleDropdown }">
        <div class="flex flex-col h-full gap-1">
          <button
            v-if="item.status === InvoiceStatus.Open"
            class="button button--block button--sm text-left flex gap-2 items-center"
            @click="toggleDropdown(); copyUrl(item)"
          >
            <TbCopyIcon class="w-4 h-4" />
            Copy Checkout Link
          </button>
          <button
            class="button button--block button--sm text-left flex gap-2 items-center"
            @click="toggleDropdown(); copyId(item)"
          >
            Copy ID
          </button>
          <RoleControlledAction
            v-slot="{ wrapper, restricted }"
            :user-role="roleName"
            :config-object="roleConfig.createInvoice"
          >
            <button
              v-if="item.status === InvoiceStatus.Open"
              :class="{ 'custom-disabled': restricted }"
              class="button button--block text-error-900 button--sm text-left"
              @click="wrapper(() => {toggleDropdown(); voidInvoice(item)})"
            >
              Void Invoice
            </button>
          </RoleControlledAction>
        </div>
      </template>

      <template #row(studentName)="{ item }">
        <routerLink :to="{ name: Pages.dashboardStudent, params: { id: item.studentId }}">
          <div class="flex flex-col">
            <span>{{ startCase(item.studentName) }}</span>
            <span class="text-xs text-gray-500">
              {{ item.studentEmail }}
            </span>
          </div>
        </routerLink>
      </template>

      <template #row(totalInCents)="{ item }">
        <span class="currency">{{ (item.currencyCode || '') + currencyInCents(item.totalInCents) }}</span>
      </template>

      <template #row(status)="{ value, item }">
        <div>
          <span
            class="inline-block w-2 h-2 mr-1 rounded-full"
            :class="invoiceStatusToColorMap[item.status]"
          />
          <span>
            {{ startCase(value) }}
          </span>
        </div>
      </template>
    </Table>
  </Page>

  <CheckoutLinkPopup
    v-if="showCopyCheckoutLinkPopup"
    :show="showCopyCheckoutLinkPopup"
    :checkout-link="checkoutLink"
    :invoice-id="createdInvoiceId"
    :student="selectedStudent"
    @close-popup="closeCopyCheckoutLinkPopup"
  />

  <CheckoutModal
    v-if="isCheckoutModalOpen"
    :checkout-link="checkoutLink"
    @close-modal="closeCheckoutModal"
  />

  <TbNotification
    :show="showCopyNotification"
    title="Success 🎉"
    :message="copyNotificationText"
    card-class="text-white bg-success"
    @close="showCopyNotification = false"
  />

  <TbNotification
    :show="showVoidInvoiceErrorNotification"
    title="Error"
    :message="voidInvoiceErrorNotificationText"
    card-class="text-white bg-error"
    @close="showVoidInvoiceErrorNotification = false"
  />
</template>

<script setup lang="ts">
import {
  ref, Ref, onActivated, watch, inject,
} from 'vue';
import { debounce, startCase } from 'lodash';
import {
  TbInput, TbNotification, TbXMarkIcon, TbPlusIcon, TbCopyIcon,
} from '@/components/tasty_bistro';
import { Pages } from '@/router';
import { useClipboard } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { currencyInCents } from '@/filters/currency';
import Table from '@/components/table.vue';
import Drawer from '@/components/drawer.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { PageState } from '@/types';
import { invoiceStatusToColorMap } from '@/helpers/category_color_mapper';
import { formatDate } from '@/filters/date';
import Filter, { FilterOption } from '@/components/filter.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import Page from '../../components/page.vue';
import CreateInvoice, { SubmitFormPayload } from './components/create.vue';
import { getInvoices, Invoice, InvoiceStatus } from './api/get_invoices';
import { archiveInvoice } from './api/archive_invoice';
import { roleConfig } from './role_configurations';
import CheckoutModal from './components/checkout_modal.vue';
import CheckoutLinkPopup from './components/checkout_link_popup.vue';

const invoices: Ref<any[]> = ref([]);
const invoicesCount = ref<number>(0);
const state = ref(PageState.loaded);
const route = useRoute();
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = route.params.projectId as string;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const search = ref('');
const pageSize = 20;
const currentPage = ref(0);
const showFilter = ref(false);
const statusOptions = [
  { label: 'Open', checked: false, value: 'open' },
  { label: 'Paid', checked: false, value: 'paid' },
  { label: 'Void', checked: false, value: 'void' },
];
const selectedStatuses = ref<FilterOption[]>([]);
const showCopyCheckoutLinkPopup = ref(false);
const checkoutLink = ref('');
const createdInvoiceId = ref('');
const selectedStudent = ref();

const isCheckoutModalOpen = ref(false);

const showCopyNotification = ref(false);
const copyNotificationText = ref('');

const showVoidInvoiceErrorNotification = ref(false);
const voidInvoiceErrorNotificationText = ref('');

const tableHeaders = [
  {
    title: 'Created',
    key: 'createdAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Customer',
    key: 'studentName',
  },
  {
    title: 'Total',
    key: 'totalInCents',
  },
  {
    title: 'Checkout',
    key: 'checkoutName',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const loadInvoices = async () => {
  try {
    state.value = PageState.loading;
    const invoicesResponse = await getInvoices({
      search: search.value,
      pageSize,
      currentPage: currentPage.value,
      status: selectedStatuses.value.map((s) => s.value),
    }, projectId);
    invoices.value = invoicesResponse.data;
    invoicesCount.value = invoicesResponse.totalCount;
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

const copyId = (invoice: Invoice) => {
  showCopyNotification.value = false;

  setTimeout(() => {
    const clipboard = useClipboard({ source: invoice.checkoutSessionV2StaticIdentifier });
    clipboard.copy();
    copyNotificationText.value = 'Checkout Session ID has been successfully copied to your Clipboard';
    showCopyNotification.value = true;
  }, 200);
};

const copyUrl = (invoice: Invoice) => {
  showCopyNotification.value = false;

  const link = `${import.meta.env.VITE_APP_CHECKOUT_URL}/c/${projectId}/
    ${invoice.checkoutStaticIdentifier}?sid=${invoice.checkoutSessionV2StaticIdentifier}`;

  setTimeout(() => {
    const clipboard = useClipboard({
      source: link,
    });
    clipboard.copy();
    copyNotificationText.value = 'Checkout link has been successfully copied to your Clipboard';
    showCopyNotification.value = true;
  }, 200);
};

const toggleFilter = () => {
  showFilter.value = !showFilter.value;
};

const closeCopyCheckoutLinkPopup = () => {
  showCopyCheckoutLinkPopup.value = false;
};

const closeCheckoutModal = async () => {
  isCheckoutModalOpen.value = false;
  await loadInvoices();
};

const applyFilter = async () => {
  showFilter.value = false;
  selectedStatuses.value = statusOptions.filter((s) => s.checked);
  await loadInvoices();
};

const cancelFilter = () => {
  showFilter.value = false;
};

const removeStatusFilter = async (status: FilterOption) => {
  selectedStatuses.value = selectedStatuses.value.filter((ss) => ss.value !== status.value);
  statusOptions.forEach((option) => {
    if (option.value === status.value) {
      option.checked = false;
    }
  });

  await loadInvoices();
};

const invoiceSubmitted = (payload: SubmitFormPayload) => {
  loadInvoices();

  // eslint-disable-next-line max-len
  checkoutLink.value = `${import.meta.env.VITE_APP_CHECKOUT_URL}/c/${projectId}/${payload.checkoutStaticId}?sid=${payload.checkoutSessionStaticId}`;
  createdInvoiceId.value = payload.createdInvoiceId;
  selectedStudent.value = payload.student;

  if (payload.checkoutOption === 'chargeCustomer') {
    isCheckoutModalOpen.value = true;
  } else {
    showCopyCheckoutLinkPopup.value = true;
  }
};

const voidInvoice = async (invoice: Invoice) => {
  try {
    await archiveInvoice({
      projectId,
      checkoutSessionStaticId: invoice.checkoutSessionV2StaticIdentifier,
    });
  } catch (err: any) {
    showVoidInvoiceErrorNotification.value = false;

    setTimeout(() => {
      voidInvoiceErrorNotificationText.value = err.response?.data?.message || 'Something went wrong';
      showVoidInvoiceErrorNotification.value = true;
    }, 200);
  }
  await loadInvoices();
};

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadInvoices();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadInvoices();
};

onActivated(async () => {
  await loadInvoices();
});

watch(search, debounce(async () => {
  currentPage.value = 0;
  await loadInvoices();
}, 500));
</script>

<style lang="scss" scoped>
  .product-name {
    @apply
      px-2
      py-[2px]
      rounded-md
      max-w-xs
      truncate
      border
      border-primary-200
  }

  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
