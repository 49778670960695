import http, { Http } from '@/lib/http';

export interface BusinessOwner {
  firstName: string;
  lastName: string;
  dob: string;
  ssnLast4: string;
  title: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
}

function addBusinessOwnerCtor(http: Http) {
  return async (form: BusinessOwner, projectId: string) => {
    await http.post(`/projects/${projectId}/business-owner`, form);
  };
}

export const addBusinessOwner = addBusinessOwnerCtor(http);
