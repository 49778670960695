import http, { Http } from '@/lib/http';
import { CurrencyCode } from '@/types';

export enum InvoiceStatus {
  Open = 'open',
  Paid = 'paid',
  Void = 'void'
}

export interface Invoice {
  id: string;
  createdAt: Date;
  studentName: string,
  studentEmail: string | null,
  studentId: string,
  totalInCents: number,
  currencyCode: CurrencyCode | null,
  checkoutName: string;
  status: InvoiceStatus;
  checkoutSessionV2StaticIdentifier: string;
  checkoutStaticIdentifier: string;
  totalCount: number;
}

export interface GetInvoicesResponse {
  data: Invoice[];
  totalCount: number;
}

export interface InvoicesFilter {
  search: string;
  pageSize: number;
  currentPage: number;
  status?: string[];
}

function getInvoicesCtor(http: Http) {
  return async (filter: InvoicesFilter, projectId: string): Promise<GetInvoicesResponse> => {
    const { data } = await http.get<GetInvoicesResponse>(`/projects/${projectId}/invoices`, {
      params: filter,
    });

    return data;
  };
}

export const getInvoices = getInvoicesCtor(http);
