let currencyCode = 'usd';

export function setCurrency(value: string) {
  currencyCode = value;
}

export function getCurrency() {
  return currencyCode;
}

export function currency(value: number, decimals = 0, currencyCodeOverride = '') {
  if (!Number.isFinite(value)) return '$-';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCodeOverride || currencyCode,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(value);
}

export function currencyInCents(value: number, decimals = 2, currencyCodeOverride = '') {
  return currency(value / 100, decimals, currencyCodeOverride);
}
