<template>
  <Page class="max-w-[478px]">
    <div class="mb-8">
      <router-link
        :to="{name: integrationsDashboard}"
        class="text-primary flex items-center font-semibold mb-"
      >
        <TbArrowLeftIcon class="w-4 h-4" /> Back
      </router-link>
    </div>
    <img
      src="../assets/nmi.svg"
      alt="Nmi Logo"
      class="h-[48px] self-start mb-4"
    >

    <div
      class="flex flex-col"
    >
      <section>
        <div class="font-semibold text-3xl mb-3">
          Connect to NMI
        </div>
        <p class="mb-3 sm:max-w-[478px] text-sm">
          Let your customers pay in full or pay over time, fully managed by Elective.
        </p>
        <p class="mb-4 sm:max-w-[478px] text-sm">
          <a
            href="https://secure.nmi.com/merchants/login.php"
            target="_blank"
            rel="noopener noreferrer"
          >Log in</a> to your NMI account to get started.
        </p>
        <div class="sm:w-[478px] sm:flex-row flex-col px-3 py-2.5 mb-6 bg-primary rounded-md justify-between items-center inline-flex">
          <div class="sm:w-[295px] flex-col justify-center items-start gap-1 inline-flex text-white text-sm sm:mb-0 mb-3">
            <div class="font-semibold">
              Need help?
            </div>
            <div>
              Watch our step-by-step video tutorial or view our Help Center article
              <a
                href="https://help.elective.com/hc/en-us/articles/24649146607515-How-do-I-set-up-NMI-integration"
                target="_blank"
                rel="noopener noreferrer"
                class="text-white font-bold"
              >here</a> to find your API key.
            </div>
          </div>
          <button
            class="button text-nowrap video-button self-start sm:self-center"
            @click="showModal = true"
          >
            Watch Video
          </button>
        </div>
        <form
          @submit.prevent="submit"
        >
          <div class="mb-4">
            <TbInput
              v-model="securityKey"
              label="API Key"
              type="text"
              :errors="securityKeyError && [securityKeyError]"
            />
          </div>
          <div class="mb-4">
            <TbInput
              v-model="tokenizationKey"
              label="Tokenization Key (Optional)"
              type="text"
              :errors="tokenizationKeyError && [tokenizationKeyError]"
            />
          </div>
          <div class="mb-6">
            <TbInput
              v-model="collectCheckoutKey"
              label="Collect Checkout Key (Optional)"
              type="text"
              :errors="collectCheckoutKeyError && [collectCheckoutKeyError]"
            />
          </div>
          <div>
            <button
              type="submit"
              class="button button--primary w-full mb-4"
            >
              Connect
            </button>
          </div>
          <div
            v-if="error"
            class="text-center error text-error-900"
          >
            <small class="error">
              {{ error }}
            </small>
          </div>
        </form>
      </section>
    </div>
    <HelpVideo
      :showModal="showModal"
      @close-modal="showModal = false"
    />
  </Page>
</template>

<script setup lang="ts">
import { ref, onActivated } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { object, string, InferType } from 'yup';
import { useForm, useField } from 'vee-validate';
import {
  TbArrowLeftIcon, TbInput,
} from '@/components/tasty_bistro';
import Page from '@/pages/app/project/dashboard/components/page.vue';
import HelpVideo from './components/help_video.vue';
import { submitCredentials } from './api';

const integrationsDashboard = ref<string>('dashboardIntegrations');

const route = useRoute();
const router = useRouter();
const projectId = route.params.projectId as string;

const showModal = ref(false);

const error = ref<string>('');

const securityKeyRegex = /^[a-zA-Z\d]{32}$/;
const tokenizationRegex = /^$|^[a-zA-Z\d-]{27}$/;
// const collectCheckoutRegex = /^$|^checkout_public_[a-zA-Z\d]{32}$/;
const collectCheckoutRegex = /^$|^[a-zA-Z\d_]{48}$/;

const credentialsSchema = object({
  securityKey: string()
    .matches(securityKeyRegex, 'The API Key must be exactly 32 alphanumeric characters.')
    .required('You need to provide us with your API key so we can connect to your account'),
  tokenizationKey: string()
    .optional()
    .matches(tokenizationRegex, 'The Tokenization Key must be exactly 27 alphanumeric characters.'),
  collectCheckoutKey: string()
    .optional()
    .matches(collectCheckoutRegex, 'The Collect Checkout Key must be exactly 48 characters.'),
});

const { handleSubmit, resetForm } = useForm<InferType<typeof credentialsSchema>>({
  validationSchema: credentialsSchema,
});

const { value: securityKey, errorMessage: securityKeyError } = useField('securityKey');
const { value: tokenizationKey, errorMessage: tokenizationKeyError } = useField('tokenizationKey');
const { value: collectCheckoutKey, errorMessage: collectCheckoutKeyError } = useField('collectCheckoutKey');

function validateExtraKeys() {
  if ((tokenizationKey.value && !collectCheckoutKey.value) || (collectCheckoutKey.value && !tokenizationKey.value)) {
    error.value = 'If you want to use 3DS, You need to provide both Tokenization and Collect Checkout keys';
    return false;
  }
  error.value = '';
  return true;
}

const submit = handleSubmit(async (values) => {
  try {
    if (!validateExtraKeys()) return;
    await submitCredentials({ projectId, credentials: values });
    router.push({ name: integrationsDashboard.value });
  } catch (err: any) {
    error.value = err.response?.data?.message ?? 'Something went wrong';
  }
});

function resetState() {
  resetForm();
  error.value = '';
}

onActivated(() => {
  resetState();
});
</script>

<style lang="scss" scoped>
.video-button {
  @apply w-[110px] px-3.5 py-2 bg-gray-100 rounded-lg justify-start items-center gap-2 flex text-center text-gray-500 text-sm font-semibold;
}
</style>
