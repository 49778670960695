<template>
  <FormWrapper>
    <FormHeader
      heading="Let's customize your account"
      subHeading="Choose your logo for your checkout page and marketing materials. You can update this at any time."
    />
    <form
      class="mt-4"
      enctype="multipart/form-data"
      @submit.prevent="submit"
    >
      <label class="text-sm font-medium">
        Add your Logo
      </label>
      <div
        class="dropZone"
        :class="isDropZoneActive ? 'dropZone--active' : 'dropZone--inactive'"
        @dragenter.prevent="isDropZoneActive = true"
        @dragover.prevent="isDropZoneActive = true"
        @dragleave.prevent="isDropZoneActive = false"
        @drop.prevent="dropHandler"
      >
        <label>
          <input
            type="file"
            class="hidden"
            accept="image/*"
            @change="handleInputChange"
          >
          <p>Drop your image here</p>
          <p>or</p>
          <p class="underline cursor-pointer">Browse Files</p>
        </label>
      </div>

      <div class="flex flex-col gap-1 mt-4 text-gray-400 font-medium">
        <strong>Please ensure your logo meets the following criteria:</strong>
        <ul class="list-disc pl-5">
          <li>Accepted file formats include .png and .jpg</li>
          <li>Recommended dimensions: 208 x 128px</li>
          <li>No background or padding</li>
        </ul>
      </div>

      <div
        v-if="url"
        class="my-4"
      >
        <!-- preview -->
        <label class="mb-2 text-sm font-medium">Preview</label>
        <div class="flex flex-col items-center">
          <div className="max-w-52 relative flex items-start justify-start">
            <img
              :src="url"
              :alt="file?.name"
              className="max-h-32 object-contain h-full w-full object-left"
            >
          </div>

          <p>{{ file?.name }}</p>
        </div>

        <!-- Warning -->
        <Note
          v-if="warning"
          variant="warning"
          class="my-3"
        >
          {{ warning }}
        </Note>

        <!-- Final -->
        <FormButton
          text="Upload Logo"
          :disabled="state === PageState.loading || !(!!file)"
          :isLoading="state === PageState.loading"
        />
        <div v-if="error">
          <div class="text-center text-error">
            {{ error }}
          </div>
        </div>
      </div>
    </form>
  </FormWrapper>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import { PageState } from '@/types';
import FormWrapper from '@/components/form_wrapper.vue';
import FormHeader from '@/components/form_header.vue';
import FormButton from '@/components/form_button.vue';
import Note from '@/components/note.vue';
import uploadLogo from './api/upload_logo';

const file = ref<File>();
const error = ref('');
const state = ref<PageState>(PageState.loaded);
const url = ref('');
const isDropZoneActive = ref(false);

const route = useRoute();
const router = useRouter();

const warning = ref('');

async function handleFile(uploadedFile: File) {
  file.value = undefined;
  url.value = '';
  error.value = '';

  const imageRegex = /^image\/\w+$/;
  if (!uploadedFile) {
    error.value = 'No image uploaded. Please choose an image.';
    return;
  }

  if (!uploadedFile.type.match(imageRegex)) {
    error.value = 'Unacceptable File Format. Choose another image.';
    return;
  }

  const computedUrl = URL.createObjectURL(uploadedFile);

  const img = new Image();

  img.onload = () => {
    const uploadedRatio = img.width / img.height;
    const idealRatio = 208 / 128;

    if (uploadedRatio !== idealRatio) {
      warning.value = 'Your logo does not fit our recommendations and may appear distorted. Do you want to proceed?';
    }
  };

  img.src = computedUrl;
  file.value = uploadedFile;
  url.value = computedUrl;
}

function handleInputChange(event: Event) {
  const uploadedFile = ((event.target as HTMLInputElement).files as FileList)[0];
  handleFile(uploadedFile);
}

function dropHandler(e: DragEvent) {
  const uploadedFile = (e.dataTransfer as DataTransfer).files[0];
  handleFile(uploadedFile);
  isDropZoneActive.value = false;
}

function submit() {
  try {
    state.value = PageState.loading;
    const { projectId } = route.params;
    uploadLogo(projectId as string, file.value as File);
    state.value = PageState.loaded;

    router.push({ name: Pages.dashboardSettingsLogo });
  } catch (err: any) {
    state.value = PageState.loaded;
    error.value = err.response?.data?.message || 'Something went wrong';
  }
}

</script>

<style lang="scss" scoped>
  .dropZone {
    @apply text-center py-14 px-6 rounded;

    &--active {
      @apply bg-primary-100;
    }

    &--inactive {
      @apply bg-gray-100;
    }
  }
</style>
