<template>
  <Page>
    <Table
      :items="projectUsers"
      :state="state"
      :headers="tableHeaders"
      item-type="User"
      table-id="users-table"
      hideHeader
      class="team-table"
    >
      <template #tableHeader>
        <div>
          <h2 class="text-lg font-bold">
            Team Members
          </h2>
          <p>
            Invite team members to your account and easily manage their access.
          </p>
        </div>

        <div class="my-4">
          <TbDrawer>
            <template #default="{ toggleDrawer }">
              <div class="flex flex-col h-full">
                <FormSendInvite
                  @cancel="toggleDrawer()"
                  @submit="
                    toggleDrawer();
                    loadUsers();
                  "
                />
              </div>
            </template>
            <template #toggle="{ toggleDrawer }">
              <button
                class="mt-2 button sm:mt-0 button--primary button--sm"
                @click="toggleDrawer"
              >
                <span class="flex items-center gap-2">
                  <TbIcon icon="user-plus" />
                  Invite
                </span>
              </button>
            </template>
          </TbDrawer>
        </div>
      </template>

      <template #action="{ item, toggleDropdown }">
        <div
          :class="{'owner-options': isOwner(item)}"
          class="flex flex-col h-full gap-1"
        >
          <TbDrawer>
            <template #default="{ toggleDrawer }">
              <div class="overflow-hidden whitespace-normal">
                <FormEditAccess
                  :user="item"
                  @cancel="
                    toggleDrawer();
                    toggleDropdown();
                  "
                  @submit="
                    toggleDrawer();
                    toggleDropdown();
                    loadUsers();
                  "
                />
              </div>
            </template>

            <template #toggle="{ toggleDrawer }">
              <button
                :disabled="isOwner(item)"
                class="button button--block button--sm text-left"
                @click="toggleDrawer"
              >
                Edit
                <span v-if="item.inviteId"> Invite </span>
                <span v-else> Access </span>
              </button>
            </template>
          </TbDrawer>

          <FormArchiveUser
            :user="item"
            @archive="
              toggleDropdown();
              loadUsers();
            "
          >
            <template #action="{ deleteHandler }">
              <button
                :disabled="isOwner(item)"
                class="button button--block text-error-900 button--sm text-left"
                @click="deleteHandler()"
              >
                Remove Access
              </button>
            </template>
          </FormArchiveUser>
        </div>
      </template>

      <template #row(email)="{ item }">
        <div
          class="text-sm sm:text-base "
        >
          <p
            class="font-[700] primary"
          >
            {{ item.name || item.email }}
          </p>
          <p
            class="text-xs sm:text-sm"
          >
            {{ item.inviteId ? 'Pending invitation': item.email }}
          </p>
        </div>
      </template>

      <template #row(roleName)="{ item }">
        <TbChip
          :text="screenWidth < 520 ? roleNameAbbr(item.roleName) : item.roleName"
          :color="roleToColorMap[item.roleName]"
          :small="screenWidth < 520"
          class="font-bold"
        />
      </template>
    </Table>
    <div
      v-if="state === 'error' && error"
      class="p-4 text-error"
    >
      {{ error }}
    </div>
  </Page>
</template>

<script setup lang="ts">
import { onActivated, ref } from 'vue';
import { useWindowSize } from 'vue-window-size';
import { TbDrawer, TbIcon, TbChip } from '@/components/tasty_bistro';
import Table from '@/components/table.vue';
import { useRoute } from 'vue-router';
import { PageState } from '@/types';
import { roleToColorMap } from '@/helpers/category_color_mapper';
import { roleNameAbbr } from '@/helpers/role_name_abbr_mapper';

import Page from '../../components/page.vue';

import { getProjectUsers, User } from './api/get_active_members';
import FormEditAccess from './forms/edit_access.vue';
import FormArchiveUser from './forms/archive.vue';
import FormSendInvite from './forms/send_invite.vue';

const projectUsers = ref<User[]>([]);
const error = ref('');
const state = ref(PageState.loading);
const route = useRoute();
const { width: screenWidth } = useWindowSize();

const { projectId } = route.params;
const tableHeaders = [
  {
    title: 'Member',
    key: 'email',
  },
  {
    title: 'Role',
    key: 'roleName',
  },
];

async function loadUsers() {
  try {
    projectUsers.value = await getProjectUsers(projectId as string);
    state.value = PageState.loaded;
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
    state.value = PageState.error;
  }
}

function isOwner(user: User) {
  return user.roleName.toLowerCase() === 'owner';
}

onActivated(async () => {
  loadUsers();
});
</script>

<style lang="scss" scoped>
  .team-table :deep tr > td:first-child {
    @apply w-[165px] sm:w-[300px];
  }

  .bg-accent {
    background-color: hsla(188, 56%, 60%, 1);
  }

  .team-table :deep tbody tr:first-child td:has(.owner-options) {
    @apply hidden;
  }
</style>
