import http, { Http } from '@/lib/http';

export interface PaymentPlan {
  installments: number;
  frequency: 'monthly';
}

export interface ActiveCheckout {
  name: string;
  totalInCents: number;
}

export interface PaymentStack {
  id: string;
  name: string;
  paymentPlans: PaymentPlan[];
  activeCheckouts: ActiveCheckout[];
  totalCount: number;
  createdAt: Date;
  ppmEnabled: boolean,
  lnplEnabled: boolean,
}

export interface StackResponse {
  count: number;
  data: PaymentStack[];
}

export interface GetPaymentStackParams {
  pageSize: number;
  currentPage: number;
  name: string;
}

interface Data {
  projectId: string;
  params: GetPaymentStackParams
}

function getPaymentStacksCtor(http: Http) {
  return async function getPaymentPlanGroups({ projectId, params }: Data): Promise<StackResponse> {
    const { data } = await http.get(`/projects/${projectId}/payment-settings`, { params });
    return data;
  };
}

export const getPaymentStacks = getPaymentStacksCtor(http);
