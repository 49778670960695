<template>
  <div class="relative flex flex-row flex-1">
    <div class="relative z-10 flex overflow-y-auto left-card">
      <slot />
    </div>

    <div class="relative flex overflow-hidden right-card">
      <div class="absolute z-10 p-4 bg-white top-20 left-20 card card--heavy w-96">
        <p>
          <strong class="font-bold">Hey there!</strong>
          <br>
          Thank you for choosing Elective.
          You are only moments away from supercharging your sales. Have any questions? Please reach out!
        </p>

        <p class="font-medium" />
        <div class="flex flex-row pt-4">
          <img src="./assets/john-najafi-icon.svg">
          <div class="pl-4">
            <h3 class="text-sm font-semibold">
              John Najafi
            </h3>

            <p class="text-sm">
              Founder + CEO, Elective
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>

  .left-card {
    @apply
      w-full
      lg:w-[60%]
      bg-gray-50;
  }

  .right-card {
    background-image: url('./assets/layout-background.svg');
    background-size: 38vw;
    @apply
      hidden
      lg:block
      lg:w-[40%]
      bg-no-repeat
      bg-bottom
      bg-gray-200;
  }
</style>
