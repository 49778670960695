import { RoleControlledActionConfiguration } from '@/types';

export const roleConfig: RoleControlledActionConfiguration = {
  dashboardProfile: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: false },
    sales_representative: { restricted: false, hidden: false },
    support_specialist: { restricted: false, hidden: false },
    view_only: { restricted: false, hidden: false },
  },
  paymentDetails: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: true },
    sales_representative: { restricted: false, hidden: true },
    support_specialist: { restricted: false, hidden: true },
    view_only: { restricted: false, hidden: true },
  },
  userManagement: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: true },
    sales_representative: { restricted: false, hidden: true },
    support_specialist: { restricted: false, hidden: true },
    view_only: { restricted: false, hidden: true },
  },
  dashboardIntegrations: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: true },
    sales_representative: { restricted: false, hidden: true },
    support_specialist: { restricted: false, hidden: true },
    view_only: { restricted: false, hidden: true },
  },
  dashboardSettingsLogo: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: true },
    sales_representative: { restricted: false, hidden: true },
    support_specialist: { restricted: false, hidden: true },
    view_only: { restricted: false, hidden: true },
  },
  dashboardNotificationsSubscribers: {
    owner: { restricted: false, hidden: false },
    admin: { restricted: false, hidden: false },
    sales_manager: { restricted: false, hidden: true },
    sales_representative: { restricted: false, hidden: true },
    support_specialist: { restricted: false, hidden: true },
    view_only: { restricted: false, hidden: true },
  },

};
