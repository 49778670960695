<template>
  <form
    class="mt-4"
    @submit.prevent="submit"
  >
    <TbSelect
      v-model="accountType"
      label="Bank Account Type*"
      class="mb-2"
      :options="accountTypes"
      :errors="accountTypeError && [accountTypeError]"
    />

    <TbSelect
      v-model="currencyCode"
      label="Bank Account Currency*"
      class="mb-2"
      :options="currencyValues"
      :errors="currencyCodeError && [currencyCodeError]"
    />

    <TbInput
      v-model.trim="institutionNumber"
      label="Institution Number*"
      class="mb-2"
      type="number"
      :errors="institutionNumberError && [institutionNumberError]"
      @change="handleInstitutionNumberChange"
    />

    <TbInput
      v-model.trim="transitNumber"
      label="Transit Number*"
      class="mb-2"
      type="number"
      :errors="transitNumberError && [transitNumberError]"
      @change="handleTransitNumberChange"
    />

    <TbInput
      v-model.trim="accountNumber"
      label="Account Number*"
      class="mb-2"
      type="number"
      :errors="accountNumberError && [accountNumberError]"
    />

    <TbInput
      v-model.trim="accountHolderName"
      label="Name of Account Holder*"
      class="mb-2"
      type="text"
      :errors="accountHolderNameError && [accountHolderNameError]"
    />

    <FormButton
      text="Add Payout Method"
      :disabled="props.state === PageState.loading"
    />

    <div v-if="error">
      <div class="text-center text-error">
        {{ props.error }}
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { object, string, InferType } from 'yup';
import { useForm, useField } from 'vee-validate';
import { TbInput, TbSelect } from '@/components/tasty_bistro';
import FormButton from '@/components/form_button.vue';
import { watch } from 'vue';
import { PageState } from '@/types';
import { PaymentDetailsCA } from '../types';

interface Props {
  data: PaymentDetailsCA;
  error: string;
  state: PageState;
  namePlaceholder: string;
}

const props = defineProps<Props>();
const emit = defineEmits(['submit']);

const currencyValues = [
  { name: 'USD - United States Dollar', value: 'USD' },
  { name: 'CAD - Canadian Dollar', value: 'CAD' },
];

const accountTypes = [
  { name: 'Checking', value: 'checking' },
  { name: 'Savings', value: 'savings' },
];

const paymentDetailsSchema = object({
  currencyCode: string().required('Required'),
  institutionNumber: string().required('Required').length(3, 'Must be 3 digits long'),
  transitNumber: string().required('Required').length(5, 'Must be 5 digits long'),
  accountNumber: string().required('Required'),
  accountHolderName: string().required('Required'),
  accountType: string().required('Required'),
});

interface PaymentDetails extends InferType<typeof paymentDetailsSchema> {}

const { handleSubmit } = useForm<PaymentDetails>({
  validationSchema: paymentDetailsSchema,
  initialValues: {
    currencyCode: props.data?.currencyCode || '',
    institutionNumber: props.data?.institutionNumber || '',
    transitNumber: props.data?.transitNumber || '',
    accountNumber: props.data?.accountNumber || '',
    accountHolderName: props.data?.accountHolderName || props.namePlaceholder || '',
    accountType: props.data?.accountType || 'checking',
  },
});

const { value: currencyCode, errorMessage: currencyCodeError } = useField('currencyCode');
const { value: accountType, errorMessage: accountTypeError } = useField('accountType');
const { value: accountNumber, errorMessage: accountNumberError } = useField('accountNumber');
const { value: accountHolderName, errorMessage: accountHolderNameError } = useField('accountHolderName');
const {
  value: institutionNumber,
  errorMessage: institutionNumberError,
  handleChange: handleInstitutionNumberChange,
} = useField('institutionNumber');
const { value: transitNumber, errorMessage: transitNumberError, handleChange: handleTransitNumberChange } = useField('transitNumber');

watch(() => props.namePlaceholder, (newValue) => {
  if (!accountHolderName.value) {
    accountHolderName.value = newValue;
  }
});

const submit = handleSubmit(async (values) => {
  emit('submit', { ...values, countryCode: 'CA' });
});
</script>
