<template>
  <router-view v-slot="{ Component }">
    <Suspense>
      <div class="h-full min-h-full">
        <component :is="Component" />
      </div>
    </Suspense>
  </router-view>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import { OnboardingStatus } from '@/pages/app/project/types';
import { getState } from './api';

const route = useRoute();
const router = useRouter();

const { status } = await getState(route.params.projectId as string);

if (status === OnboardingStatus.pending || status === OnboardingStatus.resubmissionRequested) {
  router.replace({ name: Pages.onboardingProgress });
} else if (status === OnboardingStatus.submitted) {
  router.replace({ name: Pages.onboardingSubmitted });
} else if (status === OnboardingStatus.declined) {
  router.replace({ name: Pages.onboardingDeclined });
} else if (status === OnboardingStatus.approved) {
  router.replace({ name: Pages.dashboardHome });
} else {
  router.replace({ name: Pages.crash });
}
</script>
