import http, { Http } from '@/lib/http';

interface Data {
  projectId: string;
  checkoutId: string;
}

function archiveCheckoutCtor(http: Http) {
  return async ({ projectId, checkoutId }: Data) => {
    await http.delete<Promise<void>>(`/projects/${projectId}/checkouts/${checkoutId}`);
  };
}

export const archiveCheckout = archiveCheckoutCtor(http);
