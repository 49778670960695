<template>
  <div>
    <label
      v-if="label"
      class="label"
    >
      {{ label }}

      <Tooltip
        v-if="tooltipText"
        class="py-0"
        :info="tooltipText"
      />
    </label>

    <div class="flex items-center items-baseline justify-base">
      <input
        class="relative input"
        :class="{ 'input-error' : errors.length, [inputClass]: !!inputClass, [color]: color }"
        :value="modelValue"
        v-bind="$attrs"
        :type="inputHideValue ? 'password' : props.type === 'password' ? 'string' : props.type"
        @input="$emit('update:modelValue', handleInputChange($event))"
      >

      <button
        v-if="toggleVisibility"
        class="relative bg-gray-300 flex items-center button button--icon self-center ml-2"
        type="button"
        @click="toggleInputType"
      >
        <EyeIcon
          v-if="inputHideValue"
          class="w-6 h-6 text-gray-500"
          aria-hidden="true"
        />

        <EyeSlashIcon
          v-else
          class="w-6 h-6 text-gray-500"
          aria-hidden="true"
        />
      </button>
    </div>

    <div
      v-if="errors && errors.length !== 0 || hint"
      class="flex flex-col-reverse justify-between pb-2 mt-1 ml-2 mr-2 sm:flex-row"
    >
      <div v-if="errors && errors.length">
        <ul class="list-none left-6">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="text-xs text-error"
          >
            {{ error }}
          </li>
        </ul>
      </div>

      <div
        v-if="hint"
        class="text-xs right-6 text-primary"
      >
        {{ hint }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid';
import Tooltip from '../tooltip/tooltip.vue';

type ColorOptions = 'white' | 'grey';

export default defineComponent({
  components: { EyeSlashIcon, EyeIcon, Tooltip },
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: '',
    },

    color: {
      type: String as PropType<ColorOptions>,
      default: 'grey',
    },

    inputClass: {
      type: String,
      default: '',
    },

    hint: {
      type: String,
      default: '',
    },

    errors: {
      type: [Array, String],
      default: () => [],
    },

    modelValue: {
      type: [String, Number],
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    toggleVisibility: {
      type: Boolean,
      default: false,
    },

    tooltipText: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const inputHideValue = ref(props.toggleVisibility);

    const toggleInputType = () => {
      inputHideValue.value = !inputHideValue.value;
    };

    const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

    return {
      handleInputChange,
      toggleInputType,
      inputHideValue,
      props,
    };
  },
});
</script>
