import { AxiosInstance } from 'axios';
import http from '@/lib/http';

export interface ConnectedZaps {
  eventName: string;
  count: number;
}

function getConnectedZapsCtor(http: AxiosInstance) {
  return async (projectId: string): Promise<ConnectedZaps[]> => {
    const { data } = await http.get(`/projects/${projectId}/webhooks/connected`);

    return data;
  };
}

export const getConnectedZaps = getConnectedZapsCtor(http);
