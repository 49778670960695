<template>
  <Page>
    <template #title>
      Customers
    </template>

    <Table
      date-key="createdAt"
      :items="students"
      :state="state"
      :total-item-count="studentsCount"
      :items-per-page="pageSize"
      item-type="Customer"
      :count="{ singular: 'customer', plural: 'customers' }"
      expandable
      :headers="tableHeader"
      hideHeader
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div class="flex flex-row flex-wrap gap-2">
          <div class="w-full max-w-xs">
            <TbInput
              v-model="filter.textFilter"
              label="Search"
              class="flex-1"
              placeholder="Name / Email"
            />
          </div>

          <div class="self-end">
            <RoleControlledAction
              v-slot="{wrapper, restricted}"
              :user-role="roleName"
              :config-object="roleConfig.exportStudents"
            >
              <button
                :class="{ 'custom-disabled': restricted}"
                class="mt-2 button button--primary button--sm lg:mt-0 "
                @click="wrapper(()=> exportCsv())"
              >
                <span class="flex items-center gap-2">
                  Export to CSV
                  <TbDownloadIcon class="w-4 h-4 text-white" />
                </span>
              </button>
            </RoleControlledAction>
          </div>
        </div>
      </template>
      <template #row(studentPhoneNumber)="{ item }">
        <p v-if="item.studentPhoneNumber && item.studentPhoneCountryCode">
          {{ formatPhoneNumber(item.studentPhoneNumber, item.studentPhoneCountryCode) }}
        </p>
      </template>
    </Table>
  </Page>
  <QuickStartGuideLink />

  <TbOverlay
    :show="csvState === PageState.loading"
    persistent
  >
    <div class="flex items-center justify-center w-full h-full">
      <TbLoading />
    </div>
  </TbOverlay>
</template>

<script setup lang="ts">
import {
  ref, Ref, onActivated, reactive, watch, inject,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  TbInput, TbLoading, TbDownloadIcon, TbOverlay,
} from '@/components/tasty_bistro';
import { debounce } from 'lodash';
import { Pages } from '@/router';
import { formatPhoneNumber } from '@/helpers/format_phone_number';
import { downloadFile } from '@/helpers/download_file';
import { formatDate } from '@/filters/date';
import Table from '@/components/table.vue';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import { PageState } from '@/types';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import Page from '../components/page.vue';
import { getStudents } from './api/get_students';
import { getStudentsDownload } from './api/get_students_download';
import { roleConfig } from './role_configurations';

const route = useRoute();
const projectId = route.params.projectId as string;
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const students: Ref<any> = ref([]);

const tableHeader = [
  {
    title: 'Created At',
    key: 'createdAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Name',
    key: 'studentName',
    goto: { page: Pages.dashboardStudent, idKey: 'id' },
  },
  {
    title: 'Email',
    key: 'studentEmail',
  },
  {
    title: 'Phone',
    key: 'studentPhoneNumber',
  },
];

const state = ref(PageState.loaded);
const csvState = ref(PageState.loaded);

const filter = reactive({
  textFilter: '',
});
const pageSize = 20;
const currentPage = ref(0);
const studentsCount = ref<number>(0);

const exportCsv = async () => {
  try {
    csvState.value = PageState.loading;
    const file = await getStudentsDownload({
      projectId,
      filter: { textFilter: filter.textFilter },
    });
    downloadFile({ file, fileName: 'elective_students.csv' });
    csvState.value = PageState.loaded;
  } catch (error) {
    csvState.value = PageState.error;
  }
};

const loadStudents = async () => {
  try {
    state.value = PageState.loading;
    const data = await getStudents({
      projectId,
      filter: {
        textFilter: filter.textFilter,
        currentPage: currentPage.value,
        pageSize,
      },
    });
    studentsCount.value = data.count;
    students.value = data.students;
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadStudents();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadStudents();
};

onActivated(async () => {
  await loadStudents();
});

watch(() => filter.textFilter, debounce(async () => {
  currentPage.value = 0;
  await loadStudents();
}, 500));
</script>

<style lang="scss" scoped>
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
