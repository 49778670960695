<template>
  <div class="flex flex-col items-center min-h-full bg-white sm:bg-primary">
    <div
      v-if="!hideNavBar"
      class="flex justify-center w-full p-4 shadow-md bg-primary-900"
    >
      <div class="flex items-center justify-between w-full max-w-7xl">
        <div class="h-12 w-12">
          <!-- <router-link :to="{ name: Pages.dashboardHome }"> -->
          <TbLetterLogo inverted />
          <!-- </router-link> -->
        </div>

        <div>
          <router-link
            class="text-white"
            :to="{ name: Pages.onboarding }"
          >
            Close
          </router-link>
        </div>
      </div>
    </div>

    <div class="flex justify-center w-full sm:p-4">
      <div
        class="w-full max-w-lg p-8 mt-4 bg-white sm:mt-20"
        :class="{ 'card': screenWidth >= 640 }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TbLetterLogo } from '@/components/tasty_bistro';
import { useWindowSize } from 'vue-window-size';
import { Pages } from '../router';

interface Props {
  hideNavBar?: boolean;
}

withDefaults(defineProps<Props>(), {
  hideNavBar: false,
});

const { width: screenWidth } = useWindowSize();
</script>
