<template>
  <Page class="overview-page">
    <template
      v-if="analyticsEnabled || !ppmEnabled"
      #title
    >
      Welcome back 👋
    </template>

    <template v-if="missingQuizId !== null">
      <QuizAgreement
        :agreement-id="missingQuizId"
        @close-modal="refreshAppState"
      />
    </template>

    <template v-if="showAnalytics({ roleName, analyticsEnabled })">
      <Analytics />
    </template>

    <template v-else>
      <WelcomeBanner v-if="ppmEnabled" />
      <QuickStartGuide />
    </template>
  </Page>
  <QuickStartGuideLink v-if="showAnalytics({ roleName, analyticsEnabled })" />
</template>

<script setup lang="ts">
import {
  inject, onMounted, Ref, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { RoleControlConfig } from '@/types';
import { AppState, getAppState } from '@/pages/app/api/get_app_state';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import Page from '../components/page.vue';
import Analytics from './components/analytics.vue';
import QuickStartGuide from './components/quick_start_guide.vue';
import WelcomeBanner from './components/welcome_banner.vue';
import { roleConfig } from './role_configurations';
import QuizAgreement from './components/quiz_agreement/quiz_agreement.vue';

const route = useRoute();
const projectId = route.params.projectId as string;
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const missingQuizId = ref<string | null>(null);

const { analyticsEnabled, ppmEnabled } = appState.value.projects[projectId].features;

const roleName = appState.value.projects[projectId].currentUserRoleName;

function showAnalytics({ roleName, analyticsEnabled }: {roleName: string, analyticsEnabled: boolean}) {
  const defaultPermission: RoleControlConfig = { hidden: true, restricted: true };
  const config = roleConfig.analytics[roleName] ?? defaultPermission;
  return analyticsEnabled && !(config.hidden || config.restricted);
}

function getMissingQuizId() {
  const projectConfig = appState.value.projects[projectId];
  const missingQuizzes = projectConfig.projectMissingAgreements.filter((agreement) => agreement.type === 'terms_of_service_quiz');
  missingQuizId.value = missingQuizzes[missingQuizzes.length - 1]?.agreementId ?? null;
}

async function refreshAppState() {
  missingQuizId.value = null;
  appState.value = await getAppState();
  getMissingQuizId();
}

onMounted(() => {
  getMissingQuizId();
});
</script>
