import http, { Http } from '@/lib/http';

export interface Configs {
  maxInstallments: number;
}

function getPaymentPlanGroupConfigCtor(http: Http) {
  return async function getPaymentPlanGroups(projectId: string): Promise<Configs> {
    const { data } = await http.get(`/projects/${projectId}/ppm/payment-plan-groups/configs`);
    return data;
  };
}

export const getPaymentPlanGroupConfig = getPaymentPlanGroupConfigCtor(http);
