<template>
  <a
    :href="`https://form.typeform.com/to/${props.formId}`"
    target="_blank"
    class="button shrink-0 font-semibold"
    :class="isDisabled ? 'bg-gray-200 cursor-not-allowed shadow-lg shadow-inner text-gray-400' : 'button--primary'"
  >
    <TbIcon
      v-if="isLoading"
      icon="spinner"
      class="text-xl text-white animate-spin"
    />
    <span v-else>
      {{ (isDisabled && props.disabledText) ? props.disabledText : props.buttonText }}
    </span>
  </a>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { TbIcon } from '@/components/tasty_bistro';
import { isOnWaitlist } from './api/is_on_waitlist';

interface Props {
  buttonText: string;
  formId: string;
  userEmail: string;
  disabledText?: string;
}

const props = defineProps<Props>();

const isDisabled = ref<Boolean>(false);
const isLoading = ref<Boolean>(false);

onMounted(async () => {
  try {
    isLoading.value = true;
    const isOnWaitlistResponse = await isOnWaitlist({ formId: props.formId, userEmail: props.userEmail });
    isDisabled.value = isOnWaitlistResponse;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
});
</script>
