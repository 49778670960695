import http, { Http } from '@/lib/http';

interface GetCancellationDataResponse {
  cancellationAmountInCents: number;
  isFundedCancellation: boolean;
  merchantAccountLastFourDigits: string;
}

interface Params {
  projectId: string;
  orderId: string;
}

function getCancellationDataCtor(http: Http) {
  return async ({ orderId, projectId }: Params): Promise<GetCancellationDataResponse> => {
    const { data } = await http.get(`/projects/${projectId}/orders/cancellation-data`, {
      params: { orderId },
    });

    return data;
  };
}

export const getCancellationData = getCancellationDataCtor(http);
