<template>
  <div v-if="props.showModal">
    <div
      class="modal"
      @click="emitCloseModal"
    >
      <slot />
      <button
        class="modal-close"
        @click="emitCloseModal"
      >
        &times;
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">

const props = defineProps<{ showModal: boolean }>();
const emit = defineEmits(['close-modal']);

function emitCloseModal() {
  emit('close-modal');
}
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-close {
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 2rem;
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
  }
</style>
