import { AxiosInstance } from 'axios';
import http from '@/lib/http';

interface Data {
  projectId: string;
  eventName: string;
  form: any;
}

function triggerTestZapCtor(http: AxiosInstance) {
  return async ({ form, eventName, projectId }: Data): Promise<void> => {
    const { data } = await http.post(`/projects/${projectId}/webhooks/trigger`, {
      eventName,
      payload: form,
    });
    return data;
  };
}

export const triggerTestZap = triggerTestZapCtor(http);
