<template>
  <Page>
    <template #title>
      Payouts
    </template>

    <Table
      item-type="Payout"
      date-key="payoutDate"
      :items="settlements"
      :total-item-count="settlementsCount"
      :items-per-page="pageSize"
      :state="state"
      :headers="tableHeaders"
      :count="{ singular: 'payout', plural: 'payouts' }"
      hideHeader
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div class="flex flex-col gap-6">
          <div
            v-if="metrics && origin === 'lnpl'"
            class="grid grid-cols-2 gap-x-4 gap-y-4 sm:flex sm:gap-12 "
          >
            <div class="flex flex-col">
              <span class="metrics-heading">Total Paid Out</span>
              <div class="text-lg">
                <span class="ml-1 currency">
                  {{ metrics.totalPaidOut.currency + currencyInCents(metrics.totalPaidOut.amountInCents) }}
                </span>
              </div>
            </div>

            <div class="flex flex-col">
              <span class="metrics-heading">Total Balance</span>
              <div class="text-lg">
                <span class="ml-1 currency">
                  {{ metrics.totalBalance.currency + currencyInCents(metrics.totalBalance.amountInCents) }}
                </span>
              </div>
            </div>

            <div class="flex flex-col">
              <span class="metrics-heading">Next Payout</span>
              <div class="text-lg">
                <span class="ml-1 currency">
                  {{ metrics.nextPayout.currency + currencyInCents(metrics.nextPayout.amountInCents) }}
                </span>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap gap-2">
            <TbDateRangePicker
              :dates="dates"
              @selected-date-range="setDateRange"
            />

            <div v-if="ppmEnabled && arePpmSettlementsAvailable">
              <TbSelect
                v-model="origin"
                :options="selectOptionsOrigin"
                :default-options="{ name: 'Funding', value: OrderOrigin.lnpl }"
                label="View"
                class="w-full sm:w-48"
              />
            </div>

            <div v-if="availableCurrencies && availableCurrencies.length > 1">
              <TbSelect
                v-model="currency"
                :options="selectOptionsCurrency"
                :default-options="{ name: 'USD', value: CurrencyCode.USD }"
                label="Currency"
                class="w-full sm:w-48"
              />
            </div>
          </div>
        </div>
      </template>

      <template
        v-if="origin === 'ppm'"
        #topRowInfo
      >
        <p class="text-center font-semibold">
          <TbIcon
            icon="exclamation-circle"
            class="mr-2"
          />As of May 14, 2024, all payout information can be found directly in your connected payment gateway.
        </p>
      </template>

      <template #row(period)="{ item }">
        <div class="flex flex-col">
          <div
            v-if="!(item.origin === 'In-House' && item.status === 'Active')"
            class="contents"
          >
            <span>{{ formatDate(item.period.start, 'MMM dd, yyyy') }}</span>
            <span>{{ formatPeriodEndDate(item as Settlement, 'MMM dd, yyyy') }}</span>
          </div>
          <span v-else>Current</span>
        </div>
      </template>

      <template #row(payoutDate)="{ item }">
        <div class="flex flex-col">
          <span v-if="item.origin === 'In-House' && item.status === 'Active'">N/A</span>
          <span v-else>{{ formatDate(item.payoutDate, 'MMM dd, yyyy') }}</span>
        </div>
      </template>

      <template #row(id)="{ value }">
        <div class="truncate w-36">
          {{ value }}
        </div>
      </template>

      <template #row(status)="{ value }">
        <div>
          <div
            class="inline-block w-2 h-2 mr-1 rounded-full"
            :class="payoutStatusToColorMap[value]"
          />
          {{ value }}
        </div>
      </template>

      <template #row(origin)="{ value }">
        <OriginChip :title="value" />
      </template>

      <template #row(sales)="{ item }">
        <span class="currency">{{ item.currency + currencyInCents(item.sales) }}</span>
      </template>

      <template #action="{ item, toggleDropdown }">
        <div class="flex flex-col gap-1">
          <RoleControlledAction
            v-slot="{wrapper, restricted}"
            :user-role="roleName"
            :config-object="roleConfig.exportSettlements"
          >
            <button
              :class="{ 'custom-disabled': restricted}"
              class="button button--sm button--block text-left"
            >
              <span
                class="flex items-center gap-2"
                @click="wrapper(() => {exportSettlementCsv(item.id); toggleDropdown()});"
              >
                Export to CSV
              </span>
            </button>
          </RoleControlledAction>
          <button
            class="button button--sm button--block text-left flex gap-2 items-center"
            @click="toggleDropdown(); copyPayoutId(item.id)"
          >
            Copy Payout ID
            <TbCopyIcon class="w-4 h-4" />
          </button>
        </div>
      </template>
    </Table>

    <TbNotification
      :show="copyUrlNotification"
      title="Success 🎉"
      :message="copyUrlNotificationText"
      card-class="text-white bg-success"
      @close="copyUrlNotification = false"
    />
  </Page>
  <QuickStartGuideLink />

  <TbOverlay
    :show="csvState === PageState.loading"
    persistent
  >
    <div class="flex items-center justify-center w-full h-full">
      <TbLoading />
    </div>
  </TbOverlay>
</template>

<script setup lang="ts">
import {
  ref, Ref, onActivated, watch, inject,
} from 'vue';
import {
  TbDateRangePicker, TbLoading, TbOverlay, TbSelect, TbNotification, TbCopyIcon, TbIcon,
} from '@/components/tasty_bistro';
import {
  subMonths, addWeeks, differenceInBusinessDays, parseISO,
} from 'date-fns';
import { useClipboard } from '@vueuse/core';
import { AppState } from '@/pages/app/api/get_app_state';
import { useRoute } from 'vue-router';
import OriginChip from '@/components/origin_chip.vue';
import Table from '@/components/table.vue';
import { PageState, OrderOrigin, CurrencyCode } from '@/types';
import { formatDate } from '@/filters/date';
import { currencyInCents } from '@/filters/currency';
import { downloadFile } from '@/helpers/download_file';
import { payoutStatusToColorMap } from '@/helpers/category_color_mapper';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { getSettlementDownload } from './api/get_settlement_download';
import { getSettlements, SettlementMetrics, Settlement } from './api/get_settlements';
import Page from '../components/page.vue';
import { roleConfig } from './role_configurations';

type Dates = [Date, Date]

const settlements: Ref<any> = ref([]);
const availableCurrencies = ref<CurrencyCode[]>();
const dates = ref<Dates>([subMonths(new Date(), 3), addWeeks(new Date(), 2)]);
const state = ref(PageState.loaded);
const csvState = ref(PageState.loaded);
const pageSize = 20;
const currentPage = ref(0);
const settlementsCount = ref(0);
const origin = ref(OrderOrigin.lnpl);
const currency = ref(CurrencyCode.USD);
const selectedCurrency = ref();
const metrics = ref<SettlementMetrics>();
const copyUrlNotification = ref(false);
const copyUrlNotificationText = ref('');
const arePpmSettlementsAvailable = ref(false);
const route = useRoute();
const projectId = route.params.projectId as string;

const selectOptionsOrigin = [
  { name: 'In-House', value: OrderOrigin.ppm },
];

const selectOptionsCurrency = [
  { name: 'CAD', value: CurrencyCode.CAD },
];

const tableHeaders = [
  {
    title: 'Est. Payout Date',
    key: 'payoutDate',
  },
  {
    title: 'Capture Period',
    key: 'period',
  },
  {
    title: 'Payout ID',
    key: 'id',
  },
  {
    title: 'Type',
    key: 'origin',
  },
  {
    title: 'Sales',
    key: 'sales',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Refunds',
    key: 'refunds',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Adjustments',
    key: 'adjustments',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Fees',
    key: 'fees',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Payout',
    key: 'netSettled',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const { ppmEnabled } = appState.value.projects[projectId].features;
const roleName = appState.value.projects[projectId].currentUserRoleName;

function formatPeriodEndDate(item: Settlement, dateFormat: string): string {
  if (item.origin === 'Funding' || differenceInBusinessDays(parseISO(item.period.end), parseISO(item.period.start)) > 1) {
    return formatDate(item.period.end, dateFormat);
  }
  return '';
}
function currentCurrency() {
  return (availableCurrencies.value && availableCurrencies.value.length > 1) ? currency.value : selectedCurrency.value;
}

const loadSettlements = async () => {
  try {
    state.value = PageState.loading;

    const data = await getSettlements({
      projectId,
      filter: {
        startDate: dates.value[0],
        endDate: dates.value[1],
        pageSize,
        currentPage: currentPage.value,
        origin: origin.value,
        currency: currentCurrency(),
      },
    });
    settlements.value = data.payouts;
    settlementsCount.value = data.totalCount;
    availableCurrencies.value = data.currencies;
    selectedCurrency.value = data.selectedCurrency;
    metrics.value = data.metrics;
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

const getPpmSettlements = async () => {
  try {
    const data = await getSettlements({
      projectId,
      filter: {
        origin: OrderOrigin.ppm,
        currency: currentCurrency(),
      },
    });

    arePpmSettlementsAvailable.value = data.totalCount > 0;
  } catch (error) {
    console.error(error);
    arePpmSettlementsAvailable.value = false;
  }
};

const exportSettlementCsv = async (id: string) => {
  try {
    csvState.value = PageState.loading;
    const file = await getSettlementDownload({
      id,
      origin: origin.value,
      currency: currentCurrency(),
      projectId,
    });
    downloadFile({ file, fileName: `elective_settlements_${id || 'active'}.csv` });
    csvState.value = PageState.loaded;
  } catch (error) {
    csvState.value = PageState.error;
  }
};

const copyPayoutId = (id: string) => {
  copyUrlNotification.value = false;

  setTimeout(() => {
    const clipboard = useClipboard({ source: id || 'active_payout' });
    clipboard.copy();
    copyUrlNotificationText.value = 'Payout ID has been successfully copied to your clipboard';
    copyUrlNotification.value = true;
  }, 200);
};

onActivated(async () => {
  await loadSettlements();
  await getPpmSettlements();
});

const setDateRange = async (newDates: Dates) => {
  dates.value = newDates;
  await loadSettlements();
};

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadSettlements();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadSettlements();
};

watch(currency, async () => {
  await loadSettlements();
  await getPpmSettlements();
});

watch(origin, async () => {
  await loadSettlements();
});
</script>

<style lang="scss" scoped>
  .table {
    @apply bg-secondary-100 w-full
  }

  .metrics-heading {
    @apply text-base font-regular text-gray-500
  }

  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
