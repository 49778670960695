<template>
  <router-view v-slot="{ Component }">
    <component
      :is="Component"
      :data="businessOwnerData"
    />
  </router-view>
  <div class="text-center text-error">
    {{ error }}
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Pages } from '@/router';
import { getBusinessOwner } from './api';

const route = useRoute();
const router = useRouter();
const projectId = route.params.projectId as string;

const businessOwnerData = ref();

const error = ref('');

onMounted(async () => {
  try {
    const data = await getBusinessOwner(projectId);
    businessOwnerData.value = data;
    if (data) {
      router.replace({ name: Pages.businessOwnerReview });
    } else {
      router.replace({ name: Pages.businessOwnerForm });
    }
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
});
</script>
