<template>
  <Transition
    enter-active-class="transition-all"
    leave-active-class="transition-all"
    enter-from-class="translate-x-32 opacity-0 duration-200"
    leave-to-class="translate-x-32 opacity-0 duration-200"
  >
    <div
      v-if="show"
      class="fixed z-40 w-full max-w-sm p-4 ml-auto mr-auto right-8 card top-8"
      :class="cardClass"
    >
      <h5 class="text-base font-bold">
        {{ title }}
      </h5>

      <p class="text-sm">
        {{ message }}
      </p>

      <div class="flex justify-end actions">
        <button
          class="font-bold button button--sm button--text"
          @click="close"
        >
          Ok
        </button>
      </div>
      <Progress
        v-if="showProgress"
        :duration="5000"
        :childClass="props.progressClass"
        @finish="close"
      />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import Progress from '../progress/progress.vue';

 interface Props {
   show: boolean;
   title?: string;
   message: string;
   cardClass?: string;
   progressClass?: string;
   showProgress?: boolean;
 }

const props = withDefaults(defineProps<Props>(), {
  title: '',
  cardClass: 'bg-success-100',
  progressClass: 'bg-success-600',
  showProgress: false,
});

const emit = defineEmits(['close']);

function close() {
  emit('close');
}

let timeoutId: ReturnType<typeof setTimeout>;
watch(() => props.show, () => {
  clearTimeout(timeoutId);
  if (props.show) {
    timeoutId = setTimeout(close, 5000);
  }
});
</script>
