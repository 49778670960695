<template>
  <CheckoutForm
    action="create"
    :fn="createCheckoutFn"
    @cancel="$emit('cancel')"
  />
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import CheckoutForm from '../../components/checkout_form/index.vue';
import { createCheckout, CreateCheckoutParams } from '../api/create_checkout';

const route = useRoute();
const projectId = route.params.projectId as string;

const emit = defineEmits([
  'submit',
  'cancel',
]);

const createCheckoutFn = async (values: CreateCheckoutParams) => {
  await createCheckout({ projectId, form: values });
  emit('submit');
};

</script>
