import http, { Http } from '@/lib/http';

export interface Plan {
    installments: number;
    frequency: string;
}

export interface CreateStack {
  name: string;
  lnplEnabled: boolean;
  ppmEnabled: boolean;
  paymentPlans: Plan[];
}

interface Data {
  projectId: string;
  form: CreateStack;
}

function createStackCtor(http: Http) {
  return async function createProduct({ projectId, form }: Data): Promise<void> {
    await http.post(`/projects/${projectId}/payment-settings`, form);
  };
}

export const createStack = createStackCtor(http);
