<template>
  <div class="flex flex-col h-full">
    <h2 class="text-lg font-bold">
      Add Subscriber
    </h2>

    <form
      class="my-4"
      @submit.prevent="submit"
    >
      <TbInput
        v-model="email"
        label="Email"
        :errors="emailError && [emailError]"
      />

      <TbInput
        v-model="name"
        label="Name (Optional)"
        :errors="nameError && [nameError]"
      />

      <div
        v-if="error"
        class="text-center error text-error-900"
      >
        <small class="error">
          {{ error }}
        </small>
      </div>

      <div class="mt-4 action">
        <button
          class="button button--primary button--block"
          type="submit"
        >
          Add
        </button>

        <button
          class="mt-4 button button--text button--block"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { object, string, InferType } from 'yup';
import { useForm, useField } from 'vee-validate';
import { TbInput } from '@/components/tasty_bistro';
import { addSubscriber } from '../api';

const route = useRoute();
const projectId = route.params.projectId as string;

const emit = defineEmits([
  'submit',
  'cancel',
]);

const createSubscriberSchema = object({
  email: string().required('Required').email(),
  name: string(),
});

interface CreateProduct extends InferType<typeof createSubscriberSchema> {}

const { handleSubmit } = useForm<CreateProduct>({
  validationSchema: createSubscriberSchema,
});

const { value: email, errorMessage: emailError } = useField('email');
const { value: name, errorMessage: nameError } = useField('name');

const error = ref<string>('');
const submit = handleSubmit(async (values) => {
  try {
    await addSubscriber({ ...values, projectId });
    emit('submit');
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
});

async function cancel() {
  emit('cancel');
}
</script>
