<template>
  <TbDialog
    :show="props.show"
    @close="close"
  >
    <template #header>
      <h2 class="w-full text-xl font-bold text-primary text-center mb-6">
        Your checkout link is now live!
      </h2>
    </template>

    <div
      class="w-full flex items-end gap-2 text-primary mb-4"
      :class="emailError ? 'items-center' : 'items-end'"
    >
      <div class="basis-full">
        <TbInput
          v-model.trim="emailAddress"
          class="w-full"
          label="Email Address"
          type="email"
          :errors="emailError && [emailError]"
        />
      </div>
      <button
        class="button flex items-center gap-2 font-bold bg-gray-100 min-w-[35%]"
        @click="sendCheckoutLinkEmail"
      >
        <TbEnvelopeIcon class="w-4 h-4" />
        <span class="text-sm whitespace-nowrap">Send via email</span>
      </button>
    </div>

    <div
      class="w-full flex gap-2 text-primary mb-6"
      :class="phoneNumberError ? 'items-center' : 'items-end'"
    >
      <div class="basis-full">
        <TbInput
          v-model.trim="phoneNumber"
          class="w-full"
          label="Phone Number"
          type="tel"
          placeholder="+X (XXX) XXX-XXXX"
          :errors="phoneNumberError && [phoneNumberError]"
        />
      </div>
      <button
        class="button flex items-center justify-center gap-2 font-bold bg-gray-100 min-w-[35%]"
        @click="sendCheckoutLinkSms"
      >
        <TbIcon icon="fa-mobile-screen" />

        <span class="text-sm whitespace-nowrap">Send via SMS</span>
      </button>
    </div>

    <div class="overflow-hidden w-full text-primary">
      <h4 class="text-base font-bold mb-2">
        Copy Checkout Link
      </h4>

      <div class="flex items-center justify-between bg-gray-100 rounded-md gap-6 py-2 px-3">
        <span class="max-w-[21rem] truncate">{{ props.checkoutLink }}</span>
        <button
          class="flex items-center gap-2 font-bold"
          @click="copyCheckoutLink"
        >
          <TbCopyIcon class="w-4 h-4" />
          <span class="text-sm whitespace-nowrap">Copy Link</span>
        </button>
      </div>
    </div>

    <template #footer>
      <div>
        <div class="flex gap-4 actions">
          <button
            class="button font-semibold border-2 border-primary text-primary"
            @click="close"
          >
            Done
          </button>

          <button
            class="button button--primary whitespace-nowrap"
            @click="openCheckoutLink"
          >
            <span class="mr-2 font-bold">Go to Checkout</span>
            <TbIcon
              icon="arrow-up-right-from-square"
            />
          </button>
        </div>
      </div>
    </template>
  </TbDialog>

  <TbNotification
    :show="showSuccessNotification"
    title="Success 🎉"
    :message="successNotificationText"
    card-class="text-white bg-success"
    @close="showSuccessNotification = false"
  />
</template>

<script setup lang="ts">
import {
  TbInput, TbDialog, TbIcon, TbCopyIcon, TbNotification, TbEnvelopeIcon,
} from '@/components/tasty_bistro';
import { ref } from 'vue';
import { useClipboard } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { sendCheckoutLink } from '../api/send_checkout_link';
import { Student } from '../api/get_students';

const props = defineProps<{
  show: boolean,
  checkoutLink: string,
  invoiceId: string,
  student: Student;
}>();
const emit = defineEmits(['close-popup']);

const route = useRoute();
const projectId = route.params.projectId as string;

const showSuccessNotification = ref(false);
const successNotificationText = ref('');

const emailAddress = ref(props.student.studentEmail || '');
const phoneNumber = (props.student.phoneCountryCode && props.student.phoneNumber)
  ? ref(`+${props.student.phoneCountryCode}${props.student.phoneNumber}`)
  : ref('');
const phoneNumberError = ref('');
const emailError = ref('');

const sendCheckoutLinkSms = async () => {
  try {
    phoneNumberError.value = '';
    const response = await sendCheckoutLink({
      projectId,
      invoiceId: props.invoiceId,
      contact: phoneNumber.value,
      checkoutLink: props.checkoutLink,
      method: 'sms',
    });

    if (response.status === 200) {
      setTimeout(() => {
        successNotificationText.value = 'Checkout Link has been successfully sent via SMS';
        showSuccessNotification.value = true;
      }, 200);
    }
  } catch (error: any) {
    if (error.response?.data?.type === 'ValidationError') {
      phoneNumberError.value = 'Enter a valid phone number';
    } else {
      phoneNumberError.value = error.response?.data?.message || 'An error occurred';
    }
  }
};

const sendCheckoutLinkEmail = async () => {
  try {
    emailError.value = '';
    const response = await sendCheckoutLink({
      projectId,
      invoiceId: props.invoiceId,
      contact: emailAddress.value,
      checkoutLink: props.checkoutLink,
      method: 'email',
    });

    if (response.status === 200) {
      setTimeout(() => {
        successNotificationText.value = 'Checkout Link has been successfully sent via email';
        showSuccessNotification.value = true;
      }, 200);
    }
  } catch (error: any) {
    if (error.response?.data?.type === 'ValidationError') {
      emailError.value = 'Enter a valid email address';
    } else {
      emailError.value = error.response?.data?.message || 'An error occurred';
    }
  }
};

const copyCheckoutLink = () => {
  showSuccessNotification.value = false;

  setTimeout(() => {
    const clipboard = useClipboard({ source: props.checkoutLink });
    clipboard.copy();
    successNotificationText.value = 'Checkout Link has been successfully copied to your Clipboard';
    showSuccessNotification.value = true;
  }, 200);
};

const openCheckoutLink = () => {
  window.open(props.checkoutLink, '_blank');
};

const close = () => {
  emit('close-popup');
};
</script>
