import http, { Http } from '@/lib/http';
import { CurrencyCode, OrderOrigin } from '@/types';

interface Orders {
  totalInCents: number;
  avgAmountInCents: number;
  totalOrders: number;
}
interface RevenueOverTime {
  totalInCents: number;
  date: string;
}

interface RevenueByTerm {
  totalInCents: number;
  installments: number;
}

interface AvgOrderValueByTerm {
  avgOrderValueInCents: number;
  installments: number;
}

interface RevenueByOrderStatus {
  totalInCents: number;
  name: string;
}

interface ApplicationsCreditMix {
  medianScore: number | null;
  medianScoreGrade: string | null;
  breakdown: {
    title: string;
    total: number;
  }[]
}

interface ApplicationApprovals {
  approvedApplications: number;
  totalApplications: number;
  totalOrders: number;
}

export interface RevenueByProductStatus {
  totalInCents: number;
  status: string;
}

export interface OverviewAnalytics {
  orders: Orders;
  applicationApprovals: ApplicationApprovals;
  applicationApprovalsYoy: ApplicationApprovals;
  ordersYoy: Orders;
  revenueOverTime: RevenueOverTime[];
  revenueByTerm: RevenueByTerm[];
  avgOrderValueByTerm: AvgOrderValueByTerm[];
  revenueByProduct: RevenueByOrderStatus[];
  applicationsCreditMix: ApplicationsCreditMix;
  revenueByOrderStatus: RevenueByProductStatus[];
  isProjectOlderThanApplicationsStartDate: boolean;
  currencies: CurrencyCode[];
  selectedCurrency: CurrencyCode | string;
}

export type OverviewDates = { startDate: Date | null, endDate: Date | null };
export type View = OrderOrigin.ppm | OrderOrigin.lnpl | '';

interface OverviewParams {
  projectId: string;
  dates: OverviewDates;
  view: View;
  currency: CurrencyCode;
}

function ctor(http: Http) {
  return async ({
    projectId, dates, view, currency,
  }: OverviewParams): Promise<OverviewAnalytics> => {
    const { data } = await http.get(`/projects/${projectId}/overview`, {
      params: { dates, view, currency },
    });
    return data;
  };
}

export const getOverview = ctor(http);
