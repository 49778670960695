<template>
  <FormHeader
    :heading="heading"
    :subHeading="subHeading"
  />
  <div
    v-for="option in options"
    :key="option.title"
    class="mb-3 bg-white cursor-pointer product-card hover:bg-gray-100"
    :class="{ 'border-2 border-primary': option.active }"
    @click="toggleProductTypeSelection(option)"
  >
    <div class="flex px-6 py-4">
      <img
        class="product-icon"
        :src="getIconUrl(option.icon)"
      >
      <div class="flex flex-col pl-8">
        <p class="font-bold">
          {{ option.title }}
        </p>
        <p>
          {{ option.description }}
        </p>
      </div>
    </div>
  </div>
  <button
    type="button"
    class="w-full mt-2 button button--secondary"
    :disabled="selectedProducts.length === 0"
    @click="saveProductTypes"
  >
    Continue
  </button>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import FormHeader from '@/components/form_header.vue';
import { productTypes, ProductType } from './product_types';
import { BusinessDetails } from '../api/get_business_details';

interface Props {
  data: BusinessDetails
}

const props = defineProps<Props>();

interface ProductOption extends ProductType {
  icon: string;
  active: boolean
}

const options = ref(productTypes.map((type) => ({
  ...type,
  icon: type.value,
  active: props.data?.productTypes?.includes(type.value) || false,
})));

const emit = defineEmits(['product-types-submit']);

const heading = 'What types of products would you like to sell through Elective?';
const subHeading = 'You can select more than one!';

const selectedProducts = ref<string[]>(props.data?.productTypes || []);

function getIconUrl(name: string) {
  return new URL(`./assets/${name}.svg`, import.meta.url).href;
}

function toggleProductTypeSelection(option: ProductOption) {
  option.active = !option.active;
  selectedProducts.value = (options.value.filter((item: ProductOption) => item.active)).map((p) => p.value);
}

function saveProductTypes() {
  emit('product-types-submit', selectedProducts);
}

</script>

<style lang="scss">
  .product-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: 1px solid;
    @apply border-gray-300;
  }

  .product-icon {
    @apply h-[40px] w-[40px]
  }
</style>
