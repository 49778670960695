import http, { Http } from '@/lib/http';

export interface Student {
  createdAt: Date;
  studentId: string;
  studentEmail: string;
  studentPhoneNumber: string
  studentName: string;
  studentAddressStreet: string;
  studentAddressRegion: string;
  studentAddressCountry: string;
  loanOrderIds: string[];
  status: string;
}

interface GetStudentsResponse {
  students: Student[];
  count: number;
}

export interface StudentFilter {
  textFilter: string;
  pageSize: number;
  currentPage: number;
}

interface Data {
  filter: StudentFilter;
  projectId: string;
}

function getStudentsCtor(http: Http) {
  return async function getStudents({ filter, projectId }: Data): Promise<GetStudentsResponse> {
    const { data } = await http.get(`/projects/${projectId}/students`, {
      params: filter,
    });

    return {
      count: data.totalCount,
      students: data.data,
    };
  };
}

export const getStudents = getStudentsCtor(http);
