<template>
  <div class="flex flex-col h-full">
    <h2 class="text-lg font-bold pb-1">
      Preferences
    </h2>
    <div>Choose what email notifications your team members receive.</div>
    <div class="pt-4">
      <div class="text-sm text-gray-400">
        Team Member
      </div>
      <h2 class="primary text-lg font-bold">
        {{ subscriber.value }}
      </h2>
    </div>
    <hr class="my-4">
    <h2 class="text-lg font-bold">
      Email Notifications
    </h2>
    <div class="my-4">
      <div
        v-for="{ label, options } in eventOptions"
        :key="label"
        class="mb-4"
      >
        <span class="font-semibold">{{ label }}</span>
        <div>
          <div
            v-for="option in options.value"
            :key="option.value"
            class="p-1 flex items-center"
          >
            <input
              :id="option.value"
              v-model="option.active"
              :value="option.value"
              type="checkbox"
              class="mr-2 checked:bg-primary-800 hover:cursor-pointer rounded border-2 disabled:bg-gray-300 disabled:cursor-not-allowed"
              :disabled="option.required"
            >
            <label
              :for="option.value"
              :class="option.required? 'hover:cursor-not-allowed': 'hover:cursor-pointer'"
            >{{ option.label }}
            </label>
            <TbTooltip
              v-if="option.required"
              class="subscriptionToolTip"
              info="The primary subscriber must receive this notification."
            />
          </div>
        </div>
      </div>
      <hr class="my-4">
      <div class="p-1 flex items-center">
        <input
          id="unsubscribe_all"
          type="checkbox"
          class="mr-2 checked:bg-primary-800 hover:cursor-pointer rounded border-2"
          @click="unsubscribeAll"
        >
        <label
          for="unsubscribe_all"
          class="hover:cursor-pointer"
        >
          Unsubscribe from all emails
        </label>
        <TbTooltip
          v-if="subscriber.isPrimary"
          class="subscriptionToolTip"
          info="Some notifications are enabled by default for the primary subscriber."
        />
      </div>

      <div class="mt-4 action">
        <button
          class="button button--primary button--block"
          @click="updateSubscriptions"
        >
          Save
        </button>

        <button
          class="mt-4 button button--text button--block"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbTooltip } from '@/components/tasty_bistro';
import {
  SubscriptionsDetails,
  getSubscriptionEvents,
  NotificationsSubscriber,
} from '../api';
import { updateSubscription } from '../api/update_subscription';

const route = useRoute();
const projectId = route.params.projectId as string;
const props = defineProps<{ subscriber: NotificationsSubscriber }>();
const orderEvents = ref<SubscriptionsDetails[]>([]);
const paymentEvents = ref<SubscriptionsDetails[]>([]);
const applicationEvents = ref<SubscriptionsDetails[]>([]);

const eventOptions = [
  { label: 'Orders', options: orderEvents },
  { label: 'Payments', options: paymentEvents },
  { label: 'Applications', options: applicationEvents },
];
const emit = defineEmits(['submit', 'cancel']);

const error = ref<string>('');
async function updateSubscriptions() {
  try {
    const getEventNamesByStatus = (status: boolean) => {
      const currOptions = eventOptions.flatMap((obj) => obj.options);
      const currStatusEvents = currOptions
        .flatMap((option) => option.value.filter((event) => event.active === status));

      return currStatusEvents.flatMap((event) => event.value);
    };

    const eventsToDisable = getEventNamesByStatus(false);
    const eventsToEnable = getEventNamesByStatus(true);
    await updateSubscription({
      subscriptionId: props.subscriber.id,
      eventsToDisable,
      eventsToEnable,
      projectId,
    });
    emit('submit');
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
}

async function loadSubscriberEvents() {
  try {
    const resp = await getSubscriptionEvents({ subscriptionId: props.subscriber.id, projectId });
    orderEvents.value = resp.orders;
    paymentEvents.value = resp.payments;
    applicationEvents.value = resp.applications;
  } catch (err: any) {
    error.value = err.response?.data?.message || 'Something went wrong';
  }
}
function unsubscribeAll() {
  eventOptions
    .forEach(
      (eventOption) => eventOption.options.value.forEach(
        (event) => { event.active = event.required; },
      ),
    );
}

onMounted(async () => {
  loadSubscriberEvents();
});
async function cancel() {
  emit('cancel');
}
</script>

<style lang="scss">
div .subscriptionToolTip {
  @apply py-0
}
.subscriptionToolTip > div.bg-gray-300 {
  @apply bg-primary;
}
.subscriptionToolTip > div.overflow-hidden > div {
  @apply bg-gray-500 text-white;
}
</style>
