import { AxiosError } from 'axios';
import http, { Http } from '@/lib/http';

interface RedundOrderData {
  orderId: string;
  notes: string;
  projectId: string;
  isFundedRefund: boolean;
}

function refundOrderCtor(http: Http) {
  return async (refundData: RedundOrderData): Promise<void> => {
    const { projectId, ...payload } = refundData;
    try {
      const { data } = await http.post(`/projects/${projectId}/orders/refund`, {
        ...payload,
      });
      return data;
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };
}

export const refundOrder = refundOrderCtor(http);
