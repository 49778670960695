<template>
  <Page>
    <Table
      date-key="createdAt"
      :items="paymentPlanGroups"
      :total-item-count="paymentPlanGroupsCount"
      :items-per-page="pageSize"
      :state="state"
      item-type="Payment Settings"
      :headers="tableHeader"
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div class="mb-4 flex flex-row lg:items-center items-start">
          <TbInformationIcon
            class="w-8 h-6 md:w-4 md:h-4 mr-2 text-primary"
          />
          <span class="text-gray-500 text-sm">
            A Refund Reserve is required to cover refunds & disputes for in-house payments. Please see
            <a
              href="https://help.elective.com/hc/en-us/articles/17461983700251"
              target="_blank"
              class="text-primary font-semibold"
            >here
            </a> for more.
          </span>
        </div>
        <div class="flex flex-row flex-wrap gap-2">
          <div class="w-full max-w-xs">
            <TbInput
              v-model="search"
              placeholder="Filter payment settings here"
            />
          </div>
          <TbDrawer>
            <template #default="{ toggleDrawer }">
              <div class="flex flex-col h-full">
                <FormCreatePaymentPlanGroup
                  :configs="configs"
                  @cancel="toggleDrawer()"
                  @submit="toggleDrawer(), loadPaymentPlanGroups()"
                />
              </div>
            </template>

            <template #toggle="{ toggleDrawer }">
              <RoleControlledAction
                v-slot="{wrapper, restricted}"
                :user-role="roleName"
                :config-object="roleConfig.createPaymentStack"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--primary button--sm"
                  @click="wrapper(()=> toggleDrawer())"
                >
                  <span class="flex items-center gap-2">
                    Create Payment Setting
                    <TbPlusIcon class="w-4 h-4 text-white" />
                  </span>
                </button>
              </RoleControlledAction>
            </template>
          </TbDrawer>
        </div>
      </template>

      <template #action="{ item, toggleDropdown }">
        <div class="flex flex-col h-full gap-1">
          <TbDrawer>
            <template #default="{ toggleDrawer }">
              <div class="flex flex-col h-full">
                <FormEditPaymentPlanGroup
                  :configs="configs"
                  :payment-plan-group="item"
                  @cancel="toggleDrawer()"
                  @submit="toggleDrawer(), loadPaymentPlanGroups(), toggleDropdown()"
                />
              </div>
            </template>
            <template #toggle="{ toggleDrawer }">
              <RoleControlledAction
                v-slot="{wrapper, restricted}"
                :user-role="roleName"
                :config-object="roleConfig.updatePaymentStack"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--block button--sm text-left"
                  @click="wrapper(()=>toggleDrawer())"
                >
                  Update
                </button>
              </RoleControlledAction>
            </template>
          </TbDrawer>

          <FormArchivePaymentPlanGroup
            :payment-plan-group="item"
            @archive="loadPaymentPlanGroups()"
          />
        </div>
      </template>

      <template #row(paymentPlans)="{ value }">
        <div class="whitespace-normal">
          <div
            v-for="pp in value"
            :key="pp"
            class="inline-flex flex-wrap gap-2 mb-1"
          >
            <div class="px-2 mr-2 bg-gray-300 rounded-md">
              {{ pp.installments }} Months ({{ pp.downPaymentPercentage }}%)
            </div>
          </div>
        </div>
      </template>
    </Table>
  </Page>
  <QuickStartGuideLink />
</template>

<script lang="ts" setup>
import {
  onMounted, ref, watch, inject, Ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash';
import {
  TbInput, TbPlusIcon, TbDrawer, TbInformationIcon,
} from '@/components/tasty_bistro';
import { PageState } from '@/types';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import Table from '@/components/table.vue';
import { formatDate } from '@/filters/date';
import Page from '../../components/page.vue';
import { PaymentPlanGroup, PaymentPlans, getPaymentPlanGroups } from './api/get_payment_plan_groups';
import { getPaymentPlanGroupConfig, Configs } from './api/get_configs';
import FormCreatePaymentPlanGroup from './forms/create_payment_plan_group.vue';
import FormEditPaymentPlanGroup from './forms/edit_payment_plan_group.vue';
import FormArchivePaymentPlanGroup from './forms/archive_payment_plan_group.vue';
import { roleConfig } from './role_configurations';

const pageSize = 10;
const currentPage = ref(0);
const paymentPlanGroups = ref<PaymentPlanGroup[]>([]);
const paymentPlanGroupsCount = ref<number>(0);
const search = ref('');
const state = ref(PageState.loaded);
const configs = ref<Configs>({
  maxInstallments: 0,
});

const route = useRoute();
const projectId = route.params.projectId as string;
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const tableHeader = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Payment Terms',
    key: 'paymentPlans',
    formatter: (val: PaymentPlans[]) => val.map((val2) => `${val2.installments} Months (${val2.downPaymentPercentage}%)`).join(' | '),

  },
  {
    title: 'Created At',
    key: 'createdAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
];

async function loadPaymentPlanGroups() {
  try {
    state.value = PageState.loading;

    const data = await getPaymentPlanGroups({
      projectId,
      params: {
        pageSize,
        currentPage: currentPage.value,
        search: search.value,
      },
    });
    paymentPlanGroups.value = data;
    paymentPlanGroupsCount.value = Number(data[0] ? data[0].totalCount : 0);

    state.value = PageState.loaded;
  } catch {
    state.value = PageState.error;
  }
}

onMounted(async () => {
  configs.value = await getPaymentPlanGroupConfig(projectId);
  await loadPaymentPlanGroups();
});

watch(search, debounce(async () => {
  currentPage.value = 0;
  await loadPaymentPlanGroups();
}, 500));

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadPaymentPlanGroups();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadPaymentPlanGroups();
};

</script>
<style scoped lang="scss">
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
