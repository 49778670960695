<template>
  <div class="flex gap-2">
    <Input
      v-model="dob.day"
      label="Day"
      placeholder="DD"
      inputmode="numeric"
      :disabled="props.disabled"
      :errors="errorsDay"
      class="grow"
    />
    <Input
      v-model="dob.month"
      label="Month"
      placeholder="MM"
      inputmode="numeric"
      :disabled="props.disabled"
      :errors="errorsMonth"
      class="grow"
    />
    <Input
      v-model="dob.year"
      label="Year"
      placeholder="YYYY"
      inputmode="numeric"
      :disabled="props.disabled"
      :errors="errorsYear"
      class="grow-[2]"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue';
import {
  isValid, getDate, getMonth, getYear,
} from 'date-fns';
import Input from '../input/input.vue';

interface DobProps {
  disabled?: boolean;
  date?: Date;
}
const props = defineProps<DobProps>();
const emits = defineEmits(['date-input']);

function getInitialDate(date: Date | undefined) {
  return date ? getDate(date).toString() : '';
}

function getInitialMonth(date: Date | undefined) {
  return date ? (getMonth(date) + 1).toString() : '';
}

function getInitialYear(date: Date | undefined) {
  return date ? getYear(date).toString() : '';
}

const dob = reactive({
  day: getInitialDate(props.date),
  month: getInitialMonth(props.date),
  year: getInitialYear(props.date),
});

const errorsDay = ref<string[]>([]);
const errorsMonth = ref<string[]>([]);
const errorsYear = ref<string[]>([]);

function isValidDay(day: string) {
  return Number(day) > 0 && Number(day) < 32;
}

function isValidMonth(month: string) {
  return Number(month) > 0 && Number(month) <= 12;
}

function isValidYear(year: string): Boolean {
  return !!year && year.length === 4 && !Number.isNaN(Number(year)) && Number(year) > 1800;
}

watch(
  () => dob,
  (dob) => {
    errorsDay.value = [];
    errorsMonth.value = [];
    errorsYear.value = [];
    if (!isValidDay(dob.day)) {
      errorsDay.value = ['Invalid Day'];
    }

    if (!isValidMonth(dob.month)) {
      errorsMonth.value = ['Invalid Month'];
    }

    if (!isValidYear(dob.year)) {
      errorsYear.value = ['Invalid Year'];
    }

    const birthDate = new Date(Number(dob.year), Number(dob.month) - 1, Number(dob.day));

    if (
      isValid(birthDate)
      && isValidDay(dob.day)
      && isValidMonth(dob.month)
      && isValidYear(dob.year)
    ) {
      emits('date-input', birthDate);
    }
  },
  { deep: true },
);

</script>
