import http, { Http } from '@/lib/http';

export interface SubscriptionsDetails {
    value: string;
    label: string;
    active: boolean;
    group: string;
    required: boolean;
}

export type SubscriptionEvents = Record<string, SubscriptionsDetails[]>

interface Data {
  subscriptionId: string;
  projectId: string;
}

function getSubscriptionEventsCtor(http: Http) {
  return async ({ subscriptionId, projectId }: Data) => {
    const response = await http.get<SubscriptionEvents>(`/projects/${projectId}/notifications/${subscriptionId}/events`);
    return response.data;
  };
}

export const getSubscriptionEvents = getSubscriptionEventsCtor(http);
