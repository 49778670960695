<template>
  <CheckoutForm
    action="update"
    :checkout="preppedCheckout"
    :fn="updateCheckoutFn"
    @cancel="$emit('cancel')"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import CheckoutForm from '../../components/checkout_form/index.vue';
import { updateCheckout } from '../api/update_checkout';
import { Checkout } from '../api/get_checkouts';
import { CreateCheckoutParams } from '../api/create_checkout';

const route = useRoute();
const projectId = route.params.projectId as string;

interface Props {
  checkout: Checkout;
}

const props = defineProps<Props>();

const emit = defineEmits([
  'submit',
  'cancel',
]);

const preppedCheckout = computed(() => ({
  products: props.checkout.products.map((p) => p.id),
  name: props.checkout.name,
  status: props.checkout.status,
  termsUrl: props.checkout.termsUrl,
  redirectUrl: props.checkout.redirectUrl,
  paymentSettingsId: props.checkout.paymentSettingsId || '',
  downPaymentType: props.checkout.downPaymentType,
  downPaymentValue: props.checkout.downPaymentValue,
}));

const updateCheckoutFn = async (values: CreateCheckoutParams) => {
  await updateCheckout({ ...values, checkoutId: props.checkout.staticIdentifier, projectId });
  emit('submit');
};

</script>
