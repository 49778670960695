<template>
  <TbLayoutAuth>
    <div class="z-10 flex items-center w-full max-w-5xl p-8 m-auto">
      <SplitCard
        quote="The benefits are clear: more sales and happy students!"
        name="Tim Vipond"
        designation="Founder of Corporate Finance Institute"
        imageName="tim_vipond.svg"
      >
        <div class="p-8 mb-14">
          <TbLetterLogo class="h-20 w-20 m-auto mb-3" />
          <div class="mb-3 text-center">
            <h1 class="text-xl sm:text-2xl font-serif-bold">
              Reset your Password
            </h1>
            <p>
              Enter your new password below.
            </p>
          </div>

          <form
            v-if="tokenState === 'valid'"
            @submit.prevent="submit"
          >
            <TbInput
              v-model.trim="form.password"
              label="Password*"
              type="password"
              required
            />

            <TbInput
              v-model.trim="form.confirmPassword"
              label="Confirm your password*"
              type="confirmPassword"
              required
            />

            <FormButton
              text="Update password"
              :disabled="form.state === 'loading'"
            />

            <div
              v-if="form.state === 'error'"
              class="mb-4 text-center text-error"
              data-test="error-div"
            >
              <small>
                {{ form.error }}
              </small>
            </div>
          </form>

          <div v-else-if="tokenState === 'success'">
            <p class="py-5">
              You successfully reset your password. Click the button below
              to head back to the sign in page.
            </p>

            <router-link
              class="block w-full text-center button button--secondary"
              :to="{ name: Page.signIn }"
            >
              Sign in
            </router-link>
          </div>

          <div v-else>
            <p class="py-5">
              Looks like the token you've got is either expired, or invalid.
              Click the button below to generate a new one
            </p>

            <router-link
              class="block w-full text-center button button--secondary"
              :to="{ name: Page.requestResetPassword }"
            >
              Get a new token
            </router-link>
          </div>
        </div>
      </SplitCard>
    </div>

    <TbNotification
      :show="requestPasswordNotification"
      title="Success"
      message="Your password was successfully reset. You can now sign in with your new password"
      @close="requestPasswordNotification = false"
    />
  </TbLayoutAuth>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import {
  TbLayoutAuth, TbInput, TbLetterLogo, TbNotification,
} from '@/components/tasty_bistro';
import SplitCard from '@/components/split_card/index.vue';
import FormButton from '@/components/form_button.vue';
import { useRoute } from 'vue-router';
import { Page } from '../../../router/auth';
import { validateToken } from './api/validate_token';
import { resetPassword } from './api/reset_password';

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
  error: string;
  state: 'loaded' | 'loading' | 'error';
}

const form: ResetPasswordForm = reactive({
  password: '',
  error: '',
  confirmPassword: '',
  state: 'loaded',
});

const route = useRoute();
const tokenState = ref<'valid' | 'invalid' | 'success'>();
const token = ref<string>('');
const requestPasswordNotification = ref<boolean>(false);

onMounted(async () => {
  try {
    token.value = String(route.params.token);
    await validateToken(token.value);
    tokenState.value = 'valid';
  } catch (err: any) {
    if (err?.data?.type === 'InvalidToken') {
      tokenState.value = 'invalid';
    }
  }
});

async function submit() {
  try {
    form.state = 'loading';
    await resetPassword(token.value, { password: form.password });
    requestPasswordNotification.value = true;
    form.state = 'loaded';
    tokenState.value = 'success';
  } catch (err: any) {
    form.state = 'error';
    form.error = err.response?.data?.message;
  }
}

</script>
