<template>
  <Tooltip v-if="!roleConfig.hidden">
    <template #trigger="{ show, assignReference, hide }">
      <div
        :ref="(el: any) => assignReference(el)"
        @mouseenter="showToolTip(show)"
        @mouseleave="hide"
      >
        <slot
          :wrapper="actionWrapper"
          :restricted="isRestricted()"
        />
      </div>
    </template>

    <template #tooltip>
      <div class="text-primary">
        <TbIcon icon="lock" />
        <p class="font-bold">
          This feature is unavailable
        </p>
        <p>
          Please contact your admin for access.
        </p>
      </div>
    </template>
  </Tooltip>
</template>

<script lang="ts" setup>
import { inject, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbIcon } from '@/components/tasty_bistro';
import { RoleConfiguration, RoleControlConfig } from '@/types';
import { AppState } from '@/pages/app/api/get_app_state';
import Tooltip from './tooltip.vue';

interface Props {
  userRole: string;
  configObject: RoleConfiguration
}

const route = useRoute();
const projectId = route.params.projectId as string;
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectConfig = appState.value.projects[projectId];
const userManagementEnabled = Boolean(projectConfig?.features?.userManagementEnabled);

const props = defineProps<Props>();

const defaultAccess: RoleControlConfig = { hidden: false, restricted: true };
const roleConfig = props.configObject[props.userRole] ?? defaultAccess;

const isRestricted = () => {
  if (!userManagementEnabled) {
    return false;
  }
  return (roleConfig.hidden || roleConfig.restricted);
};

const actionWrapper = async function (callback: any) {
  if (isRestricted()) {
    return;
  }

  await callback();
};

// eslint-disable-next-line consistent-return
function showToolTip(show: any): void {
  if (isRestricted()) {
    return show();
  }
}

</script>
