<template>
  <div class="flex flex-col gap-2">
    <div
      :class="[
        'w-full select-none',
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
      ]"
      @click="toggle"
    >
      <slot
        name="label"
        :open="open"
        :disabled="disabled"
      />
    </div>

    <Transition
      enter-active-class="transition duration-150 linear opacity-0"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 linear opacity-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="open">
        <slot name="content" />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

  interface Props {
    disabled?: boolean;
  }

const props = defineProps<Props>();

const open = ref(false);

const toggle = () => {
  if (props.disabled) return;

  open.value = !open.value;
};
</script>
