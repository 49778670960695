<template>
  <div
    v-if="chartData.datasets[0].data.length"
    class="flex flex-col justify-between h-full"
  >
    <div class="flex justify-center w-full">
      <div class="flex justify-center">
        <TbDoughnutChart
          ref="chartRef"
          :data="chartData"
          :options="chartOptions"
          :plugins="[htmlLegendPlugin]"
          showPercentage
          :formatter="props.labelFormatter"
        />
      </div>
    </div>

    <div class="flex flex-col w-full gap-2">
      <h6 class="font-bold text-gray-500">
        {{ title }}
      </h6>

      <div
        ref="containerRef"
        class="flex flex-row flex-wrap gap-2 max-w-[80%]"
      >
        <template
          v-for="(elem, i) of elements"
          :key="i"
        >
          <template v-if="compressed && i === maxLabels - 1">
            <TbDrawer>
              <template #default="{ toggleDrawer }">
                <Breakdown
                  :data="sortedDataSet"
                  :title="title"
                  :first-column-title="firstColumnTableTitle"
                  :formatter="props.labelFormatter"
                  showPercentage
                  @close="toggleDrawer"
                />
              </template>

              <template #toggle="{ toggleDrawer }">
                <div
                  class="flex flex-row items-center gap-2 cursor-pointer"
                  @click="toggleDrawer"
                >
                  <div
                    class="w-4 h-4 rounded-full"
                    :style="{ backgroundColor: elem.fillStyle}"
                  />
                  <p class="font-bold text-info">
                    {{ elem.text }}
                  </p>
                </div>
              </template>
            </TbDrawer>
          </template>
          <div
            v-else
            class="flex flex-row items-center gap-2 cursor-pointer"
            @click="clickHandler(elem)"
          >
            <div
              class="w-4 h-4 rounded-full"
              :style="{ backgroundColor: elem.fillStyle}"
            />
            <p :style="{textDecoration: elem.hidden ? 'line-through' : ''}">
              {{ elem.text }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>

  <div
    v-else
    class="flex items-center justify-center h-full"
  >
    <div class="flex flex-row max-w-sm gap-2">
      <TbEyeIcon class="h-5 w-5 mt-0.5 shrink-0" />
      <div class="flex flex-col gap-2">
        <div class="font-bold">
          Not enough data
        </div>
        <data class="font-regular">
          It looks like we don't have enough data to display this chart for you.
          Please change your date range or try accessing the chart at a later time when more data becomes available.
        </data>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import {
  Chart as ChartJS, ArcElement, Legend,
  // ChartOptions
} from 'chart.js';
import TbDoughnutChart from '@/lib/graphs/doughnut_chart';
import TbTooltip from '@/lib/graphs/tooltip';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { defaultColors } from '@/lib/graphs/colors';
import { TbDrawer, TbEyeIcon } from '@/components/tasty_bistro';
import { chartFonts } from '@/lib/graphs/fonts';
import { percentage } from '@/filters/percentage';
import Breakdown from './breakdown.vue';

export interface Dataset {
  label: string;
  data: number[];
  title?: {
    display: boolean;
    text: string;
  };
  backgroundColor?: string[];
}

export interface Props {
  id: string;
  title: string;
  dataset: Dataset[];
  labels: string[];
  labelFormatter?: (value: number | string) => string;
  maxLabels?: number;
  firstColumnTableTitle?: string;
  sortByLabel?: boolean;
}

const chartRef = ref();

ChartJS.register(ArcElement, ChartDataLabels, Legend);

const containerRef = ref();
const props = defineProps<Props>();

const maxLabels = props.maxLabels || 6;
const sortedDataSet = computed(() => {
  const dataset = props.dataset[0];
  const labelData = props.labels.map((label, index) => ({
    label,
    value: Number(dataset.data[index]),
  }));

  if (props.sortByLabel) {
    // Sort the labelData array based on the data labels in ascending order
    return labelData.sort((a, b) => parseInt(a.label, 10) - parseInt(b.label, 10));
  }

  // Sort the labelData array based on the data values in descending order
  return labelData.sort((a, b) => b.value - a.value);
});

const chartData = computed(() => {
  const dataset = props.dataset[0];
  const cutout = 70;

  // Create an array of objects mapping labels to their corresponding numbers
  const labelData = props.labels.map((label, index) => ({
    label,
    data: dataset.data[index],
  }));

  // Sort the labelData array based on the data values in descending order
  labelData.sort((a, b) => b.data - a.data);

  // Check if there are more than 6 items
  if (labelData.length > maxLabels) {
    const otherItems = labelData.slice(maxLabels - 1); // Get the items from index 5 onwards
    const otherTotal = otherItems.reduce((sum, item) => sum + Number(item.data), 0); // Calculate the total of the other items
    // Create a new "Other" item with the total
    const otherItem = {
      label: 'Other',
      data: otherTotal,
    };

    // Combine the first 5 items with the "Other" item
    const combinedData = [...labelData.slice(0, maxLabels - 1), otherItem];

    // Update the labels and data with the combined data
    const sortedLabels = combinedData.map((item) => item.label);
    const sortedData = combinedData.map((item) => item.data);

    return {
      labels: sortedLabels,
      datasets: [
        {
          ...dataset,
          data: sortedData,
          backgroundColor: dataset.backgroundColor ?? sortedData.map((_d, i) => defaultColors[i] || defaultColors[0]),
          cutout,
        },
      ],
    };
  }

  // Update the background colors if available
  const backgroundColor = dataset.backgroundColor ?? labelData.map((_d, i) => defaultColors[i] || defaultColors[0]);

  return {
    labels: props.labels,
    datasets: [
      {
        ...dataset,
        data: dataset.data,
        backgroundColor,
        cutout,
      },
    ],
  };
});

const compressed = ref(props.labels.length > maxLabels);
const elements = ref([]) as any;

const chartOptions = computed(() => ({
  responsive: true,
  interaction: {
    intersect: false,
  },
  layout: {
    padding: 45,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      ...TbTooltip,
      enabled: true,
      callbacks: {
        title: () => '',
        label: (ctx: any) => {
          const label = ctx.label || '';
          const value = ctx.parsed;

          if (ctx.parsed && ctx.parsed > 0) {
            return `${label}: ${props.labelFormatter ? props.labelFormatter(value) : value}`;
          }

          return label;
        },
      },
    },
    datalabels: {
      display: 'auto',
      anchor: 'end',
      align: 'end',
      font: {
        size: 14,
        weight: 'bold',
        ...chartFonts,
      },
      offset: 4,
      formatter: (value: any, ctx: any) => {
        const dataArray: any = ctx.chart.data.datasets[0].data;
        const sum = dataArray.reduce((agg: number, curr: number) => agg + curr, 0);
        return percentage(value / sum);
      },
    },
  },
}) as any); // TODO: use ChartOptions instead of any, and fix type errors

const htmlLegendPlugin = computed(() => ({
  id: 'htmlLegend',
  afterUpdate(chart: any) {
    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    elements.value = items;
  },
}));

const clickHandler = (elem: any) => {
  chartRef.value.chart.toggleDataVisibility(elem.index);
  chartRef.value.chart.update();
};

</script>
