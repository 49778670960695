<template>
  <TbLayoutAuth>
    <div class="z-10 w-full p-8 m-auto max-w-7xl">
      <div class="flex w-full overflow-hidden bg-white rounded-xl">
        <main class="flex flex-col w-full lg:flex-row">
          <div class="w-full lg:w-1/2">
            <div class="p-8">
              <TbLetterLogo class="h-20 w-20 m-auto mb-3" />
              <div class="mb-3 text-center">
                <h1 class="py-2 text-xl font-medium sm:text-2xl">
                  Welcome to Elective 🚀
                </h1>
                <p>
                  You are joining world-class course creators who use Elective to grow their education empires.
                </p>
              </div>
              <form @submit.prevent="submit">
                <TbInput
                  v-model="firstName"
                  label="First Name*"
                  type="text"
                  class="w-full"
                  :errors="firstNameError && [firstNameError]"
                />
                <TbInput
                  v-model="lastName"
                  label="Last Name*"
                  type="text"
                  class="w-full"
                  :errors="lastNameError && [lastNameError]"
                />
                <TbInput
                  :modelValue="email"
                  label="Business Email*"
                  type="email"
                  class="w-full"
                  :errors="emailError && [emailError]"
                  @change="handleEmailChange"
                />
                <TbInput
                  :modelValue="password"
                  label="Password*"
                  type="password"
                  class="w-full"
                  toggleVisibility
                  :errors="passwordError && [passwordError]"
                  @change="handlePasswordChange"
                />
                <TbSelect
                  v-model="revenue"
                  label="Yearly Revenue*"
                  :options="yearlyRevenueOptions"
                  class="w-full"
                  :errors="revenueError && [revenueError]"
                />
                <div class="px-2 mt-4 mb-2">
                  <input
                    id="termsAndConditions"
                    v-model="termsAndConditions"
                    type="checkbox"
                    class="cursor-pointer checkbox"
                  >
                  <label
                    for="termsAndConditions"
                    class="ml-2 text-sm font-light"
                  >
                    I agree to the
                    <a
                      href="https://elective.com/terms"
                      target="_blank"
                      class="font-medium text-secondary"
                    >
                      Merchant Portal Terms & Conditions
                    </a>
                  </label>
                  <div :class="{'text-error text-xs' : termsError}">
                    {{ termsError }}
                  </div>
                </div>

                <FormButton
                  text="Create Account"
                  :disabled="state === PageState.loading"
                />
                <div
                  v-if="state === PageState.error"
                  class="my-4 text-center text-error"
                  data-test="error-div"
                >
                  {{ apiError }}
                </div>
                <div class="flex flex-col justify-center text-sm text-center sm:flex-row">
                  <p class="mt-8 text-center">
                    Already have an account? <router-link to="/sign-in">
                      Sign In
                    </router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="flex flex-col items-center justify-center w-full px-6 lg:w-1/2">
            <div>
              <div
                class="cursor-pointer"
                @click="showModal = true"
              >
                <img
                  src="./assets/testimonial-thumbnail.png"
                  class="sm:w-[600px] lg:w-[500px]"
                >
              </div>
              <Modal
                :showModal="showModal"
                @close-modal="showModal = false"
              >
                <div class="w-[70%]">
                  <Video :videoUrl="testimonialVideoUrl" />
                </div>
              </Modal>
            </div>
            <div class="px-4 py-8 text-lg text-center sm:px-16 lg:text-left lg:px-8 text-primary">
              <img
                src="./assets/open-quote.png"
                class="inline pb-3"
              >
              <p class="inline text-sm md:text-base">
                Elective has been a massive part of our success, helping us increase revenue by 35%+.
                We've worked with other funders before but what Elective does is unmatchable in the industry.
                We cannot recommend them enough!
              </p>
              <img
                src="./assets/close-quote.png"
                class="inline pt-2"
              >
            </div>
            <div class="pb-4 text-center lg:pb-0 lg:my-2 ">
              <p class="text-base font-bold md:text-lg text-primary">
                Arek & Brian Zabierek
              </p>
              <p class="text-sm font-light text-gray-500 md:text-base">
                The FBA Start-Up
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  </TbLayoutAuth>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
  TbLayoutAuth, TbInput, TbSelect, TbLetterLogo,
} from '@/components/tasty_bistro';
import { Pages } from '@/router';
import { PageState } from '@/types';
import { useRouter } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import {
  object, string, boolean, InferType,
} from 'yup';
import FormButton from '@/components/form_button.vue';
import Modal from '@/components/modal.vue';
import Video from '@/components/video.vue';
import signUp from './api/sign_up';
import { auth } from '../../../lib/auth';

interface YearlyRevenue {
  name: string,
  value: number
}

const router = useRouter();

const showModal = ref(false);
const apiError = ref('');
const state = ref<PageState>(PageState.loaded);

const yearlyRevenueOptions: YearlyRevenue[] = [
  { name: 'Less than $100K', value: 0 },
  { name: 'Between $100K - $500K', value: 1 },
  { name: 'Between $500K - $1M', value: 2 },
  { name: 'Over $1M', value: 3 },
];

const testimonialVideoUrl = 'https://player.vimeo.com/video/814693309?autoplay=1';

const userSchema = object({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  email: string().required('Required').email('Must be a valid email'),
  password: string().required('Required').min(7, 'Must be atleast 7 characters long'),
  revenue: string().required('Required'),
  termsAndConditions: boolean().required('Required').equals([true], 'The terms field must be checked'),
});

interface User extends InferType<typeof userSchema> {}

const { handleSubmit } = useForm<User>({
  validationSchema: userSchema,
});

const { value: firstName, errorMessage: firstNameError } = useField('firstName');
const { value: lastName, errorMessage: lastNameError } = useField('lastName');
const { value: email, errorMessage: emailError, handleChange: handleEmailChange } = useField('email');
const { value: password, errorMessage: passwordError, handleChange: handlePasswordChange } = useField('password');
const { value: revenue, errorMessage: revenueError } = useField('revenue');
const { value: termsAndConditions, errorMessage: termsError } = useField<undefined>('termsAndConditions');

const submit = handleSubmit(async (values) => {
  try {
    state.value = PageState.loading;

    // 0, 100K, 500K, 1M, 10B(infinity)
    const yearlyRevenueTiers = [0, 10000000, 50000000, 100000000, 10 ** 10];
    const yearlyRevenue = {
      amountInCentsMin: yearlyRevenueTiers[Number(values.revenue)],
      amountInCentsMax: yearlyRevenueTiers[Number(values.revenue) + 1] - 1,
    };

    const { token } = await signUp({ ...values, revenue: yearlyRevenue });
    auth.signIn(token);
    router.push({ name: Pages.app });

    state.value = PageState.loaded;
  } catch (err: any) {
    state.value = PageState.error;
    apiError.value = err.response?.data?.message;
  }
});
</script>
