<template>
  <FontAwesomeIcon
    :icon="name"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FontAwesomeIcon from './load_fa_icons';

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  props: {
    // the name of the icon is same as the name of the font awesome icon
    // Example: phone, spinner, house
    // Note that it is not using fas or fa used normally'fas fa-phone' or 'fas fa-spinner'
    // fas, fa are assumed
    name: {
      type: String,
      default: '',
    },
  },
});
</script>
