import { AxiosError } from 'axios';
import http, { Http } from '@/lib/http';

interface CancelOrderData {
  orderId: string;
  reason: string;
  projectId: string;
  isFundedCancellation: boolean;
}

function cancelOrderCtor(http: Http) {
  return async (cancellationData: CancelOrderData): Promise<void> => {
    const { projectId, ...payload } = cancellationData;
    try {
      const { data } = await http.post(`/projects/${projectId}/orders/cancel`, {
        ...payload,
      });
      return data;
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };
}

export const cancelOrder = cancelOrderCtor(http);
