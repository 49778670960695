import http, { Http } from '@/lib/http';

export interface UpdateProduct {
  productId: string;
  projectId: string;
  name: string;
  description?: string | null;
  priceInCents: number;
  state: string;
  taxCode?: string;
}

function updateProductCtor(http: Http) {
  return async function updateProduct({ productId, projectId, ...form }: UpdateProduct): Promise<void> {
    await http.patch(`/projects/${projectId}/products/${productId}`, form);
  };
}

export const updateProduct = updateProductCtor(http);
