import http, { Http } from '@/lib/http';

function getLogoUrlCtor(http: Http) {
  return async (projectId: string): Promise<string> => {
    const { data } = await http.get<{ url: string }>(`/projects/${projectId}/logo`);
    return data.url;
  };
}

export const getLogoUrl = getLogoUrlCtor(http);
