export function roleNameAbbr(roleName: string): string {
  const nameToAbbrMap: Record<string, string> = {
    Owner: 'Owner',
    Admin: 'Admin',
    'Sales Manager': 'Sales Mgr',
    'Sales Representative': 'Sales Rep',
    'Support Specialist': 'Sup Spec',
    'View Only': 'View Only',
  };
  return nameToAbbrMap[roleName];
}
