<template>
  <TbLayoutAuth>
    <div class="z-10 flex items-center w-full max-w-5xl p-8 m-auto">
      <SplitCard
        quote="The benefits are clear: more sales and happy students!"
        name="Tim Vipond"
        designation="Founder of Corporate Finance Institute"
        imageName="tim_vipond.svg"
      >
        <div class="p-8">
          <TbLetterLogo
            class="h-20 w-20 m-auto mb-3"
            :inverted="false"
          />
          <div class="mb-3 text-center">
            <h1 class="text-xl sm:text-2xl font-serif-bold">
              Welcome to Elective 🚀
            </h1>

            <p>Sign in below.</p>
          </div>

          <form @submit.prevent="submit">
            <TbInput
              v-model.trim="form.email"
              label="Email*"
              type="email"
              required
            />

            <TbInput
              v-model.trim="form.password"
              label="Password*"
              toggleVisibility
              type="password"
              required
            />

            <FormButton
              text="Sign in"
              :disabled="form.state === PageState.loading"
            />

            <div
              v-if="form.state === PageState.error"
              class="mb-4 text-center text-error"
              data-test="error-div"
            >
              <small>
                {{ form.error }}
              </small>
            </div>
          </form>

          <div class="text-sm text-center">
            <router-link :to="{ name: Page.requestResetPassword }">
              Forgot password?
            </router-link>
          </div>
        </div>
      </SplitCard>
    </div>
  </TbLayoutAuth>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { Pages } from '@/router';
import { PageState } from '@/types';
import { TbLayoutAuth, TbInput, TbLetterLogo } from '@/components/tasty_bistro';
import SplitCard from '@/components/split_card/index.vue';
import FormButton from '@/components/form_button.vue';
import { useRouter } from 'vue-router';
import { Page } from '../../../router/auth';
import { signIn } from './api/sign_in';
import { auth } from '../../../lib/auth';

interface SignInForm {
  email: string;
  password: string;
  error: string;
  state: PageState;
}

const form: SignInForm = reactive({
  email: '',
  password: '',
  error: '',
  state: PageState.loaded,
});

const router = useRouter();

async function submit() {
  try {
    form.state = PageState.loading;

    const { token } = await signIn(form.email, form.password);
    auth.signIn(token);
    router.push({ name: Pages.app });

    form.state = PageState.loaded;
  } catch (err: any) {
    form.state = PageState.error;
    form.error = err.response?.data?.message;
  }
}
</script>
