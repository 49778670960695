<template>
  <div class="text-center w-full">
    <TbWordLogo class="inline-flex max-w-[12rem] pb-12" />
    <div class="pb-12">
      <img
        v-if="!existingUser"
        src="./assets/info.svg"
        alt="Image representing user information"
        class="inline-flex"
      >
      <img
        v-else
        src="./assets/mail_flying.svg"
        class="inline-flex"
        alt="Email being open"
      >
    </div>
    <div class="w-full">
      <p class="welcome-message">
        {{ welcomeMessage }}
      </p>
      <div class="pb-6">
        <p>
          {{ joinMessage }}
        </p>
      </div>
      <button
        class="button button--primary button--md w-full"
        @click="routeToForm(existingUser)"
      >
        <span v-if="!existingUser">
          Get Started
        </span>
        <span v-else>
          Accept Invitation
        </span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable arrow-body-style  */
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { TbWordLogo } from '@/components/tasty_bistro';
import { Pages } from '@/router';
import { InviteInfo } from '../api';

interface Props {
  data: InviteInfo;
}
const props = defineProps<Props>();
const existingUser = computed(() => !!props.data?.userId);

const router = useRouter();
const route = useRoute();

const welcomeMessage = computed(() => {
  return existingUser.value ? `You've been invited to ${props.data.projectName}` : 'Create your Elective account';
});

const joinMessage = computed(() => {
  const message = existingUser.value ? 'Accept this invitation to gain access.' : 'Easily set up your account to get access.';
  return `Join ${props.data.projectName} on Elective. ${message}`;
});

function routeToForm(existingUser: boolean) {
  const page = existingUser ? Pages.inviteExistingUser : Pages.inviteNewUser;
  router.replace({ name: page, query: route.query });
}
</script>

<style scoped lang="scss">
* {
  @apply font-regular;
}
.welcome-message {
  @apply text-primary font-bold text-3xl pb-3;
}
</style>
