import http, { Http } from '@/lib/http';

export interface NewUserInfoParams {
    inviteId: string;
    password: string;
    firstName: string;
    lastName: string;
    confirmationCode: string;
}

function acceptInviteNewUserCtor(http: Http) {
  return async (data: NewUserInfoParams) => {
    await http.post<void>(`/invite/${data.inviteId}/user/new`, data);
  };
}

export const acceptInviteNewUser = acceptInviteNewUserCtor(http);
