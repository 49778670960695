<template>
  <a
    href="mailto:help@elective.com"
    class="text-secondary"
  >
    help@elective.com
  </a>
</template>

<script setup lang="ts">
</script>
