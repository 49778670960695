import http, { Http } from '@/lib/http';

export interface CreateProduct {
  name: string;
  description?: string | null;
  priceInCents: number;
  currencyCode: string;
  state: string;
  taxCode?: string;
}

export interface Response {
  id: string;
}

interface Data {
  projectId: string;
  form: CreateProduct;
}

function createProductCtor(http: Http) {
  return async function createProduct({ projectId, form }: Data): Promise<Response> {
    const response = await http.post(`/projects/${projectId}/products`, form);

    return response.data;
  };
}

export const createProduct = createProductCtor(http);
